import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { CurrencyDto } from '../../../api/types/CurrencyDto'
import { SupplierDto } from '../../../api/types/SupplierData'
import { RootState } from '../../store'
import { ShipperType, UpdateTransferRequest } from '../../../api/types/Transfers'
import transfersApi from '../../../api/TransfersApi'

export interface CreateTransferState {
  status: 'idle' | 'loading' | 'saving' | 'failed';
  reason: string;
  shipperType: ShipperType;
  shipperName?: string;
  shipperId?: number;
  shipper: SupplierDto | null;
  startLocation: SupplierDto | null;
  finishLocation: SupplierDto | null;
  cost?: number;
  currency?: CurrencyDto;
  deliveryDate?: string;
  transferTaskId?: number;
  comment: string;
  responsible: string[];
  pageError?: SerializedError;
  actionError?: SerializedError;
  showConfirmDeleteDialog: boolean;
}

const initialState: CreateTransferState = {
  status: 'idle',
  reason: '',
  shipperType: 'Supplier',
  startLocation: null,
  finishLocation: null,
  shipper: null,
  comment: '',
  responsible: [],
  showConfirmDeleteDialog: false,
}

const sliceName = 'tooling-transfers-create-slice'

export const save = createAsyncThunk(
  `${sliceName}/save`,
  async (data: {
    toolingId: number;
    request: UpdateTransferRequest
  }) => {
    await transfersApi.createTransfer(data.toolingId, data.request)
  },
)

export const createTransferSlice = createSlice({
                                                 name: sliceName,
                                                 initialState,
                                                 reducers: {
                                                   startLoading: (state) => {
                                                     state.status = 'loading'
                                                   },
                                                   successLoading: (state) => {
                                                     state.status = 'idle'
                                                   },
                                                   fail: (state) => {
                                                     state.status = 'failed'
                                                   },
                                                   setReason: (state, action: PayloadAction<string>) => {
                                                     state.reason = action.payload
                                                   },
                                                   setShipperType: (state, action: PayloadAction<ShipperType>) => {
                                                     state.shipperType = action.payload
                                                   },
                                                   setShipper: (state, action: PayloadAction<SupplierDto | null>) => {
                                                     state.shipper = action.payload
                                                   },
                                                   setStartLocation: (
                                                     state, action: PayloadAction<SupplierDto | null>) => {
                                                     state.startLocation = action.payload
                                                   },
                                                   setFinishLocation: (
                                                     state, action: PayloadAction<SupplierDto | null>) => {
                                                     state.finishLocation = action.payload
                                                   },
                                                   setShipperName: (state, action: PayloadAction<string>) => {
                                                     state.shipperName = action.payload
                                                   },
                                                   setCost: (state, action: PayloadAction<number | undefined>) => {
                                                     state.cost = action.payload
                                                   },
                                                   setCurrency: (
                                                     state, action: PayloadAction<CurrencyDto | undefined>) => {
                                                     state.currency = action.payload
                                                   },
                                                   setDeliveryDate: (
                                                     state, action: PayloadAction<string | undefined>) => {
                                                     state.deliveryDate = action.payload
                                                   },
                                                   setTransferTaskId: (state, action: PayloadAction<number>) => {
                                                     state.transferTaskId = action.payload
                                                   },
                                                   setComment: (state, action: PayloadAction<string>) => {
                                                     state.comment = action.payload
                                                   },
                                                   hideActionError: (state) => {
                                                     state.actionError = undefined
                                                   },
                                                   showConfirmDeleteDialog: (state) => {
                                                     state.showConfirmDeleteDialog = true
                                                   },
                                                   hideConfirmDeleteTooling: (state) => {
                                                     state.showConfirmDeleteDialog = false
                                                   },
                                                   clear: (state) => {
                                                     return initialState
                                                   },
                                                 },
                                                 extraReducers: (build) => {
                                                   build.addCase(save.pending, (state, action) => {
                                                     state.status = 'saving'
                                                   })

                                                   build.addCase(save.fulfilled, (state, action) => {
                                                     state.status = 'idle'
                                                   })

                                                   build.addCase(save.rejected, (state, action) => {
                                                     state.status = 'idle'
                                                     state.actionError = action.error
                                                   })
                                                 },
                                               })

export const {
               startLoading,
               successLoading,
               fail,
               setReason,
               setShipperType,
               setShipper,
               setStartLocation,
               setFinishLocation,
               setShipperName,
               setCost,
               setCurrency,
               setDeliveryDate,
               setTransferTaskId,
               setComment,
               hideActionError,
               showConfirmDeleteDialog,
               hideConfirmDeleteTooling,
               clear,
             } = createTransferSlice.actions

export const selectCreateTransfer = (state: RootState) => state.tooling.transfers.create

export default createTransferSlice.reducer
