import { Grid, Button, Card, CardContent, CardActions, CardHeader, Divider } from '@mui/material';
import { FunctionComponent, useRef } from 'react';
import { FileDto } from '../../api/types/Files';
import FilesList from './FilesList';
import { FileState, UploadFile } from '../../store/tooling/files/filesSlice';
import EmptyListHandler from '../../views/components/EmptyRepairsListHandler';
import ErrorStateHandler from '../../views/components/ErrorsStateHandler';
import ListLoadingStateHandler from '../../views/components/LoadingStateHandler';

interface Props {
  isLoading: boolean;
  isFailed: boolean;
  canEdit: boolean;
  files: FileState[];
  uploading: UploadFile[];
  onUpload: (files: FileList) => void;
  onOpen: (file: FileDto) => void;
  onClose: () => void;
  onDelete: (file: FileDto) => void;
}

const FilesContainer: FunctionComponent<Props> = (props) => {
  const uploadButtonRef = useRef<HTMLInputElement>(null);

  const handleAddFile = () => {
    uploadButtonRef.current?.click();
  };

  const handleUpload = (files: FileList | null) => {
    if (files) {
      props.onUpload(files);
    }
  };

  const handleOpenViewer = (file: FileDto) => {
    props.onOpen(file);
  };

  const handleDelete = (file: FileDto) => {
    props.onDelete(file);
  };

  return (
    <Card>
      <CardActions>
        <Grid container direction="row" alignItems="center">
          <Grid item xs>
            <CardHeader title="Files" />
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" disabled={!props.canEdit} onClick={handleAddFile}>
              + Add files
            </Button>
            <input
              type="file"
              hidden
              ref={uploadButtonRef}
              multiple
              onChange={(event) => handleUpload(event.target.files)}
            />
          </Grid>
        </Grid>
      </CardActions>
      <Divider />
      <CardContent>
        <ErrorStateHandler failed={props.isFailed}>
          <ListLoadingStateHandler isLoading={props.isLoading} itemsCount={3} itemHeight={50}>
            <EmptyListHandler itemsCount={props.files.length} emptyText="No files yet">
              <FilesList
                files={props.files}
                uploading={props.uploading}
                canEdit={props.canEdit}
                onOpen={handleOpenViewer}
                onDelete={handleDelete}
              />
            </EmptyListHandler>
          </ListLoadingStateHandler>
        </ErrorStateHandler>
      </CardContent>
    </Card>
  );
};

export default FilesContainer;
