import * as React from 'react';
import { FC } from 'react';
import heic2any from 'heic2any';
import { Typography } from '@mui/material';

interface Props {
  url: string;
}

const HeicViewer: FC<Props> = (props) => {
  const [jpgUrl, setJpgUrl] = React.useState('');

  React.useEffect(() => {
    fetch(props.url)
      .then((res) => res.blob())
      .then((blob) => heic2any({ blob, toType: 'image/jpg' }))
      .then((jpg) => {
        if (jpg instanceof Blob) {
          return URL.createObjectURL(jpg);
        }

        return '';
      })
      .then((url) => setJpgUrl(url));
  }, [props.url]);

  if (jpgUrl === '') {
    return (
      <Typography variant="h3" color="white">
        Converting HEIC to JPG. It may take a few seconds.
      </Typography>
    );
  }

  return <img {...props} src={jpgUrl} width="100%" height="100%" alt="" />;
};

export default HeicViewer;
