import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { CurrencyDto } from '../../api/types/CurrencyDto';
import { OwnerDto } from '../../api/types/OwnerDto';
import { StatusDto } from '../../api/types/StatusDto';
import { StockDto } from '../../api/types/Stocks';
import { SupplierDto } from '../../api/types/SupplierData';
import { ToolingSummaryDto, UpdateToolingStatusDto, UpdateToolingSummaryDto } from '../../api/types/ToolingDto';
import { LocationItem } from '../../views/tooling/components/LocationSelector';
import { RootState } from '../store';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import toolingApi from '../../api/ToolingApi';
import { MaterialDto } from '../../api/types/MaterialDto';

export interface ToolingSummaryEditState {
  status: 'idle' | 'loading' | 'saving' | 'failed';
  toolingNumber?: string;
  description?: string;
  markingNumber?: string;
  toolingStatus?: StatusDto;
  owner?: OwnerDto;
  cost?: number;
  currency?: CurrencyDto;
  actualNumberOfUsages?: number;
  allowableNumberOfUsages?: number;
  material?: MaterialDto;
  location?: LocationItem;
  suppliers: SupplierDto[];
  stocks: StockDto[];
  locations: LocationItem[];
  actionError?: SerializedError;
}

const initialState: ToolingSummaryEditState = {
  status: 'idle',
  suppliers: [],
  locations: [],
  stocks: [],
};

const buildLocations = (state: ToolingSummaryEditState) => {
  let supplierItems = state.suppliers.map((s) => {
    return { id: uuidv4(), type: 'supplier', objectId: s.supplierId, name: s.name } as LocationItem;
  });

  supplierItems = _.sortBy(supplierItems, (i) => i.name);

  let stockItems = state.stocks.map((w) => {
    return { id: uuidv4(), type: 'stock', objectId: w.stockId, name: w.name } as LocationItem;
  });

  stockItems = _.sortBy(stockItems, (i) => i.name);

  state.locations = [...supplierItems, ...stockItems];
};

export const saveSummary = createAsyncThunk(
  'tooling-summary-edit-slice/saveSummary',
  async (data: { toolingId: number | string; summary: UpdateToolingSummaryDto }) => {
    await toolingApi.updateSummary(data.toolingId, data.summary);
  },
);

export const saveStatus = createAsyncThunk(
  'tooling-summary-edit-slice/saveStatus',
  async (data: { toolingId: number | string; status: UpdateToolingStatusDto }) => {
    await toolingApi.updateStatus(data.toolingId, data.status);
  },
);

export const summarySlice = createSlice({
  name: 'tooling-summary-edit-slice',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.status = 'loading';
    },
    successLoading: (state) => {
      state.status = 'idle';
    },
    fail: (state) => {
      state.status = 'failed';
    },
    init: (state, action: PayloadAction<{ summary?: ToolingSummaryDto; suppliers: SupplierDto[] }>) => {
      state.toolingNumber = action.payload.summary?.toolingNumber;
      state.description = action.payload.summary?.description;
      state.markingNumber = action.payload.summary?.markingNumber;
      state.toolingStatus = action.payload.summary?.status;
      state.owner = action.payload.summary?.owner;
      state.cost = action.payload.summary?.cost;
      state.currency = action.payload.summary?.currency;
      state.actualNumberOfUsages = action.payload.summary?.actualNumberOfUsages;
      state.allowableNumberOfUsages = action.payload.summary?.allowableNumberOfUsages;
      state.material = action.payload.summary?.material;

      state.suppliers = [...action.payload.suppliers];

      buildLocations(state);
    },
    setToolingNumber: (state, action: PayloadAction<string | undefined>) => {
      state.toolingNumber = action.payload;
    },
    setDescription: (state, action: PayloadAction<string | undefined>) => {
      state.description = action.payload;
    },
    setMarkingNumber: (state, action: PayloadAction<string | undefined>) => {
      state.markingNumber = action.payload;
    },
    setStatus: (state, action: PayloadAction<StatusDto | undefined>) => {
      state.toolingStatus = action.payload;
    },
    setOwner: (state, action: PayloadAction<OwnerDto | undefined>) => {
      state.owner = action.payload;
    },
    setCost: (state, action: PayloadAction<number | undefined>) => {
      state.cost = action.payload;
    },
    setCurrency: (state, action: PayloadAction<CurrencyDto | undefined>) => {
      state.currency = action.payload;
    },
    setActualNumberOfUsages: (state, action: PayloadAction<number | undefined>) => {
      state.actualNumberOfUsages = action.payload;
    },
    setAllowableNumberOfUsages: (state, action: PayloadAction<number | undefined>) => {
      state.allowableNumberOfUsages = action.payload;
    },
    setStocks: (state, action: PayloadAction<StockDto[]>) => {
      state.stocks = [...action.payload];

      buildLocations(state);
    },
    setLocation: (state, action: PayloadAction<string>) => {
      state.location = state.locations.find((l) => l.id === action.payload);
    },
    hideActionError: (state) => {
      state.actionError = undefined;
    },
    setMaterial: (state, action: PayloadAction<MaterialDto | undefined>) => {
      state.material = action.payload;
    },
  },
  extraReducers: (build) => {
    build.addCase(saveSummary.pending, (state) => {
      state.status = 'saving';
    });

    build.addCase(saveSummary.fulfilled, (state) => {
      state.status = 'idle';
    });

    build.addCase(saveSummary.rejected, (state, action) => {
      state.status = 'idle';
      state.actionError = action.error;
    });
    build.addCase(saveStatus.pending, (state) => {
      state.status = 'saving';
    });

    build.addCase(saveStatus.fulfilled, (state) => {
      state.status = 'idle';
    });

    build.addCase(saveStatus.rejected, (state, action) => {
      state.status = 'idle';
      state.actionError = action.error;
    });
  },
});

export const {
  startLoading,
  successLoading,
  fail,
  init,
  setToolingNumber,
  setDescription,
  setMarkingNumber,
  setStatus,
  setOwner,
  setCost,
  setCurrency,
  setActualNumberOfUsages,
  setAllowableNumberOfUsages,
  setLocation,
  setStocks,
  hideActionError,
  setMaterial,
} = summarySlice.actions;

export const selectToolingEditSummary = (state: RootState) => state.tooling.edit.summary;
export const selectToolingEditStatus = (state: RootState) => state.tooling.edit.summary.toolingStatus;
export const selectToolingLoadEditStatus = (state: RootState) => state.tooling.edit.summary.status;
export const selectToolingEditActionError= (state: RootState) => state.tooling.edit.summary.actionError;

export default summarySlice.reducer;
