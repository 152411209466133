import authService from '../auth/AuthService'
import { API_ADDRESS } from './api'
import { msalFetch } from './graph'
import { UnitToolingDto, ToolingUnitDto } from './types/UnitToolingDto'
import { SetToolingItemDto } from './types/Sets/SetDto'

const UNITS_API_ADDRESS = `${API_ADDRESS}/api/units`

export class UnitsToolingApi {
  async getAssemblyUnitToolings(unitFileId: number) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .get(`${UNITS_API_ADDRESS}/${unitFileId}/tooling`, result.accessToken)
        .then<ToolingUnitDto[]>((res) => res.json())
    }
  }

  async getUnitsToolings(unitFileId: number) {
    const result = await authService.getIdentity()
    if (result) {
      return msalFetch()
        .get(`${UNITS_API_ADDRESS}/tooling?unit=${unitFileId}`, result.accessToken)
        .then<SetToolingItemDto[]>((res) => res.json())
    }
  }
}

const unitsToolingApi = new UnitsToolingApi()

export default unitsToolingApi
