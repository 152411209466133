import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, loginRequest, toolingApiRequest } from './authConfig';

class AuthService {
  private msalInstance: IPublicClientApplication;

  constructor() {
    this.msalInstance = new PublicClientApplication(msalConfig);
  }

  get serviceName() {
    return 'Microsoft';
  }

  get instance() {
    return this.msalInstance;
  }

  signIn() {
    return this.msalInstance.loginRedirect(loginRequest);
  }

  signOut() {
    return this.msalInstance.logoutRedirect();
  }

  async getClientToken() {
    const accounts = this.msalInstance.getAllAccounts();
    const account = accounts[0];
    if (account) {
      try {
        const request = {
          ...loginRequest,
          account,
        };

        const response = await this.msalInstance.acquireTokenSilent(request);
        return response;
        //return new Identity(response);
      } catch (error) {
        // if (error instanceof InteractionRequiredAuthError) {
        //   throw new InteractiveSignInRequired();
        // }
        // if (error instanceof ClientAuthError) {
        //   // On mobile devices, ClientAuthError is sometimes thrown when we
        //   // can't do silent auth - this isn't generally an issue here.
        //   if (error.errorCode === 'block_token_requests') {
        //     throw new InteractiveSignInRequired();
        //   }
        //   console.warn('ClientAuthError: error code = ', error.errorCode);
        // }
        throw error;
      }
    }
    throw new Error('Failed to access');
    //throw new InteractiveSignInRequired();
  }

  async getIdentity() {
    const accounts = this.msalInstance.getAllAccounts();
    const account = accounts[0];
    if (account) {
      try {
        const request = {
          ...toolingApiRequest,
          account,
        };

        const response = await this.msalInstance.acquireTokenSilent(request);
        return response;
        //return new Identity(response);
      } catch (error) {
        // if (error instanceof InteractionRequiredAuthError) {
        //   throw new InteractiveSignInRequired();
        // }
        // if (error instanceof ClientAuthError) {
        //   // On mobile devices, ClientAuthError is sometimes thrown when we
        //   // can't do silent auth - this isn't generally an issue here.
        //   if (error.errorCode === 'block_token_requests') {
        //     throw new InteractiveSignInRequired();
        //   }
        //   console.warn('ClientAuthError: error code = ', error.errorCode);
        // }
        throw error;
      }
    }
    throw new Error('Failed to access');
    //throw new InteractiveSignInRequired();
  }
}

const authService = new AuthService();

export default authService;
