import React, { Fragment, FunctionComponent } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import LoadingTableBody from '../../../components/LoadingTableBody';
import EnhancedTableHead, { HeadCell } from '../../../../components/EnhancedTableHead';
import { ResponsibleDto, ToolingListItemDto } from '../../../../api/types/ToolingDto';
import { SupplierDto } from '../../../../api/types/SupplierData';
import { UnitDto } from '../../../../api/types/UnitData';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { ToolingListResponse } from '../../../../api/types/ToolingListResponse';
import { useAppSelector } from '../../../../store/hooks';
import {
  changePage,
  selectToolingList,
  setRowsPerPage,
  setSortBy,
  setSortOrder,
  SortableHeads,
} from '../../../../store/tooling/toolingListSlice';
import { useDispatch } from 'react-redux';
import UnitInfo from '../../components/UnitInfo';

interface ToolingTableProps {
  status: 'idle' | 'loading';
  items: ToolingListResponse | null;
}

const headers: HeadCell[] = [
  { id: 'toolingNumber', numeric: false, label: 'Tooling number' },
  { id: 'status', numeric: false, label: 'Status' },
  { id: 'owner', numeric: false, label: 'Owner' },
  { id: 'usages', numeric: false, label: 'Usages' },
  { id: 'suppliers', numeric: false, label: 'Suppliers' },
  { id: 'units', numeric: false, label: 'Units' },
  { id: 'responsible', numeric: false, label: 'Responsible' },
];

interface ToolingTableItem {
  toolingId: number;
  toolingNumber: number;
  name: string;
  status: string;
  owner: string;
  actualNumberOfUsages?: number;
  allowableNumberOfUsages?: number;
  usages: string;
  suppliers: SupplierDto[];
  units: UnitDto[];
  responsible: ResponsibleDto[];
}

const convertToViewItem = (tooling: ToolingListItemDto): ToolingTableItem => {
  let item = {} as ToolingTableItem;
  item.toolingId = tooling.toolingId;
  item.toolingNumber = tooling.toolingNumber;
  item.status = tooling.status?.name ?? '';
  item.owner = tooling.owner?.name ?? '';
  item.actualNumberOfUsages = tooling.actualNumberOfUsages;
  item.allowableNumberOfUsages = tooling.allowableNumberOfUsages;
  item.usages = `${
    tooling.actualNumberOfUsages === null || tooling.actualNumberOfUsages === undefined
      ? '?'
      : tooling.actualNumberOfUsages
  }/${
    tooling.allowableNumberOfUsages === null || tooling.allowableNumberOfUsages === undefined
      ? '?'
      : tooling.allowableNumberOfUsages
  }`;
  item.suppliers = tooling.suppliers;
  item.units = tooling.units;
  item.responsible = tooling.responsible;

  return item;
};

const ToolingTable: FunctionComponent<ToolingTableProps> = (props) => {
  const dispatch = useDispatch();
  const toolingListState = useAppSelector(selectToolingList);

  const onRequestSort = (property: SortableHeads) => {
    const isAsc = toolingListState.sortBy === property && toolingListState.sortDirection === 'asc';
    const sortDirection = isAsc ? 'desc' : 'asc';

    dispatch(setSortOrder(sortDirection));
    dispatch(setSortBy(property));
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    dispatch(setRowsPerPage(rowsPerPage));
  };

  const handleChangePage = (page: number) => {
    dispatch(changePage(page));
  };

  return (
    <Fragment>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
        component="div"
        count={props.items?.count ?? 0}
        rowsPerPage={toolingListState.pageSize}
        page={toolingListState.page}
        onPageChange={(event, page) => handleChangePage(page)}
        onRowsPerPageChange={(event) => handleChangeRowsPerPage(parseInt(event.target.value ?? ''))}
      />
      <Table>
        <EnhancedTableHead
          leftColumn={false}
          headers={headers}
          order={toolingListState.sortDirection}
          orderBy={toolingListState.sortBy}
          onRequestSort={(event, property) => onRequestSort(property as SortableHeads)}
        />
        <TableBody>
          {props.status === 'loading' && <LoadingTableBody rows={10} columns={7} />}
          {props.status === 'idle' &&
            props.items &&
            props.items.items
              .map((t) => convertToViewItem(t))
              .map((t) => (
                <TableRow key={t.toolingId}>
                  <TableCell>
                    <Typography variant="textSmMedium">
                      <Link to={`/tooling/${t.toolingId}`}>{t.toolingNumber}</Link>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="textSmRegular">{t.status}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="textSmRegular">{t.owner}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="textSmRegular">{t.usages}</Typography>
                  </TableCell>
                  <TableCell>
                    <ul>
                      {_.sortBy(t.suppliers, (s) => s.name).map((s) => (
                        <li key={s.supplierId}>
                          <Typography variant="textSmRegular">{s.name}</Typography>
                        </li>
                      ))}
                    </ul>
                  </TableCell>
                  <TableCell>
                    <List>
                      {_.sortBy(t.units, (u) => u.elementId).map((u) => (
                        <ListItem key={u.unitFileId}>
                          <ListItemText
                            primary={u.elementId}
                            primaryTypographyProps={{ variant: 'textSmMedium' }}
                            secondary={<UnitInfo unit={u} captionWidth={3} />}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </TableCell>
                  <TableCell>
                    <ul>
                      {_.sortBy(t.responsible, (u) => u.name).map((u) => (
                        <li key={u.userId}>
                          <Typography variant="textSmRegular">{u.name}</Typography>
                        </li>
                      ))}
                    </ul>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
        component="div"
        count={props.items?.count ?? 0}
        rowsPerPage={toolingListState.pageSize}
        page={toolingListState.page}
        onPageChange={(event, page) => handleChangePage(page)}
        onRowsPerPageChange={(event) => handleChangeRowsPerPage(parseInt(event.target.value ?? ''))}
      />
    </Fragment>
  );
};

export default ToolingTable;
