import { FunctionComponent } from 'react'
import { Edit } from '@mui/icons-material'
import {
  Card,
  CardHeader,
  Toolbar,
  Tooltip,
  IconButton,
  Divider,
  CardContent,
  List,
  Skeleton,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from '@mui/material'
import {
  ToolingViewState, selectToolingView, showEditResponsibleDialog, hideEditResponsibleDialog, updateResponsible,
} from '../../../../store/tooling/toolingViewSlice'
import { useAppSelector, useAppDispatch, useAccount } from '../../../../store/hooks'
import GraphAvatar from '../../../../components/GraphAvatar'
import { checkPermission } from '../../../../store/user/userSlice'
import { Permissions_Tooling_Responsibles_Edit } from '../../../../api/types/permissions'
import EditResponsibleDialog from './EditResponsibleDialog'
import { SCRAPPED_STATUS } from '../../../../store/types/statuses'

interface Props {
  toolingId: string | number;
}

const ResponsibleCard: FunctionComponent<Props> = ({ toolingId }) => {
  const toolingViewState: ToolingViewState = useAppSelector(selectToolingView)
  const dispatch = useAppDispatch()

  const canEdit = useAppSelector((root) => checkPermission(root, Permissions_Tooling_Responsibles_Edit))

  const isLoading = toolingViewState.status === 'loading'

  const calcEditPermissions = (haveRight: boolean) => {
    const isScrappedStatus = toolingViewState.tooling?.summary?.status?.name === SCRAPPED_STATUS

    if (isScrappedStatus) {
      return (): boolean => false
    }

    return (): boolean => haveRight
  }

  const canEditResponsilbe = calcEditPermissions(canEdit)

  return (
    <Card>
      <CardHeader
        title="Responsibles"
        titleTypographyProps={{
          variant: 'textLgSemibold',
        }}
        action={
          toolingViewState.status === 'idle' &&
          toolingViewState.tooling?.responsibles && (
            <Toolbar>
              {canEditResponsilbe() && (
                <Tooltip title="Edit responsible">
                  <IconButton onClick={() => dispatch(showEditResponsibleDialog())}>
                    <Edit/>
                  </IconButton>
                </Tooltip>
              )}
            </Toolbar>
          )
        }
      />
      <Divider/>
      <CardContent>
        <List>
          {isLoading ? (
            <Skeleton/>
          ) : (
             toolingViewState.tooling?.responsibles?.map((responsible) => (
               <ListItem key={responsible.userId} disablePadding>
                 <ListItemAvatar>
                   <GraphAvatar userId={responsible.userId}/>
                 </ListItemAvatar>
                 <ListItemText
                   primary={responsible.name}
                   primaryTypographyProps={{ variant: 'textSmMedium' }}
                   secondary={responsible.mail}
                   secondaryTypographyProps={{ variant: 'textSmRegular' }}
                 />
               </ListItem>
             ))
           )}
        </List>
      </CardContent>

      {toolingViewState.tooling?.responsibles && (
        <EditResponsibleDialog
          open={toolingViewState.openResponsibleDialog}
          toolingId={toolingId}
          responsibles={toolingViewState.tooling.responsibles}
          onCancel={() => dispatch(hideEditResponsibleDialog())}
          onOk={(responsibles) => {
            dispatch(hideEditResponsibleDialog())
            dispatch(updateResponsible(responsibles))
          }}
        />
      )}

    </Card>
  )
}

export default ResponsibleCard
