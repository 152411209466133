import { FunctionComponent } from 'react';
import { Skeleton, Typography } from '@mui/material';

interface Props extends React.PropsWithChildren {
  isLoading: boolean;
  loadingWidth?: number;
}

const LoadingTypography: FunctionComponent<Props> = (props) => {
  return props.isLoading ? (
    <Skeleton width={props.loadingWidth} />
  ) : (
    <Typography variant="textSmRegular">{props.children}</Typography>
  );
};

export default LoadingTypography;
