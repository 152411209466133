import { createSlice, SerializedError, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { OrderType } from '../../helpers/tableCompare'
import { RootState } from '../store'
import setsApi from '../../api/SetsApi'
import { SetItemDto } from '../../api/types/Sets/SetDto'

export type SortableHeads = 'toolingNumber' | 'status' | 'owner' | 'usages' | 'suppliers' | 'units' | 'responsible';

export interface SetsListState {
  status: 'idle' | 'loading' | 'failed';

  error?: SerializedError;
  actionError?: SerializedError;
  pageSize: number;
  page: number;
  items?: SetItemDto[],
  count?: number

  sortDirection: OrderType;
  sortBy: SortableHeads;
}

export const loadSets = createAsyncThunk(
  'sets-list-slice/loadSets',
  async (payload: {
    page?: number,
    pageSize?: number,
    setNumber?: string,
    status?: string,
    supplier?: string,
    unit?: string,
    responsible?: string,
  }) => {
    return await setsApi.getSetsList(payload.page, payload.pageSize, payload.setNumber, payload.status, payload.supplier, payload.unit, payload.responsible)
  },
)

export const exportSets = createAsyncThunk(
  'sets-list-slice/exportSets',
  async (payload: {
    page: number,
    pageSize: number,
    setNumber?: string,
    status?: string,
    supplier?: string,
    unit?: string,
    responsible?: string,
  }) => {
    return await setsApi.exportSetsList(payload.page, payload.pageSize, payload.setNumber, payload.status, payload.supplier, payload.unit, payload.responsible)
                        .then((blob) => blob && window.URL.createObjectURL(blob))
  },
)

const initialState: SetsListState = {
  status: 'idle',

  pageSize: Number.parseInt(localStorage.getItem('sets.list.pageSize') ?? '25'),
  page: 0,
  sortDirection: (localStorage.getItem('sets.list.sort.direction') ?? 'asc') as OrderType,
  sortBy: (localStorage.getItem('sets.list.sort.by') ?? 'setNumber') as SortableHeads,
}

export const setsListSlice = createSlice({
                                           name: 'sets-list',
                                           initialState,
                                           reducers: {
                                             setRowsPerPage: (state, action: PayloadAction<number>) => {
                                               state.page = 0
                                               state.pageSize = action.payload

                                               localStorage.setItem('sets.list.pageSize', state.pageSize.toString())
                                             },

                                             clearList: (state) => {
                                               state.items = []
                                             },
                                           },
                                           extraReducers: (build) => {
                                             build.addCase(loadSets.pending, (state) => {
                                               state.status = 'loading'
                                             })

                                             build.addCase(loadSets.fulfilled, (state, action) => {
                                               state.status = 'idle'
                                               state.items = action.payload?.items ?? []
                                               state.page = action.payload?.page ?? 0
                                               state.count = action.payload?.count
                                             })

                                             build.addCase(loadSets.rejected, (state, action) => {
                                               state.status = 'failed'
                                               state.error = action.error
                                             })
                                           },
                                         })

export const { clearList, setRowsPerPage } = setsListSlice.actions

export const selectSetsList = (state: RootState) => state.sets.setsList

export default setsListSlice.reducer
