import {
  createAsyncThunk,
  createSlice,
  type SerializedError,
} from '@reduxjs/toolkit'

import { type RootState } from '../store'
import applicationInformationApi from '../../api/ApplicationInformationApi'

export interface ApplicationInformationState {
  status: 'pending' | 'idle' | 'failed'
  currentToolingApiBuild?: string
  error?: SerializedError
}

const initialState: ApplicationInformationState = { status: 'idle' }

export const loadToolingApplicationInformation = createAsyncThunk('elementConnectApplicationInformation/load', async () => {
  return await applicationInformationApi.getTrApplicationInfo()
})

export const applicationInformationSlice = createSlice({
                                                         name: 'applicationInformation',
                                                         initialState,
                                                         reducers: {},
                                                         extraReducers: (build) => {
                                                           build.addCase(loadToolingApplicationInformation.pending, (
                                                             state, _) => {
                                                             state.status = 'pending'
                                                           })

                                                           build.addCase(loadToolingApplicationInformation.fulfilled, (
                                                             state, action) => {
                                                             state.status = 'idle'
                                                             state.currentToolingApiBuild = action.payload.currentBuild
                                                           })

                                                           build.addCase(loadToolingApplicationInformation.rejected, (
                                                             state, action) => {
                                                             state.status = 'failed'
                                                             state.error = action.error
                                                           })
                                                         },
                                                       })

export const selectApplicationInformation = (state: RootState) => state.applicationInformation

export default applicationInformationSlice.reducer
