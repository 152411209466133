import { FunctionComponent } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';

interface Props {
  text?: string;
}

export type CenteredCircularProgressClassKey = 'root' | 'progress' | 'text';

const CenteredCircularProgress: FunctionComponent<Props> = (props) => {
  return (
    <Box m={2}>
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
        <Grid item>{props && <Typography variant="h3">{props.text}</Typography>}</Grid>
      </Grid>
    </Box>
  );
};

export default CenteredCircularProgress;
