import { FunctionComponent, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { isEmptyOrSpaces } from '../../../../helpers/stringHelper';

interface Props {
  title: string;
  text: string;
  onNo: () => void;
  onYes: (comment: string) => void;
}

const DeleteToolingDialog: FunctionComponent<Props> = (props) => {
  const [comment, setComment] = useState('');
  const [showHelper, setShowHelper] = useState(false);

  const handleChangeComment = (value: string) => {
    setComment(value);
  };

  const handleYesClick = () => {
    if (isEmptyOrSpaces(comment)) {
      setShowHelper(true);
    } else {
      props.onYes(comment);
    }
  };

  return (
    <Dialog open={true} onClose={props.onNo} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <DialogContentText>{props.text}</DialogContentText>
          <TextField
            value={comment}
            label="Comment"
            fullWidth
            required
            error={showHelper}
            helperText={showHelper ? 'Required field' : ''}
            onChange={(e) => handleChangeComment(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onNo}>NO</Button>
        <Button onClick={handleYesClick}>YES</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteToolingDialog;
