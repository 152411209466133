import { Edit } from '@mui/icons-material'
import {
  Card,
  CardHeader,
  Toolbar,
  Tooltip,
  IconButton,
  Divider,
  CardContent,
  Grid,
  Skeleton,
  Typography,
  Link,
} from '@mui/material'
import { Fragment, FunctionComponent } from 'react'
import { Permissions_Tooling_Accounting_Edit } from '../../../../api/types/permissions'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  selectToolingView, showEditAccountingDialog, hideEditAccountingDialog,
} from '../../../../store/tooling/toolingViewSlice'
import { checkPermission } from '../../../../store/user/userSlice'
import PropertyItem from '../PropertyItem'
import { SCRAPPED_STATUS } from '../../../../store/types/statuses'
import EditAccountingDialog from './EditAccountingDialog'

interface Props {
  toolingId: string | number;
}

export const AccountingCard: FunctionComponent<Props> = ({ toolingId }) => {
  const toolingViewState = useAppSelector(selectToolingView)
  const dispatch = useAppDispatch()

  const isLoading = toolingViewState.status === 'loading'

  const canEdit = useAppSelector((root) => checkPermission(root, Permissions_Tooling_Accounting_Edit))

  const calcEditPermissions = (haveRight: boolean) => {
    const isScrappedStatus = toolingViewState.tooling?.summary?.status?.name === SCRAPPED_STATUS

    if (isScrappedStatus) {
      return (): boolean => false
    } else {
      return (): boolean => {
        return haveRight
      }
    }
  }

  const canEditAcc = calcEditPermissions(canEdit)()

  return (
    <Card
      sx={{
        mt: 5,
      }}
    >
      <CardHeader
        title="Accounting"
        titleTypographyProps={{
          variant: 'textLgSemibold',
        }}
        action={
          toolingViewState.status === 'idle' &&
          toolingViewState.tooling?.technicalInfo && (
            <Toolbar>
              {canEditAcc && (
                <Tooltip title="Edit accountig information">
                  <IconButton onClick={() => dispatch(showEditAccountingDialog())}>
                    <Edit/>
                  </IconButton>
                </Tooltip>
              )}
            </Toolbar>
          )
        }
      />
      <Divider/>
      <CardContent>
        <Grid container direction="column" spacing={1}>
          <PropertyItem caption="Accounting number">
            {isLoading ? (
              <Skeleton width={100}/>
            ) : (
               <Typography variant="textSmRegular">{toolingViewState.tooling?.accounting?.accountingNumber}</Typography>
             )}
          </PropertyItem>
          <PropertyItem caption="Accounting date">
            {isLoading ? (
              <Skeleton width={100}/>
            ) : toolingViewState.tooling?.accounting?.accountingDate ? (
              <Typography variant="textSmRegular">
                {new Date(toolingViewState.tooling?.accounting?.accountingDate).toLocaleDateString()}
              </Typography>
            ) : (
                  ''
                )}
          </PropertyItem>
          <PropertyItem caption="Scrapped date">
            {isLoading ? (
              <Skeleton width={100}/>
            ) : toolingViewState.tooling?.accounting?.scrappingDate ? (
              <Typography variant="textSmRegular">
                {new Date(toolingViewState.tooling?.accounting?.scrappingDate).toLocaleDateString()}
              </Typography>
            ) : (
                  ''
                )}
          </PropertyItem>
          <PropertyItem caption="Depreciation as a fixed asset">
            {isLoading ? (
              <Skeleton width={50}/>
            ) : toolingViewState.tooling?.accounting?.accountingDepreciationAsFixedAsset ? (
              <Typography variant="textSmRegular">Yes</Typography>
            ) : (
                  <Typography variant="textSmRegular">No</Typography>
                )}
          </PropertyItem>
          {toolingViewState.tooling?.accounting?.productionTask && (
            <Fragment>
              <Grid item>
                <Divider sx={{ my: 6 }}/>
              </Grid>
              <Grid item>
                <Typography
                  variant="textMdMedium"
                  gutterBottom
                  sx={{
                    mt: 2,
                  }}
                >
                  Production task
                </Typography>
              </Grid>
              <Grid item container direction="column">
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12} sm={6}>
                    <Typography>
                      <Link
                        href={toolingViewState.tooling.accounting.productionTask.url}
                        variant="textSmRegular"
                        target="_blank"
                      >
                        {toolingViewState.tooling.accounting.productionTask.name}
                      </Link>
                    </Typography>
                  </Grid>
                  <PropertyItem caption="Status">
                    {isLoading ? (
                      <Skeleton width={50}/>
                    ) : (
                       <Typography variant="textSmRegular">
                         {toolingViewState.tooling.accounting.productionTask.status}
                       </Typography>
                     )}
                  </PropertyItem>
                </Grid>
                <Grid item container xs>
                  <PropertyItem caption="Deadline">
                    {isLoading ? (
                      <Skeleton width={50}/>
                    ) : (
                       <Typography variant="textSmRegular">
                         {new Date(toolingViewState.tooling.accounting.productionTask.deadline).toLocaleString()}
                       </Typography>
                     )}
                  </PropertyItem>
                  <PropertyItem caption="Completed date">
                    {isLoading ? (
                      <Skeleton width={50}/>
                    ) : toolingViewState.tooling.accounting.productionTask.completed ? (
                      <Typography variant="textSmRegular">
                        {new Date(toolingViewState.tooling.accounting.productionTask.completed).toLocaleString()}
                      </Typography>
                    ) : (
                          <Typography variant="textSmRegular"></Typography>
                        )}
                  </PropertyItem>
                </Grid>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </CardContent>
      <EditAccountingDialog
        open={toolingViewState.openAccountingDialog}
        toolingId={toolingId}
        accounting={toolingViewState.tooling?.accounting}
        onClose={() => dispatch(hideEditAccountingDialog())}
      />
    </Card>
  )
}
