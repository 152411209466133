import { combineReducers } from '@reduxjs/toolkit';
import list from './repairsSlice';
import properties from './editRepairSlice';
import create from './createRepairSlice';
import files from './filesSlice';

export const repairs = combineReducers({
  list,
  edit: combineReducers({ properties, files }),
  create,
});
