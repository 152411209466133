import { FunctionComponent } from 'react';
import { Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Tooltip } from '@mui/material';
import _ from 'lodash';
import GraphAvatar from '../../../components/GraphAvatar';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { removeUser, selectEditRole } from '../../../store/administration/roleSlice';
import { Clear, Delete, Remove, RemoveCircleOutline } from '@mui/icons-material';

const RoleUsersList: FunctionComponent = () => {
  const roleState = useAppSelector(selectEditRole);
  const dispatch = useAppDispatch();

  const handleRemoveUser = (userId: string) => {
    roleState.roleId && dispatch(removeUser({ roleId: roleState.roleId, userId }));
  };

  if (roleState.status === 'loading') {
    return (
      <List>
        {Array.from(Array(5).keys()).map((i) => {
          return (
            <ListItem key={i} divider>
              <ListItemAvatar>
                <Skeleton variant="circular" width={40} height={40} />
              </ListItemAvatar>
              <Box sx={{ width: '100%' }}>
                <Skeleton />
              </Box>
            </ListItem>
          );
        })}
      </List>
    );
  }

  return (
    <List>
      {_.sortBy(roleState.users, (u) => u.displayName ?? '').map((u) => {
        return (
          <ListItem
            key={u.userId}
            disabled={u.status === 'saving'}
            secondaryAction={
              <Tooltip title="Remove user from role">
                <IconButton disabled={u.status === 'saving'} onClick={() => handleRemoveUser(u.userId)}>
                  <Clear />
                </IconButton>
              </Tooltip>
            }
          >
            <ListItemAvatar>
              <GraphAvatar userId={u.userId} />
            </ListItemAvatar>
            <ListItemText primary={u.displayName ?? ''} />
          </ListItem>
        );
      })}
    </List>
  );
};

export default RoleUsersList;
