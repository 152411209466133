import { combineReducers } from '@reduxjs/toolkit'
import list from './transfersSlice'
import properties from './editTransferSlice'
import create from './createTransferSlice'
import files from './filesSlice'

export const transfers = combineReducers({
                                           list,
                                           edit: combineReducers({ properties, files }),
                                           create,
                                         })
