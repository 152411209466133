import { Close } from '@mui/icons-material';
import { Backdrop, Box, Button, Container, Grid, IconButton, Typography } from '@mui/material';
import { FC, Fragment } from 'react';
import { FileDto } from '../../api/types/Files';
import AuthorizedViewer from './AuthorizedViewer';
import LoadingStateHandler from './LoadingStateHandler';

interface Props {
  open: boolean;
  loading: boolean;
  file?: FileDto;
  url?: string;
  downloadUrl?: string;
  onClose: () => void;
}

const BackdropViewer: FC<Props> = (props) => {
  const handleDownload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    if (props.downloadUrl) {
      const link = document.createElement('a');
      link.href = props.downloadUrl;
      link.setAttribute('download', props.file?.name ?? 'file');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (!props.open) {
    return <Fragment />;
  }

  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, root: { backgroundColor: (theme) => theme.palette.grey[800] } }}
      open={true}
      onClick={() => props.onClose()}
    >
      <Container style={{ height: '100%', position: 'absolute' }}>
        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            <Typography variant="h5" sx={{ color: 'white', textAlign: 'center' }}>
              {props.file?.name}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton sx={{ color: 'white' }}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            maxHeight: 'calc(100% - 210px)',
            overflow: 'auto',
          }}
        >
          <LoadingStateHandler isLoading={props.loading || !props.file || !props.url}>
            <AuthorizedViewer
              contentType={props.file?.type ?? ''}
              src={props.url}
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          </LoadingStateHandler>
        </Box>

        {props.downloadUrl && (
          <Box height="210px" display="flex" justifyContent="center" alignItems="center">
            <Button variant="contained" color="primary" onClick={handleDownload}>
              Download
            </Button>
          </Box>
        )}
      </Container>
    </Backdrop>
  );
};

export default BackdropViewer;
