import { FunctionComponent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import toolingApi from '../../../../api/ToolingApi';
import { useAppDispatch } from '../../../../store/hooks';
import { load } from '../../../../store/tooling/toolingViewSlice';
import { SupplierDto } from '../../../../api/types/SupplierData';
import MultipleSuppliersAutocomplete from '../../components/MultipleSuppliersAutocomplete';

interface Props {
  toolingId: number | string;
  open: boolean;
  onClose: () => void;
  suppliers: SupplierDto[];
}

const EditSuppliersDialog: FunctionComponent<Props> = (props) => {
  const [saving, setSaving] = useState(false);
  const [suppliers, setSuppliers] = useState<SupplierDto[] | undefined>(undefined);

  useEffect(() => {
    if (props.open) {
      setSuppliers(props.suppliers);
    }
  }, [props.open, props.suppliers]);

  const dispatch = useAppDispatch();

  const onUpdate = () => {
    setSaving(true);

    if (suppliers) {
      toolingApi
        .updateSupplier(props.toolingId, suppliers)
        .then((res) => {
          dispatch(load(props.toolingId));
          setSaving(false);
          props.onClose();
        })
        .catch((e) => {
          setSaving(false);
          console.error(e);
        });
    }
  };

  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle>Tooling suppliers</DialogTitle>
      <Divider />
      <DialogContent>
        <MultipleSuppliersAutocomplete
          sx={{ mt: 2 }}
          disabled={saving}
          suppliers={suppliers}
          error={!suppliers || suppliers.length === 0}
          helperText={suppliers && suppliers.length > 0 ? undefined : 'Supplier cannot by empty'}
          onSelectSupplier={(newSupplier) => setSuppliers(newSupplier)}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={saving} onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          variant="contained"
          disabled={saving || !suppliers || suppliers.length === 0}
          onClick={() => onUpdate()}
        >
          {saving ? <CircularProgress size={20} /> : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSuppliersDialog;
