import { FunctionComponent } from 'react';
import { UploadFile } from '../../store/tooling/files/filesSlice';
import { Error, PlayArrow } from '@mui/icons-material';

interface UploadFileIconProps {
  file: UploadFile;
}

const UploadFileIcon: FunctionComponent<UploadFileIconProps> = (props) => {
  if (props.file.status === 'failed') {
    return <Error sx={{ color: (theme) => theme.palette.error.main }} />;
  }

  return <PlayArrow />;
};

export default UploadFileIcon;
