import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { FunctionComponent } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { selectToolingOrders } from '../../../../../store/tooling/orders/ordersSlice';

interface Props {
  toolingId: number;
}

const OrdersList: FunctionComponent<Props> = (props) => {
  const ordersState = useAppSelector(selectToolingOrders);

  return (
    <List disablePadding>
      {ordersState.orders.map((order) => (
        <ListItem key={order.orderId} disableGutters divider>
          <ListItemButton
            component="a"
            href={`https://scm.miningelement.com/crm/deal/details/${order.orderId}/`}
            target="_blank"
          >
            <ListItemText
              primary={order.name}
              primaryTypographyProps={{ color: 'primary' }}
              secondary={order.date ? new Date(order.date).toLocaleDateString() : ''}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default OrdersList;
