export const Permissions_Tooling_CreateDuplicate = 'Permissions.Tooling.CreateDuplicate'
export const Permissions_Tooling_Delete = 'Permissions.Tooling.Delete'
export const Permissions_Tooling_Responsibles_Edit = 'Permissions.Tooling.Responsibles.Edit'
export const Permissions_Tooling_Accounting_Edit = 'Permissions.Tooling.Accounting.Edit'
export const Permissions_Tooling_Summary_EditLocation = 'Permissions.Tooling.Summary.EditLocation'
export const Permissions_Tooling_Summary_EditActualNumberOfUsages =
               'Permissions.Tooling.Summary.EditActualNumberOfUsages'
export const Permissions_Materials_Edit = 'Permissions.Materials.Edit'
export const Permissions_Administration_Edit = 'Permissions.Administration.Edit'
export const Permissions_Repairs_EditAny = 'Permissions.Repairs.EditAny'
export const Permissions_Transfers_EditAny = 'Permissions.Transfers.EditAny'
export const Permissions_Tooling_Files_Edit = 'Permissions.Tooling.Files.Edit'
export const Permissions_Tooling_Comment_Edit = 'Permissions.Tooling.Comment.Edit'
export const Permissions_Tooling_Edit = 'Permissions.Tooling.Edit'
export const Permissions_Tooling_Summary_Edit = 'Permissions.Tooling.Summary.Edit'
export const Permissions_Tooling_Status_Edit = 'Permissions.Tooling.Status.Edit'
export const Permissions_Tooling_Scrape = 'Permissions.Tooling.Scrape'
export const Permissions_Tooling_DraftDelete = 'Permissions.Tooling.DraftDelete'
export const Permissions_Tooling_Units_Edit = 'Permissions.Tooling.Units.Edit'
export const Permissions_Tooling_Suppliers_Edit = 'Permissions.Tooling.Suppliers.Edit'
export const Permissions_Tooling_TechnicalInformation_Edit = 'Permissions.Tooling.TechnicalInformation.Edit'
export const Permissions_Sets_View = 'Permissions.Sets.View'
export const Permissions_Sets_Edit = 'Permissions.Sets.Edit'
export const Permissions_Sets_Delete = 'Permissions.Sets.Delete'
