import './App.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from './auth/authConfig';
import { InteractionStatus } from '@azure/msal-browser';
import UnauthenticatedView from './components/Unauthenticated';
import AuthenticatedView from './components/AuthenticatedView';
import React from 'react';

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginRequest);
  }

  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        <AuthenticatedView />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedView />
      </UnauthenticatedTemplate>
    </React.Fragment>
  );
}

export default App;
