import { AccountCircle } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

const UnauthenticatedView: FunctionComponent = () => {
  return (
    <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center" sx={{ height: '100vh' }}>
      <Grid item container direction="row" spacing={1} justifyContent="center" alignItems="center">
        <Grid item>
          <AccountCircle color="success" fontSize="large" />
        </Grid>
        <Grid item>
          <Typography variant="h4" align="center" color="GrayText">
            Checking your permissions
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UnauthenticatedView;
