import { combineReducers } from '@reduxjs/toolkit';
import usersList from './usersListSlice';
import rolesList from './rolesListSlice';
import editRole from './roleSlice';
import selectUsers from './selectRoleUsersSlice';
import editRights from './editRoleRightsSlice';

export const administration = combineReducers({
  users: combineReducers({
    list: usersList,
  }),
  roles: combineReducers({
    list: rolesList,
    edit: combineReducers({
      editRole,
      selectUsers,
      editRights,
    }),
  }),
});
