import { FunctionComponent } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFoundPage: FunctionComponent = () => {
  const navigate = useNavigate()
  return (
    <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '90vh' }}
    >
      <Grid item>
        <Typography variant="h1" align="center">
          404
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h4" align="center">
          This page could not be found
        </Typography>
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={() => navigate('/')}>
          Back to Home
        </Button>
      </Grid>
    </Grid>
  );
};

export default NotFoundPage;
