import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns'
import { Button, Grid, Skeleton, TextField } from '@mui/material'
import { FunctionComponent, useEffect, useState } from 'react'
import { CurrencyDto } from '../../../../api/types/CurrencyDto'
import { SupplierDto } from '../../../../api/types/SupplierData'
import { loadCurrencies, selectCurrencies } from '../../../../store/currencies/currenciesSlice'
import { useAppSelector, useAppDispatch } from '../../../../store/hooks'
import TextEditPropertyItem from '../../../components/TextEditPropertyItem'
import CurrencySelector from '../../components/CurrencySelector'
import ShipperGridItem from '../components/ShipperGridItem'
import ReasonGridItem from '../components/ReasonGridItem'
import { hasCostError, hasCurrencyError, hasReasonError, hasCommonShipperError, hasShipperError } from './validation'
import { ShipperType } from '../../../../api/types/Transfers'
import SupplierAutocomplete from '../../components/SupplierAutocomplete'
import { useRepairPermissions, useTransferPermissions } from '../../view/tabs/operation/hooks'
import { selectToolingView } from '../../../../store/tooling/toolingViewSlice'
import { selectToolingTransfers } from '../../../../store/tooling/transfers/transfersSlice'

interface Props {
  toolingId: number;
  canEdit: boolean;
  reason: string;
  shipperType: ShipperType;
  shipper?: SupplierDto | null;
  startLocation?: SupplierDto | null;
  finishLocation?: SupplierDto | null;
  shipperName?: string;
  cost?: number;
  currency?: CurrencyDto;
  deliveryDate?: string;
  transferTaskId?: number;
  comment: string;
  isLoading: boolean;
  isSaving: boolean;

  onChangeReason: (reason: string) => void;
  onChangeShipperType: (type: ShipperType) => void;
  onChangeShipperName: (providerName: string) => void;
  onChangeShipper: (shipper: SupplierDto | null) => void;
  onChangeStartLocation: (location: SupplierDto | null) => void;
  onChangeFinishLocation: (location: SupplierDto | null) => void;
  onChangeCost: (cost: string) => void;
  onChangeCurrency: (currency?: CurrencyDto) => void;
  onChangeDeliveryDate: (deliveryDate?: string) => void;
  onChangeTransferTaskId: (transferTaskId?: number) => void;
  onChangeComment: (comment: string) => void;
  onSave: () => void;
}

const EditTransferContent: FunctionComponent<Props> = (props) => {
  const currenciesState = useAppSelector(selectCurrencies)
  const dispatch = useAppDispatch()
  const [hasErrors, setHasErrors] = useState(false)

  useEffect(() => {
    dispatch(loadCurrencies())
  }, [dispatch])

  useEffect(() => {
    const errors =
            hasReasonError(props.reason) ||
            hasCommonShipperError(props.shipperType, props.shipper, props.shipperName) ||
            hasCostError(props.cost) ||
            hasCurrencyError(props.currency)
    setHasErrors(errors)
  }, [props.cost, props.currency, props.shipperName, props.shipperType, props.reason, props.shipper])

  const handleChangeDeliveryDate = (date: Date | null) => {
    if (date) {
      const utcDate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
      props.onChangeDeliveryDate(new Date(utcDate).toISOString())
    } else {
      props.onChangeDeliveryDate(undefined)
    }
  }
  return (
    <Grid container spacing={2}>
      <ReasonGridItem
        reason={props.reason}
        isLoading={props.isLoading}
        disabled={props.isSaving || !props.canEdit}
        onChange={(value) => props.onChangeReason(value)}
      />
      <ShipperGridItem
        shipperType={props.shipperType}
        shipperName={props.shipperName ?? ''}
        shipper={props.shipper ?? null}
        isLoading={props.isLoading}
        disabled={props.isSaving || !props.canEdit}
        onChangeShipperType={(value) => props.onChangeShipperType(value)}
        onChangeShipperName={(value) => props.onChangeShipperName(value)}
        onChangeShipper={(value) => props.onChangeShipper(value)}
      />
      <Grid item xs={12}>
        {props.isLoading ? (
          <Skeleton/>
        ) : (
           <SupplierAutocomplete
             supplier={props.startLocation ?? null}
             disabled={props.isSaving || !props.canEdit}
             error={hasShipperError(props.startLocation ?? null)}
             label="Start location"
             helperText={hasShipperError(props.startLocation ?? null) ? 'Start location cannot by empty' : undefined}
             noOptionsText="No locations"
             onSelectSupplier={(value) => props.onChangeStartLocation(value)}
           />
         )}
      </Grid>
      <Grid item xs={12}>
        {props.isLoading ? (
          <Skeleton/>
        ) : (
           <SupplierAutocomplete
             supplier={props.finishLocation ?? null}
             disabled={props.isSaving || !props.canEdit}
             error={hasShipperError(props.finishLocation ?? null)}
             label="Finish location"
             helperText={hasShipperError(props.finishLocation ?? null) ? 'Finish location cannot by empty' : undefined}
             noOptionsText="No locations"
             onSelectSupplier={(value) => props.onChangeFinishLocation(value)}
           />
         )}
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs>
          {props.isLoading ? (
            <Skeleton/>
          ) : (
             <TextField
               fullWidth
               label="Cost (VAT included)"
               type="number"
               value={props.cost ?? ''}
               disabled={props.isSaving || !props.canEdit}
               error={hasCostError(props.cost)}
               helperText={hasCostError(props.cost) ? 'Cost must be greater than zero' : ''}
               onChange={(event) => props.onChangeCost(event.target.value)}
             />
           )}
        </Grid>
        <Grid item xs={4}>
          {props.isLoading ? (
            <Skeleton/>
          ) : (
             <CurrencySelector
               currencies={currenciesState.currencies}
               currentCurrency={props.currency}
               disabled={props.isSaving || !props.canEdit}
               error={hasCurrencyError(props.currency)}
               helperText={hasCurrencyError(props.currency) ? 'Currency is required' : ''}
               onChangeCurrency={(value) => props.onChangeCurrency(value)}
             />
           )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {props.isLoading ? (
          <Skeleton/>
        ) : (
           <LocalizationProvider dateAdapter={DateAdapter}>
             <DesktopDatePicker
               label="Delivery date"
               format="dd/MM/yyyy"
               value={props.deliveryDate ? new Date(props.deliveryDate) : null}
               disabled={props.isSaving || !props.canEdit}
               slotProps={{ textField: { fullWidth: true } }}
               onChange={handleChangeDeliveryDate}
             />
           </LocalizationProvider>
         )}
      </Grid>
      <Grid item xs={12}>
        <TextEditPropertyItem
          caption="Transfer task"
          value={props.transferTaskId ?? ''}
          isLoading={props.isLoading}
          disabled={props.isSaving || !props.canEdit}
          onChange={(value) => {
            if (value === '') {
              props.onChangeTransferTaskId(undefined)
              return
            }
            props.onChangeTransferTaskId(+value)
          }}
        />
      </Grid>

      <Grid item xs={12}>
        {props.isLoading ? (
          <Skeleton/>
        ) : (
           <TextField
             multiline
             fullWidth
             label="Comment"
             value={props.comment}
             disabled={props.isSaving || !props.canEdit}
             onChange={(event) => props.onChangeComment(event.target.value)}
           />
         )}
      </Grid>
      <Grid item xs={12} container justifyContent="end">
        <Grid item>
          <Button
            variant="contained"
            disabled={props.isLoading || props.isSaving || hasErrors || !props.canEdit}
            onClick={() => props.onSave()}
          >
            Save changes
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EditTransferContent
