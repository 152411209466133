import { FunctionComponent, useEffect } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { ResponsibleDto } from '../../../../api/types/ToolingDto'
import ErrorBox from '../../../../components/ErrorBox'
import {
  init,
  selectToolingEditResponsible,
  removeUser,
  addUser,
  saveResponsibles,
  hideActionError,
} from '../../../../store/tooling/responsibleSlice'
import { Clear } from '@mui/icons-material'
import _ from 'lodash'
import GraphAvatar from '../../../../components/GraphAvatar'
import UsersAutocomplete from './UsersAutocomplete'
import { GraphUser } from '../../../../api/types/graph/graph'
import ErrorDialog from '../../../../components/ErrorDialog'

interface Props {
  toolingId: string | number;
  responsibles: ResponsibleDto[];
  open: boolean;
  onCancel: () => void;
  onOk: (responsibles: ResponsibleDto[]) => void;
}

const EditResponsibleDialog: FunctionComponent<Props> = (props) => {
  const state = useAppSelector(selectToolingEditResponsible)
  const dispatch = useAppDispatch()

  const isLoading = state.status === 'loading'
  const isSaving = state.status === 'saving'
  const isFail = state.status === 'failed'

  useEffect(() => {
    if (props.open) {
      dispatch(init(props.responsibles))
    }
  }, [dispatch, props.open, props.responsibles])

  const handleAddUser = (user: GraphUser) => {
    dispatch(addUser({ userId: user.id, name: user.displayName, mail: user.mail } as ResponsibleDto))
  }

  const handleUpdate = () => {
    dispatch(saveResponsibles({ toolingId: props.toolingId, responsibles: state.responsibles.map((u) => u.userId) }))
      .unwrap()
      .then((responsibles) => props.onOk(responsibles))
      .catch(() => {
      })
  }

  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle>
        <Typography>Responsibles</Typography>
      </DialogTitle>
      <Divider/>
      <DialogContent>
        <UsersAutocomplete alreadySelected={state.responsibles.map((u) => u.userId)} onSelectUnit={handleAddUser}/>
        {isFail && <ErrorBox code="500" description="Error while loading data"/>}
        {!isFail && <ResponsibleList disabled={isSaving} responsibles={state.responsibles}/>}

        <ErrorDialog
          open={state.actionError !== undefined}
          title={state.actionError?.name || 'Error'}
          text={`Error while saving data. Please try again later or contact the site administrator.\n${state.actionError?.message}`}
          onOk={() => dispatch(hideActionError())}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSaving} onClick={() => props.onCancel()}>
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }}/>
        <Button variant="contained" disabled={isSaving || isLoading || isFail} onClick={handleUpdate}>
          {isSaving ? <CircularProgress size={20}/> : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface ResponsibleListProps {
  responsibles: ResponsibleDto[];
  disabled?: boolean;
}

const ResponsibleList: FunctionComponent<ResponsibleListProps> = (props) => {
  const dispatch = useAppDispatch()

  const handleRemoveUser = (userId: string) => {
    dispatch(removeUser(userId))
  }

  if (props.responsibles.length === 0) {
    return (
      <Typography textAlign={'center'} color={(theme) => theme.palette.text.secondary} sx={{ mt: 1 }}>
        No responsibles
      </Typography>
    )
  }

  return (
    <List>
      {_.sortBy(props.responsibles, (u) => u.name).map((u) => {
        return (
          <ListItem
            key={u.userId}
            secondaryAction={
              <Tooltip title="Remove user">
                <IconButton disabled={props.disabled} onClick={() => handleRemoveUser(u.userId)}>
                  <Clear/>
                </IconButton>
              </Tooltip>
            }
          >
            <ListItemAvatar>
              <GraphAvatar userId={u.userId}/>
            </ListItemAvatar>
            <ListItemText primary={u.name ?? ''}/>
          </ListItem>
        )
      })}
    </List>
  )
}

export default EditResponsibleDialog
