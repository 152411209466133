import { Chip, FormControl, Grid, InputLabel, MenuItem, Select, Skeleton, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { SxProps, Theme } from '@mui/system';
import _ from 'lodash';

interface Props {
  isLoading?: boolean;
  locations: LocationItem[];
  selectedLocation?: LocationItem;
  onChange: (locationId: string) => void;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  error?: boolean;
  size?: 'small' | 'medium';
}

export interface LocationItem {
  id: string;
  type: 'supplier' | 'stock';
  objectId: number;
  name: string;
}

const LocationSelector: FunctionComponent<Props> = (props) => {
  return props.isLoading ? (
    <Skeleton width={200} />
  ) : (
    <FormControl fullWidth sx={props.sx} size={props.size}>
      <InputLabel id="location-label">Location</InputLabel>
      <Select
        labelId="location-label"
        label="Location"
        disabled={props.disabled}
        error={props.error}
        value={props.selectedLocation?.id ?? ''}
        onChange={(event) => {
          props.onChange(event.target.value);
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {props.locations.map((o) => (
          <MenuItem key={o.id} value={o.id}>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <Chip label={o.type === 'supplier' ? 'Supplier' : 'Stock'} />
              </Grid>
              <Grid item>
                <Typography>{o.name}</Typography>
              </Grid>
            </Grid>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LocationSelector;
