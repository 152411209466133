import { combineReducers } from '@reduxjs/toolkit'
import setsList from './setsListSlice'
import setsFilters from './setsFiltersSlice'
import currentItem from './currentItemSlice'
import createNewSet from './createNewSetDialogSlice'
import currentItemHistory from './history/historySlice'
import orders from './orders/ordersSlice'

export const sets = combineReducers({
                                      setsList,
                                      setsFilters,
                                      currentItem,
                                      createNewSet,
                                      currentItemHistory,
                                      orders,
                                    })
