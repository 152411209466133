import { FunctionComponent } from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

interface Props extends React.PropsWithChildren {
  code?: string;
  description?: string;
  sx?: SxProps<Theme>;
}

const ErrorBox: FunctionComponent<Props> = (props) => {
  return (
    <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center" sx={props.sx}>
      <Grid item>
        <Typography variant="h1" align="center">
          {props.code || ''}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h4" align="center">
          {props.description || ''}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" align="center" sx={{ color: (theme) => theme.palette.text.secondary }}>
          Notification sent to administrator
        </Typography>
      </Grid>
      <Grid item>{props.children}</Grid>
    </Grid>
  );
};

export default ErrorBox;
