import { ArrowBackIos } from '@mui/icons-material'
import { Box, Grid, IconButton, Skeleton, Tooltip, Typography, Stack } from '@mui/material'
import { FunctionComponent, PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'

interface Props extends PropsWithChildren {
  title: string;
  isLoading: boolean;
  backPath?: string;
}

const SetPageToolbar: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate()

  return (
    <Box mb={2}>
      <Stack justifyContent="space-between" direction="row">
        <Grid container spacing={2} alignItems="center">
          {props.backPath &&
            <Grid item>
              <Tooltip title="Back">
                <IconButton onClick={() => navigate(props.backPath ?? `/sets`)}>
                  <ArrowBackIos/>
                </IconButton>
              </Tooltip>
            </Grid>}
          <Grid item>
            <Typography variant="h5">{props.isLoading ? <Skeleton width={150}/> : props.title}</Typography>
          </Grid>
        </Grid>
        <Stack spacing={2} direction={'row'}>
          {props.children}
        </Stack>
      </Stack>
    </Box>
  )
}

export default SetPageToolbar
