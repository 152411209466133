import { FunctionComponent } from 'react'
import { Box, Link, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@mui/material'
import TextureIcon from '@mui/icons-material/Texture'
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard'
import { NavLink } from 'react-router-dom'
import { AssignmentInd, Group } from '@mui/icons-material'
import { useAppSelector } from '../store/hooks'
import { checkPermission } from '../store/user/userSlice'
import { Permissions_Administration_Edit, Permissions_Sets_Edit, Permissions_Sets_View } from '../api/types/permissions'
import AccountTreeIcon from '@mui/icons-material/AccountTree'

const MenuContent: FunctionComponent = () => {
  const canAdministration = useAppSelector((root) => checkPermission(root, Permissions_Administration_Edit))
  const canViewSets = useAppSelector((root) => checkPermission(root, Permissions_Sets_View))

  return (
    <Box>
      <List>
        <Link component={NavLink} to="/tooling" sx={{ textDecoration: 'none' }}>
          <ListItem button>
            <ListItemIcon>
              <DeveloperBoardIcon/>
            </ListItemIcon>
            <ListItemText primary="Tooling"/>
          </ListItem>
        </Link>

        {canViewSets && <Link component={NavLink} to="/sets" sx={{ textDecoration: 'none' }}>
          <ListItem button>
            <ListItemIcon>
              <AccountTreeIcon/>
            </ListItemIcon>
            <ListItemText primary="Sets"/>
          </ListItem>
        </Link>
        }
        <List subheader={<ListSubheader component="div">Library</ListSubheader>}>
          <Link component={NavLink} to="/materials" sx={{ textDecoration: 'none' }}>
            <ListItem button>
              <ListItemIcon>
                <TextureIcon/>
              </ListItemIcon>
              <ListItemText primary="Materials"/>
            </ListItem>
          </Link>
        </List>

        {canAdministration && (
          <List subheader={<ListSubheader component="div">Administration</ListSubheader>}>
            <Link component={NavLink} to="/administration/users" sx={{ textDecoration: 'none' }}>
              <ListItem button>
                <ListItemIcon>
                  <Group/>
                </ListItemIcon>
                <ListItemText primary="Users"/>
              </ListItem>
            </Link>
            <Link component={NavLink} to="/administration/roles" sx={{ textDecoration: 'none' }}>
              <ListItem button>
                <ListItemIcon>
                  <AssignmentInd/>
                </ListItemIcon>
                <ListItemText primary="Roles"/>
              </ListItem>
            </Link>
          </List>
        )}
      </List>
    </Box>
  )
}

export default MenuContent
