import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import rightsApi from '../../api/administration/RightsApi';
import rolesApi from '../../api/administration/RolesApi';
import { RootState } from '../store';
import { Right } from './roleSlice';

export interface EditRoleRightsState {
  status: 'idle' | 'loading' | 'saving' | 'failed';
  systemRights: Right[];
  roleRights: number[];
  dialogError?: SerializedError;
  actionError?: SerializedError;
}

const initialState: EditRoleRightsState = {
  status: 'idle',
  systemRights: [],
  roleRights: [],
};

export const loadRights = createAsyncThunk('edit-role-rights/load', async () => {
  return await rightsApi.getRights();
});

export const saveRoleRights = createAsyncThunk(
  'edit-role-rights/save',
  async (data: { roleId: number | string; rights: number[] }) => {
    return await rolesApi.saveRights(data.roleId, data.rights);
  },
);

export const editRoleRightsSlice = createSlice({
  name: 'edit-role-rights',
  initialState,
  reducers: {
    setActionError: (state, action: PayloadAction<Error>) => {
      state.actionError = action.payload;
    },
    clearActionError: (state) => {
      state.actionError = undefined;
    },
    setRoleRights: (state, action: PayloadAction<number[]>) => {
      state.roleRights = [...action.payload];
    },
    toggleRoleRight: (state, atcion: PayloadAction<number>) => {
      const currentIndex = state.roleRights.indexOf(atcion.payload) ?? -1;
      const newChecked = [...state.roleRights];
      if (currentIndex === -1) {
        newChecked.push(atcion.payload);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      state.roleRights = [...newChecked];
    },
  },
  extraReducers: (build) => {
    build.addCase(loadRights.pending, (state, action) => {
      state.status = 'loading';
    });

    build.addCase(loadRights.fulfilled, (state, action) => {
      state.status = 'idle';
      state.systemRights = action.payload.map((r) => {
        return { rightId: r.rightId, name: r.name, description: r.description, grouping: r.grouping } as Right;
      });
    });

    build.addCase(loadRights.rejected, (state, action) => {
      state.status = 'failed';
      state.dialogError = action.error;
    });

    build.addCase(saveRoleRights.pending, (state, action) => {
      state.status = 'saving';
    });

    build.addCase(saveRoleRights.fulfilled, (state, action) => {
      state.status = 'idle';
    });

    build.addCase(saveRoleRights.rejected, (state, action) => {
      state.status = 'idle';
      state.actionError = action.error;
    });
  },
});

export const { setActionError, setRoleRights, toggleRoleRight, clearActionError } = editRoleRightsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectEditRoleRightsDialog = (state: RootState) => state.administration.roles.edit.editRights;

export default editRoleRightsSlice.reducer;
