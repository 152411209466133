import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import setUsagesApi from '../../../api/SetUsagesApi'
import { SetOrdersItemDto } from '../../../api/types/Sets/SetUsages'

export interface OrdersState {
  status: 'idle' | 'loading' | 'failed';
  orders: SetOrdersItemDto[];
  actionError?: SerializedError;
}

const initialState: OrdersState = {
  status: 'idle',
  orders: [],
}

const sliceName = 'set-orders-slice'

export const loadSetOrders = createAsyncThunk(`${sliceName}/load`, async (setNumber: number) => {
  return await setUsagesApi.getOrders(setNumber)
})

export const ordersSlice = createSlice({
                                         name: sliceName,
                                         initialState,
                                         reducers: {
                                           startLoading: (state) => {
                                             state.status = 'loading'
                                           },
                                           successLoading: (state) => {
                                             state.status = 'idle'
                                           },
                                           fail: (state) => {
                                             state.status = 'failed'
                                           },
                                         },
                                         extraReducers: (build) => {
                                           build.addCase(loadSetOrders.pending, (state, action) => {
                                             state.status = 'loading'
                                           })

                                           build.addCase(loadSetOrders.fulfilled, (state, action) => {
                                             state.status = 'idle'
                                             state.orders = action.payload?.items ?? []
                                           })

                                           build.addCase(loadSetOrders.rejected, (state, action) => {
                                             state.status = 'failed'
                                             state.actionError = action.error
                                           })
                                         },
                                       })

export const { startLoading, successLoading, fail } = ordersSlice.actions

export const selectSetOrders = (state: RootState) => state.sets.orders

export default ordersSlice.reducer
