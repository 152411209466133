import React, { FunctionComponent } from 'react';
import { Grid, GridSize, Typography } from '@mui/material';
import { UnitDto } from '../../../api/types/UnitData';

interface UnitInfoProps {
  unit: UnitDto;
  captionWidth: GridSize;
}

const UnitInfo: FunctionComponent<UnitInfoProps> = (props) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="textSmRegular" display="flex">
          {props.unit.elementName}
        </Typography>
      </Grid>
      <Grid item container direction="row" sx={{ mt: 3 }}>
        <Grid item xs={props.captionWidth}>
          <Typography variant="textSmMedium">PID</Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption" display="flex">
            {props.unit.productionId}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" sx={{ mt: 3 }}>
        <Grid item xs={props.captionWidth}>
          <Typography variant="textSmMedium">PG</Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption" display="flex">
            {props.unit.productGroup}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" sx={{ mt: 3 }}>
        <Grid item xs={props.captionWidth}>
          <Typography variant="textSmMedium">PK</Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption" display="flex">
            {props.unit.productKey}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" sx={{ mt: 3 }}>
        <Grid item xs={props.captionWidth}>
          <Typography variant="textSmMedium">Design ID</Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption" noWrap>
            {props.unit.designId}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" sx={{ mt: 3 }}>
        <Grid item xs={props.captionWidth}>
          <Typography variant="textSmMedium">KD Rev</Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption" noWrap>
            {props.unit.kdRevision}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UnitInfo;
