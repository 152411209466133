import React from 'react';
import { msalFetch } from '../api/graph';
import { handleErrors } from '../api/errorHandler';
import authService from '../auth/AuthService';

export interface FileDownloadProps {
  url: string;
  options?: any;
  filename: string;
  onDownloaded: () => void;
  onError: (error: Error) => void;
}

export class FileDownload extends React.Component<FileDownloadProps> {
  private link: React.RefObject<HTMLAnchorElement>;

  constructor(props: FileDownloadProps) {
    super(props);

    this.link = React.createRef();
  }

  componentDidMount() {
    this.link.current?.click();
  }

  render() {
    return (
      <>
        <a role="button" ref={this.link} onClick={this.handleAction}></a>
      </>
    );
  }

  handleAction = async () => {
    if (!this.link.current) return;

    if (this.link.current.href) return;

    const result = await authService.getIdentity();
    if (!result) return;

    const headers = new Headers();
    headers.append('x-timezone-offset', (-new Date().getTimezoneOffset() / 60).toString());

    msalFetch()
      .get(this.props.url, result.accessToken, undefined, headers)

      //adalApiFetch(fetch, this.props.url, this.props.options)
      .then(handleErrors)
      .then((result) => result.blob())
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);

        if (this.link.current) {
          this.link.current.download = this.props.filename;
          this.link.current.href = href;

          this.link.current.click();

          this.props.onDownloaded();
        }
      })
      .catch(
        (error) => {
          console.error(error);
          this.props.onError(error);
        },
        //this.setState({
        // ...this.state,
        // error: true,
        // errorText: error.message
        //})
      );
  };
}
