import { Box, Drawer, Toolbar, Typography, Stack } from '@mui/material'
import { FunctionComponent, useEffect } from 'react'
import MenuContent from './MenuContent'
import { CURRENT_BUILD } from '../shared/api'
import { useAppSelector, useAppDispatch } from '../store/hooks'
import {
  selectApplicationInformation, loadToolingApplicationInformation,
} from '../store/application/applicationInformationSlice'

interface Props {
  drawerWidth: number;
  mobileOpen: boolean;
  onClose: () => void;
}

const currentBuild = Number(CURRENT_BUILD)

const AppMenu: FunctionComponent<Props> = (props) => {

  const applicationInformation = useAppSelector(selectApplicationInformation)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(loadToolingApplicationInformation())
  }, [dispatch])

  return (
    <Box
      component="nav"
      sx={{
        width: {
          md: props.drawerWidth,
        },
        flexShrink: {
          sm: 0,
        },
      }}
      onClick={props.onClose}
    >
      <Drawer
        variant="temporary"
        open={props.mobileOpen}
        onClose={props.onClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: {
            xs: 'block',
            md: 'none',
          },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: props.drawerWidth,
          },
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          height="100vh">
          <Box>
            <Toolbar/>
            <MenuContent/>
          </Box>
          <Box m={1.5}>
            <Typography variant="body2">
              {`Client version: ${currentBuild}`}
            </Typography>
            <Typography variant="body2">
              {`TR api version: ${applicationInformation.currentToolingApiBuild}`}
            </Typography>
          </Box>
        </Stack>
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
          [`& .MuiDrawer-paper`]: {
            width: props.drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Stack
          direction="column"
          justifyContent="space-between"
          height="100vh">
          <Box>
            <Toolbar/>
            <MenuContent/>
          </Box>
          <Box m={1.5}>
            <Typography variant="body2">
              {`Client version: ${currentBuild}`}
            </Typography>
            <Typography variant="body2">
              {`TR api version: ${applicationInformation.currentToolingApiBuild}`}
            </Typography>
          </Box>
        </Stack>
      </Drawer>
    </Box>
  )
}

export default AppMenu
