export const msalFetch = () => {
  const authHeader = (accessToken: string, init?: HeadersInit) => {
    const headers = new Headers(init);

    const bearer = `Bearer ${accessToken}`;
    headers.append('Authorization', bearer);

    return headers;
  };

  const request = (method: string) => {
    return function (url: string, accessToken: string, body?: any, headers?: HeadersInit) {
      const requestOptions = {
        method,
        headers: authHeader(accessToken, headers),
        body,
      } as RequestInit;

      return fetch(url, requestOptions);
    };
  };

  return {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE'),
  };
};
