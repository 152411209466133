import { List, ListItem, ListItemButton } from '@mui/material'
import { FunctionComponent } from 'react'
import { useAppSelector } from '../../../../../store/hooks'
import { selectToolingRepairs } from '../../../../../store/tooling/repairs/repairsSlice'
import RepairItemCard from './RepairItemCard'
import TransferItemCard from './TransferItemCard'
import { useNavigate } from 'react-router-dom'
import { selectToolingTransfers } from '../../../../../store/tooling/transfers/transfersSlice'
import { RepairDto } from '../../../../../api/types/Repairs'
import { TransferDto } from '../../../../../api/types/Transfers'
import { useRepairPermissions, useTransferPermissions } from './hooks'
import { selectToolingView } from '../../../../../store/tooling/toolingViewSlice'

interface Props {
  toolingId: number;
}

type Operation = RepairDto | TransferDto

const isRepair = (operation: Operation): operation is RepairDto => {
  return 'repairId' in operation
}

const isTransfer = (operation: Operation): operation is TransferDto => {
  return 'transferId' in operation
}

const OperationsList: FunctionComponent<Props> = (props) => {
  const repairsState = useAppSelector(selectToolingRepairs)
  const transfersState = useAppSelector(selectToolingTransfers)
  const toolingState = useAppSelector(selectToolingView)

  const operationsList: Operation[] = [...repairsState.repairs, ...transfersState.transfers]
  const sortedList = operationsList.sort(function (a, b) {
    const aDate = a.createDate ?? ''
    const bDate = b.createDate ?? ''
    if (new Date(aDate) > new Date(bDate)) {
      return -1
    } else if (new Date(aDate) < new Date(bDate)) {
      return 1
    } else {
      return 0
    }
  })

  const repairPermissions = useRepairPermissions(toolingState.tooling?.responsibles?.map((u) => u.userId) ?? [])
  const transferPermissions = useTransferPermissions(toolingState.tooling?.responsibles?.map((u) => u.userId) ?? [])

  const isIdle = repairsState.status === 'idle' && transfersState.status === 'idle'
  const isFailed = repairsState.status === 'failed' && transfersState.status === 'failed'

  const canEditRepairs = isIdle && repairPermissions
  const canEditTransfers = isIdle && transferPermissions

  const navigate = useNavigate()

  const handleRepairClick = (repairId: number) => {
    navigate(`/tooling/${props.toolingId}/repairs/${repairId}`)
  }

  const handleTransferClick = (transferId: number) => {
    navigate(`/tooling/${props.toolingId}/transfers/${transferId}`)
  }

  return (
    <List disablePadding>
      {sortedList.map((operation) => {
        if (isRepair(operation)) {
          return <ListItem key={operation.repairId} disableGutters>
            <ListItemButton disableGutters
                            onClick={() => handleRepairClick(operation.repairId)}>
              <RepairItemCard repair={operation} disabled={!canEditRepairs}/>
            </ListItemButton>
          </ListItem>
        } else if (isTransfer(operation)) {
          return <ListItem key={operation.transferId} disableGutters>
            <ListItemButton disableGutters
                            onClick={() => handleTransferClick(operation.transferId)}>
              <TransferItemCard transfer={operation} disabled={!canEditTransfers}/>
            </ListItemButton>
          </ListItem>
        }
      })}
    </List>
  )
}

export default OperationsList

