import React, { FunctionComponent, useState } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
} from '@mui/material'
import { Edit } from '@mui/icons-material'
import PropertyItem from './PropertyItem'
import { ToolingViewState, selectToolingView } from '../../../store/tooling/toolingViewSlice'
import LoadingTypography from './components/LoadingTypography'
import { useAppSelector, useAccount } from '../../../store/hooks'
import EditStatusDialog from './components/EditStatusDialog'
import { checkPermission } from '../../../store/user/userSlice'
import { Permissions_Tooling_Status_Edit } from '../../../api/types/permissions'
import { SCRAPPED_STATUS } from '../../../store/types/statuses'

export const StatusCard: FunctionComponent = () => {
  const [editStatus, setEditStatus] = useState(false)

  const toolingViewState: ToolingViewState = useAppSelector(selectToolingView)
  const isLoading = toolingViewState.status === 'loading'
  const haveEditStatusRight = useAppSelector((root) => checkPermission(root, Permissions_Tooling_Status_Edit))

  const userAccount = useAccount()

  const calcEditPermissions = () => {
    const responsibles = toolingViewState.tooling?.responsibles.filter((r) => r.userId === userAccount.localAccountId)
    const isScrappedStatus = toolingViewState.tooling?.summary?.status?.name === SCRAPPED_STATUS

    if (isScrappedStatus) {
      return (): boolean => false
    }

    return (): boolean => {
      if (haveEditStatusRight) {
        return true
      } else {
        if (responsibles) {
          return responsibles.length > 0
        } else {
          return false
        }
      }
    }
  }
  const canEditStatus = calcEditPermissions()

  return (
    <Card>
      <CardHeader
        title="Status"
        titleTypographyProps={{
          variant: 'textLgSemibold',
        }}
        action={
          toolingViewState.status === 'idle' &&
          toolingViewState.tooling?.technicalInfo &&
          canEditStatus() && (
            <Toolbar>
              <Tooltip title="Edit status">
                <IconButton onClick={() => setEditStatus(true)}>
                  <Edit/>
                </IconButton>
              </Tooltip>
            </Toolbar>
          )
        }
      />
      <Divider/>
      <CardContent>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} sm={6} marginBottom={3} container direction="column" spacing={1}>
            <PropertyItem caption="Status">
              <LoadingTypography isLoading={isLoading} loadingWidth={50}>
                {toolingViewState.tooling?.summary?.status?.name}
              </LoadingTypography>
            </PropertyItem>
            <PropertyItem caption="Change date">
              <LoadingTypography isLoading={isLoading} loadingWidth={100}>
                {toolingViewState.tooling?.statusChangedAt
                 ? new Date(toolingViewState.tooling?.statusChangedAt).toLocaleDateString()
                 : ''}
              </LoadingTypography>
            </PropertyItem>
          </Grid>
        </Grid>
      </CardContent>
      <EditStatusDialog
        open={editStatus}
        toolingId={toolingViewState.tooling?.toolingId.toString() || ''}
        summary={toolingViewState.tooling?.summary}
        suppliers={toolingViewState.tooling?.suppliers}
        onClose={() => setEditStatus(false)}
      />
    </Card>
  )
}
