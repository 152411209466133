import { FunctionComponent, useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material'
import { Box } from '@mui/system'
import { SearchUnitDto } from '../../../../api/types/UnitData'
import ToolingProperties from './ToolingProperties'
import { OwnerDto } from '../../../../api/types/OwnerDto'
import ownersApi from './../../../../api/OwnersApi'
import { SupplierDto } from '../../../../api/types/SupplierData'
import toolingApi from '../../../../api/ToolingApi'
import ToolingUnits from '../ToolingUnits'
import { FindToolingDto, ToolingListItemDto } from '../../../../api/types/ToolingDto'
import unitsApi from '../../../../api/UnitsApi'
import { SearchUnit, createSearchUnitFromSearchDto } from '../../../../store/types/searchUnit'
import {
  saveTooling,
  setIdleStatus,
  selectToolingCreateDialog,
  setAllowableNumbersOfUsages,
  setError,
  setQuestion,
  setSavingStatus,
} from '../../../../store/tooling/create/dialogSlice'
import { selectMaterial, selectToolingCreateMaterial } from '../../../../store/tooling/create/materialSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import ErrorDialog from '../../../../components/ErrorDialog'
import { ToolingStatuses } from '../../../../api/types/StatusDto'
import YesNoDialog from '../../../../components/YesNoDialog'
import { checkPermission } from '../../../../store/user/userSlice'
import { Permissions_Tooling_CreateDuplicate } from '../../../../api/types/permissions'
import { unwrapResult } from '@reduxjs/toolkit'

interface Props {
  open: boolean;
  unit?: SearchUnit;
  supplier: SupplierDto | null;
  onClose: (tooling?: ToolingListItemDto) => void;
}

const CreateNewToolingFromSetDialog: FunctionComponent<Props> = (props) => {
  const [selectedUnit, setSelectedUnit] = useState<SearchUnit | undefined>(undefined)

  const [owners, setOwners] = useState<OwnerDto[]>([])
  const [isOwnersLoading, setIsOwnerLoading] = useState(true)
  const [selectedOwner, setOwner] = useState<OwnerDto | undefined>()

  const [selectedSupplier, setSupplier] = useState<SupplierDto | null>(null)

  const dialogState = useAppSelector(selectToolingCreateDialog)
  const materialsState = useAppSelector(selectToolingCreateMaterial)

  const canCreateDuplicates = useAppSelector((root) => checkPermission(root, Permissions_Tooling_CreateDuplicate))

  const isFail = dialogState.status === 'failed'
  const isWarning = dialogState.status === 'warning'
  const saving = dialogState.status === 'saving'

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (props.open) {

      dispatch(selectMaterial())
      dispatch(setAllowableNumbersOfUsages())
    }
  }, [props.open, dispatch])

  useEffect(() => {
    if (props.open) {
      setSupplier(props.supplier ?? null)
      setSelectedUnit(props.unit)
      setIsOwnerLoading(true)

      ownersApi
        .getOwners()
        .then((res) => {
          res && setOwners(res)
          setIsOwnerLoading(false)
        })
        .catch(() => {
          setOwners([])
          setIsOwnerLoading(false)
        })
    }
  }, [props.open])

  const canCreate = () => {
    return (
      selectedSupplier &&
      dialogState.allowableNumberOfUsages !== undefined &&
      dialogState.allowableNumberOfUsages > 0 &&
      selectedOwner
    )
  }

  const save = () => {
    dispatch(
      saveTooling({
                    allowableNumberOfUsages: dialogState.allowableNumberOfUsages,
                    selectedSupplier,
                    selectedUnits: selectedUnit ? [selectedUnit] : [],
                    selectedOwner,
                    materialId: materialsState.selectedMaterial?.materialId,
                  }),
    ).then((payload) => {
      if (payload.meta.requestStatus === 'fulfilled') {
        const result = unwrapResult(payload)
        props.onClose(result)
      }
    })
  }

  const checkDuplicatesResults = (res?: FindToolingDto[]) => {
    if (!res || res.length === 0) {
      save()
    } else {
      const workingTooling = res.filter(
        (t) => t.status.statusId === ToolingStatuses.Draft || t.status.statusId === ToolingStatuses.Functional,
      )
      if (workingTooling.length > 0) {
        if (canCreateDuplicates) {
          dispatch(
            setQuestion(
              `Tooling ${
                workingTooling[0].toolingNumber
              } already exists with specified combination of supplier and unit(s) in ${
                ToolingStatuses[ToolingStatuses.Draft]
              } or ${
                ToolingStatuses[ToolingStatuses.Functional]
              } status. Are you sure you want to continue creating new tooling?`,
            ),
          )
        } else {
          dispatch(
            setError(
              new Error(
                `Tooling ${
                  workingTooling[0].toolingNumber
                } already exists with specified combination of supplier and unit(s) in ${
                  ToolingStatuses[ToolingStatuses.Draft]
                } or ${ToolingStatuses[ToolingStatuses.Functional]} status`,
              ),
            ),
          )
        }
      } else {
        const notDecommissionedTooling = res.filter((t) => t.status.statusId !== ToolingStatuses.Decommissioned)
        if (notDecommissionedTooling.length > 0) {
          dispatch(
            setQuestion(
              `Tooling ${notDecommissionedTooling[0].toolingNumber} already exists with specified combination of supplier and unit(s) in ${notDecommissionedTooling[0].status.name} status. Are you sure you want to continue creating new tooling?`,
            ),
          )
        } else {
          save()
        }
      }
    }
  }

  const handleCreate = () => {
    if (dialogState.allowableNumberOfUsages === undefined || dialogState.allowableNumberOfUsages <= 0) {
      return
    }

    if (!selectedSupplier) {
      return
    }

    if (!selectedUnit) {
      return
    }

    dispatch(setSavingStatus())

    toolingApi
      .findDuplicates(selectedSupplier, [selectedUnit])
      .then((res) => checkDuplicatesResults(res))
      .catch((error) => dispatch(setError(error)))
  }

  const getTitle = (unit ?: SearchUnit) => {
    return `New tooling for unit ${unit?.elementName}: properties`
  }

  return (
    <Dialog open={props.open} fullWidth maxWidth="md">
      <DialogTitle>{getTitle(selectedUnit)}</DialogTitle>
      <Divider/>
      <DialogContent>
        <ToolingProperties
          owners={owners}
          selectedOwner={selectedOwner}
          isLoading={isOwnersLoading}
          disabled={saving}
          onChangeOwner={(newOwner) => setOwner(newOwner)}
          selectedSupplier={selectedSupplier}
          onChangeSupplier={(newSupplier) => setSupplier(newSupplier)}
        />
        <ErrorDialog
          open={isFail}
          title={'Error'}
          text={'Error while saving data. Please try again later or contact the site administrator.'}
          onOk={() => dispatch(setIdleStatus())}
        />
        <YesNoDialog
          open={isWarning}
          title="Warning"
          onNo={() => {
            dispatch(setIdleStatus())
          }}
          onYes={() => {
            save()
          }}
        >
          {dialogState.question}
        </YesNoDialog>
      </DialogContent>
      <DialogActions>
        <Button disabled={saving} onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }}/>
        <Button variant="contained" disabled={saving || !canCreate()} onClick={() => handleCreate()}>
          {saving ? <CircularProgress size={20}/> : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateNewToolingFromSetDialog
