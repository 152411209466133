import { FunctionComponent, useEffect } from 'react'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Skeleton,
  TextField,
} from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { ToolingAccountingDto } from '../../../../api/types/ToolingDto'
import {
  selectToolingEditAccounting,
  init,
  setAccountingNumber,
  setAccountingDate,
  save,
  hideActionError,
  setAccountingDepreciationAsFixedAsset, setScrappingDate,
} from '../../../../store/tooling/accountingSlice'
import ErrorBox from '../../../../components/ErrorBox'
import { updateAccounting } from '../../../../store/tooling/toolingViewSlice'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers'
import moment, { Moment } from 'moment'
import ErrorDialog from '../../../../components/ErrorDialog'

interface Props {
  toolingId: number | string;
  accounting?: ToolingAccountingDto;
  open: boolean;
  onClose: () => void;
}

const EditAccountingDialog: FunctionComponent<Props> = (props) => {
  const state = useAppSelector(selectToolingEditAccounting)
  const dispatch = useAppDispatch()

  const isLoading = state.status === 'loading'
  const isFail = state.status === 'failed'
  const saving = state.status === 'saving'

  useEffect(() => {
    if (props.open) {
      dispatch(init(props.accounting))
    }
  }, [dispatch, props.accounting, props.open])

  useEffect(() => {
    moment.locale('en', { week: { dow: 1 } })
  }, [])

  const changeAccountingNumber = (value: string) => {
    dispatch(setAccountingNumber(value))
  }

  const changeAccountingDate = (date: Moment | null) => {
    dispatch(setAccountingDate(date?.toISOString()))
  }

  const changeScrappingDate = (date: Moment | null) => {
    dispatch(setScrappingDate(date?.toISOString()))
  }

  const handleToggleAccountingDepreciationAsFixedAsset = (value: boolean) => {
    dispatch(setAccountingDepreciationAsFixedAsset(value))
  }

  const onUpdate = () => {
    const dto = {
      accountingNumber: state.accountingNumber,
      accountingDate: state.accountingDate,
      scrappingDate: state.scrappingDate,
      accountingDepreciationAsFixedAsset: state.accountingDepreciationAsFixedAsset,
    } as ToolingAccountingDto

    dispatch(save({ toolingId: props.toolingId, accounting: dto }))
      .unwrap()
      .then(() => {
        dispatch(updateAccounting(dto))
        props.onClose()
      })
      .catch(() => {
      })
  }

  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle>Accounting information</DialogTitle>
      <Divider/>
      <DialogContent>
        {isFail && <ErrorBox code="500" description="Error while loading data"/>}
        {!isFail && (
          <Grid container direction="column" spacing={1}>
            {isLoading ? (
              <Skeleton/>
            ) : (
               <TextField
                 label="Accounting number"
                 fullWidth
                 value={state.accountingNumber || ''}
                 onChange={(event) => changeAccountingNumber(event.target.value)}
               />
             )}
            <Box mt={2}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Accounting date"
                  format="DD.MM.YYYY"
                  value={state.accountingDate ? moment(state.accountingDate) : undefined}
                  slotProps={{ textField: { fullWidth: true } }}
                  onChange={changeAccountingDate}
                />
              </LocalizationProvider>
            </Box>
            <Box mt={2}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Scrapped date"
                  format="DD.MM.YYYY"
                  value={state.scrappingDate ? moment(state.scrappingDate) : undefined}
                  slotProps={{ textField: { fullWidth: true } }}
                  onChange={changeScrappingDate}
                />
              </LocalizationProvider>
            </Box>
            {isLoading ? (
              <Skeleton/>
            ) : (
               <FormGroup>
                 <FormControlLabel
                   control={
                     <Checkbox
                       disabled={saving}
                       checked={state.accountingDepreciationAsFixedAsset}
                       onChange={(value) => handleToggleAccountingDepreciationAsFixedAsset(value.target.checked)}
                     />
                   }
                   label="Depreciation as a fixed asset"
                 />
               </FormGroup>
             )}
          </Grid>
        )}
        <ErrorDialog
          open={state.actionError !== undefined}
          title={state.actionError?.name || 'Error'}
          text={`Error while saving data. Please try again later or contact the site administrator.\n${state.actionError?.message}`}
          onOk={() => dispatch(hideActionError())}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={saving} onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }}/>
        <Button variant="contained" disabled={saving || isLoading || isFail} onClick={() => onUpdate()}>
          {saving ? <CircularProgress size={20}/> : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditAccountingDialog
