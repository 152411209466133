import { CircularProgress, FormControl, Grid, InputLabel } from '@mui/material'
import { FunctionComponent, useEffect } from 'react'
import { OwnerDto } from '../../../../api/types/OwnerDto'
import { SupplierDto } from './../../../../api/types/SupplierData'
import OwnerSelector from '../OwnerSelector'
import SupplierAutocomplete from '../SupplierAutocomplete'
import MaterialSelector from '../../view/components/MaterialSelector'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  loadMaterials,
  selectMaterial,
  selectToolingCreateMaterial,
} from '../../../../store/tooling/create/materialSlice'
import TextEditPropertyItem from '../../../components/TextEditPropertyItem'
import { selectToolingCreateDialog, setAllowableNumbersOfUsages } from '../../../../store/tooling/create/dialogSlice'
import { validateInt } from '../../../../helpers/validation'

interface Props {
  isLoading: boolean;
  disabled?: boolean;
  owners: OwnerDto[];
  selectedOwner: OwnerDto | undefined;
  onChangeOwner: (newOwner: OwnerDto | undefined) => void;
  selectedSupplier: SupplierDto | null;
  onChangeSupplier: (newSupplier: SupplierDto | null) => void;
}

const ToolingProperties: FunctionComponent<Props> = (props) => {
  const dialogState = useAppSelector(selectToolingCreateDialog)
  const materialsState = useAppSelector(selectToolingCreateMaterial)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(loadMaterials())
  }, [dispatch])

  const changeAllowableNumberOfUsages = (value: string) => {
    validateInt(value, (numberOfUsages) => {
      if (numberOfUsages && numberOfUsages < 0) {
        return
      }

      dispatch(setAllowableNumbersOfUsages(numberOfUsages))
    })
  }

  if (props.isLoading) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress/>
      </Grid>
    )
  }

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <OwnerSelector
          disabled={props.disabled}
          error={!props.selectedOwner}
          owners={props.owners}
          helperText={props.selectedOwner ? undefined : 'Owner cannot by empty'}
          selectedOwner={props.selectedOwner}
          onChangeOwner={props.onChangeOwner}
        />
      </Grid>

      <Grid item>
        <SupplierAutocomplete
          disabled={props.disabled}
          supplier={props.selectedSupplier}
          error={!props.selectedSupplier}
          helperText={props.selectedSupplier ? undefined : 'Supplier cannot by empty'}
          onSelectSupplier={props.onChangeSupplier}
        />
      </Grid>

      <Grid item>
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id="material-label">Material</InputLabel>
          <MaterialSelector
            isLoading={materialsState.status === 'loading'}
            materials={materialsState.materials}
            selectedMaterialId={materialsState.selectedMaterial?.materialId}
            onChange={(material) => {
              dispatch(selectMaterial(material))
              if (dialogState.allowableNumberOfUsages === undefined || dialogState.allowableNumberOfUsages <= 0) {
                dispatch(setAllowableNumbersOfUsages(material?.defaultAllowableUsages))
              }
            }}
          />
        </FormControl>
      </Grid>

      <TextEditPropertyItem
        isLoading={materialsState.status === 'loading'}
        caption="Allowable number of usages"
        type="number"
        value={dialogState.allowableNumberOfUsages || ''}
        error={dialogState.allowableNumberOfUsages === undefined || dialogState.allowableNumberOfUsages <= 0}
        helperText={
          dialogState.allowableNumberOfUsages === undefined || dialogState.allowableNumberOfUsages <= 0
          ? 'Allowable number of usages cannot by empty'
          : undefined
        }
        onChange={(value) => changeAllowableNumberOfUsages(value)}
      />
    </Grid>
  )
}

export default ToolingProperties
