import { Grid, Typography, Skeleton, ToggleButtonGroup, ToggleButton, Divider } from '@mui/material'
import { FunctionComponent } from 'react'
import { SupplierDto } from '../../../../api/types/SupplierData'
import { isEmptyOrSpaces } from '../../../../helpers/stringHelper'
import TextEditPropertyItem from '../../../components/TextEditPropertyItem'
import SupplierAutocomplete from '../../components/SupplierAutocomplete'
import { hasExternalShipperNameError, hasShipperError } from '../edit/validation'
import { ShipperType } from '../../../../api/types/Transfers'

interface Props {
  shipperType: ShipperType;
  shipperName: string;
  shipper: SupplierDto | null;
  isLoading: boolean;
  disabled: boolean;
  onChangeShipperType: (providerType: ShipperType) => void;
  onChangeShipperName: (name: string) => void;
  onChangeShipper: (supplier: SupplierDto | null) => void;
}

const ShipperGridItem: FunctionComponent<Props> = (props) => {
  const handleChangeShipperType = (type: ShipperType) => {
    props.onChangeShipperType(type)
  }

  const handleChangeShipperName = (name: string) => {
    props.onChangeShipperName(name)
  }

  const handleChangeShipper = (supplier: SupplierDto | null) => {
    props.onChangeShipper(supplier)
  }

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Divider/>
      </Grid>
      <Grid item container direction="row" spacing={2} alignItems="center">
        <Grid item>
          {props.isLoading ? (
            <Skeleton width={50}/>
          ) : (
             <Typography variant="subtitle1">Shipper</Typography>
           )}
        </Grid>
        <Grid item>
          {props.isLoading ? (
            <Skeleton width={200}/>
          ) : (
             <ToggleButtonGroup
               color="primary"
               value={props.shipperType}
               exclusive
               disabled={props.disabled}
               onChange={(_, value) => handleChangeShipperType(value as ShipperType)}
             >
               <ToggleButton value="Supplier">Supplier</ToggleButton>
               <ToggleButton value="External">External</ToggleButton>
             </ToggleButtonGroup>
           )}
        </Grid>
        {props.shipperType === 'External' && (
          <Grid item xs={12}>
            <TextEditPropertyItem
              caption="Shipper name"
              value={props.shipperName}
              isLoading={props.isLoading}
              disabled={props.disabled}
              error={hasExternalShipperNameError(props.shipperName)}
              helperText={isEmptyOrSpaces(props.shipperName) ? 'Shipper name cannot be empty' : ''}
              onChange={handleChangeShipperName}
            />
          </Grid>
        )}
        {props.shipperType === 'Supplier' && (
          <Grid item xs={12}>
            {props.isLoading ? (
              <Skeleton/>
            ) : (
               <SupplierAutocomplete
                 supplier={props.shipper}
                 disabled={props.disabled}
                 error={hasShipperError(props.shipper)}
                 label="Shipper name"
                 helperText={hasShipperError(props.shipper) ? 'Shipper cannot by empty' : undefined}
                 noOptionsText="No shippers"
                 onSelectSupplier={handleChangeShipper}
               />
             )}
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Divider/>
      </Grid>
    </Grid>
  )
}

export default ShipperGridItem
