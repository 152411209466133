import authService from '../auth/AuthService';
import { API_ADDRESS } from './api';
import { msalFetch } from './graph';
import { StockDto } from './types/Stocks';

const STOCKS_API_ADDRESS = `${API_ADDRESS}/api/stocks`;

export class StocksApi {
  async getStocks() {
    const result = await authService.getIdentity();

    if (result) {
      return msalFetch()
        .get(STOCKS_API_ADDRESS, result.accessToken)
        .then<StockDto[]>((res) => res.json());
    }
  }
}

const stocksApi = new StocksApi();

export default stocksApi;
