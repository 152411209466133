import { FunctionComponent } from 'react';
import { Grid, Typography } from '@mui/material';

interface Props extends React.PropsWithChildren {
  caption?: string;
}

const PropertyItem: FunctionComponent<Props> = (props) => {
  return (
    <Grid item container alignItems="left" direction="row" sx={{ mt: 6 }}>
      {props.caption && <Grid item xs={12}>
        <Typography variant="textSmMedium">{props.caption}</Typography>
      </Grid>}
      <Grid item xs={12}>
        {props.children}
      </Grid>
    </Grid>
  );
};

export default PropertyItem;
