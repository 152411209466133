import { FunctionComponent, useEffect } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material'
import { Box } from '@mui/system'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  selectCreateNewSet, setActiveStep, setSelectedUnit, setSelectedSupplier, clear, getAssemblyUnitTooling,
  getAssemblyUnitBom, clearSupplier, clearToolingData, createSet, clearError,
} from '../../../store/sets/createNewSetDialogSlice'
import { useAppSelector, useAppDispatch } from '../../../store/hooks'
import { SetItemDto } from '../../../api/types/Sets/SetDto'
import YesNoDialog from '../../../components/YesNoDialog'
import ErrorDialog from '../../../components/ErrorDialog'
import SetUnit from './SetUnit'
import { SearchUnit } from '../../../store/types/searchUnit'
import SetSupplier from './SetProperties'
import SetUnitsTooling from './SetUnitsTooling'

interface Props {
  open: boolean;
  onClose: (set?: SetItemDto) => void;
  closeCallBack: (close: boolean) => void;
}

const unitStep = 0
const supplierStep = 1
const toolingStep = 2

const CreateNewSetDialog: FunctionComponent<Props> = (props) => {
  const createNewSetState = useAppSelector(selectCreateNewSet)
  const activeStep = createNewSetState.activeStep
  const isFail = createNewSetState.status === 'failed'
  const isWarning = createNewSetState.status === 'warning'
  const saving = createNewSetState.status === 'saving'

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (props.open) {
      dispatch(setActiveStep(0))
      dispatch(setSelectedUnit(undefined))
    }
  }, [props.open, dispatch])

  const canBackVisible = () => {
    return activeStep > 0
  }

  const canNextVisible = () => {
    return activeStep < 2
  }

  const isNextDisabled = () => {
    if (createNewSetState.setUnit) {
      return false
    }
    if (createNewSetState.setSupplier) {
      return false
    }
    return true
  }

  const onBack = () => {
    if (activeStep > 0) {
      dispatch(setActiveStep(activeStep - 1))
    }
    if (activeStep === supplierStep) {
      dispatch(clearSupplier())
    }
    if (activeStep === toolingStep) {
      dispatch(clearToolingData())
    }
  }

  const onNext = () => {
    dispatch(setActiveStep(activeStep + 1))
    if (createNewSetState.setUnit !== undefined && createNewSetState.setSupplier !== null) {
      dispatch(getAssemblyUnitTooling({ unitFileId: createNewSetState.setUnit.unitFileId }))
      dispatch(getAssemblyUnitBom({ unitFileId: createNewSetState.setUnit.unitFileId }))
    }
  }

  const onRemoveUnit = () => {
    dispatch(setSelectedUnit(undefined))
  }
  const onAddUnit = (unit: SearchUnit) => {
    dispatch(setSelectedUnit(unit))
  }

  const canCreate = (): boolean => {
    return (
      createNewSetState.setUnit !== undefined &&
      createNewSetState.setSupplier !== null &&
      createNewSetState.selectedUnitsTooling.length > 0
    )
  }

  const save = () => {
    if (createNewSetState.setUnit &&
      createNewSetState.setSupplier &&
      createNewSetState.selectedUnitsTooling.length > 0) {
      dispatch(
        createSet({
                    toolingIds: createNewSetState.selectedUnitsTooling.map(t => t.tooling.id),
                    unitFileId: createNewSetState.setUnit.unitFileId,
                    supplierId: createNewSetState.setSupplier.supplierId,
                    description: '',
                    comment: '',
                  }),
      ).then((payload) => {
        if (payload.meta.requestStatus === 'fulfilled') {
          const result = unwrapResult(payload)
          props.onClose(result)
        }
      })
    }
  }

  const handleCreate = () => {
    if (createNewSetState.setUnit &&
      createNewSetState.setSupplier &&
      createNewSetState.selectedUnitsTooling.length > 0) {
      dispatch(
        createSet({
                    toolingIds: createNewSetState.selectedUnitsTooling.map(t => t.tooling.id),
                    unitFileId: createNewSetState.setUnit.unitFileId,
                    supplierId: createNewSetState.setSupplier.supplierId,
                    description: '',
                    comment: '',
                  }),
      ).then((payload) => {
        if (payload.meta.requestStatus === 'fulfilled') {
          const result = unwrapResult(payload)
          dispatch(clear())
          dispatch(clearToolingData())
          props.onClose(result)
        }
      })
    }
  }

  const getTitle = () => {
    switch (activeStep) {
      case unitStep: {
        return 'New set: target unit'
      }

      case supplierStep: {
        return 'New set: supplier'
      }

      case toolingStep: {
        return 'New set: tooling'
      }
    }
  }

  const handleClose = () => {
    dispatch(clear())
    dispatch(clearToolingData())
    props.onClose()
  }

  return (
    <Dialog open={props.open} fullWidth maxWidth="md">
      <DialogTitle>{getTitle()}</DialogTitle>
      <Divider/>
      <DialogContent>
        <Stepper activeStep={activeStep} sx={{ pb: 3 }}>
          <Step>
            <StepLabel>Set unit</StepLabel>
          </Step>
          <Step>
            <StepLabel>Supplier</StepLabel>
          </Step>
          <Step>
            <StepLabel>Tooling</StepLabel>
          </Step>
        </Stepper>
        {activeStep === 0 && (
          <SetUnit
            selectedUnit={createNewSetState.setUnit}
            disabled={saving}
            onSelectUnit={(unit) => onAddUnit(unit)}
            onRemoveUnit={() => onRemoveUnit()}
          />
        )}
        {activeStep === 1 && (
          <SetSupplier
            isLoading={false}
            disabled={saving}
            selectedSupplier={createNewSetState.setSupplier}
            onChangeSupplier={(newSupplier) => dispatch(setSelectedSupplier(newSupplier))}
          />
        )}
        {activeStep === 2 && (
          <SetUnitsTooling
            selectedUnit={createNewSetState.setUnit}
            selectedSupplier={createNewSetState.setSupplier ?? null}
            selectedUnitsTooling={createNewSetState.selectedUnitsTooling}
            assemblyUnits={createNewSetState.assemblyUnits}
            availableUnitsTooling={createNewSetState.availableUnitsTooling}

          />
        )}
        <ErrorDialog
          open={isFail}
          title={'Error'}
          text={`Error while saving data. Please try again later or contact the site administrator.
          ${createNewSetState.error?.message}`}
          onOk={() => {
            props.closeCallBack(false)
            dispatch(clearError())
          }}
        />
        <YesNoDialog
          open={isWarning}
          title="Warning"
          onNo={() => {
            props.closeCallBack(false)
          }}
          onYes={() => {
            save()
          }}
        >
          {createNewSetState.question}
        </YesNoDialog>
      </DialogContent>
      <DialogActions>
        <Button disabled={saving} onClick={handleClose}>
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }}/>
        {canBackVisible() && (
          <Button disabled={saving} onClick={() => onBack()}>
            Back
          </Button>
        )}
        {canNextVisible() && (
          <Button variant="contained" disabled={isNextDisabled()} onClick={() => onNext()}>
            Next
          </Button>
        )}
        {activeStep === toolingStep && (
          <Button variant="contained" disabled={saving || !canCreate()} onClick={() => handleCreate()}>
            {saving ? <CircularProgress size={20}/> : 'Create'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default CreateNewSetDialog
