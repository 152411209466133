import * as React from 'react';
import { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';

const UnsupportedType: FC = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={6} md={4}>
        <Box
          sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: (theme) => theme.palette.grey[500],
            backgroundColor: (theme) => theme.palette.grey[800],
            minHeight: '100px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3" sx={{ color: 'white', textAlign: 'center' }}>
            Unsupported file type
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UnsupportedType;
