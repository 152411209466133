import { combineReducers } from '@reduxjs/toolkit';
import materialsList from './materialsListSlice';
import editMaterialReducer from './editMaterialSlice';

export const materials = combineReducers({
  list: combineReducers({
    materialsList,
  }),
  edit: editMaterialReducer,
});
