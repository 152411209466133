import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { FunctionComponent } from 'react';
import { UploadFile } from '../../store/tooling/files/filesSlice';
import UploadFileIcon from './UploadFileIcon';

interface Props {
  file: UploadFile;
}

const UploadFileListItem: FunctionComponent<Props> = (props) => {
  return (
    <ListItem key={props.file.uploadId} disableGutters>
      <ListItemIcon>
        <UploadFileIcon file={props.file} />
      </ListItemIcon>
      <ListItemText
        primary={props.file.name}
        secondary={props.file.status === 'failed' ? props.file.actionError ?? 'Error' : props.file.size}
      />
    </ListItem>
  );
};

export default UploadFileListItem;
