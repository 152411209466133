import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import usagesApi from '../../../api/UsagesApi';
import { OrdersItemDto } from '../../../api/types/Usages';
import { RootState } from '../../store';

export interface OrdersState {
  status: 'idle' | 'loading' | 'failed';
  orders: OrdersItemDto[];
  actionError?: SerializedError;
}

const initialState: OrdersState = {
  status: 'idle',
  orders: [],
};

const sliceName = 'tooling-orders-slice';

export const loadOrders = createAsyncThunk(`${sliceName}/load`, async (toolingId: number) => {
  return await usagesApi.getOrders(toolingId);
});

export const ordersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    startLoading: (state) => {
      state.status = 'loading';
    },
    successLoading: (state) => {
      state.status = 'idle';
    },
    fail: (state) => {
      state.status = 'failed';
    },
  },
  extraReducers: (build) => {
    build.addCase(loadOrders.pending, (state, action) => {
      state.status = 'loading';
    });

    build.addCase(loadOrders.fulfilled, (state, action) => {
      state.status = 'idle';
      state.orders = action.payload?.items ?? [];
    });

    build.addCase(loadOrders.rejected, (state, action) => {
      state.status = 'failed';
      state.actionError = action.error;
    });
  },
});

export const { startLoading, successLoading, fail } = ordersSlice.actions;

export const selectToolingOrders = (state: RootState) => state.tooling.orders;

export default ordersSlice.reducer;
