import { Fragment, FunctionComponent } from 'react';
import CenteredCircularProgress from '../CenteredCircularProgress';

interface Props extends React.PropsWithChildren {
  isLoading: boolean;
}

const LoadingStateHandler: FunctionComponent<Props> = (props) => {
  if (props.isLoading) {
    return <CenteredCircularProgress />;
  }

  return <Fragment>{props.children}</Fragment>;
};

export default LoadingStateHandler;
