import { FunctionComponent, useEffect, useState } from 'react';
import { Box, Avatar } from '@mui/material';
import graphApi from '../api/GraphApi';

interface Props {
  userId?: string;
}

const GraphAvatar: FunctionComponent<Props> = (props) => {
  const [blobUrl, setBlobUrl] = useState('');

  useEffect(() => {
    if (!props.userId) {
      setBlobUrl('');
      return;
    }

    graphApi
      .getUserPhoto(props.userId)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        setBlobUrl(url);
      })
      .catch((error) => console.log(error));
  }, [props.userId]);

  return (
    <Box {...props}>
      <Avatar src={blobUrl} />
    </Box>
  );
};

export default GraphAvatar;
