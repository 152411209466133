import React, { FunctionComponent } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Typography, Grid } from '@mui/material';
import { CoreboxDto } from '../../../../api/types/ToolingDto';
import LoadingTableBody from '../../../components/LoadingTableBody';

interface Props {
  isLoading: boolean;
  coreboxes?: CoreboxDto[];
}

const CoreboxesView: FunctionComponent<Props> = (props) => {
  if (!props.isLoading && (!props.coreboxes || props.coreboxes?.length === 0)) {
    return (
      <Typography align="center" sx={{ color: (theme) => theme.palette.text.secondary }}>
        No coreboxes
      </Typography>
    );
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ paddingLeft: 0 }}>
            <Typography variant="textSmMedium">Quantity</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="textSmMedium">Material</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.isLoading && <LoadingTableBody rows={2} columns={2} />}
        {!props.isLoading &&
          props.coreboxes?.map((cb) => (
            <TableRow key={cb.coreboxId}>
              <TableCell sx={{ paddingLeft: 0 }}>
                <Typography variant="textSmRegular">{cb.quantity}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="textSmRegular">{cb.material?.name}</Typography>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default CoreboxesView;
