import React, { FunctionComponent } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface Props extends React.PropsWithChildren {
  open: boolean;
  title: string;
  onNo: () => void;
  onYes: () => void;
}

const YesNoDialog: FunctionComponent<Props> = (props) => {
  return (
    <Dialog open={props.open} onClose={props.onNo} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{props.children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onNo}>NO</Button>
        <Button onClick={() => props.onYes()}>YES</Button>
      </DialogActions>
    </Dialog>
  );
};

export default YesNoDialog;
