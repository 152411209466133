import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { CurrencyDto } from '../../../api/types/CurrencyDto'
import { SupplierDto } from '../../../api/types/SupplierData'
import { RootState } from '../../store'
import { ProviderType, UpdateRepairRequest } from '../../../api/types/Repairs'
import repairsApi from '../../../api/RepairsApi'

export interface EditRepairState {
  status: 'idle' | 'loading' | 'saving' | 'failed';
  toolingId: number;
  repairId: number;
  reason: string;
  providerType: ProviderType;
  providerName?: string;
  providerId?: number;
  supplier: SupplierDto | null;
  cost?: number;
  currency?: CurrencyDto;
  repairDate?: string;
  repairOrder: string;
  comment: string;
  responsible: string[];
  pageError?: SerializedError;
  actionError?: SerializedError;
  showConfirmDeleteDialog: boolean;
}

const initialState: EditRepairState = {
  toolingId: 0,
  repairId: 0,
  status: 'idle',
  reason: '',
  providerType: 'Supplier',
  supplier: null,
  repairOrder: '',
  comment: '',
  responsible: [],
  showConfirmDeleteDialog: false,
}

const sliceName = 'tooling-operation-edit-slice'

export const load = createAsyncThunk(`${sliceName}/load`, async (data: { toolingId: number; repairId: number }) => {
  return await repairsApi.get(data.toolingId, data.repairId)
})

export const save = createAsyncThunk(
  `${sliceName}/save`,
  async (data: { toolingId: number; repairId: number; request: UpdateRepairRequest }) => {
    await repairsApi.updateRepair(data.toolingId, data.repairId, data.request)
  },
)

export const deleteRepair = createAsyncThunk(
  `${sliceName}/delete`,
  async (data: { toolingId: number; repairId: number }) => {
    await repairsApi.deleteRepair(data.toolingId, data.repairId)
  },
)

export const editRepairSlice = createSlice({
                                             name: sliceName,
                                             initialState,
                                             reducers: {
                                               startLoading: (state) => {
                                                 state.status = 'loading'
                                               },
                                               successLoading: (state) => {
                                                 state.status = 'idle'
                                               },
                                               fail: (state) => {
                                                 state.status = 'failed'
                                               },
                                               setReason: (state, action: PayloadAction<string>) => {
                                                 state.reason = action.payload
                                               },
                                               setProviderType: (state, action: PayloadAction<ProviderType>) => {
                                                 state.providerType = action.payload
                                               },
                                               setSupplier: (state, action: PayloadAction<SupplierDto | null>) => {
                                                 state.supplier = action.payload
                                               },
                                               setProviderName: (state, action: PayloadAction<string>) => {
                                                 state.providerName = action.payload
                                               },
                                               setCost: (state, action: PayloadAction<number | undefined>) => {
                                                 state.cost = action.payload
                                               },
                                               setCurrency: (state, action: PayloadAction<CurrencyDto | undefined>) => {
                                                 state.currency = action.payload
                                               },
                                               setRepairDate: (state, action: PayloadAction<string | undefined>) => {
                                                 state.repairDate = action.payload
                                               },
                                               setRepairOrder: (state, action: PayloadAction<string>) => {
                                                 state.repairOrder = action.payload
                                               },
                                               setComment: (state, action: PayloadAction<string>) => {
                                                 state.comment = action.payload
                                               },
                                               hideActionError: (state) => {
                                                 state.actionError = undefined
                                               },
                                               showConfirmDeleteDialog: (state) => {
                                                 state.showConfirmDeleteDialog = true
                                               },
                                               hideConfirmDeleteTooling: (state) => {
                                                 state.showConfirmDeleteDialog = false
                                               },
                                             },
                                             extraReducers: (build) => {
                                               build.addCase(load.pending, (state, action) => {
                                                 state.repairId = action.meta.arg.repairId
                                                 state.toolingId = action.meta.arg.toolingId
                                                 state.status = 'loading'
                                               })

                                               build.addCase(load.fulfilled, (state, action) => {
                                                 state.status = 'idle'

                                                 state.reason = action.payload?.reason ?? ''
                                                 state.providerType = action.payload?.provider.type ?? 'Supplier'
                                                 state.providerName = action.payload?.provider.name ?? ''
                                                 state.providerId = action.payload?.provider.providerId
                                                 state.cost = action.payload?.cost.amount ?? 0
                                                 state.currency = action.payload?.cost.currency
                                                 state.repairDate = action.payload?.repairDate
                                                 state.repairOrder = action.payload?.repairOrder ?? ''
                                                 state.comment = action.payload?.comment ?? ''

                                                 state.responsible = action.payload?.responsible ?? []

                                                 if (action.payload?.provider.type === 'Supplier') {
                                                   state.supplier = {
                                                     supplierId: action.payload.provider.providerId,
                                                     name: action.payload.provider.name,
                                                   } as SupplierDto
                                                 }
                                               })

                                               build.addCase(load.rejected, (state, action) => {
                                                 state.status = 'failed'
                                                 state.pageError = action.error
                                               })

                                               build.addCase(save.pending, (state, action) => {
                                                 state.status = 'saving'
                                               })

                                               build.addCase(save.fulfilled, (state, action) => {
                                                 state.status = 'idle'
                                               })

                                               build.addCase(save.rejected, (state, action) => {
                                                 state.status = 'idle'
                                                 state.actionError = action.error
                                               })
                                             },
                                           })

export const {
               startLoading,
               successLoading,
               fail,
               setReason,
               setProviderType,
               setSupplier,
               setProviderName,
               setCost,
               setCurrency,
               setRepairDate,
               setRepairOrder,
               setComment,
               hideActionError,
               showConfirmDeleteDialog,
               hideConfirmDeleteTooling,
             } = editRepairSlice.actions

export const selectEditRepair = (state: RootState) => state.tooling.repairs.edit.properties

export default editRepairSlice.reducer
