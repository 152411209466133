import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { FunctionComponent, useState, useEffect } from 'react'
import { SxProps, Theme } from '@mui/system'

enum SetStatuses {
  Created        = 0,
  Draft          = 1,
  Functional     = 2,
  Suspended      = 3,
  Decommissioned = 4,
}

interface Props {
  currentStatus: string;
  onChangeStatus: (status: string) => void;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}

const StatusSelector: FunctionComponent<Props> = (props) => {

  const [currentValue, setCurrentValue] = useState(0)

  useEffect(() => {
    if (props.currentStatus) {
      const statusEnum = SetStatuses[props.currentStatus as keyof typeof SetStatuses]
      setCurrentValue(statusEnum.valueOf())
    }
  }, [props.currentStatus])

  return (
    <FormControl fullWidth sx={props.sx}>
      <InputLabel id="status-label">Status</InputLabel>
      <Select sx={{ width: 250 }}
              labelId="status-label"
              label="Status"
              disabled={props.disabled}
              value={currentValue}
              error={props.error}
              onChange={(value) => {
                const newValue = value.target.value as number
                setCurrentValue(newValue)
                props.onChangeStatus(value.target.name)
              }}
      >
        <MenuItem value={0} sx={{ display: 'none' }}>
          <em>Created</em>
        </MenuItem>
        <MenuItem value={1} sx={{ display: 'none' }}>
          <em>Draft</em>
        </MenuItem>
        <MenuItem value={2} sx={{ display: 'none' }}>
          <em>Functional</em>
        </MenuItem>
        <MenuItem value={3} sx={{ display: 'none' }}>
          <em>Suspended</em>
        </MenuItem>
        <MenuItem value={4}>
          <em>Decommissioned</em>
        </MenuItem>
      </Select>
      {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  )
}

export default StatusSelector
