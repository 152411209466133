import React, { FunctionComponent, useState } from 'react'
import { TextField, Autocomplete, CircularProgress } from '@mui/material'
import { throttle } from 'lodash'
import suppliersApi from '../../../api/SuppliersApi'
import { SupplierDto } from '../../../api/types/SupplierData'
import { SxProps, Theme } from '@mui/system'

interface Props {
  supplier: SupplierDto | null;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  error?: boolean;
  label?: string;
  helperText?: string;
  noOptionsText?: string;
  onSelectSupplier: (unit: SupplierDto | null) => void;
}

const SupplierAutocomplete: FunctionComponent<Props> = (props) => {
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<SupplierDto[]>([])
  const [loading, setLoading] = useState(false)

  const fetchApi = (filter: string, callback: (results: SupplierDto[]) => void) => {
    setLoading(true)

    suppliersApi
      .search(10, filter)
      .then((data) => {
        callback(data || [])
        setLoading(false)
      })
      .catch((error) => callback([]))
  }

  const fetchCache = React.useMemo(
    () =>
      throttle((request: string, callback: (results: SupplierDto[]) => void) => fetchApi(request, callback), 600, {
        leading: false,
      }),
    [],
  )

  React.useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions([])
      return undefined
    }

    if (inputValue === props.supplier?.name) {
      return
    }

    fetchCache(inputValue, (results?: SupplierDto[]) => {
      if (active) {
        let newOptions = [] as SupplierDto[]

        if (props.supplier) {
          newOptions = [props.supplier]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [props.supplier, inputValue, fetchCache])

  return (
    <Autocomplete
      sx={props.sx}
      disabled={props.disabled}
      options={options}
      noOptionsText={props.noOptionsText}
      getOptionLabel={(option) => option.name || ''}
      value={props.supplier}
      inputValue={inputValue}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          error={props.error}
          helperText={props.helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress size={20}/> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      onChange={(event: any, newValue) => {
        props.onSelectSupplier(newValue as SupplierDto)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
    />
  )
}

export default SupplierAutocomplete
