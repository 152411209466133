import { SearchUnitDto, UnitDto } from '../../api/types/UnitData';
import { ToolingUnit } from './tooling';

export interface SearchUnit extends SearchUnitDto {
  identicals?: SearchUnit[];
}

export const createSearchUnitFromSearchDto = (unit: SearchUnitDto) => {
  return {
    unitFileId: unit.unitFileId,
    elementId: unit.elementId,
    elementName: unit.elementName,
    productionId: unit.productionId,
    productKey: unit.productKey,
    productGroup: unit.productGroup,
    hasIdentical: unit.hasIdentical,
    useIn: [...unit.useIn],
  } as SearchUnit;
};

export const createSearchUnitFromUnit = (unit: ToolingUnit) => {
  return {
    unitFileId: unit.unitFileId,
    elementId: unit.elementId,
    elementName: unit.elementName,
    productionId: unit.productionId,
    byElementId: true,
    productKey: unit.productKey,
    productGroup: unit.productGroup,
    addedManually: unit.addedManually,
    analogId: unit.analogId,
    hasIdentical: false,
    useIn: [],
  } as SearchUnit;
};

export const createUnitFromUnitDto = (unit: UnitDto) => {
  return {
    unitFileId: unit.unitFileId,
    elementId: unit.elementId,
    elementName: unit.elementName,
    productionId: unit.productionId,
    productKey: unit.productKey,
    productGroup: unit.productGroup,
    addedManually: unit.addedManually,
    analogId: unit.analogId,
    designId: unit.designId,
    kdRevision: unit.kdRevision,
  } as ToolingUnit;
};

export const convertToToolingUnit = (unit: SearchUnit) => {
  return {
    unitFileId: unit.unitFileId,
    elementId: unit.elementId,
    elementName: unit.elementName,
    productionId: unit.productionId,
    productKey: unit.productKey,
    productGroup: unit.productGroup,
    analogId: unit.analogId,
  } as ToolingUnit;
};
