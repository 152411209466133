import authService from '../../auth/AuthService';
import { API_ADDRESS } from '../api';
import { handleErrors } from '../errorHandler';
import { msalFetch } from '../graph';
import { RightDto } from '../types/administration/Rights';
import { RoleDto, RoleDto as RoleItemDto } from '../types/administration/Roles';
import { UserDto } from '../types/administration/Users';

const ROLES_API_ADDRESS = `${API_ADDRESS}/api/administration/roles`;

export class RolesApi {
  async getRoles() {
    const token = await authService.getIdentity();

    return msalFetch()
      .get(ROLES_API_ADDRESS, token.accessToken)
      .then(handleErrors)
      .then<RoleItemDto[]>((res) => res.json());
  }

  async getRole(roleId: number | string) {
    const token = await authService.getIdentity();

    return msalFetch()
      .get(`${ROLES_API_ADDRESS}/${roleId}`, token.accessToken)
      .then(handleErrors)
      .then<RoleDto>((res) => res.json());
  }

  async addUsers(roleId: number | string, users: string[]) {
    const token = await authService.getIdentity();

    return msalFetch()
      .post(`${ROLES_API_ADDRESS}/${roleId}/users`, token.accessToken, JSON.stringify({ users }), {
        'content-type': 'application/json',
      })
      .then(handleErrors)
      .then<UserDto[]>((res) => res.json());
  }

  async removeUser(roleId: number | string, userId: string) {
    const token = await authService.getIdentity();

    return msalFetch().delete(`${ROLES_API_ADDRESS}/${roleId}/users/${userId}`, token.accessToken).then(handleErrors);
  }

  async saveRights(roleId: number | string, rights: number[]) {
    const token = await authService.getIdentity();

    return msalFetch()
      .put(`${ROLES_API_ADDRESS}/${roleId}/rights`, token.accessToken, JSON.stringify({ rights }), {
        'content-type': 'application/json',
      })
      .then(handleErrors)
      .then<RightDto[]>((res) => res.json());
  }

  async createRole() {
    const token = await authService.getIdentity();

    return msalFetch()
      .post(`${ROLES_API_ADDRESS}`, token.accessToken)
      .then(handleErrors)
      .then<RoleDto>((res) => res.json());
  }

  async updateRole(roleId: number | string, data: { name: string }) {
    const token = await authService.getIdentity();

    return msalFetch()
      .put(`${ROLES_API_ADDRESS}/${roleId}`, token.accessToken, JSON.stringify(data), {
        'content-type': 'application/json',
      })
      .then(handleErrors);
  }

  async deleteRole(roleId: number | string) {
    const token = await authService.getIdentity();
    return msalFetch().delete(`${ROLES_API_ADDRESS}/${roleId}`, token.accessToken).then(handleErrors);
  }
}

const rolesApi = new RolesApi();

export default rolesApi;
