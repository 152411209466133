import authService from '../auth/AuthService';
import { API_ADDRESS } from './api';
import { msalFetch } from './graph';
import { OwnerDto } from './types/OwnerDto';

const OWNERS_API_ADDRESS = `${API_ADDRESS}/api/owners`;

export class OwnersApi {
  async getOwners() {
    const result = await authService.getIdentity();

    if (result) {
      return msalFetch()
        .get(OWNERS_API_ADDRESS, result.accessToken)
        .then<OwnerDto[]>((res) => res.json());
    }
  }
}

const ownersApi = new OwnersApi();

export default ownersApi;
