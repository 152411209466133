import { FunctionComponent, useEffect } from 'react';
import { FileDto } from '../../../../../api/types/Files';
import BackdropViewer from '../../../../../components/viewers/BackdropViewer';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { deleteFile, loadFiles, selectToolingFiles, upload } from '../../../../../store/tooling/files/filesSlice';
import {
  closeFileViewer,
  getFileUrls,
  openFileViewer,
  selectFilesViewer,
} from '../../../../../store/tooling/files/viewerSlice';
import { selectToolingView } from '../../../../../store/tooling/toolingViewSlice';
import FilesContainer from '../../../../../components/files/FilesContainer';
import FileIteratorHandler from '../../../../../components/files/FileIteratorHandler';
import { useCheckResponsibleOrPermissions } from '../../../../../helpers/useCheckResponsibleOrPermissions';
import { Permissions_Tooling_Files_Edit } from '../../../../../api/types/permissions';

interface Props {
  toolingId: number;
}

const FilesTab: FunctionComponent<Props> = (props) => {
  const toolingState = useAppSelector(selectToolingView);
  const filesState = useAppSelector(selectToolingFiles);
  const viewerState = useAppSelector(selectFilesViewer);
  const dispatch = useAppDispatch();

  const isLoading = filesState.status === 'loading';
  const isIdle = filesState.status === 'idle';
  const isFailed = filesState.status === 'failed';

  const filesPermissions = useCheckResponsibleOrPermissions(
    toolingState.tooling?.responsibles?.map((u) => u.userId) ?? [],
    Permissions_Tooling_Files_Edit,
  );

  const canEdit = isIdle && filesPermissions;

  useEffect(() => {
    dispatch(loadFiles(props.toolingId));
  }, [dispatch, props.toolingId]);

  useEffect(() => {
    if (viewerState.file) {
      dispatch(getFileUrls(viewerState.file.fileId));
    }
  }, [dispatch, viewerState.file]);

  const handleOpenViewer = (file: FileDto) => {
    dispatch(openFileViewer(file));
  };

  const handleCloseViwer = () => {
    dispatch(closeFileViewer());
  };

  const handleDelete = (file: FileDto) => {
    dispatch(deleteFile({ toolingId: props.toolingId, fileId: file.fileId }));
  };

  const handleUpload = (files: FileList) => {
    for (let index = 0; index < files.length; index++) {
      const file = files[index];

      dispatch(upload({ toolingId: props.toolingId, file }));
    }
  };

  return (
    <FileIteratorHandler
      files={filesState.files}
      openedFile={viewerState.file}
      onOpen={handleOpenViewer}
      onClose={handleCloseViwer}
    >
      <FilesContainer
        isLoading={isLoading}
        isFailed={isFailed}
        canEdit={canEdit}
        files={filesState.files}
        uploading={filesState.uploading}
        onUpload={handleUpload}
        onOpen={handleOpenViewer}
        onClose={handleCloseViwer}
        onDelete={handleDelete}
      />

      <BackdropViewer
        open={viewerState.open}
        loading={viewerState.status === 'loading'}
        onClose={handleCloseViwer}
        file={viewerState.file}
        url={viewerState.url}
        downloadUrl={viewerState.downloadUrl}
      />
    </FileIteratorHandler>
  );
};

export default FilesTab;
