import {
  Container, Card, CardHeader, Divider, CardContent,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { FunctionComponent } from 'react'
import OrdersList from './OrdersList'
import { useAppSelector } from '../../../store/hooks'
import { selectSetOrders } from '../../../store/sets/orders/ordersSlice'
import ErrorStateHandler from '../../components/ErrorsStateHandler'
import ListLoadingStateHandler from '../../components/LoadingStateHandler'
import EmptyListHandler from '../../components/EmptyRepairsListHandler'

interface Props {
  setNumber: number;
}

const SetOrders: FunctionComponent<Props> = (props) => {
  const usagesState = useAppSelector(selectSetOrders)

  const isLoading = usagesState.status === 'loading'

  const isFailed = usagesState.status === 'failed'

  return (
    <Box mt={2}>
      <Card>
        <CardHeader title="Orders"/>
        <Divider/>
        <CardContent>
          <ErrorStateHandler failed={isFailed}>
            <ListLoadingStateHandler isLoading={isLoading} itemsCount={3} itemHeight={100}>
              <EmptyListHandler itemsCount={usagesState.orders.length} emptyText="No usages yet">
                <Container maxWidth="md">
                  <OrdersList setNumber={props.setNumber}/>
                </Container>
              </EmptyListHandler>
            </ListLoadingStateHandler>
          </ErrorStateHandler>
        </CardContent>
      </Card>
    </Box>
  )
}

export default SetOrders
