import { Grid } from '@mui/material';
import { FunctionComponent } from 'react';
import TextEditPropertyItem from '../../../components/TextEditPropertyItem';
import { hasReasonError } from '../edit/validation';

interface Props {
  reason: string;
  isLoading: boolean;
  disabled?: boolean;
  onChange: (value: string) => void;
}

const ReasonGridItem: FunctionComponent<Props> = (props) => {
  const handleChangeReason = (value: string) => {
    props.onChange(value);
  };

  return (
    <Grid item xs={12}>
      <TextEditPropertyItem
        caption="Reason"
        value={props.reason}
        isLoading={props.isLoading}
        disabled={props.disabled}
        error={hasReasonError(props.reason)}
        helperText={hasReasonError(props.reason) ? 'Reason cannot be empty' : ''}
        onChange={handleChangeReason}
      />
    </Grid>
  );
};

export default ReasonGridItem;
