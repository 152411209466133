import { Grid, Typography, Skeleton, ToggleButtonGroup, ToggleButton, Divider } from '@mui/material'
import { FunctionComponent } from 'react'
import { ProviderType } from '../../../../api/types/Repairs'
import { SupplierDto } from '../../../../api/types/SupplierData'
import { isEmptyOrSpaces } from '../../../../helpers/stringHelper'
import TextEditPropertyItem from '../../../components/TextEditPropertyItem'
import SupplierAutocomplete from '../../components/SupplierAutocomplete'
import { hasExternalProviderNameError, hasSupplierError } from '../edit/validation'

interface Props {
  providerType: ProviderType;
  providerName: string;
  supplier: SupplierDto | null;
  isLoading: boolean;
  disabled: boolean;
  onChangeProviderType: (providerType: ProviderType) => void;
  onChangeProviderName: (name: string) => void;
  onChangeSupplier: (supplier: SupplierDto | null) => void;
}

const ProviderGridItem: FunctionComponent<Props> = (props) => {
  const handleChangeProviderType = (type: ProviderType) => {
    props.onChangeProviderType(type)
  }

  const handleChangeProviderName = (name: string) => {
    props.onChangeProviderName(name)
  }

  const handleChangeSupplier = (supplier: SupplierDto | null) => {
    props.onChangeSupplier(supplier)
  }

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Divider/>
      </Grid>
      <Grid item container direction="row" spacing={2} alignItems="center">
        <Grid item>
          {props.isLoading ? (
            <Skeleton width={50}/>
          ) : (
             <Typography variant="subtitle1">Provider</Typography>
           )}
        </Grid>
        <Grid item>
          {props.isLoading ? (
            <Skeleton width={200}/>
          ) : (
             <ToggleButtonGroup
               color="primary"
               value={props.providerType}
               exclusive
               disabled={props.disabled}
               onChange={(_, value) => handleChangeProviderType(value as ProviderType)}
             >
               <ToggleButton value="Supplier">Supplier</ToggleButton>
               <ToggleButton value="External">External</ToggleButton>
             </ToggleButtonGroup>
           )}
        </Grid>
        {props.providerType === 'External' && (
          <Grid item xs={12}>
            <TextEditPropertyItem
              caption="Provider name"
              value={props.providerName}
              isLoading={props.isLoading}
              disabled={props.disabled}
              error={hasExternalProviderNameError(props.providerName)}
              helperText={isEmptyOrSpaces(props.providerName) ? 'Provider name cannot be empty' : ''}
              onChange={handleChangeProviderName}
            />
          </Grid>
        )}
        {props.providerType === 'Supplier' && (
          <Grid item xs={12}>
            {props.isLoading ? (
              <Skeleton/>
            ) : (
               <SupplierAutocomplete
                 supplier={props.supplier}
                 disabled={props.disabled}
                 error={hasSupplierError(props.supplier)}
                 label="Supplier name"
                 helperText={hasSupplierError(props.supplier) ? 'Supplier cannot by empty' : undefined}
                 noOptionsText="No suppliers"
                 onSelectSupplier={handleChangeSupplier}
               />
             )}
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Divider/>
      </Grid>
    </Grid>
  )
}

export default ProviderGridItem
