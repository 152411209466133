import { Box, Typography } from '@mui/material';
import { Fragment, FunctionComponent } from 'react';

interface Props extends React.PropsWithChildren {
  itemsCount: number;
  emptyText: string;
}

const EmptyListHandler: FunctionComponent<Props> = (props) => {
  if (!props.itemsCount) {
    return (
      <Box m={5}>
        <Typography variant="h6" textAlign="center" color="GrayText">
          {props.emptyText}
        </Typography>
      </Box>
    );
  }

  return <Fragment>{props.children}</Fragment>;
};

export default EmptyListHandler;
