import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit'
import repairsApi from '../../../api/RepairsApi'
import { RepairDto } from '../../../api/types/Repairs'
import { RootState } from '../../store'
import transfersApi from '../../../api/TransfersApi'
import { TransferDto } from '../../../api/types/Transfers'

export interface TransfersState {
  status: 'idle' | 'loading' | 'failed';
  transfers: TransferDto[];
  actionError?: SerializedError;
}

const initialState: TransfersState = {
  status: 'idle',
  transfers: [],
}

const sliceName = 'tooling-transfers-slice'

export const loadTransfers = createAsyncThunk(`${sliceName}/load`, async (toolingId: number) => {
  return await transfersApi.getTransfers(toolingId)
})

export const transfersSlice = createSlice({
                                            name: sliceName,
                                            initialState,
                                            reducers: {
                                              startLoading: (state) => {
                                                state.status = 'loading'
                                              },
                                              successLoading: (state) => {
                                                state.status = 'idle'
                                              },
                                              fail: (state) => {
                                                state.status = 'failed'
                                              },
                                            },
                                            extraReducers: (build) => {
                                              build.addCase(loadTransfers.pending, (state, action) => {
                                                state.status = 'loading'
                                              })

                                              build.addCase(loadTransfers.fulfilled, (state, action) => {
                                                state.status = 'idle'
                                                state.transfers = action.payload?.items ?? []
                                              })

                                              build.addCase(loadTransfers.rejected, (state, action) => {
                                                state.status = 'failed'
                                                state.actionError = action.error
                                              })
                                            },
                                          })

export const { startLoading, successLoading, fail } = transfersSlice.actions

export const selectToolingTransfers = (state: RootState) => state.tooling.transfers.list

export default transfersSlice.reducer
