import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import { FunctionComponent } from 'react';
import { OwnerDto } from '../../../api/types/OwnerDto';
import { SxProps, Theme } from '@mui/system';

interface Props {
  isLoading?: boolean;
  owners: OwnerDto[];
  selectedOwner: OwnerDto | undefined;
  onChangeOwner: (newOwner: OwnerDto | undefined) => void;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  size?: 'small' | 'medium';
}

const OwnerSelector: FunctionComponent<Props> = (props) => {
  return props.isLoading ? (
    <Skeleton width={200} />
  ) : (
    <FormControl fullWidth sx={props.sx} size={props.size} error={props.error}>
      <InputLabel id="owner-label">Owner</InputLabel>
      <Select
        labelId="owner-label"
        label="Owner"
        disabled={props.disabled}
        error={props.error}
        value={props.selectedOwner?.ownerId || ''}
        onChange={(value) => {
          const ownerId = value.target.value as number;

          if (ownerId) {
            const newOwner = props.owners.find((o) => o.ownerId === ownerId);
            props.onChangeOwner(newOwner);
          } else {
            props.onChangeOwner(undefined);
          }
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {props.owners.map((o) => (
          <MenuItem key={o.ownerId} value={o.ownerId}>
            {o.name}
          </MenuItem>
        ))}
      </Select>
      {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};

export default OwnerSelector;
