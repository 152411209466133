import { List, Menu, MenuItem } from '@mui/material';
import { Fragment, FunctionComponent, useState } from 'react';
import { FileState, UploadFile } from '../../store/tooling/files/filesSlice';
import { FileDto } from '../../api/types/Files';
import FileListItem from './FileListItem';
import UploadFileListItem from './UploadFileListItem';
import _ from 'lodash';

interface Props {
  files: FileState[];
  uploading: UploadFile[];
  canEdit: boolean;
  onOpen: (file: FileDto) => void;
  onDelete: (file: FileDto) => void;
}

enum MenuAction {
  Delete,
}

const FilesList: FunctionComponent<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorParameter, setAnchorParameter] = useState<FileDto | undefined>();

  const handleFileClick = (file: FileDto) => {
    props.onOpen(file);
  };

  const handleClickMore = (target: HTMLElement, file: FileDto) => {
    setAnchorEl(target);
    setAnchorParameter(file);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorParameter(undefined);
  };

  const handleMenuClick = (action: MenuAction) => {
    if (anchorParameter) {
      switch (action) {
        case MenuAction.Delete:
          props.onDelete && props.onDelete(anchorParameter);
          break;

        default:
          break;
      }
    }
    handleClose();
  };

  return (
    <Fragment>
      <List disablePadding>
        {_.sortBy(props.files, 'file.name').map((fileState) => (
          <FileListItem
            key={fileState.file.fileId}
            state={fileState}
            onFileClick={handleFileClick}
            onMoreClick={handleClickMore}
          />
        ))}
        {props.uploading.map((uploadState) => (
          <UploadFileListItem key={uploadState.uploadId} file={uploadState} />
        ))}
      </List>

      <Menu id="menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {
          <MenuItem disabled={!props.canEdit} onClick={() => handleMenuClick(MenuAction.Delete)}>
            Delete
          </MenuItem>
        }
      </Menu>
    </Fragment>
  );
};

export default FilesList;
