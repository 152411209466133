import { EntityState, SerializedError, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { loadSetHistory } from './actions'
import { RootState } from '../../store'
import { SetHistoryActionType } from '../../../api/types/Sets/SetHistoryActionType'

const name = 'set/history'

interface HistoryItem {
  date: string;
  author: string;
  action: Action;
  description?: string;
}

interface Action {
  id: SetHistoryActionType;
  name: string;
}

const historyAdapter = createEntityAdapter<HistoryItem>({ selectId: (item) => item.date })

interface ToolingHistoryState {
  status: 'idle' | 'loading' | 'error';
  items: EntityState<HistoryItem>;
  error?: SerializedError;
}

const initialState: ToolingHistoryState = {
  status: 'idle',
  items: historyAdapter.getInitialState(),
}

const slice = createSlice({
                            name,
                            initialState,
                            reducers: {},
                            extraReducers: (build) => {
                              build.addCase(loadSetHistory.pending, (state) => {
                                state.status = 'loading'
                                historyAdapter.removeAll(state.items)
                              })

                              build.addCase(loadSetHistory.fulfilled, (state, action) => {
                                const items = action.payload.map((i) => {
                                  return {
                                    date: i.timestamp,
                                    author: i.author,
                                    action: { id: i.type.id, name: i.type.name },
                                    description: i.message,
                                  } as HistoryItem
                                })
                                historyAdapter.addMany(state.items, items)
                                state.status = 'idle'
                              })

                              build.addCase(loadSetHistory.rejected, (state, action) => {
                                state.status = 'error'
                                state.error = action.error
                              })
                            },
                          })

export const { selectAll: selectHistory } = historyAdapter.getSelectors<RootState>(
  (state) => state.sets.currentItemHistory.items,
)

export const {} = slice.actions

export default slice.reducer
