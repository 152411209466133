import { useAppSelector } from '../store/hooks';
import { checkPermission } from '../store/user/userSlice';
import { useMsal } from '@azure/msal-react';

export function useCheckResponsibleOrPermissions(responsible: string[], permission: string): boolean {
  const msal = useMsal();

  const hasPermission = useAppSelector((root) => checkPermission(root, permission));
  if (hasPermission) {
    return true;
  }

  const userId = msal.accounts[0].localAccountId;
  const isUserResponsible = responsible.some((r) => r === userId);

  return isUserResponsible ?? false;
}
