import React, { Fragment, FunctionComponent, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  hideEditRightsDialog,
  selectEditRole,
  setRights,
  showEditRightsDialog,
} from '../../../store/administration/roleSlice';
import RoleRightsList from './RoleRightsList';
import RoleRightsEditor from './RoleRightsEditor';

const RoleRightsCard: FunctionComponent = () => {
  const roleState = useAppSelector(selectEditRole);
  const dispatch = useAppDispatch();

  const renderButtons = () => {
    return (
      roleState.status === 'idle' && (
        <Button variant="outlined" color="primary" onClick={() => dispatch(showEditRightsDialog())}>
          Edit
        </Button>
      )
    );
  };

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title="Role rights" action={renderButtons()} />
      <Divider />
      <CardContent>
        <RoleRightsList />
      </CardContent>

      {roleState.openEditRightsDialog && (
        <RoleRightsEditor
          open={roleState.openEditRightsDialog}
          onCancel={() => dispatch(hideEditRightsDialog())}
          onOk={(rights) => {
            dispatch(setRights(rights));
            dispatch(hideEditRightsDialog());
          }}
        />
      )}
    </Card>
  );
};

export default RoleRightsCard;
