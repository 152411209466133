import { Edit } from '@mui/icons-material'
import {
  Card,
  CardHeader,
  Toolbar,
  Tooltip,
  IconButton,
  Divider,
  CardContent,
  Skeleton,
  Typography,
  List, ListItem, ListItemText,
} from '@mui/material'
import { FunctionComponent, useState } from 'react'
import {
  Permissions_Tooling_Suppliers_Edit,
} from '../../../../api/types/permissions'
import { useAppSelector, useAccount } from '../../../../store/hooks'
import { selectToolingView } from '../../../../store/tooling/toolingViewSlice'
import { checkPermission } from '../../../../store/user/userSlice'
import { SCRAPPED_STATUS } from '../../../../store/types/statuses'
import _ from 'lodash'
import EditSuppliersDialog from './EditSuppliersDialog'

interface Props {
  toolingId: string | number;
}

export const SuppliersCard: FunctionComponent<Props> = ({ toolingId }) => {
  const [editSuppliers, setEditSuppliers] = useState(false)
  const toolingViewState = useAppSelector(selectToolingView)

  const isLoading = toolingViewState.status === 'loading'

  const haveEditSuppliersRight = useAppSelector((root) => checkPermission(root, Permissions_Tooling_Suppliers_Edit))
  const userAccount = useAccount()

  const calcEditPermissions = (haveRight: boolean) => {
    const responsibles = toolingViewState.tooling?.responsibles.filter((r) => r.userId === userAccount.localAccountId)
    const isScrappedStatus = toolingViewState.tooling?.summary?.status?.name === SCRAPPED_STATUS

    if (isScrappedStatus) {
      return (): boolean => false
    }

    return (): boolean => {
      if (haveRight) {
        return true
      } else {
        if (responsibles) {
          return responsibles.length > 0
        } else {
          return false
        }
      }
    }
  }

  const canSuppliersSummary = calcEditPermissions(haveEditSuppliersRight)

  return (
    <Card sx={{ mt: 5 }}>
      <CardHeader
        title="Suppliers"
        titleTypographyProps={{
          variant: 'textLgSemibold',
        }}
        action={
          toolingViewState.status === 'idle' && canSuppliersSummary() && (
            <Toolbar>
              <Tooltip title="Add or remove suppliers">
                <IconButton onClick={() => setEditSuppliers(true)}>
                  <Edit/>
                </IconButton>
              </Tooltip>
            </Toolbar>
          )
        }
      />
      <Divider/>
      <CardContent>
        <List>
          {isLoading ? (
            <Skeleton/>
          ) : (
             toolingViewState.tooling?.suppliers &&
             _.sortBy(toolingViewState.tooling?.suppliers, (s) => s.name).map((supplier) => (
               <ListItem key={supplier.supplierId} disablePadding>
                 <ListItemText
                   primary={<Typography variant="textSmRegular">{supplier.name}</Typography>}
                 />
               </ListItem>
             ))
           )}
        </List>
      </CardContent>
      <EditSuppliersDialog
        open={editSuppliers}
        toolingId={toolingId}
        suppliers={toolingViewState.tooling?.suppliers || []}
        onClose={() => setEditSuppliers(false)}
      />
    </Card>
  )
}
