import {
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  IconButton,
  ListItemIcon,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { FileDto } from '../../api/types/Files';
import { FileState } from '../../store/tooling/files/filesSlice';
import MoreIcon from '@mui/icons-material/MoreVert';
import { PlayArrow, ErrorOutline, FileCopy } from '@mui/icons-material';
import bytesToSize from '../../helpers/bytesToSize';

interface FileListItemProps {
  state: FileState;
  onFileClick: (file: FileDto) => void;
  onMoreClick: (target: HTMLElement, file: FileDto) => void;
}

const FileListItem: FunctionComponent<FileListItemProps> = (props) => {
  const file = props.state.file;

  const handleFileClick = (file: FileDto) => {
    props.onFileClick(file);
  };

  const handleClickMore = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, file: FileDto) => {
    props.onMoreClick(event.currentTarget, file);
  };

  return (
    <ListItem key={file.fileId} disableGutters>
      <ListItemButton disableGutters onClick={() => handleFileClick(file)}>
        <ListItemIcon>
          <FileListItemIcon fileState={props.state} />
        </ListItemIcon>
        <ListItemText primary={file.name} secondary={bytesToSize(file.size)} />
      </ListItemButton>
      <ListItemSecondaryAction>
        <Tooltip title="More options">
          <IconButton edge="end" onClick={(event) => handleClickMore(event, file)} size="large">
            <MoreIcon />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

interface FileListItemIconProps {
  fileState: FileState;
}

const FileListItemIcon: FunctionComponent<FileListItemIconProps> = (props) => {
  switch (props.fileState.status) {
    case 'loading': {
      return <PlayArrow />;
    }

    case 'failed': {
      return (
        <Tooltip title={props.fileState.actionError ?? ''}>
          <ErrorOutline sx={{ color: (theme) => theme.palette.error.main }} />
        </Tooltip>
      );
    }

    default:
      return <FileCopy />;
  }
};

export default FileListItem;
