import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OwnerDto } from '../../api/types/OwnerDto';
import { StatusDto } from '../../api/types/StatusDto';
import { RootState } from '../store';

export interface ToolingListState {
  toolingFilter?: string;
  statusFilter?: StatusDto;
  ownerFilter?: OwnerDto;
  supplierFilter?: string;
  unitFilter?: string;
  responsibleFilter?: string;
}

const initialState: ToolingListState = {};

export const toolingListSlice = createSlice({
  name: 'tooling-list-filters',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setToolingFilter: (state, action: PayloadAction<string | undefined>) => {
      state.toolingFilter = action.payload;
    },

    setStatusFilter: (state, action: PayloadAction<StatusDto | undefined>) => {
      state.statusFilter = action.payload;
    },

    setOwnerFilter: (state, action: PayloadAction<OwnerDto | undefined>) => {
      state.ownerFilter = action.payload;
    },

    setSupplierFilter: (state, action: PayloadAction<string | undefined>) => {
      state.supplierFilter = action.payload;
    },

    setUnitFilter: (state, action: PayloadAction<string | undefined>) => {
      state.unitFilter = action.payload;
    },

    setResponsibleFilter: (state, action: PayloadAction<string | undefined>) => {
      state.responsibleFilter = action.payload;
    },

    clearAllFilters: (state) => {
      state.toolingFilter = undefined;
      state.statusFilter = undefined;
      state.ownerFilter = undefined;
      state.supplierFilter = undefined;
      state.unitFilter = undefined;
      state.responsibleFilter = undefined;
    },
  },
});

export const {
  setToolingFilter,
  setStatusFilter,
  setOwnerFilter,
  setSupplierFilter,
  setUnitFilter,
  setResponsibleFilter,
  clearAllFilters,
} = toolingListSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectToolingListFilters = (state: RootState) => state.tooling.list.filters;

export default toolingListSlice.reducer;
