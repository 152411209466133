import authService from '../auth/AuthService';
import { API_ADDRESS } from './api';
import { handleErrors } from './errorHandler';
import { msalFetch } from './graph';
import { StatusDto } from './types/StatusDto';

const STATUSES_API_ADDRESS = `${API_ADDRESS}/api/statuses`;

export class StatusesApi {
  async getStatuses() {
    const result = await authService.getIdentity();

    if (result) {
      return msalFetch()
        .get(STATUSES_API_ADDRESS, result.accessToken)
        .then(handleErrors)
        .then<StatusDto[]>((res) => res.json());
    }
  }
}

const statusesApi = new StatusesApi();

export default statusesApi;
