import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit'
import repairsApi from '../../../api/RepairsApi'
import { RepairDto } from '../../../api/types/Repairs'
import { RootState } from '../../store'

export interface RepairsState {
  status: 'idle' | 'loading' | 'failed';
  repairs: RepairDto[];
  actionError?: SerializedError;
}

const initialState: RepairsState = {
  status: 'idle',
  repairs: [],
}

const sliceName = 'tooling-operation-slice'

export const loadRepairs = createAsyncThunk(`${sliceName}/load`, async (toolingId: number) => {
  return await repairsApi.getRepairs(toolingId)
})

export const repairsSlice = createSlice({
                                          name: sliceName,
                                          initialState,
                                          reducers: {
                                            startLoading: (state) => {
                                              state.status = 'loading'
                                            },
                                            successLoading: (state) => {
                                              state.status = 'idle'
                                            },
                                            fail: (state) => {
                                              state.status = 'failed'
                                            },
                                          },
                                          extraReducers: (build) => {
                                            build.addCase(loadRepairs.pending, (state, action) => {
                                              state.status = 'loading'
                                            })

                                            build.addCase(loadRepairs.fulfilled, (state, action) => {
                                              state.status = 'idle'
                                              state.repairs = action.payload?.items ?? []
                                            })

                                            build.addCase(loadRepairs.rejected, (state, action) => {
                                              state.status = 'failed'
                                              state.actionError = action.error
                                            })
                                          },
                                        })

export const { startLoading, successLoading, fail } = repairsSlice.actions

export const selectToolingRepairs = (state: RootState) => state.tooling.repairs.list

export default repairsSlice.reducer
