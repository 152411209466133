import authService from '../auth/AuthService'
import { API_ADDRESS } from './api'
import { handleErrors } from './errorHandler'
import { msalFetch } from './graph'
import { TransferDto, TransfersResponse, UpdateTransferRequest } from './types/Transfers'

const TOOLING_API_ADDRESS = `${API_ADDRESS}/api/tooling`

export class TransfersApi {
  async get(toolingId: number, transferId: number) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .get(`${TOOLING_API_ADDRESS}/${toolingId}/transfers/${transferId}`, result.accessToken)
        .then(handleErrors)
        .then<TransferDto>((res) => res.json())
    }
  }

  async getTransfers(toolingId: number) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .get(`${TOOLING_API_ADDRESS}/${toolingId}/transfers`, result.accessToken)
        .then(handleErrors)
        .then<TransfersResponse>((res) => res.json())
    }
  }

  async createTransfer(toolingId: number, request: UpdateTransferRequest) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .post(`${TOOLING_API_ADDRESS}/${toolingId}/transfers`, result.accessToken, JSON.stringify(request), {
          'content-type': 'application/json',
        })
        .then(handleErrors)
    }
  }

  async updateTransfer(toolingId: number, transferId: number, request: UpdateTransferRequest) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .put(`${TOOLING_API_ADDRESS}/${toolingId}/transfers/${transferId}`, result.accessToken, JSON.stringify(request), {
          'content-type': 'application/json',
        })
        .then(handleErrors)
    }
  }

  async deleteTransfer(toolingId: number, transferId: number) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .delete(`${TOOLING_API_ADDRESS}/${toolingId}/transfers/${transferId}`, result.accessToken)
        .then(handleErrors)
    }
  }
}

const transfersApi = new TransfersApi()

export default transfersApi
