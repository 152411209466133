import { Delete } from '@mui/icons-material'
import {
  Container,
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  TableBody,
  Table,
  Button,
  Tooltip,
  IconButton,
} from '@mui/material'
import _ from 'lodash'
import { Fragment, FunctionComponent, useEffect } from 'react'
import ErrorBox from '../../../components/ErrorBox'
import ErrorDialog from '../../../components/ErrorDialog'
import GraphAvatar from '../../../components/GraphAvatar'
import SelectUserDialog from '../../../components/SelectUserDialog'
import {
  addUser,
  hideSelectUserDialog,
  loadUsers,
  selectUsersList,
  setActionError,
  showSelectUserDialog,
} from '../../../store/administration/usersListSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import LoadingTableBody from '../../components/LoadingTableBody'
import EnableLoginSwitch from './components/EnableLoginSwitch'

const UsersListPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()

  const usersListState = useAppSelector(selectUsersList)

  useEffect(() => {
    dispatch(loadUsers())
  }, [dispatch])

  const handleAddUser = () => {
    dispatch(showSelectUserDialog())
  }

  return (
    <Container maxWidth="md">
      <Box mt={4} mb={4}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs>
            <Typography variant="h4">Users</Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleAddUser}>
              Add user
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box mt={4}>
        {usersListState.status === 'loading' && (
          <Table>
            <TableBody>
              <LoadingTableBody rows={5} columns={2}/>
            </TableBody>
          </Table>
        )}
        {usersListState.status === 'idle' && (
          <Fragment>
            <Grid container direction="row">
              <Grid item xs></Grid>
              <Grid item>
                <Typography>Enable login</Typography>
              </Grid>
            </Grid>
            <List>
              {_.sortBy(usersListState.users, (u) => u.displayName ?? u.userPrincipalName).map((u) => {
                return (
                  <ListItem
                    key={u.userId}
                    divider
                    secondaryAction={u.status !== 'loading' && <EnableLoginSwitch user={u}/>}
                  >
                    <ListItemAvatar>
                      <GraphAvatar userId={u.userId}/>
                    </ListItemAvatar>
                    <ListItemText
                      primary={u.displayName ?? u.userPrincipalName}
                      secondary={u.userPrincipalName}
                      primaryTypographyProps={{ sx: { overflowWrap: 'anywhere' } }}
                      secondaryTypographyProps={{ sx: { overflowWrap: 'anywhere' } }}
                    ></ListItemText>
                  </ListItem>
                )
              })}
            </List>
          </Fragment>
        )}
        {usersListState.status === 'failed' && usersListState.error && (
          <ErrorBox
            code={usersListState.error.name}
            description={usersListState.error.message}
            sx={{ minHeight: '90vh' }}
          >
            <Button variant="contained" onClick={() => dispatch(loadUsers())}>
              Try again
            </Button>
          </ErrorBox>
        )}
      </Box>
      {usersListState.selectUsersDialogOpened && (
        <SelectUserDialog
          open={usersListState.selectUsersDialogOpened}
          alreadyAddedUsersId={usersListState.users.map((u) => u.userId)}
          onCancel={() => dispatch(hideSelectUserDialog())}
          onOk={(userId) => {
            dispatch(hideSelectUserDialog())
            dispatch(addUser(userId))
          }}
        />
      )}
      <ErrorDialog
        open={usersListState.actionError !== undefined}
        title={usersListState.actionError?.name || 'Error'}
        text={usersListState.actionError?.message ?? ''}
        onOk={() => dispatch(setActionError())}
      />
    </Container>
  )
}

export default UsersListPage
