import { FunctionComponent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import ErrorStateHandler from '../../../../components/ErrorsStateHandler';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import EnhancedTableHead, { HeadCell } from '../../../../../components/EnhancedTableHead';
import LoadingTableBody from '../../../../components/LoadingTableBody';
import { orderBy } from 'lodash';
import { selectHistory } from '../../../../../store/tooling/history/historySlice';
import { loadHistory } from '../../../../../store/tooling/history/actions';

const headers: HeadCell[] = [
  { id: 'date', numeric: false, label: 'Date', disabled: true },
  { id: 'author', numeric: false, label: 'Author', disabled: true },
  { id: 'action', numeric: false, label: 'Action', disabled: true },
];

const HistoryTab: FunctionComponent<{ toolingId: number }> = (props) => {
  const dispatch = useAppDispatch();

  const historyState = useAppSelector((root) => root.tooling.history);
  const items = useAppSelector(selectHistory);

  const isLoading = historyState.status === 'loading';
  const isIdle = historyState.status === 'idle';
  const isFailed = historyState.status === 'error';

  useEffect(() => {
    dispatch(loadHistory(props.toolingId));
  }, [dispatch, props.toolingId]);

  return (
    <Card>
      <CardHeader title="History" />
      <Divider />
      <CardContent>
        <ErrorStateHandler failed={isFailed}>
          <TableContainer>
            <Table>
              <EnhancedTableHead leftColumn={false} headers={headers} order={'desc'} orderBy={'date'} />
              {isLoading ? (
                <LoadingTableBody columns={3} rows={10} />
              ) : (
                <TableBody>
                  {orderBy(items, (i) => i.date, 'desc').map((row) => (
                    <TableRow key={row.date}>
                      <TableCell>
                        <Typography>{new Date(row.date).toLocaleString()}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{row.author}</Typography>
                      </TableCell>
                      <TableCell>
                        {row.description ? (
                          <Stack direction="column">
                            <Typography>{row.description || ''}</Typography>
                          </Stack>
                        ) : (
                          <Typography>{row.action.name}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </ErrorStateHandler>
      </CardContent>
    </Card>
  );
};

export default HistoryTab;
