import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { Tooling, ToolingSummary, ToolingUnit } from '../types/tooling'
import { RootState } from '../store'
import { SupplierDto } from '../../api/types/SupplierData'
import { ResponsibleDto, TechnicalInfoDto, ToolingAccountingDto, ToolingDto } from '../../api/types/ToolingDto'
import { createUnitFromUnitDto } from '../types/searchUnit'
import toolingApi from '../../api/ToolingApi'
import { saveComment } from './comments'

export interface ToolingViewState {
  status: 'idle' | 'loading' | 'failed';
  tooling?: Tooling;
  actionError?: SerializedError;
  openResponsibleDialog: boolean;
  openAccountingDialog: boolean;
  editComment: boolean;
  saveComment: boolean;
}

const initialState: ToolingViewState = {
  status: 'idle',
  openResponsibleDialog: false,
  openAccountingDialog: false,
  editComment: false,
  saveComment: false,
}

export const load = createAsyncThunk<ToolingDto | undefined, string | number, { state: RootState }>(
  'tooling-view/load',
  async (toolingId: string | number) => {
    return await toolingApi.getTooling(toolingId)
  },
)

export const toolingViewSlice =
               createSlice(
                 {
                   name: 'tooling-view',
                   initialState,
                   reducers: {
                     startLoading: (state) => {
                       state.status = 'loading'
                     },
                     successLoading: (state) => {
                       state.status = 'idle'
                     },
                     fail: (state) => {
                       state.status = 'failed'
                     },
                     updateUnits: (state, action: PayloadAction<ToolingUnit[]>) => {
                       if (state.tooling) {
                         state.tooling.units = [...action.payload]
                       }
                     },
                     updateSupplier: (state, action: PayloadAction<SupplierDto>) => {
                       if (state.tooling) {
                         state.tooling.suppliers = [action.payload]
                       }
                     },
                     updateTechnicalInfo: (
                       state, action: PayloadAction<TechnicalInfoDto>) => {
                       if (state.tooling) {
                         state.tooling.technicalInfo = action.payload
                       }
                     },
                     updateAccounting: (
                       state, action: PayloadAction<ToolingAccountingDto>) => {
                       if (state.tooling) {
                         state.tooling.accounting = action.payload
                       }
                     },
                     updateSummary: (state, action: PayloadAction<ToolingSummary>) => {
                       if (state.tooling) {
                         state.tooling.summary = action.payload
                       }
                     },
                     updateResponsible: (state, action: PayloadAction<ResponsibleDto[]>) => {
                       if (state.tooling) {
                         state.tooling.responsibles = action.payload
                       }
                     },
                     showActionError: (state, action: PayloadAction<Error>) => {
                       state.actionError = action.payload
                     },
                     hideActionError: (state) => {
                       state.actionError = undefined
                     },
                     showEditResponsibleDialog: (state) => {
                       state.openResponsibleDialog = true
                     },
                     hideEditResponsibleDialog: (state) => {
                       state.openResponsibleDialog = false
                     },
                     showEditAccountingDialog: (state) => {
                       state.openAccountingDialog = true
                     },
                     hideEditAccountingDialog: (state) => {
                       state.openAccountingDialog = false
                     },
                     setEditComment: (state, action: PayloadAction<boolean>) => {
                       state.editComment = action.payload
                     },
                   },
                   extraReducers: (build) => {
                     build.addCase(load.pending, (state, action) => {
                       state.status = 'loading'
                     })

                     build.addCase(load.fulfilled, (state, action) => {
                       state.status = 'idle'

                       state.tooling = {
                         toolingId: action.payload?.toolingId,
                         summary: action.payload?.summary,
                         accounting: action.payload?.accounting,
                         technicalInfo: action.payload?.technicalInfo,
                         suppliers: action.payload?.suppliers,
                         units: action.payload?.units.map((u) => createUnitFromUnitDto(u)),
                         responsibles: action.payload?.responsibles,
                         statusChangedAt: action.payload?.statusChangedAt,
                         repairsCount: action.payload?.repairsCount,
                         transfersCount: action.payload?.transfersCount,
                         ordersCount: action.payload?.ordersCount,
                         filesCount: action.payload?.filesCount,
                         comment: action.payload?.comment,
                         sets: action.payload?.sets,
                       } as Tooling
                     })

                     build.addCase(load.rejected, (state, action) => {
                       state.status = 'idle'
                       state.actionError = action.error
                     })

                     build.addCase(saveComment.pending, (state, action) => {
                       state.saveComment = true
                     })

                     build.addCase(saveComment.fulfilled, (state, action) => {
                       if (state.tooling) {
                         state.editComment = false
                         state.tooling.comment = action.meta.arg.comment
                       }

                       state.saveComment = false
                     })

                     build.addCase(saveComment.rejected, (state, action) => {
                       state.actionError = action.error
                       state.saveComment = false
                     })
                   },
                 })

export const {
               startLoading,
               successLoading,
               fail,
               updateUnits,
               updateSupplier,
               updateTechnicalInfo,
               updateAccounting,
               updateSummary,
               showActionError,
               hideActionError,
               showEditResponsibleDialog,
               hideEditResponsibleDialog,
               updateResponsible,
               showEditAccountingDialog,
               hideEditAccountingDialog,
               setEditComment,
             } = toolingViewSlice.actions

export const selectToolingView = (state: RootState) => state.tooling.view.main
export const selectToolingViewActionError = (state: RootState) => state.tooling.view.main.actionError
export const selectToolingViewStatus = (state: RootState) => state.tooling.view.main.tooling?.summary?.status

export default toolingViewSlice.reducer
