import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { CurrencyDto } from '../../../api/types/CurrencyDto'
import { SupplierDto } from '../../../api/types/SupplierData'
import { RootState } from '../../store'
import { ProviderType, UpdateRepairRequest } from '../../../api/types/Repairs'
import repairsApi from '../../../api/RepairsApi'

export interface CreateRepairState {
  status: 'idle' | 'loading' | 'saving' | 'failed';
  reason: string;
  providerType: ProviderType;
  providerName?: string;
  providerId?: number;
  supplier: SupplierDto | null;
  cost?: number;
  currency?: CurrencyDto;
  repairDate?: string;
  repairOrder: string;
  comment: string;
  responsible: string[];
  pageError?: SerializedError;
  actionError?: SerializedError;
  showConfirmDeleteDialog: boolean;
}

const initialState: CreateRepairState = {
  status: 'idle',
  reason: '',
  providerType: 'Supplier',
  supplier: null,
  repairOrder: '',
  comment: '',
  responsible: [],
  showConfirmDeleteDialog: false,
}

const sliceName = 'tooling-operation-create-slice'

export const save = createAsyncThunk(
  `${sliceName}/save`,
  async (data: { toolingId: number; request: UpdateRepairRequest }) => {
    await repairsApi.createRepair(data.toolingId, data.request)
  },
)

export const createRepairSlice = createSlice({
                                               name: sliceName,
                                               initialState,
                                               reducers: {
                                                 startLoading: (state) => {
                                                   state.status = 'loading'
                                                 },
                                                 successLoading: (state) => {
                                                   state.status = 'idle'
                                                 },
                                                 fail: (state) => {
                                                   state.status = 'failed'
                                                 },
                                                 setReason: (state, action: PayloadAction<string>) => {
                                                   state.reason = action.payload
                                                 },
                                                 setProviderType: (state, action: PayloadAction<ProviderType>) => {
                                                   state.providerType = action.payload
                                                 },
                                                 setSupplier: (state, action: PayloadAction<SupplierDto | null>) => {
                                                   state.supplier = action.payload
                                                 },
                                                 setProviderName: (state, action: PayloadAction<string>) => {
                                                   state.providerName = action.payload
                                                 },
                                                 setCost: (state, action: PayloadAction<number | undefined>) => {
                                                   state.cost = action.payload
                                                 },
                                                 setCurrency: (
                                                   state, action: PayloadAction<CurrencyDto | undefined>) => {
                                                   state.currency = action.payload
                                                 },
                                                 setRepairDate: (state, action: PayloadAction<string | undefined>) => {
                                                   state.repairDate = action.payload
                                                 },
                                                 setRepairOrder: (state, action: PayloadAction<string>) => {
                                                   state.repairOrder = action.payload
                                                 },
                                                 setComment: (state, action: PayloadAction<string>) => {
                                                   state.comment = action.payload
                                                 },
                                                 hideActionError: (state) => {
                                                   state.actionError = undefined
                                                 },
                                                 showConfirmDeleteDialog: (state) => {
                                                   state.showConfirmDeleteDialog = true
                                                 },
                                                 hideConfirmDeleteTooling: (state) => {
                                                   state.showConfirmDeleteDialog = false
                                                 },
                                                 clear: (state) => {
                                                   return initialState
                                                 },
                                               },
                                               extraReducers: (build) => {
                                                 build.addCase(save.pending, (state, action) => {
                                                   state.status = 'saving'
                                                 })

                                                 build.addCase(save.fulfilled, (state, action) => {
                                                   state.status = 'idle'
                                                 })

                                                 build.addCase(save.rejected, (state, action) => {
                                                   state.status = 'idle'
                                                   state.actionError = action.error
                                                 })
                                               },
                                             })

export const {
               startLoading,
               successLoading,
               fail,
               setReason,
               setProviderType,
               setSupplier,
               setProviderName,
               setCost,
               setCurrency,
               setRepairDate,
               setRepairOrder,
               setComment,
               hideActionError,
               showConfirmDeleteDialog,
               hideConfirmDeleteTooling,
               clear,
             } = createRepairSlice.actions

export const selectCreateRepair = (state: RootState) => state.tooling.repairs.create

export default createRepairSlice.reducer
