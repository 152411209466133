import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { OrderType } from '../../helpers/tableCompare';
import { RootState } from '../store';

export type SortableHeads = 'toolingNumber' | 'status' | 'owner' | 'usages' | 'suppliers' | 'units' | 'responsible';

export interface ToolingListState {
  status: 'idle' | 'loading' | 'failed';
  statusesStatus: 'idle' | 'loading' | 'failed';
  ownersStatus: 'idle' | 'loading' | 'failed';
  isExporting: boolean;
  error?: Error;
  actionError?: SerializedError;
  pageSize: number;
  page: number;
  sortDirection: OrderType;
  sortBy: SortableHeads;
}

const initialState: ToolingListState = {
  status: 'idle',
  statusesStatus: 'idle',
  ownersStatus: 'idle',
  isExporting: false,
  pageSize: Number.parseInt(localStorage.getItem('tooling.list.pageSize') ?? '100'),
  page: 0,
  sortDirection: (localStorage.getItem('tooling.list.sort.direction') ?? 'asc') as OrderType,
  sortBy: (localStorage.getItem('tooling.list.sort.by') ?? 'toolingNumber') as SortableHeads,
};

export const toolingListSlice = createSlice({
  name: 'tooling-list',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    startLoading: (state) => {
      state.status = 'loading';
    },
    successLoading: (state) => {
      state.status = 'idle';
    },
    fail: (state, action?: PayloadAction<Error>) => {
      state.status = 'failed';
      state.error = action?.payload;
    },
    startLoadingStatuses: (state) => {
      state.statusesStatus = 'loading';
    },
    successLoadingStatuses: (state) => {
      state.statusesStatus = 'idle';
    },
    failLoadingStatuses: (state, action?: PayloadAction<Error>) => {
      state.statusesStatus = 'failed';
    },
    startLoadingOwners: (state) => {
      state.ownersStatus = 'loading';
    },
    successLoadingOwners: (state) => {
      state.ownersStatus = 'idle';
    },
    failLoadingOwners: (state, action?: PayloadAction<Error>) => {
      state.ownersStatus = 'failed';
    },

    startExporting: (state) => {
      state.isExporting = true;
    },

    stopExporting: (state) => {
      state.isExporting = false;
    },

    setActionError: (state, action: PayloadAction<Error | undefined>) => {
      state.actionError = action.payload;
    },

    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.page = 0;
      state.pageSize = action.payload;

      localStorage.setItem('tooling.list.pageSize', state.pageSize.toString());
    },

    changePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    setSortOrder: (state, action: PayloadAction<OrderType>) => {
      state.sortDirection = action.payload;

      localStorage.setItem('tooling.list.sort.direction', state.sortDirection);
    },

    setSortBy: (state, action: PayloadAction<SortableHeads>) => {
      state.sortBy = action.payload;

      localStorage.setItem('tooling.list.sort.by', state.sortBy);
    },
  },
});

export const {
  startLoading,
  successLoading,
  fail,
  startLoadingStatuses,
  successLoadingStatuses,
  failLoadingStatuses,
  startLoadingOwners,
  successLoadingOwners,
  failLoadingOwners,
  startExporting,
  stopExporting,
  setActionError,
  setRowsPerPage,
  changePage,
  setSortOrder,
  setSortBy,
} = toolingListSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectToolingList = (state: RootState) => state.tooling.list.toolingList;

export default toolingListSlice.reducer;
