import authService from '../auth/AuthService';
import { API_ADDRESS } from './api';
import { handleErrors } from './errorHandler';
import { msalFetch } from './graph';
import { CurrencyDto } from './types/CurrencyDto';

const CURRENCIES_API_ADDRESS = `${API_ADDRESS}/api/currencies`;

export class CurrenciesApi {
  async getCurrencies() {
    const result = await authService.getIdentity();

    if (result) {
      return msalFetch()
        .get(CURRENCIES_API_ADDRESS, result.accessToken)
        .then(handleErrors)
        .then<CurrencyDto[]>((res) => res.json());
    }
  }
}

const currenciesApi = new CurrenciesApi();

export default currenciesApi;
