import { Fragment, FunctionComponent, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router';
import ErrorDialog from '../../../components/ErrorDialog';
import {
  deleteRole,
  hideActionError,
  hideConfirmDeleteDialog,
  loadRole,
  saveChanges,
  selectEditRole,
  setEditName,
  showConfirmDeleteDialog,
} from '../../../store/administration/roleSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import RoleUsersCard from './RoleUsersCard';
import ErrorBox from '../../../components/ErrorBox';
import RoleRightsCard from './RoleRightsCard';
import { isEmptyOrSpaces } from '../../../helpers/stringHelper';
import { ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import YesNoDialog from '../../../components/YesNoDialog';

const RolePage: FunctionComponent = (props) => {
  let { roleId } = useParams();

  const roleState = useAppSelector(selectEditRole);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const idle = roleState.status === 'idle';
  const loading = roleState.status === 'loading';
  const saving = roleState.status === 'saving';
  const failed = roleState.status === 'failed';

  const cardError = isEmptyOrSpaces(roleState.editName);

  useEffect(() => {
    dispatch(loadRole(roleId ?? 0));
  }, [roleId, dispatch]);

  const handleSaveChanges = () => {
    dispatch(saveChanges({ roleId: roleId ?? 0, name: roleState.editName }));
  };

  const handleDelete = () => {
    dispatch(showConfirmDeleteDialog());
  };

  return (
    <Box mt={4}>
      <Container maxWidth='md'>
        {(idle || loading || saving) && (
          <Fragment>
            <Box mb={2}>
              <Grid container spacing={3} alignItems='center'>
                <Grid item>
                  <Tooltip title='Back'>
                    <IconButton onClick={() => navigate(`/administration/roles`)}>
                      <ArrowBackIos />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant='h5'>{loading ? <Skeleton width={50} /> : `Role ${roleState.name}`}</Typography>
                </Grid>
                <Grid item xs />
                <Grid item>
                  <Button variant='contained' color='error' disabled={loading || saving} onClick={handleDelete}>
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Paper>
              <Box p={2}>
                <Grid container direction='column' spacing={2}>
                  <Grid item>
                    <Typography variant='h3'>
                      {loading ? (
                        <Skeleton width={100} />
                      ) : (
                        <TextField
                          variant='outlined'
                          label='Name'
                          error={isEmptyOrSpaces(roleState.editName)}
                          value={roleState.editName}
                          onChange={(e) => dispatch(setEditName(e.target.value))}
                        />
                      )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {(idle || saving) && (
                      <Button variant='contained' disabled={saving || cardError} onClick={handleSaveChanges}>
                        {saving ? <CircularProgress size={20} /> : 'Save changes'}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Paper>

            <Box mt={3}>
              <Grid container spacing={3} alignItems='stretch'>
                <Grid item xs={12} lg={6}>
                  <RoleUsersCard />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <RoleRightsCard />
                </Grid>
              </Grid>
            </Box>
          </Fragment>
        )}

        {failed && roleState.pageError && (
          <ErrorBox
            code={roleState.pageError.name}
            description={roleState.pageError.message}
            sx={{ minHeight: '90vh' }}
          >
            <Button variant='contained' onClick={() => dispatch(loadRole(roleId ?? 0))}>
              Try again
            </Button>
          </ErrorBox>
        )}
      </Container>

      <ErrorDialog
        open={roleState.actionError !== undefined}
        title={roleState.actionError?.name || 'Error'}
        text={roleState.actionError?.message ?? ''}
        onOk={() => dispatch(hideActionError())}
      />

      <YesNoDialog
        open={roleState.openConfirmDeleteDialog}
        title='Delete role'
        onNo={() => dispatch(hideConfirmDeleteDialog())}
        onYes={() => {
          dispatch(hideConfirmDeleteDialog());
          dispatch(deleteRole(roleId ?? 0))
            .unwrap()
            .then(() => {
              navigate(`/administration/roles`);
            })
            .catch(() => {
            });
        }}
      >
        {`Are you sure you want to delete role ${roleState.name}`}
      </YesNoDialog>
    </Box>
  );
};

export default RolePage;
