import { Box, Grid, Button, Stack } from '@mui/material'
import { FunctionComponent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { loadRepairs, selectToolingRepairs } from '../../../../../store/tooling/repairs/repairsSlice'
import { loadTransfers, selectToolingTransfers } from '../../../../../store/tooling/transfers/transfersSlice'
import { selectToolingView } from '../../../../../store/tooling/toolingViewSlice'
import EmptyListHandler from '../../../../components/EmptyRepairsListHandler'
import ErrorStateHandler from '../../../../components/ErrorsStateHandler'
import ListLoadingStateHandler from '../../../../components/LoadingStateHandler'
import OperationsList from './OperationsList'
import { useRepairPermissions, useTransferPermissions } from './hooks'

interface Props {
  toolingId: number;
}

const OperationTab: FunctionComponent<Props> = (props) => {
  const toolingState = useAppSelector(selectToolingView)
  const repairsState = useAppSelector(selectToolingRepairs)
  const transfersState = useAppSelector(selectToolingTransfers)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const isLoading = repairsState.status === 'loading' && transfersState.status === 'loading'
  const isIdle = repairsState.status === 'idle' && transfersState.status === 'idle'
  const isFailed = repairsState.status === 'failed' && transfersState.status === 'failed'

  const repairPermissions = useRepairPermissions(toolingState.tooling?.responsibles?.map((u) => u.userId) ?? [])
  const transferPermissions = useTransferPermissions(toolingState.tooling?.responsibles?.map((u) => u.userId) ?? [])

  const canEditRepairs = isIdle && repairPermissions
  const canEditTransfers = isIdle && transferPermissions

  useEffect(() => {
    dispatch(loadRepairs(props.toolingId))
    dispatch(loadTransfers(props.toolingId))
  }, [dispatch, props.toolingId])

  const handleAddRepair = () => {
    navigate(`/tooling/${props.toolingId}/repairs/new`)
  }

  const handleAddTransfer = () => {
    navigate(`/tooling/${props.toolingId}/transfers/new`)
  }

  return (
    <ErrorStateHandler failed={isFailed}>
      <Box mt={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs/>
          <Grid item>
            <Stack direction={'row'} spacing={1}>
              <Button variant="contained" color="primary" disabled={!canEditRepairs} onClick={handleAddRepair}>
                + Add repair
              </Button>
              <Button variant="contained" color="primary" disabled={!canEditTransfers} onClick={handleAddTransfer}>
                + Add transfer
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <ListLoadingStateHandler isLoading={isLoading} itemsCount={3} itemHeight={100}>
        <EmptyListHandler itemsCount={repairsState.repairs.length + transfersState.transfers.length}
                          emptyText="No operations yet">
          <OperationsList toolingId={props.toolingId}/>
        </EmptyListHandler>
      </ListLoadingStateHandler>
    </ErrorStateHandler>
  )
}

export default OperationTab
