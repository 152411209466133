import { API_ADDRESS } from './api'
import { handleErrors } from './errorHandler'
import { msalFetch } from './graph'
import authService from '../auth/AuthService'
import { ApplicationInformation } from './types/application/ApplicationInformationDto'

const GET_TR_APPLICATION_INFORMATION = `${API_ADDRESS}/api/info`

export class ApplicationInformationApi {
  async getTrApplicationInfo() {
    const token = await authService.getIdentity()
    return await msalFetch()
      .get(GET_TR_APPLICATION_INFORMATION, token.accessToken)
      .then(handleErrors)
      .then<ApplicationInformation>(async (res) => await res.json())
  }
}

const applicationInformationApi = new ApplicationInformationApi()

export default applicationInformationApi
