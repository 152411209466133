import authService from '../auth/AuthService';
import { API_ADDRESS } from './api';
import { handleErrors } from './errorHandler';
import { msalFetch } from './graph';
import { OrdersResponse } from './types/Usages';

const TOOLING_API_ADDRESS = `${API_ADDRESS}/api/tooling`;

export class UsagesApi {
  async getOrders(toolingId: number) {
    const result = await authService.getIdentity();

    if (result) {
      return msalFetch()
        .get(`${TOOLING_API_ADDRESS}/${toolingId}/usages/orders`, result.accessToken)
        .then(handleErrors)
        .then<OrdersResponse>((res) => res.json());
    }
  }
}

const usagesApi = new UsagesApi();

export default usagesApi;
