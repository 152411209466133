import React, { FunctionComponent } from 'react'
import { SetUnitDto } from '../../api/types/Sets/SetDto'
import { Grid, Stack, Typography, Box } from '@mui/material'
import { UnitElementId, ElementIdIndicator } from '../tooling/components/UnitIndicator'
import UpdateUnitToolingIcon from './UpdateUnitToolingIcon'

interface Props {
  assemblyUnit: SetUnitDto;
  canEdit: boolean;
}

const SetAssemblyUnit: FunctionComponent<Props> = (props) => {
  return (
    <Box m={5}>
      <Grid container direction="column" spacing={4}>
        <Grid mb={3} mt={3}>
          <Typography sx={{ fontWeight: 600 }}>Set assembly unit</Typography>
        </Grid>
        <Stack direction="row" spacing={3} alignItems="center">
          <ElementIdIndicator variant={'inherit'}/>
          <Typography sx={{ fontWeight: 400 }}>{props.assemblyUnit.elementId}</Typography>
        </Stack>
        <Stack direction="row" spacing={3} alignItems="center">
          <Typography variant="caption" sx={{ fontWeight: 400 }}>{props.assemblyUnit.elementName}</Typography>
        </Stack>
      </Grid>
    </Box>
  )
}

export default SetAssemblyUnit
