import { Fragment, FunctionComponent, useEffect } from 'react';
import { FileDto } from '../../api/types/Files';
import { FileState } from '../../store/tooling/files/filesSlice';

interface Props extends React.PropsWithChildren {
  openedFile?: FileDto;
  files: FileState[];
  onClose: () => void;
  onOpen: (file: FileDto) => void;
}

const FileIteratorHandler: FunctionComponent<Props> = (props) => {
  useEffect(() => {
    const downHandler = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        props.onClose();
      }

      if (props.openedFile) {
        const index = props.files.findIndex((f) => f.file.fileId === props.openedFile?.fileId);

        if (e.keyCode === 37) {
          if (index > 0) {
            const prevIndex = index - 1;
            if (index < 0) {
              return;
            }

            const file = props.files[prevIndex];
            props.onOpen(file.file);
          }
        } else if (e.keyCode === 39) {
          if (index < props.files.length) {
            const nextIndex = index + 1;
            if (nextIndex >= props.files.length) {
              return;
            }

            const file = props.files[nextIndex];
            props.onOpen(file.file);
          }
        }
      }
    };

    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [props]);

  return <Fragment>{props.children}</Fragment>;
};

export default FileIteratorHandler;
