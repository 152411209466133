import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns';
import { Button, Grid, Skeleton, TextField } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { CurrencyDto } from '../../../../api/types/CurrencyDto';
import { ProviderType } from '../../../../api/types/Repairs';
import { SupplierDto } from '../../../../api/types/SupplierData';
import { loadCurrencies, selectCurrencies } from '../../../../store/currencies/currenciesSlice';
import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import TextEditPropertyItem from '../../../components/TextEditPropertyItem';
import CurrencySelector from '../../components/CurrencySelector';
import ProviderGridItem from '../components/ProviderGridItem';
import ReasonGridItem from '../components/ReasonGridItem';
import { hasCostError, hasCurrencyError, hasProviderError, hasReasonError } from './validation';

interface Props {
  toolingId: number;
  canEdit: boolean;
  reason: string;
  providerType: ProviderType;
  supplier: SupplierDto | null;
  providerName?: string;
  cost?: number;
  currency?: CurrencyDto;
  repairDate?: string;
  repairOrder: string;
  comment: string;
  isLoading: boolean;
  isSaving: boolean;

  onChangeReason: (reason: string) => void;
  onChangeProviderType: (type: ProviderType) => void;
  onChnageProviderName: (providerName: string) => void;
  onChangeSupplier: (supplier: SupplierDto | null) => void;
  onChangeCost: (cost: string) => void;
  onChangeCurrency: (currency?: CurrencyDto) => void;
  onChangeRepairDate: (repairDate?: string) => void;
  onChangeOrder: (order: string) => void;
  onChangeComment: (comment: string) => void;

  onSave: () => void;
}

const EditRepairContent: FunctionComponent<Props> = (props) => {
  const currenciesState = useAppSelector(selectCurrencies);
  const dispatch = useAppDispatch();

  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    dispatch(loadCurrencies());
  }, [dispatch]);

  useEffect(() => {
    const errors =
      hasReasonError(props.reason) ||
      hasProviderError(props.providerType, props.supplier, props.providerName) ||
      hasCostError(props.cost) ||
      hasCurrencyError(props.currency);
    setHasErrors(errors);
  }, [props.cost, props.currency, props.providerName, props.providerType, props.reason, props.supplier]);

  const handleChangeRepairDate = (date: Date | null) => {
    if (date) {
      const utcDate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
      props.onChangeRepairDate(new Date(utcDate).toISOString());
    } else {
      props.onChangeRepairDate(undefined);
    }
  };

  return (
    <Grid container spacing={2}>
      <ReasonGridItem
        reason={props.reason}
        isLoading={props.isLoading}
        disabled={props.isSaving || !props.canEdit}
        onChange={(value) => props.onChangeReason(value)}
      />
      <ProviderGridItem
        providerType={props.providerType}
        providerName={props.providerName ?? ''}
        supplier={props.supplier}
        isLoading={props.isLoading}
        disabled={props.isSaving || !props.canEdit}
        onChangeProviderType={(value) => props.onChangeProviderType(value)}
        onChangeProviderName={(value) => props.onChnageProviderName(value)}
        onChangeSupplier={(value) => props.onChangeSupplier(value)}
      />
      <Grid item container direction="row" spacing={2}>
        <Grid item xs>
          {props.isLoading ? (
            <Skeleton />
          ) : (
            <TextField
              fullWidth
              label="Cost (VAT included)"
              type="number"
              value={props.cost ?? ''}
              disabled={props.isSaving || !props.canEdit}
              error={hasCostError(props.cost)}
              helperText={hasCostError(props.cost) ? 'Cost must be greater than zero' : ''}
              onChange={(event) => props.onChangeCost(event.target.value)}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          {props.isLoading ? (
            <Skeleton />
          ) : (
            <CurrencySelector
              currencies={currenciesState.currencies}
              currentCurrency={props.currency}
              disabled={props.isSaving || !props.canEdit}
              error={hasCurrencyError(props.currency)}
              helperText={hasCurrencyError(props.currency) ? 'Currency is required' : ''}
              onChangeCurrency={(value) => props.onChangeCurrency(value)}
            />
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {props.isLoading ? (
          <Skeleton />
        ) : (
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DesktopDatePicker
              label="Repair date"
              format="dd/MM/yyyy"
              value={props.repairDate ? new Date(props.repairDate) : null}
              disabled={props.isSaving || !props.canEdit}
              slotProps={{ textField: { fullWidth: true } }}
              onChange={handleChangeRepairDate}
            />
          </LocalizationProvider>
        )}
      </Grid>

      <Grid item xs={12}>
        <TextEditPropertyItem
          caption="Repair order"
          value={props.repairOrder}
          isLoading={props.isLoading}
          disabled={props.isSaving || !props.canEdit}
          onChange={(value) => props.onChangeOrder(value)}
        />
      </Grid>

      <Grid item xs={12}>
        {props.isLoading ? (
          <Skeleton />
        ) : (
          <TextField
            multiline
            fullWidth
            label="Comment"
            value={props.comment}
            disabled={props.isSaving || !props.canEdit}
            onChange={(event) => props.onChangeComment(event.target.value)}
          />
        )}
      </Grid>

      <Grid item xs={12} container justifyContent="end">
        <Grid item>
          <Button
            variant="contained"
            disabled={props.isLoading || props.isSaving || hasErrors || !props.canEdit}
            onClick={() => props.onSave()}
          >
            Save changes
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditRepairContent;
