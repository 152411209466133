import React, { FunctionComponent } from 'react';
import { Grid, Stack, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { Variant } from '@mui/material/styles/createTypography';

interface UnitIndicatorProps {
  useElementId: boolean;
}
const UnitIndicator: FunctionComponent<UnitIndicatorProps> = (props) => {
  return props.useElementId ? (
    <Typography sx={{ color: 'green' }}>EID</Typography>
  ) : (
    <Typography sx={{ color: 'orange' }}>PID</Typography>
  );
};

interface IndicatorProps {
  variant: Variant | 'inherit';
  sx?: SxProps<Theme>;
}

export const ElementIdIndicator: FunctionComponent<IndicatorProps> = (props) => {
  return (
    <Typography variant={props.variant} sx={{ color: 'green', ...props.sx }}>
      EID
    </Typography>
  );
};

export const ProductionIdIndicator: FunctionComponent<IndicatorProps> = (props) => {
  return (
    <Typography variant={props.variant} sx={{ color: 'orange', ...props.sx }}>
      PID
    </Typography>
  );
};

interface UnitElementIdProps {
  elementId: string;
}

export const UnitElementId: FunctionComponent<UnitElementIdProps> = (props) => {
  return (
    <Stack direction="row" spacing={1}>
      <ElementIdIndicator variant="caption" />
      <Typography variant="caption">{props.elementId}</Typography>
    </Stack>
  );
};

interface UnitProductionIdProps {
  productionId: string;
}

export const UnitProductionId: FunctionComponent<UnitProductionIdProps> = (props) => {
  return (
    <Stack direction="row" spacing={1}>
      <ProductionIdIndicator variant="caption" />
      <Typography variant="caption">{props.productionId}</Typography>
    </Stack>
  );
};

export default UnitIndicator;
