import {
  Card, CardContent, Grid, ListItem, ListItemAvatar, ListItemText, List,
} from '@mui/material'
import { FunctionComponent } from 'react'
import { SetItemDto } from '../../../api/types/Sets/SetDto'
import PropertyItem from './PropertyItem'
import GraphAvatar from '../../../components/GraphAvatar'

interface Props {
  item: SetItemDto;
}

const SetsItemCard: FunctionComponent<Props> = (props) => {
  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={4} container spacing={3}>
            <Grid item xs={12}>
              <PropertyItem property={'Set number'} value={props.item.toolingSetNumber.toString()}/>
            </Grid>
            <Grid item xs={12}>
              <PropertyItem property={'Set unit'}
                            value={`${props.item.setUnit.elementId} ${props.item.setUnit.elementName}`}/>
            </Grid>
          </Grid>
          <Grid item xs={5} container spacing={3}>
            <Grid item xs={12}>
              <PropertyItem property={'Set status'} value={props.item.status}/>
            </Grid>
            <Grid item xs={12}>
              <PropertyItem property={'Set supplier'} value={props.item.supplier?.name ?? ''}/>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <List>
              {props.item.responsibles?.map((responsible) => (
                <ListItem key={responsible.userId} disablePadding>
                  <ListItemAvatar>
                    <GraphAvatar userId={responsible.userId}/>
                  </ListItemAvatar>
                  <ListItemText
                    primary={responsible.displayName}
                    primaryTypographyProps={{ variant: 'textSmMedium' }}
                    secondary={responsible.mail}
                    secondaryTypographyProps={{ variant: 'textSmRegular' }}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default SetsItemCard
