import { FunctionComponent } from 'react'
import { Route } from 'react-router-dom'
import RolePage from '../views/administration/roles/RolePage'
import RolesListPage from '../views/administration/roles/RolesListPage'
import UsersListPage from '../views/administration/users/UsersListPage'
import NotFoundPage from '../views/errors/NotFoundPage'
import MaterialsListPage from '../views/materials/MaterialsListPage'
import ToolingListPage from '../views/tooling/list/ToolingListPage'
import CreateRepairPage from '../views/tooling/repairs/create/CreateRepairPage'
import EditRepairPage from '../views/tooling/repairs/edit/EditRepairPage'
import ToolingViewPage from '../views/tooling/view/ToolingViewPage'
import { Routes } from 'react-router-dom'
import CreateTransferPage from '../views/tooling/transfers/create/CreateTransferPage'
import EditTransferPage from '../views/tooling/transfers/edit/EditTransferPage'
import SetsListPage from '../views/Sets/List/SetsListPage'
import SetPage from '../views/Sets/SetPage'

export const AppRouter: FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<ToolingListPage/>}/>
      <Route path="/tooling" element={<ToolingListPage/>}/>
      <Route path="/sets" element={<SetsListPage/>}/>
      <Route path="/sets/:setNumber" element={<SetPage/>}/>
      <Route path="/tooling/:toolingId" element={<ToolingViewPage/>}/>
      <Route path="/tooling/:toolingId/repairs/new" element={<CreateRepairPage/>}/>
      <Route path="/tooling/:toolingId/transfers/new" element={<CreateTransferPage/>}/>
      <Route path="/tooling/:toolingId/repairs/:repairId" element={<EditRepairPage/>}/>
      <Route path="/tooling/:toolingId/transfers/:transferId" element={<EditTransferPage/>}/>
      <Route path="/materials" element={< MaterialsListPage/>}/>
      <Route path="/administration/users" element={<UsersListPage/>}/>
      <Route path="/administration/roles" element={<RolesListPage/>}/>
      <Route path="/administration/roles/:roleId" element={<RolePage/>}/>
      <Route path="*" element={<NotFoundPage/>}/>
    </Routes>
  )
}
