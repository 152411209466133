import { Box, Chip } from '@mui/material';
import { FunctionComponent } from 'react';

interface Props {
  title: string;
  count: number;
}

const TabLabel: FunctionComponent<Props> = (props) => {
  return props.count > 0 ? (
    <Box>
      <span>{`${props.title} `}</span>
      <Chip label={props.count} size="small" />
    </Box>
  ) : (
    <span>{props.title}</span>
  );
};

export default TabLabel;
