import { FunctionComponent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import materialsApi from '../../../../api/MaterialsApi';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { updateTechnicalInfo } from '../../../../store/tooling/toolingViewSlice';
import { CoreboxDto, TechnicalInfoDto } from '../../../../api/types/ToolingDto';
import {
  selectToolingTechnicalInfoEdit,
  init,
  setCastingToleranceGrade,
  setFlaskDimensions,
  setTotalWeight,
  setGauges,
  setChills,
  setPackingWidth,
  setPackingHeight,
  setPackingLength,
  setLoosePartsQuantity,
  setLoosePartsGatingSystem,
  setLoosePartsRisers,
  setLoosePartsGasVents,
  addCorebox,
  removeCorebox,
  updateCorebox,
  ToolingTechincalInfoEditState,
} from '../../../../store/tooling/toolingTechincalInfoEditSlice';
import { MaterialDto } from '../../../../api/types/MaterialDto';
import ErrorBox from '../../../../components/ErrorBox';
import { TextEditPropertyItem } from '../../../components/TextEditPropertyItem';
import toolingApi from '../../../../api/ToolingApi';
import { ControlPoint, HighlightOff } from '@mui/icons-material';
import MaterialSelector from './MaterialSelector';
import { validateInt, validateFloat } from '../../../../helpers/validation';

interface Props {
  toolingId: number | string;
  technicalInfo: TechnicalInfoDto;
  open: boolean;
  onClose: () => void;
}

const EditTechnicalInfoDialog: FunctionComponent<Props> = (props) => {
  const [saving, setSaving] = useState(false);
  const [status, setStatus] = useState<'loading' | 'idle' | 'fail'>('loading');
  const [materials, setMaterials] = useState<MaterialDto[]>([]);

  const isLoading = status === 'loading';
  const isFail = status === 'fail';

  const state: ToolingTechincalInfoEditState = useAppSelector(selectToolingTechnicalInfoEdit);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.open) {
      setStatus('loading');
      dispatch(init(props.technicalInfo));

      materialsApi
        .getMaterials()
        .then((res) => {
          res && setMaterials(res);
          setStatus('idle');
        })
        .catch(() => setStatus('fail'));
    }
  }, [dispatch, props.open, props.technicalInfo]);

  const changeCastingToleranceGrade = (value: string) => {
    dispatch(setCastingToleranceGrade(value));
  };

  const changeFlaskDimensions = (value: string) => {
    validateInt(value, (flaskDimensions) => {
      dispatch(setFlaskDimensions(flaskDimensions));
    });
  };

  const changeTotalWeight = (value: string) => {
    validateFloat(value, (totalWeight) => {
      dispatch(setTotalWeight(totalWeight));
    });
  };

  const changeGauges = (value: string) => {
    validateInt(value, (gauges) => {
      dispatch(setGauges(gauges));
    });
  };

  const changeChills = (value: string) => {
    validateInt(value, (gauges) => {
      dispatch(setChills(gauges));
    });
  };

  const changePackingWidth = (value: string) => {
    validateFloat(value, (totalWeight) => {
      dispatch(setPackingWidth(totalWeight));
    });
  };

  const changePackingHeight = (value: string) => {
    validateFloat(value, (totalWeight) => {
      dispatch(setPackingHeight(totalWeight));
    });
  };

  const changePackingLength = (value: string) => {
    validateFloat(value, (totalWeight) => {
      dispatch(setPackingLength(totalWeight));
    });
  };

  const changeLoosePartsQuantity = (value: string) => {
    validateFloat(value, (totalWeight) => {
      dispatch(setLoosePartsQuantity(totalWeight));
    });
  };

  const changeLoosePartsGatingSystem = (value: string) => {
    validateFloat(value, (totalWeight) => {
      dispatch(setLoosePartsGatingSystem(totalWeight));
    });
  };

  const changeLoosePartsRisers = (value: string) => {
    validateFloat(value, (totalWeight) => {
      dispatch(setLoosePartsRisers(totalWeight));
    });
  };

  const changeLoosePartsGasVents = (value: string) => {
    validateFloat(value, (totalWeight) => {
      dispatch(setLoosePartsGasVents(totalWeight));
    });
  };

  const changeCoreboxQuantity = (corebox: CoreboxDto, value: string) => {
    validateInt(value, (quantity) => {
      dispatch(
        updateCorebox({
          coreboxId: corebox.coreboxId,
          quantity: quantity || 0,
          material: corebox.material,
        }),
      );
    });
  };

  const changeCoreboxMaterial = (corebox: CoreboxDto, material?: MaterialDto) => {
    dispatch(
      updateCorebox({
        coreboxId: corebox.coreboxId,
        quantity: corebox.quantity,
        material: material,
      }),
    );
  };

  const onUpdate = () => {
    if (state.technicalInfo) {
      const ti = state.technicalInfo;

      setSaving(true);
      toolingApi
        .updateTechnicalInfo(props.toolingId, ti)
        .then(() => {
          dispatch(updateTechnicalInfo(ti));
          setSaving(false);
          props.onClose();
        })
        .catch((e) => {
          setSaving(false);
          console.error(e);
        });
    }
  };

  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle>Technical information</DialogTitle>
      <Divider />
      <DialogContent>
        {isFail && <ErrorBox code="500" description="Error while loading data" />}
        {!isFail && (
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography variant="subtitle1" gutterBottom>
                Summary
              </Typography>
            </Grid>
            <TextEditPropertyItem
              caption="Casting tolerance grade"
              isLoading={isLoading}
              value={state.technicalInfo?.castingToleranceGrade?.toString() || ''}
              onChange={(value) => changeCastingToleranceGrade(value)}
            />
            <TextEditPropertyItem
              caption="Flask dimensions"
              type="number"
              isLoading={isLoading}
              value={state.technicalInfo?.flaskDimensions?.toString() || ''}
              onChange={(value) => changeFlaskDimensions(value)}
            />
            <TextEditPropertyItem
              caption="Total weight"
              type="number"
              isLoading={isLoading}
              value={state.technicalInfo?.totalWeight?.toString() || ''}
              onChange={(value) => changeTotalWeight(value)}
            />
            <TextEditPropertyItem
              caption="Gauges"
              type="number"
              isLoading={isLoading}
              value={state.technicalInfo?.gauges?.toString() || ''}
              onChange={(value) => changeGauges(value)}
            />
            <TextEditPropertyItem
              caption="Chills"
              type="number"
              isLoading={isLoading}
              value={state.technicalInfo?.chills?.toString() || ''}
              onChange={(value) => changeChills(value)}
            />
            <Grid item>
              <Typography variant="subtitle1" gutterBottom>
                Packed dimensions
              </Typography>
            </Grid>
            <TextEditPropertyItem
              caption="Width"
              type="number"
              isLoading={isLoading}
              value={state.technicalInfo?.packing?.width?.toString() || ''}
              onChange={(value) => changePackingWidth(value)}
            />
            <TextEditPropertyItem
              caption="Height"
              type="number"
              isLoading={isLoading}
              value={state.technicalInfo?.packing?.height?.toString() || ''}
              onChange={(value) => changePackingHeight(value)}
            />
            <TextEditPropertyItem
              caption="Length"
              type="number"
              isLoading={isLoading}
              value={state.technicalInfo?.packing?.length?.toString() || ''}
              onChange={(value) => changePackingLength(value)}
            />
            <Grid item>
              <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                Loose parts
              </Typography>
            </Grid>
            <TextEditPropertyItem
              caption="Quantity"
              type="number"
              isLoading={isLoading}
              value={state.technicalInfo?.looseParts?.quantity?.toString() || ''}
              onChange={(value) => changeLoosePartsQuantity(value)}
            />
            <TextEditPropertyItem
              caption="Gating system"
              type="number"
              isLoading={isLoading}
              value={state.technicalInfo?.looseParts?.gatingSystem?.toString() || ''}
              onChange={(value) => changeLoosePartsGatingSystem(value)}
            />
            <TextEditPropertyItem
              caption="Risers"
              type="number"
              isLoading={isLoading}
              value={state.technicalInfo?.looseParts?.risers?.toString() || ''}
              onChange={(value) => changeLoosePartsRisers(value)}
            />
            <TextEditPropertyItem
              caption="Gas vents"
              type="number"
              isLoading={isLoading}
              value={state.technicalInfo?.looseParts?.gasVents?.toString() || ''}
              onChange={(value) => changeLoosePartsGasVents(value)}
            />
            <Grid item container direction="row" alignItems="center" sx={{ mt: 2 }}>
              <Grid item>
                <Typography variant="subtitle1">Coreboxes</Typography>
              </Grid>
              <Grid item xs></Grid>
              <Grid item>
                <Tooltip title="Add corebox">
                  <IconButton onClick={() => dispatch(addCorebox())}>
                    <ControlPoint />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Material</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.technicalInfo?.coreboxes?.map((cb) => (
                    <TableRow key={cb.coreboxId}>
                      <TableCell>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          <TextField
                            fullWidth
                            type="number"
                            value={cb.quantity}
                            onChange={(event) => changeCoreboxQuantity(cb, event.target.value)}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <MaterialSelector
                          isLoading={isLoading}
                          sx={{ width: '100%' }}
                          materials={materials}
                          selectedMaterialId={cb.material?.materialId}
                          onChange={(material) => {
                            changeCoreboxMaterial(cb, material);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Remove corebox">
                          <IconButton onClick={() => dispatch(removeCorebox(cb.coreboxId))}>
                            <HighlightOff />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={saving} onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="contained" disabled={saving || isLoading || isFail} onClick={() => onUpdate()}>
          {saving ? <CircularProgress size={20} /> : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTechnicalInfoDialog;
