import { FunctionComponent } from 'react'
import { FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material'
import { StatusDto } from '../../../api/types/StatusDto'
import _ from 'lodash'

interface Props {
  isLoading: boolean;
  currentStatus?: number;
  statuses: StatusDto[];
  hasEmpty?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  onChange: (status: StatusDto | undefined) => void;
}

const StatusSelector: FunctionComponent<Props> = (props) => {
  const onChange = (statusId: number | undefined) => {
    const status = props.statuses.find((m) => m.statusId === statusId)
    props.onChange(status)
  }

  return props.isLoading ? (
    <Skeleton width={200}/>
  ) : (
           <FormControl sx={{ width: '100%' }} disabled={props.disabled} size={props.size}>
             <InputLabel id="status-label">Status</InputLabel>
             <Select
               value={props.currentStatus || ''}
               labelId="status-label"
               label="Status"
               onChange={(event) => onChange(event.target.value as number)}
               disabled={props.disabled}
             >
               {props.hasEmpty && (
                 <MenuItem value="">
                   <em>None</em>
                 </MenuItem>
               )}
               {props.statuses.map((m) => (
                 <MenuItem key={m.statusId} value={m.statusId}>
                   {m.name}
                 </MenuItem>
               ))}
             </Select>
           </FormControl>
         )
}

export default StatusSelector
