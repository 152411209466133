import { Container } from '@mui/material';
import { Box } from '@mui/system';
import { FunctionComponent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { loadOrders, selectToolingOrders } from '../../../../../store/tooling/orders/ordersSlice';
import EmptyListHandler from '../../../../components/EmptyRepairsListHandler';
import ErrorStateHandler from '../../../../components/ErrorsStateHandler';
import ListLoadingStateHandler from '../../../../components/LoadingStateHandler';
import OrdersList from './OrdersList';

interface Props {
  toolingId: number;
}

const UsagesTab: FunctionComponent<Props> = (props) => {
  const usagesState = useAppSelector(selectToolingOrders);
  const dispatch = useAppDispatch();

  const isLoading = usagesState.status === 'loading';

  const isFailed = usagesState.status === 'failed';

  useEffect(() => {
    dispatch(loadOrders(props.toolingId));
  }, [dispatch, props.toolingId]);

  return (
    <Box mt={2}>
      <ErrorStateHandler failed={isFailed}>
        <ListLoadingStateHandler isLoading={isLoading} itemsCount={3} itemHeight={100}>
          <EmptyListHandler itemsCount={usagesState.orders.length} emptyText="No usages yet">
            <Container maxWidth="md">
              <OrdersList toolingId={props.toolingId} />
            </Container>
          </EmptyListHandler>
        </ListLoadingStateHandler>
      </ErrorStateHandler>
    </Box>
  );
};

export default UsagesTab;
