import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import materialsApi from '../../../api/MaterialsApi';
import { MaterialDto } from '../../../api/types/MaterialDto';
import { RootState } from '../../store';

export interface ToolingSummaryEditState {
  status: 'idle' | 'loading' | 'failed';
  selectedMaterial?: MaterialDto;
  materials: MaterialDto[];
  error?: SerializedError;
}

const initialState: ToolingSummaryEditState = {
  status: 'idle',
  materials: [],
};

export const loadMaterials = createAsyncThunk('tooling-create-material/load', async () => {
  return await materialsApi.getMaterials();
});

export const materialSlice = createSlice({
  name: 'tooling-create-material',
  initialState,
  reducers: {
    selectMaterial: (state, action: PayloadAction<MaterialDto | undefined>) => {
      state.selectedMaterial = action.payload;
    },
  },
  extraReducers: (build) => {
    build.addCase(loadMaterials.pending, (state, action) => {
      state.status = 'loading';
    });

    build.addCase(loadMaterials.fulfilled, (state, action) => {
      state.status = 'idle';
      state.materials = [...action.payload];
    });

    build.addCase(loadMaterials.rejected, (state, action) => {
      state.status = 'failed';
      state.materials = [];
      state.error = action.error;
    });
  },
});

export const { selectMaterial } = materialSlice.actions;

export const selectToolingCreateMaterial = (state: RootState) => state.tooling.create.material;

export default materialSlice.reducer;
