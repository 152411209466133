import React, { Fragment, FunctionComponent } from 'react';
import { Typography } from '@mui/material';

interface IdenticalIndicatorProps {
  hasIdentical: boolean;
}

const IdenticalIndicator: FunctionComponent<IdenticalIndicatorProps> = (props) => {
  if (props.hasIdentical) {
    return <Typography color="GrayText">IDNT</Typography>;
  }

  return <Fragment />;
};

export default IdenticalIndicator;
