import { FunctionComponent, useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Skeleton,
} from '@mui/material'
import { useAppDispatch, useAppSelector, useAccount } from '../../../../store/hooks'
import { ToolingSummaryDto, UpdateToolingSummaryDto } from '../../../../api/types/ToolingDto'
import {
  selectToolingEditSummary,
  init,
  startLoading,
  successLoading,
  fail,
  setToolingNumber,
  setDescription,
  setOwner,
  setCost,
  setCurrency,
  setActualNumberOfUsages,
  setAllowableNumberOfUsages,
  setMarkingNumber,
  setLocation,
  setStocks,
  saveSummary,
  hideActionError,
  setMaterial,
  ToolingSummaryEditState,
} from '../../../../store/tooling/summarySlice'
import ErrorBox from '../../../../components/ErrorBox'
import { load, ToolingViewState, selectToolingView } from '../../../../store/tooling/toolingViewSlice'
import moment from 'moment'
import OwnerSelector from '../../components/OwnerSelector'
import CurrencySelector from '../../components/CurrencySelector'
import { OwnerDto } from '../../../../api/types/OwnerDto'
import ownersApi from '../../../../api/OwnersApi'
import currenciesApi from '../../../../api/CurrenciesApi'
import { validateInt, validateFloat } from '../../../../helpers/validation'
import { CurrencyDto } from '../../../../api/types/CurrencyDto'
import {
  Permissions_Tooling_Summary_EditActualNumberOfUsages,
  Permissions_Tooling_Summary_EditLocation,
} from '../../../../api/types/permissions'
import stocksApi from '../../../../api/StocksApi'
import { SupplierDto } from '../../../../api/types/SupplierData'
import TextEditPropertyItem from '../../../components/TextEditPropertyItem'
import { checkPermission } from '../../../../store/user/userSlice'
import LocationSelector from '../../components/LocationSelector'
import ErrorDialog from '../../../../components/ErrorDialog'
import { UpdateLocationDto } from '../../../../api/types/LocationDto'
import MaterialSelector from './MaterialSelector'
import { MaterialDto } from '../../../../api/types/MaterialDto'
import materialsApi from '../../../../api/MaterialsApi'

interface Props {
  toolingId: string | number;
  summary?: ToolingSummaryDto;
  suppliers?: SupplierDto[];
  open: boolean;
  onClose: () => void;
}

const EditSummaryDialog: FunctionComponent<Props> = (props) => {
  const [owners, setOwners] = useState<OwnerDto[]>([])
  const [currencies, setCurrencies] = useState<CurrencyDto[]>([])
  const [materials, setMaterials] = useState<MaterialDto[]>([])

  const state: ToolingSummaryEditState = useAppSelector(selectToolingEditSummary)
  const toolingViewState: ToolingViewState = useAppSelector(selectToolingView)
  const dispatch = useAppDispatch()

  const isLoading = state.status === 'loading'
  const isSaving = state.status === 'saving'
  const isFail = state.status === 'failed'

  const allowableNumberOfUsagesValidationError =
          state.allowableNumberOfUsages === undefined || state.allowableNumberOfUsages <= 0

  const ownerError = !!!state.owner
  const locationError = !!!state.location
  const validationError = allowableNumberOfUsagesValidationError || ownerError || locationError

  const userAccount = useAccount()

  const calcEditPermissions = (havePermissions: boolean) => {
    const responsibles = toolingViewState.tooling?.responsibles.filter((r) => r.userId === userAccount.localAccountId)
    return (): boolean => {
      if (havePermissions) {
        return true
      } else {
        if (responsibles) {
          return responsibles.length > 0
        } else {
          return false
        }
      }
    }
  }

  const haveEditLocationPermission = useAppSelector((root) => checkPermission(root, Permissions_Tooling_Summary_EditLocation))

  const haveEditActualNumberOfUsagesPermission = useAppSelector((root) =>
                                                                  checkPermission(root, Permissions_Tooling_Summary_EditActualNumberOfUsages),
  )

  const canEditLocation = calcEditPermissions(haveEditLocationPermission)
  const canEditActualNumberOfUsages = calcEditPermissions(haveEditActualNumberOfUsagesPermission)

  useEffect(() => {
    if (props.open) {
      dispatch(startLoading())
      dispatch(init({ summary: props.summary, suppliers: props.suppliers ?? [] }))

      const getOwners = ownersApi.getOwners().then((res) => {
        res && setOwners(res)
      })

      const getCurrencies = currenciesApi.getCurrencies().then((res) => {
        res && setCurrencies(res)
      })

      const getStocks = stocksApi.getStocks().then((res) => {
        res && dispatch(setStocks(res))
      })

      const getMaterials = materialsApi.getMaterials().then((res) => {
        res && setMaterials(res)
      })

      Promise.all([getOwners, getCurrencies, getStocks, getMaterials])
             .then(() => dispatch(successLoading()))
             .catch(() => dispatch(fail()))
    }
  }, [dispatch, props.open, props.summary, props.suppliers])

  useEffect(() => {
    moment.locale('en', { week: { dow: 1 } })
  }, [])

  useEffect(() => {
    if (props.summary?.location?.atSupplier) {
      const location = state.locations.find(
        (l) => l.type === 'supplier' && l.objectId === props.summary?.location?.atSupplier?.supplierId,
      )
      location && dispatch(setLocation(location.id))
    } else if (props.summary?.location?.atStock) {
      const location = state.locations.find(
        (l) => l.type === 'stock' && l.objectId === props.summary?.location?.atStock?.stockId,
      )

      location && dispatch(setLocation(location.id))
    }
  }, [state.locations, props.summary?.location, dispatch])

  const changeToolingNumber = (value: string) => {
    dispatch(setToolingNumber(value))
  }

  const changeDescription = (value: string) => {
    dispatch(setDescription(value))
  }

  const changeMarkingNumber = (value: string) => {
    dispatch(setMarkingNumber(value))
  }

  const changeOwner = (owner?: OwnerDto) => {
    dispatch(setOwner(owner))
  }

  const changeCost = (value: string) => {
    validateFloat(value, (cost) => {
      dispatch(setCost(cost))
    })
  }

  const changeCurrency = (currency?: CurrencyDto) => {
    dispatch(setCurrency(currency))
  }

  const changeActualNumberOfUsages = (value: string) => {
    validateInt(value, (numberOfUsages) => {
      dispatch(setActualNumberOfUsages(numberOfUsages))
    })
  }

  const changeAllowableNumberOfUsages = (value: string) => {
    validateInt(value, (numberOfUsages) => {
      if (numberOfUsages && numberOfUsages < 0) {
        return
      }

      dispatch(setAllowableNumberOfUsages(numberOfUsages))
    })
  }

  const handleChangeLocation = (locationId: string) => {
    dispatch(setLocation(locationId))
  }

  const handleChangeMaterial = (material?: MaterialDto) => {
    dispatch(setMaterial(material))
  }

  const onUpdate = () => {
    const summary = {
      toolingNumber: state.toolingNumber,
      description: state.description,
      markingNumber: state.markingNumber,
      owner: state.owner,
      location: {
        supplierId: state.location?.type === 'supplier' ? state.location.objectId : undefined,
        stockId: state.location?.type === 'stock' ? state.location.objectId : undefined,
      } as UpdateLocationDto,
      cost: state.cost,
      currency: state.currency,
      actualNumberOfUsages: state.actualNumberOfUsages,
      allowableNumberOfUsages: state.allowableNumberOfUsages,
      materialId: state.material?.materialId,
    } as UpdateToolingSummaryDto

    dispatch(saveSummary({ toolingId: props.toolingId, summary }))
      .unwrap()
      .then(() => {
        dispatch(load(props.toolingId))
        props.onClose()
      })
      .catch(() => {
      })
  }

  return (
    <Dialog open={props.open} fullWidth maxWidth="md">
      <DialogTitle>Summary information</DialogTitle>
      <Divider/>
      <DialogContent>
        {isFail && <ErrorBox code="500" description="Error while loading data"/>}
        {!isFail && (
          <Grid container direction="column" spacing={4}>
            <TextEditPropertyItem
              isLoading={isLoading}
              disabled={true}
              caption="Tooling number"
              value={state.toolingNumber || ''}
              onChange={(value) => changeToolingNumber(value)}
            />
            <TextEditPropertyItem
              isLoading={isLoading}
              caption="Description"
              value={state.description || ''}
              onChange={(value) => changeDescription(value)}
            />
            <TextEditPropertyItem
              isLoading={isLoading}
              caption="Marking number"
              value={state.markingNumber || ''}
              onChange={(value) => changeMarkingNumber(value)}
            />
            <Grid item>
              {isLoading ? (
                <Skeleton/>
              ) : (
                 <OwnerSelector
                   owners={owners}
                   selectedOwner={state.owner}
                   error={ownerError}
                   helperText={ownerError ? 'Owner cannot by empty' : undefined}
                   onChangeOwner={(owner) => changeOwner(owner)}
                 />
               )}
            </Grid>
            <Grid item>
              {isLoading ? (
                <Skeleton/>
              ) : (
                 <LocationSelector
                   locations={state.locations}
                   selectedLocation={state.location}
                   error={locationError}
                   disabled={!canEditLocation()}
                   onChange={handleChangeLocation}
                 />
               )}
            </Grid>
            <Grid item container direction="row" spacing={2}>
              <TextEditPropertyItem
                isLoading={isLoading}
                caption="Cost (VAT included)"
                type="number"
                value={state.cost?.toString() || ''}
                onChange={(value) => changeCost(value)}
              />
              <Grid item xs>
                {isLoading ? (
                  <Skeleton/>
                ) : (
                   <CurrencySelector
                     currencies={currencies}
                     currentCurrency={state.currency}
                     onChangeCurrency={(currency) => changeCurrency(currency)}
                   />
                 )}
              </Grid>
            </Grid>
            <TextEditPropertyItem
              isLoading={isLoading}
              caption="Actual number of usages"
              type="number"
              value={state.actualNumberOfUsages?.toString() || ''}
              disabled={!canEditActualNumberOfUsages()}
              onChange={(value) => changeActualNumberOfUsages(value)}
            />
            <TextEditPropertyItem
              isLoading={isLoading}
              caption="Allowable number of usages"
              type="number"
              value={state.allowableNumberOfUsages?.toString() || ''}
              error={allowableNumberOfUsagesValidationError}
              helperText={
                allowableNumberOfUsagesValidationError ? 'You must specify allowable number of usages' : undefined
              }
              onChange={(value) => changeAllowableNumberOfUsages(value)}
            />
            <Grid item>
              {isLoading ? (
                <Skeleton/>
              ) : (
                 <FormControl sx={{ width: '100%' }}>
                   <InputLabel id="material-label">Material</InputLabel>
                   <MaterialSelector
                     isLoading={isLoading}
                     materials={materials}
                     selectedMaterialId={state.material?.materialId}
                     onChange={(material) => handleChangeMaterial(material)}
                   />
                 </FormControl>
               )}
            </Grid>
          </Grid>
        )}
        <ErrorDialog
          open={state.actionError !== undefined}
          title={'Error'}
          text={`Error while saving data. Please try again later or contact the site administrator.\n${state.actionError?.message}`}
          onOk={() => dispatch(hideActionError())}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSaving} onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }}/>
        <Button
          variant="contained"
          disabled={isSaving || isLoading || isFail || validationError}
          onClick={() => onUpdate()}
        >
          {isSaving ? <CircularProgress size={20}/> : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditSummaryDialog
