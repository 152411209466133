import React, { FunctionComponent } from 'react';
import { Button, Card, CardContent, CardHeader, Divider } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import RoleUsersList from './RoleUsersList';
import {
  addUsers,
  hideAddUserDialog,
  selectEditRole,
  showAddUserDialog,
} from '../../../store/administration/roleSlice';
import SelectUserDialog from './SelectUserDialog';

const RoleUsersCard: FunctionComponent = () => {
  const roleState = useAppSelector(selectEditRole);
  const dispatch = useAppDispatch();

  const handleAddUser = () => {
    dispatch(showAddUserDialog());
  };

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title="Role users"
        action={
          roleState.status === 'idle' && (
            <Button variant="outlined" color="primary" onClick={handleAddUser}>
              Add
            </Button>
          )
        }
      />
      <Divider />
      <CardContent>
        <RoleUsersList />
      </CardContent>

      <SelectUserDialog
        open={roleState.openAddUserDialog}
        exists={roleState.users.map((u) => u.userId)}
        onCancel={() => dispatch(hideAddUserDialog())}
        onOk={(users) => {
          dispatch(hideAddUserDialog());
          roleState.roleId && dispatch(addUsers({ roleId: roleState.roleId, users: users.map((u) => u.userId) }));
        }}
      />
    </Card>
  );
};

export default RoleUsersCard;
