import authService from '../auth/AuthService'
import { API_ADDRESS } from './api'
import { msalFetch } from './graph'
import { OwnerDto } from './types/OwnerDto'
import { SupplierDto } from './types/SupplierData'
import { SearchUnitDto, UnitDto } from './types/UnitData'
import {
  TechnicalInfoDto,
  ToolingListItemDto,
  ToolingDto,
  ToolingAccountingDto,
  FindToolingDto,
  ResponsibleDto,
  UpdateToolingSummaryDto,
  UpdateToolingStatusDto,
} from './types/ToolingDto'
import { handleErrors } from './errorHandler'
import { ToolingListResponse } from './types/ToolingListResponse'
import { StatusDto } from './types/StatusDto'

const TOOLING_API_ADDRESS = `${API_ADDRESS}/api/tooling`

export class ToolingApi {
  async getToolingList(
    toolingNumber?: string,
    status?: StatusDto,
    owner?: OwnerDto,
    supplier?: string,
    unit?: string,
    responsible?: string,
    page?: number,
    pageSize?: number,
    sortBy?: string,
    sortDirection?: string,
  ) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .get(
          `${TOOLING_API_ADDRESS}?tooling=${toolingNumber ?? ''}&status=${status?.statusId ?? ''}&owner=${
            owner?.ownerId ?? ''
          }&supplier=${supplier ?? ''}&unit=${unit ?? ''}&responsible=${responsible ?? ''}&page=${
            page ?? ''
          }&pageSize=${pageSize ?? 1000}&sortBy=${sortBy ?? ''}&sortDirection=${sortDirection ?? ''}`,
          result.accessToken,
        )
        .then(handleErrors)
        .then<ToolingListResponse>((res) => res.json())
    }
  }

  getExportUrl(
    toolingNumber?: string,
    status?: StatusDto,
    owner?: OwnerDto,
    supplier?: string,
    unit?: string,
    responsible?: string,
    sortBy?: string,
    sortDirection?: string,
  ) {
    return `${TOOLING_API_ADDRESS}/export?tooling=${toolingNumber ?? ''}&status=${status?.statusId ?? ''}&owner=${
      owner?.ownerId ?? ''
    }&supplier=${supplier ?? ''}&unit=${unit ?? ''}&responsible=${responsible ?? ''}&sortBy=${
      sortBy ?? ''
    }&sortDirection=${sortDirection ?? ''}`
  }

  async getTooling(toolingId: string | number) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .get(`${TOOLING_API_ADDRESS}/${toolingId}`, result.accessToken)
        .then(handleErrors)
        .then<ToolingDto>((res) => res.json())
    }
  }

  async findDuplicates(supplier: SupplierDto, units: SearchUnitDto[]) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .post(
          `${TOOLING_API_ADDRESS}/duplicates`,
          result.accessToken,
          JSON.stringify({
                           supplierId: supplier.supplierId,
                           units,
                         }),
          {
            'content-type': 'application/json',
          },
        )
        .then(handleErrors)
        .then<FindToolingDto[]>((res) => res.json())
    }
  }

  async create(
    units: SearchUnitDto[],
    allowableNumberOfUsages: number,
    owner: OwnerDto | undefined,
    supplier: SupplierDto,
    materialId?: number,
  ) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .post(
          TOOLING_API_ADDRESS,
          result.accessToken,
          JSON.stringify({
                           units,
                           ownerId: owner?.ownerId,
                           supplierId: supplier.supplierId,
                           materialId,
                           allowableNumberOfUsages,
                         }),
          {
            'content-type': 'application/json',
          },
        )
        .then(handleErrors)
        .then<ToolingListItemDto>((res) => res.json())
    }
  }

  async updateSummary(toolingId: string | number, summary: UpdateToolingSummaryDto) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .put(`${TOOLING_API_ADDRESS}/${toolingId}/summary`, result.accessToken, JSON.stringify(summary), {
          'content-type': 'application/json',
        })
        .then(handleErrors)
    }
  }

  async updateStatus(toolingId: string | number, status: UpdateToolingStatusDto) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .put(`${TOOLING_API_ADDRESS}/${toolingId}/status`, result.accessToken, JSON.stringify(status), {
          'content-type': 'application/json',
        })
        .then(handleErrors)
    }
  }

  async updateAccounting(toolingId: string | number, accounting: ToolingAccountingDto) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .put(`${TOOLING_API_ADDRESS}/${toolingId}/accounting`, result.accessToken, JSON.stringify(accounting), {
          'content-type': 'application/json',
        })
        .then(handleErrors)
    }
  }

  async updateTechnicalInfo(toolingId: string | number, technicalInfo: TechnicalInfoDto) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .put(`${TOOLING_API_ADDRESS}/${toolingId}/technical-info`, result.accessToken, JSON.stringify(technicalInfo), {
          'content-type': 'application/json',
        })
        .then(handleErrors)
    }
  }

  async updateUnits(toolingId: string | number, units: SearchUnitDto[]) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .put(`${TOOLING_API_ADDRESS}/${toolingId}/units`, result.accessToken, JSON.stringify({ units }), {
          'content-type': 'application/json',
        })
        .then(handleErrors)
        .then<UnitDto[]>((res) => res.json())
    }
  }

  async updateSupplier(toolingId: string | number, suppliers: SupplierDto[]) {
    var token = await authService.getIdentity()

    return msalFetch()
      .put(`${TOOLING_API_ADDRESS}/${toolingId}/suppliers`, token.accessToken, JSON.stringify({ suppliers }), {
        'content-type': 'application/json',
      })
      .then(handleErrors)
  }

  async updateResponsibles(toolingId: string | number, responsibles: string[]) {
    const token = await authService.getIdentity()

    return msalFetch()
      .put(`${TOOLING_API_ADDRESS}/${toolingId}/responsibles`, token.accessToken, JSON.stringify({ responsibles }), {
        'content-type': 'application/json',
      })
      .then(handleErrors)
      .then<ResponsibleDto[]>((res) => res.json())
  }

  async updateComment(toolingId: string | number, comment: string) {
    var token = await authService.getIdentity()

    return msalFetch()
      .put(`${TOOLING_API_ADDRESS}/${toolingId}/comment`, token.accessToken, JSON.stringify({ comment }), {
        'content-type': 'application/json',
      })
      .then(handleErrors)
  }

  async removeUnit(toolingId: string | number, unitFileId: string | number) {
    const result = await authService.getIdentity()

    if (result) {
      await msalFetch().delete(`${TOOLING_API_ADDRESS}/${toolingId}/units/${unitFileId}`, result.accessToken)
    }
  }

  async delete(toolingId: string | number, comment: string) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .delete(`${TOOLING_API_ADDRESS}/${toolingId}`, result.accessToken, JSON.stringify({ comment }), {
          'content-type': 'application/json',
        })
        .then(handleErrors)
    }
  }
}

const toolingApi = new ToolingApi()

export default toolingApi
