import { Fragment, FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';

interface Props extends React.PropsWithChildren {
  failed: boolean;
}

const ErrorStateHandler: FunctionComponent<Props> = (props) => {
  if (props.failed) {
    return (
      <Box m={5}>
        <Typography variant="h6" textAlign="center" color={(theme) => theme.palette.error.main}>
          Error while loading data. Please try again later or contact the site administrator.
        </Typography>
      </Box>
    );
  }

  return <Fragment>{props.children}</Fragment>;
};

export default ErrorStateHandler;
