import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import toolingApi from '../../api/ToolingApi';
import { ResponsibleDto } from '../../api/types/ToolingDto';
import { RootState } from '../store';

export interface ToolingSummaryEditState {
  status: 'idle' | 'loading' | 'saving' | 'failed';
  responsibles: ResponsibleDto[];
  actionError?: SerializedError;
}

const initialState: ToolingSummaryEditState = {
  status: 'idle',
  responsibles: [],
};

export const saveResponsibles = createAsyncThunk(
  'tooling-responsible-edit-slice/saveResponsibles',
  async (data: { toolingId: number | string; responsibles: string[] }) => {
    return await toolingApi.updateResponsibles(data.toolingId, data.responsibles);
  },
);

export const responsibleSlice = createSlice({
  name: 'tooling-responsible-edit-slice',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.status = 'loading';
    },
    successLoading: (state) => {
      state.status = 'idle';
    },
    fail: (state) => {
      state.status = 'failed';
    },
    init: (state, action: PayloadAction<ResponsibleDto[]>) => {
      state.responsibles = action.payload;
    },
    addUser: (state, action: PayloadAction<ResponsibleDto>) => {
      state.responsibles = [...state.responsibles, action.payload];
    },
    removeUser: (state, action: PayloadAction<string>) => {
      state.responsibles = state.responsibles.filter((u) => u.userId !== action.payload);
    },
    hideActionError: (state) => {
      state.actionError = undefined;
    },
  },
  extraReducers: (build) => {
    build.addCase(saveResponsibles.pending, (state, action) => {
      state.status = 'saving';
    });

    build.addCase(saveResponsibles.fulfilled, (state, action) => {
      state.status = 'idle';
    });

    build.addCase(saveResponsibles.rejected, (state, action) => {
      state.status = 'idle';
      state.actionError = action.error;
    });
  },
});

export const { startLoading, successLoading, fail, init, addUser, removeUser, hideActionError } =
  responsibleSlice.actions;

export const selectToolingEditResponsible = (state: RootState) => state.tooling.edit.responsible;

export default responsibleSlice.reducer;
