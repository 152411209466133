import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { tooling } from './tooling/redusers'
import { sets } from './sets/reducers'
import { materials } from './materials/redusers'
import currencies from './currencies/currenciesSlice'
import { administration } from './administration/redusers'
import applicationInformation from './application/applicationInformationSlice'

import user from './user/userSlice'

export const store = configureStore({
                                      reducer: {
                                        tooling,
                                        sets,
                                        materials,
                                        currencies,
                                        user,
                                        administration,
                                        applicationInformation,
                                      },
                                    })

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
