import authService from '../auth/AuthService';
import { API_ADDRESS } from './api';
import { handleErrors } from './errorHandler';
import { msalFetch } from './graph';
import { FileDto, FilesResponse } from './types/Files';

const TOOLING_API_ADDRESS = `${API_ADDRESS}/api/tooling`;

export class RepairFilesApi {
  async getFiles(toolingId: number, repairId: number) {
    const identity = await authService.getIdentity();

    return msalFetch()
      .get(`${TOOLING_API_ADDRESS}/${toolingId}/repairs/${repairId}/files`, identity.accessToken)
      .then(handleErrors)
      .then<FilesResponse>((res) => res.json());
  }

  async upload(toolingId: number, repairId: number, file: File) {
    const formData = new FormData();
    formData.append('file', file);

    const identity = await authService.getIdentity();

    return msalFetch()
      .post(`${TOOLING_API_ADDRESS}/${toolingId}/repairs/${repairId}/files`, identity.accessToken, formData)
      .then(handleErrors)
      .then<FileDto>((res) => res.json());
  }

  async deleteFile(toolingId: number, repairId: number, fileId: number) {
    const identity = await authService.getIdentity();
    msalFetch()
      .delete(`${TOOLING_API_ADDRESS}/${toolingId}/repairs/${repairId}/files/${fileId}`, identity.accessToken)
      .then(handleErrors);
  }
}

const repairFilesApi = new RepairFilesApi();

export default repairFilesApi;
