import { FunctionComponent } from 'react';
import { Grid, Skeleton, TextField } from '@mui/material';

interface Props {
  caption: string;
  isLoading?: boolean;
  value: string | number;
  onChange?: (newValue: string) => void;
  type?: any;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}

export const TextEditPropertyItem: FunctionComponent<Props> = (props) => {
  return (
    <Grid item>
      {props.isLoading ? (
        <Skeleton />
      ) : (
        <TextField
          fullWidth
          label={props.caption}
          type={props.type}
          value={props.value}
          disabled={props.disabled}
          error={props.error}
          helperText={props.helperText}
          onChange={(event) => props.onChange && props.onChange(event.target.value)}
        />
      )}
    </Grid>
  );
};

export default TextEditPropertyItem;
