import { Grid, TextField, Stack, Button } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';

interface Props {
  initialValue: string;
  disabled?: boolean;
  onSave: (value: string) => void;
  onCancel: () => void;
}

const CommentEditor: FunctionComponent<Props> = (props) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(props.initialValue);
  }, [props.initialValue]);

  const handleChangeComment = (value: string) => {
    setValue(value);
  };

  const handleCancelClick = () => {
    props.onCancel();
  };

  const handleSaveClick = () => {
    props.onSave(value);
  };

  return (
    <Grid item xs={12}>
      <TextField
        multiline
        fullWidth
        minRows={5}
        value={value}
        disabled={props.disabled}
        onChange={(e) => handleChangeComment(e.target.value)}
        InputProps={{
          endAdornment: (
            <Stack spacing={4}>
              <Button variant="contained" disabled={props.disabled} onClick={handleSaveClick}>
                Save
              </Button>
              <Button variant="outlined" disabled={props.disabled} onClick={handleCancelClick}>
                Cancel
              </Button>
            </Stack>
          ),
        }}
      />
    </Grid>
  );
};

export default CommentEditor;
