import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import currenciesApi from '../../api/CurrenciesApi';
import { CurrencyDto } from '../../api/types/CurrencyDto';
import { RootState } from '../store';

export type SortableHeads = 'material';

export interface CurrenciesState {
  status: 'idle' | 'loading' | 'failed';
  currencies: CurrencyDto[];
  error?: SerializedError;
}

const initialState: CurrenciesState = {
  status: 'idle',
  currencies: [],
};

const sliceName = 'currencies';

export const loadCurrencies = createAsyncThunk<CurrencyDto[] | undefined, void, { state: RootState }>(
  `${sliceName}/load`,
  async () => {
    return await currenciesApi.getCurrencies();
  },
  {
    condition: (arg, api) => {
      return api.getState().currencies.currencies.length === 0;
    },
  },
);

export const currenciesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build.addCase(loadCurrencies.pending, (state, action) => {
      state.status = 'loading';
    });

    build.addCase(loadCurrencies.fulfilled, (state, action) => {
      state.status = 'idle';
      state.currencies = action.payload ?? [];
    });

    build.addCase(loadCurrencies.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error;
    });
  },
});

export const {} = currenciesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCurrencies = (state: RootState) => state.currencies;

export default currenciesSlice.reducer;
