import {
  Button, Grid, Skeleton, CardHeader, Divider, Card, CardContent, Box, Stack, Chip, Typography,
} from '@mui/material'
import React, { FunctionComponent, Fragment } from 'react'
import { SetToolingItemDto, SetUnitDto } from '../../api/types/Sets/SetDto'
import PropertyGridItem from './PropertyGridItem'
import StatusSelector from './StatusSelector'
import SetAssemblyUnit from './SetAssemblyUnit'
import SetSupplier from './CreateNewSetDialog/SetProperties'
import { SupplierDto } from '../../api/types/SupplierData'
import SetUnitTooling from './SetUnitTooling'
import { useAppDispatch } from '../../store/hooks'
import { calculateSet, setCurrentItemStatus } from '../../store/sets/currentItemSlice'
import LoadingTypography from '../tooling/view/components/LoadingTypography'
import { LocationDto } from '../../api/types/LocationDto'

interface Props {
  toolingSetId: number;
  toolingSetNumber: number;
  status: string;
  description?: string;
  comment?: string;
  supplier: SupplierDto | null;
  tooling: SetToolingItemDto[];
  editTooling: SetToolingItemDto[];
  assemblyUnits: SetUnitDto[];
  locations: LocationDto[];
  setUnit: SetUnitDto;
  canEdit: boolean;
  isLoading: boolean;
  isSaving: boolean;
  canSave: boolean;

  onChangeDescription: (description: string) => void;
  onChangeComment: (comment: string) => void;
  onChangeSupplier: (supplier: SupplierDto | null) => void;
  onCheckTooling: (tooling: SetToolingItemDto) => void;

  onSave: () => void;
}

const createdStatus = 'Created'
const decommissionedStatus = 'Decommissioned'

const SetContent: FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch()
  const handleChangeStatus = (status: string) => {
    dispatch(setCurrentItemStatus(status))
  }

  const handleCalculate = () => {
    dispatch(calculateSet({ setNumber: props.toolingSetNumber }))
  }

  return (
    <Box mt={2}>
      <Card>
        <CardHeader title="Properties"/>
        <Divider/>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {props.isLoading ? (
                <Skeleton/>
              ) : (
                 <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                   <StatusSelector currentStatus={props.status}
                                   onChangeStatus={handleChangeStatus}
                                   disabled={!props.canEdit || props.isSaving}/>
                   {props.canEdit &&
                     (props.status === createdStatus ||
                       props.status === decommissionedStatus) &&
                     <Button
                       sx={{ height: 40 }}
                       variant="contained"
                       disabled={props.isLoading || props.isSaving || !props.canEdit}
                       onClick={() => handleCalculate()}
                     >
                       Calculate
                     </Button>}
                 </Stack>
               )}
            </Grid>
            <Grid m={2} item xs={12}>
              <Stack m={1} spacing={3} direction="row" alignItems="center">
                <LoadingTypography isLoading={props.isLoading} loadingWidth={200}>
                  Location:
                </LoadingTypography>
                <Stack m={1} spacing={1} direction="row" alignItems="center">
                  {props.locations.map((l) => {
                    const locationName = l.atSupplier
                                         ? l.atSupplier?.name
                                         : l.atStock?.name
                    return <Fragment>
                      {(l.atSupplier || l.atStock) && <Chip label={l.atSupplier ? 'Supplier' : 'Stock'}/>}
                      <LoadingTypography isLoading={props.isLoading} loadingWidth={200}>
                        {locationName ?? ''}
                      </LoadingTypography>
                    </Fragment>
                  })}
                </Stack>
              </Stack>

              {props.locations.length > 1
                && <Typography variant="textSmRegular">Set have more than one location</Typography>}
            </Grid>

            <Grid item xs={12}>
              {props.isLoading ? (
                <Skeleton/>
              ) : (
                 <PropertyGridItem title={'Description'}
                                   value={props.description ?? ''}
                                   isLoading={props.isLoading}
                                   onChange={props.onChangeDescription}
                                   disabled={!props.canEdit || props.isSaving}
                 />
               )}
            </Grid>
            <Grid item xs={12}>
              {props.isLoading ? (
                <Skeleton/>
              ) : (
                 <PropertyGridItem title={'Comment'}
                                   value={props.comment ?? ''}
                                   isLoading={props.isLoading}
                                   onChange={props.onChangeComment}
                                   disabled={!props.canEdit || props.isSaving}
                 />
               )}
            </Grid>
            <Grid item xs={12}>
              {props.isLoading ? (
                <Skeleton/>
              ) : (
                 <SetSupplier
                   isLoading={props.isLoading}
                   disabled={!props.canEdit || props.isSaving}
                   selectedSupplier={props.supplier}
                   onChangeSupplier={(newSupplier) => props.onChangeSupplier(newSupplier)}
                 />
               )}
            </Grid>
            <Grid item xs={12}>
              {props.isLoading ? (
                <Skeleton/>
              ) : (
                 <SetAssemblyUnit assemblyUnit={props.setUnit} canEdit={props.canEdit}/>
               )}
            </Grid>
            <Grid item xs={12}>
              {props.isLoading ? (
                <Skeleton/>
              ) : (
                 <SetUnitTooling
                   tooling={props.tooling}
                   assemblyUnits={props.assemblyUnits}
                   editTooling={props.editTooling}
                   onCheckTooling={(tooling) => props.onCheckTooling(tooling)}
                   disabled={!props.canEdit || props.isSaving}
                   canEdit={props.canEdit}
                 />
               )}
            </Grid>
            {props.canEdit && <Grid item xs={12} container justifyContent="end" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  disabled={props.isLoading || props.isSaving || !props.canEdit || !props.canSave}
                  onClick={() => props.onSave()}
                >
                  Save changes
                </Button>
              </Grid>
            </Grid>}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

export default SetContent
