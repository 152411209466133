import {
  Container,
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  TableBody,
  Table,
  Button,
  AvatarGroup,
  Tooltip,
} from '@mui/material';
import _ from 'lodash';
import { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorBox from '../../../components/ErrorBox';
import ErrorDialog from '../../../components/ErrorDialog';
import GraphAvatar from '../../../components/GraphAvatar';
import { createRole, loadRoles, selectRolesList, setActionError } from '../../../store/administration/rolesListSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import LoadingTableBody from '../../components/LoadingTableBody';

const RolesListPage: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const rolesListState = useAppSelector(selectRolesList);

  useEffect(() => {
    dispatch(loadRoles());
  }, [dispatch]);

  const handleAddRole = () => {
    dispatch(createRole())
      .unwrap()
      .then((role) => {
        navigate(`/administration/roles/${role.roleId}`);
      })
      .catch(() => { });
  };

  const handleClickRole = (roleId: number) => {
    navigate(`/administration/roles/${roleId}`);
  };

  return (
    <Container maxWidth="md">
      <Box mt={4} mb={4}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs>
            <Typography variant="h4">Roles</Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleAddRole}>
              Add role
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box mt={4}>
        {rolesListState.status === 'loading' && (
          <Table>
            <TableBody>
              <LoadingTableBody rows={5} columns={2} />
            </TableBody>
          </Table>
        )}
        {rolesListState.status === 'idle' && (
          <List>
            {_.sortBy(rolesListState.roles, (r) => r.name).map((r) => {
              return (
                <ListItem key={r.roleId} divider button onClick={() => handleClickRole(r.roleId)}>
                  <ListItemText primary={r.name}></ListItemText>
                  <Tooltip
                    title={
                      <ul>
                        {_.sortBy(r.users, (u) => u.displayName)?.map((u) => (
                          <li key={u.userId}>{u.displayName}</li>
                        ))}
                      </ul>
                    }
                  >
                    <AvatarGroup max={8}>
                      {_.sortBy(r.users, (u) => u.displayName)?.map((u) => (
                        <GraphAvatar key={u.userId} userId={u.userId} />
                      ))}
                    </AvatarGroup>
                  </Tooltip>
                </ListItem>
              );
            })}
          </List>
        )}
        {rolesListState.status === 'failed' && rolesListState.pageError && (
          <ErrorBox
            code={rolesListState.pageError.name}
            description={rolesListState.pageError.message}
            sx={{ minHeight: '90vh' }}
          >
            <Button variant="contained" onClick={() => dispatch(loadRoles())}>
              Try again
            </Button>
          </ErrorBox>
        )}
      </Box>
      {/* {rolesListState.addRoleDialogOpened && (
        <SelectUserDialog
          open={rolesListState.selectUsersDialogOpened}
          alreadyAddedUsersId={rolesListState.users.map((u) => u.userId)}
          onCancel={() => dispatch(hideSelectUserDialog())}
          onOk={(userId) => {
            dispatch(hideSelectUserDialog());
            dispatch(addUser(userId));
          }}
        />
      )} */}
      <ErrorDialog
        open={rolesListState.actionError !== undefined}
        title={rolesListState.actionError?.name || 'Error'}
        text={rolesListState.actionError?.message ?? ''}
        onOk={() => dispatch(setActionError())}
      />
    </Container>
  );
};

export default RolesListPage;
