import authService from '../../auth/AuthService';
import { API_ADDRESS } from '../api';
import { handleErrors } from '../errorHandler';
import { msalFetch } from '../graph';
import { RightDto } from '../types/administration/Rights';

const RIGHTS_API_ADDRESS = `${API_ADDRESS}/api/administration/rights`;

export class RightsApi {
  async getRights() {
    const token = await authService.getIdentity();

    return msalFetch()
      .get(RIGHTS_API_ADDRESS, token.accessToken)
      .then(handleErrors)
      .then<RightDto[]>((res) => res.json());
  }
}

const rightsApi = new RightsApi();

export default rightsApi;
