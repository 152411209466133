import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import materialsApi from '../../api/MaterialsApi';
import { MaterialDto } from '../../api/types/MaterialDto';
import { OrderType } from '../../helpers/tableCompare';
import { RootState } from '../store';

export type SortableHeads = 'material';

export interface MaterialsListState {
  status: 'idle' | 'loading' | 'failed';
  materialsList: MaterialDto[] | null;
  error?: SerializedError;
  actionError?: Error;
  sortDirection: OrderType;
  sortBy: SortableHeads;
}

const initialState: MaterialsListState = {
  status: 'idle',
  materialsList: null,
  sortDirection: (localStorage.getItem('materials.list.sort.direction') ?? 'asc') as OrderType,
  sortBy: (localStorage.getItem('materials.list.sort.by') ?? 'material') as SortableHeads,
};

export const loadMaterialsList = createAsyncThunk('materials-list/load', async () => {
  return await materialsApi.getMaterials();
});

export const materialsListSlice = createSlice({
  name: 'materials-list',
  initialState,
  reducers: {
    setActionError: (state, action: PayloadAction<Error | undefined>) => {
      state.actionError = action.payload;
    },

    setSortOrder: (state, action: PayloadAction<OrderType>) => {
      state.sortDirection = action.payload;

      localStorage.setItem('materials.list.sort.direction', state.sortDirection);
    },

    setSortBy: (state, action: PayloadAction<SortableHeads>) => {
      state.sortBy = action.payload;

      localStorage.setItem('materials.list.sort.by', state.sortBy);
    },

    updateMaterial: (state, action: PayloadAction<MaterialDto>) => {
      if (state.materialsList) {
        const existMaterial = state.materialsList.find((m) => m.materialId === action.payload.materialId);
        if (existMaterial) {
          existMaterial.name = action.payload.name;
          existMaterial.defaultAllowableUsages = action.payload.defaultAllowableUsages;
        }
      }
    },
  },
  extraReducers: (build) => {
    build.addCase(loadMaterialsList.pending, (state, action) => {
      state.status = 'loading';
    });

    build.addCase(loadMaterialsList.fulfilled, (state, action) => {
      state.status = 'idle';
      state.materialsList = [...action.payload];
    });

    build.addCase(loadMaterialsList.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error;
    });
  },
});

export const { setActionError, setSortOrder, setSortBy, updateMaterial } = materialsListSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectMaterialsList = (state: RootState) => state.materials.list.materialsList;

export default materialsListSlice.reducer;
