import { Box, Toolbar } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useAppDispatch } from '../store/hooks';
import { loadMe } from '../store/user/userSlice';
import AppMenu from './AppMenu';
import { AppRouter } from './AppRouter';
import ButtonAppBar from './ButtonAppBar';
import ScrollToTop from './ScrollToTop';

const AuthenticatedView: FunctionComponent = (props) => {
  const drawerWidth = 240;

  const [mobileOpen, setMobileOpen] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadMe());
  }, [dispatch]);

  const handleMenuToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuClose = () => {
    setMobileOpen(false);
  };

  return (
    <Router>
      <ScrollToTop />
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <ButtonAppBar onMenuToggle={handleMenuToggle} />
        <AppMenu mobileOpen={mobileOpen} onClose={handleMenuClose} drawerWidth={drawerWidth} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: {
              sm: `calc(100% - ${drawerWidth}px)`,
            },
          }}
        >
          <Toolbar />
          <AppRouter />
        </Box>
      </Box>
    </Router>
  );
};

export default AuthenticatedView;
