import { FunctionComponent } from 'react'
import { Stack, Typography } from '@mui/material'

interface Props {
  property: string;
  value: string;
}

const PropertyItem: FunctionComponent<Props> = (props) => {
  return (
    <Stack spacing={1} direction={'column'}>
      <Typography variant="subtitle2" color="GrayText" sx={{ overflowWrap: 'anywhere' }}>
        {props.property}
      </Typography>
      <Typography variant="h6" sx={{ overflowWrap: 'anywhere' }}>
        {props.value}
      </Typography>
    </Stack>
  )
}

export default PropertyItem
