import { Fragment, FunctionComponent } from 'react';
import { Switch, Tooltip } from '@mui/material';
import { updateEnableLogin, UserListItem } from '../../../../store/administration/usersListSlice';
import { useAppDispatch } from '../../../../store/hooks';
import { useMsal } from '@azure/msal-react';

interface Props {
  user: UserListItem;
}

const EnableLoginSwitch: FunctionComponent<Props> = (props) => {
  const { accounts } = useMsal();
  const accountId = accounts[0] && accounts[0].localAccountId;

  const dispatch = useAppDispatch();

  const handleChange = (value: boolean) => {
    dispatch(updateEnableLogin({ userId: props.user.userId, enableLogin: value }));
  };

  if (props.user.userId === accountId) {
    return <Fragment />;
  }

  return (
    <Tooltip title={props.user.enableLogin ? 'Disable login' : 'Enable login'}>
      <Switch
        checked={props.user.enableLogin}
        disabled={props.user.status === 'saving'}
        onChange={(e) => handleChange(e.target.checked)}
      />
    </Tooltip>
  );
};

export default EnableLoginSwitch;
