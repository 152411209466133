import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../store'
import setsApi from '../../api/SetsApi'

export interface SetsFiltersState {
  statusesStatus: 'idle' | 'loading' | 'failed';
  setNumberFilter?: string;
  statusFilter?: string;
  statuses?: string[];
  supplierFilter?: string;
  unitFilter?: string;
  responsibleFilter?: string;
}

const initialState: SetsFiltersState = {
  statusesStatus: 'idle',
}

export const getSetsStatuses = createAsyncThunk(
  'sets-filters/getSetsStatuses',
  async () => {
    return await setsApi.getStatuses()
  },
)

export const setFiltersSlice =
               createSlice(
                 {
                   name: 'set-list-filters',
                   initialState,
                   reducers: {
                     setNumberFilter: (
                       state, action: PayloadAction<string | undefined>) => {
                       state.setNumberFilter = action.payload
                     },

                     setStatusFilter: (
                       state, action: PayloadAction<string | undefined>) => {
                       state.statusFilter = action.payload
                     },

                     setSupplierFilter: (
                       state, action: PayloadAction<string | undefined>) => {
                       state.supplierFilter = action.payload
                     },

                     setUnitFilter: (state, action: PayloadAction<string | undefined>) => {
                       state.unitFilter = action.payload
                     },

                     setResponsibleFilter: (
                       state, action: PayloadAction<string | undefined>) => {
                       state.responsibleFilter = action.payload
                     },

                     clearAllFilters: (state) => {
                       state.setNumberFilter = undefined
                       state.statusFilter = undefined
                       state.supplierFilter = undefined
                       state.unitFilter = undefined
                       state.responsibleFilter = undefined
                     },
                   },
                   extraReducers: (build) => {
                     build.addCase(getSetsStatuses.pending, (state) => {
                       state.statusesStatus = 'loading'
                     })

                     build.addCase(getSetsStatuses.fulfilled, (state, action) => {
                       state.statuses = action.payload
                     })

                     build.addCase(getSetsStatuses.rejected, (state, action) => {
                       state.statusesStatus = 'failed'
                     })
                   },
                 })

export const {
               setNumberFilter,
               setStatusFilter,
               setSupplierFilter,
               setUnitFilter,
               setResponsibleFilter,
               clearAllFilters,
             } = setFiltersSlice.actions
export const selectSetsFilters = (state: RootState) => state.sets.setsFilters
export default setFiltersSlice.reducer
