import { Box, Container } from '@mui/material'
import { FunctionComponent, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CurrencyDto } from '../../../../api/types/CurrencyDto'

import { SupplierDto } from '../../../../api/types/SupplierData'
import ErrorDialog from '../../../../components/ErrorDialog'
import { validateFloat } from '../../../../helpers/validation'
import { useAppSelector, useAppDispatch } from '../../../../store/hooks'
import {
  ToolingViewState, load, selectToolingView,
} from '../../../../store/tooling/toolingViewSlice'
import PageToolbar from '../../components/PageToolbar'
import { useTransferPermissions } from '../../view/tabs/operation/hooks'
import ErrorStateHandler from '../../../components/ErrorsStateHandler'
import EditTransferContent from '../edit/EditTransferContent'
import {
  selectCreateTransfer, CreateTransferState, clear, setReason, setShipper, setShipperType, setShipperName, setCost,
  setCurrency, save, setDeliveryDate, setTransferTaskId, setComment, hideActionError, setStartLocation,
  setFinishLocation,
} from '../../../../store/tooling/transfers/createTransferSlice'
import {
  ExternalShipperRequest, ShipperRequest, UpdateShipperRequest, UpdateTransferRequest, ShipperType,
} from '../../../../api/types/Transfers'

const CreateTransferPage: FunctionComponent = () => {
  let { toolingId } = useParams()
  const toolingIdParsed = Number.parseInt(toolingId ?? '0')

  const toolingState: ToolingViewState = useAppSelector(selectToolingView)

  const location = toolingState.tooling?.summary?.location?.atSupplier
  const transferState: CreateTransferState = useAppSelector(selectCreateTransfer)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const isLoading = toolingState.status === 'loading' || transferState.status === 'loading'
  const isSaving = transferState.status === 'saving'
  const isIdle = toolingState.status === 'idle' && transferState.status === 'idle'
  const isFailed = toolingState.status === 'failed' || transferState.status === 'failed'

  const transferPermissions = useTransferPermissions(toolingState.tooling?.responsibles?.map((u) => u.userId) ?? [])
  const canEdit = isIdle && transferPermissions

  useEffect(() => {
    dispatch(clear())
  }, [dispatch])

  useEffect(() => {
    dispatch(load(toolingIdParsed))
    if (location) {
      dispatch(setStartLocation(location))
    }
  }, [dispatch, toolingIdParsed])

  const handleChangeReason = (reason: string) => {
    dispatch(setReason(reason))
  }

  const handleChangeShipperType = (type: ShipperType) => {
    dispatch(setShipperType(type))
  }

  const handleChangeShipperName = (shipperName: string) => {
    dispatch(setShipperName(shipperName))
  }
  const handleChangeShipper = (shipper: SupplierDto | null) => {
    dispatch(setShipper(shipper))
  }

  const handleChangeStartLocation = (location: SupplierDto | null) => {
    dispatch(setStartLocation(location))
  }

  const handleChangeFinishLocation = (location: SupplierDto | null) => {
    dispatch(setFinishLocation(location))
  }

  const handleChangeCost = (cost: string) => {
    validateFloat(cost, (costNumber) => {
      dispatch(setCost(costNumber))
    })
  }

  const handleChangeCurrency = (currency?: CurrencyDto) => {
    dispatch(setCurrency(currency))
  }

  const handleChangeDeliveryDate = (deliveryDate?: string) => {
    dispatch(setDeliveryDate(deliveryDate))
  }

  const handleChangeTask = (transferTaskId?: number) => {
    dispatch(setTransferTaskId(transferTaskId ?? 0))
  }

  const handleChangeComment = (comment: string) => {
    dispatch(setComment(comment))
  }

  const handleSave = () => {
    const shipper = {
      external:
        transferState.shipperType === 'External'
        ? ({ name: transferState.shipperName } as ExternalShipperRequest)
        : undefined,
      shipper:
        transferState.shipperType === 'Supplier'
        ? ({ shipperId: transferState.shipper?.supplierId } as ShipperRequest)
        : undefined,
    } as UpdateShipperRequest

    const request = {
      comment: transferState.comment,
      cost: transferState.cost,
      currencyId: transferState.currency?.currencyId ?? 0,
      deliveryDate: transferState.deliveryDate,
      finishLocationId: transferState.finishLocation?.supplierId,
      reason: transferState.reason,
      shipper: shipper,
      startLocationId: transferState.startLocation?.supplierId,
      transferTaskId: transferState.transferTaskId,
    } as unknown as UpdateTransferRequest

    dispatch(save({ toolingId: toolingIdParsed, request })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        navigate(`/tooling/${toolingId}#operations`)
      }
    })
  }

  return (
    <Box mt={4} mb={4}>
      <Container maxWidth="lg">
        <PageToolbar title="New tooling transfer" backPath={`/tooling/${toolingIdParsed}#operation`}
                     isLoading={isLoading}/>
        <ErrorStateHandler failed={isFailed}>
          <EditTransferContent
            toolingId={toolingIdParsed}
            canEdit={canEdit}
            reason={transferState.reason}
            shipperType={transferState.shipperType}
            shipperName={transferState.shipperName}
            shipper={transferState.shipper}
            startLocation={transferState.startLocation}
            finishLocation={transferState.finishLocation}
            cost={transferState.cost}
            currency={transferState.currency}
            deliveryDate={transferState.deliveryDate}
            transferTaskId={transferState.transferTaskId}
            comment={transferState.comment}
            isLoading={isLoading}
            isSaving={isSaving}
            onChangeReason={handleChangeReason}
            onChangeShipperType={handleChangeShipperType}
            onChangeShipperName={handleChangeShipperName}
            onChangeShipper={handleChangeShipper}
            onChangeStartLocation={handleChangeStartLocation}
            onChangeFinishLocation={handleChangeFinishLocation}
            onChangeCost={handleChangeCost}
            onChangeCurrency={handleChangeCurrency}
            onChangeDeliveryDate={handleChangeDeliveryDate}
            onChangeTransferTaskId={handleChangeTask}
            onChangeComment={handleChangeComment}
            onSave={handleSave}
          />
          <ErrorDialog
            open={transferState.actionError !== undefined}
            title={'Error'}
            text={`Error while saving data. Please try again later or contact the site administrator.\n${transferState.actionError?.message}`}
            onOk={() => dispatch(hideActionError())}
          />
        </ErrorStateHandler>
      </Container>
    </Box>
  )
}

export default CreateTransferPage
