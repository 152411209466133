import { CurrencyDto } from '../../../../api/types/CurrencyDto'
import { isEmptyOrSpaces } from '../../../../helpers/stringHelper'
import { ShipperType } from '../../../../api/types/Transfers'
import { SupplierDto } from '../../../../api/types/SupplierData'

export const hasReasonError = (reason: string) => {
  return isEmptyOrSpaces(reason)
}

export const hasShipperError = (shipper: SupplierDto | null) => {
  return !shipper
}

export const hasExternalShipperNameError = (shipperName?: string) => {
  return isEmptyOrSpaces(shipperName)
}

export const hasCommonShipperError = (shipperType: ShipperType, shipper?: SupplierDto | null, shipperName?: string) => {
  if (shipperType === 'Supplier') {
    if (!shipper) {
      return true
    }
    return hasShipperError(shipper)
  }

  if (shipperType === 'External') {
    return hasExternalShipperNameError(shipperName)
  }

  return false
}

export const hasCostError = (cost?: number) => {
  return !cost || cost <= 0
}

export const hasCurrencyError = (currency?: CurrencyDto) => {
  return !currency
}
