import { Box, Card, CardContent, CardHeader, Container, Divider, Grid } from '@mui/material'
import { FunctionComponent, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ErrorDialog from '../../../../components/ErrorDialog'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  hideActionError,
  hideConfirmDeleteTooling,
  load,
  selectEditRepair,
  showConfirmDeleteDialog,
  deleteRepair,
  setReason,
  setProviderType,
  setProviderName,
  setSupplier,
  setCost,
  setCurrency,
  setRepairDate,
  setRepairOrder,
  setComment,
  save,
} from '../../../../store/tooling/repairs/editRepairSlice'
import PageToolbar from '../../components/PageToolbar'
import EditRepairContent from './EditRepairContent'
import ErrorStateHandler from '../../../components/ErrorsStateHandler'
import YesNoDialog from '../../../../components/YesNoDialog'
import { useRepairPermissions } from '../../view/tabs/operation/hooks'
import {
  ExternalRepairProviderRequest,
  ProviderType,
  SupplierRepairProviderRequest,
  UpdateRepairProviderRequest,
  UpdateRepairRequest,
} from '../../../../api/types/Repairs'
import { SupplierDto } from '../../../../api/types/SupplierData'
import { CurrencyDto } from '../../../../api/types/CurrencyDto'
import { validateFloat } from '../../../../helpers/validation'
import RepairFilesPanel from './files/RepairFilesPanel'

const EditRepairPage: FunctionComponent = () => {
  let { toolingId, repairId } = useParams()
  const toolingIdParsed = Number.parseInt(toolingId ?? '0')
  const repairIdParsed = Number.parseInt(repairId ?? '0')

  const repairState = useAppSelector(selectEditRepair)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(load({ toolingId: toolingIdParsed, repairId: repairIdParsed }))
  }, [dispatch, toolingIdParsed, repairIdParsed])

  const isLoading = repairState.status === 'loading'
  const isSaving = repairState.status === 'saving'
  const isFailed = repairState.status === 'failed'
  const isIdle = repairState.status === 'idle'

  const repairPermissions = useRepairPermissions(repairState.responsible)
  const canEdit = isIdle && repairPermissions

  const handleDelete = () => {
    dispatch(showConfirmDeleteDialog())
  }

  const handleConfirmDelete = () => {
    dispatch(hideConfirmDeleteTooling())
    dispatch(deleteRepair({ toolingId: toolingIdParsed, repairId: repairIdParsed }))
      .then(() => navigate(`/tooling/${toolingIdParsed}#operations`))
  }

  const handleChangeReason = (reason: string) => {
    dispatch(setReason(reason))
  }

  const handleChangeProviderType = (type: ProviderType) => {
    dispatch(setProviderType(type))
  }

  const handleChangeProviderName = (providerName: string) => {
    dispatch(setProviderName(providerName))
  }

  const handleChangeSupplier = (supplier: SupplierDto | null) => {
    dispatch(setSupplier(supplier))
  }

  const handleChangeCost = (cost: string) => {
    validateFloat(cost, (costNumber) => {
      dispatch(setCost(costNumber))
    })
  }

  const handleChangeCurrency = (currency?: CurrencyDto) => {
    dispatch(setCurrency(currency))
  }

  const handleChangeRepairDate = (repairDate?: string) => {
    dispatch(setRepairDate(repairDate))
  }

  const handleChangeOrder = (order: string) => {
    dispatch(setRepairOrder(order))
  }

  const handleChangeComment = (comment: string) => {
    dispatch(setComment(comment))
  }

  const handleSave = () => {
    const provider = {
      external:
        repairState.providerType === 'External'
        ? ({ name: repairState.providerName } as ExternalRepairProviderRequest)
        : undefined,
      supplier:
        repairState.providerType === 'Supplier'
        ? ({ supplierId: repairState.supplier?.supplierId } as SupplierRepairProviderRequest)
        : undefined,
    } as UpdateRepairProviderRequest

    const request = {
      reason: repairState.reason,
      provider,
      cost: repairState.cost,
      currencyId: repairState.currency?.currencyId ?? 0,
      repairDate: repairState.repairDate,
      repairOrder: repairState.repairOrder,
      comment: repairState.comment,
    } as UpdateRepairRequest

    dispatch(save({ toolingId: toolingIdParsed, repairId: repairIdParsed, request })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        navigate(`/tooling/${toolingId}#operations`)
      }
    })
  }

  return (
    <Box mt={4} mb={4}>
      <Container maxWidth="lg">
        <PageToolbar
          title="Edit tooling repair"
          backPath={`/tooling/${toolingIdParsed}#operations`}
          isLoading={isLoading}
          hideButton={canEdit}
          onButtonAction={handleDelete}
        />

        <ErrorStateHandler failed={isFailed}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Card>
                <CardHeader title="Properties"/>
                <Divider/>
                <CardContent>
                  <EditRepairContent
                    toolingId={toolingIdParsed}
                    canEdit={canEdit}
                    reason={repairState.reason}
                    providerType={repairState.providerType}
                    supplier={repairState.supplier}
                    providerName={repairState.providerName}
                    cost={repairState.cost}
                    currency={repairState.currency}
                    repairDate={repairState.repairDate}
                    repairOrder={repairState.repairOrder}
                    comment={repairState.comment}
                    isLoading={isLoading}
                    isSaving={isSaving}
                    onChangeReason={handleChangeReason}
                    onChangeProviderType={handleChangeProviderType}
                    onChnageProviderName={handleChangeProviderName}
                    onChangeSupplier={handleChangeSupplier}
                    onChangeCost={handleChangeCost}
                    onChangeCurrency={handleChangeCurrency}
                    onChangeRepairDate={handleChangeRepairDate}
                    onChangeOrder={handleChangeOrder}
                    onChangeComment={handleChangeComment}
                    onSave={handleSave}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <RepairFilesPanel toolingId={toolingIdParsed} repairId={repairIdParsed}/>
            </Grid>
          </Grid>
          <ErrorDialog
            open={repairState.actionError !== undefined}
            title={'Error'}
            text={`Error while saving data. Please try again later or contact the site administrator.\n${repairState.actionError?.message}`}
            onOk={() => dispatch(hideActionError())}
          />
          <YesNoDialog
            open={repairState.showConfirmDeleteDialog}
            title="Delete repair"
            onNo={() => dispatch(hideConfirmDeleteTooling())}
            onYes={handleConfirmDelete}
          >
            {`Are you sure you want to delete tooling repair?`}
          </YesNoDialog>
        </ErrorStateHandler>
      </Container>
    </Box>
  )
}

export default EditRepairPage
