import { EntityState, SerializedError, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { ToolingHistoryActionType } from '../../../api/types/history/ToolingHistoryActionType';
import { loadHistory } from './actions';
import { RootState } from '../../store';
const name = 'order/history';

interface HistoryItem {
  date: string;
  author: string;
  action: Action;
  description?: string;
}

interface Action {
  id: ToolingHistoryActionType;
  name: string;
}

const historyAdapter = createEntityAdapter<HistoryItem>({ selectId: (item) => item.date });

interface ToolingHistoryState {
  status: 'idle' | 'loading' | 'error';
  items: EntityState<HistoryItem>;
  error?: SerializedError;
}

const initialState: ToolingHistoryState = {
  status: 'idle',
  items: historyAdapter.getInitialState(),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build.addCase(loadHistory.pending, (state) => {
      state.status = 'loading';
      historyAdapter.removeAll(state.items);
    });

    build.addCase(loadHistory.fulfilled, (state, action) => {
      const items = action.payload.items.map((i) => {
        return {
          date: i.timestamp,
          author: i.author,
          action: { id: i.type.id, name: i.type.name },
          description: i.message,
        } as HistoryItem;
      });
      historyAdapter.addMany(state.items, items);
      state.status = 'idle';
    });

    build.addCase(loadHistory.rejected, (state, action) => {
      state.status = 'error';
      state.error = action.error;
    });
  },
});

export const { selectAll: selectHistory } = historyAdapter.getSelectors<RootState>(
  (state) => state.tooling.history.items,
);

export const {} = slice.actions;

export default slice.reducer;
