import { CurrencyDto } from '../../../../api/types/CurrencyDto';
import { ProviderType } from '../../../../api/types/Repairs';
import { SupplierDto } from '../../../../api/types/SupplierData';
import { isEmptyOrSpaces } from '../../../../helpers/stringHelper';

export const hasReasonError = (reason: string) => {
  return isEmptyOrSpaces(reason);
};

export const hasSupplierError = (supplier: SupplierDto | null) => {
  return !supplier;
};

export const hasExternalProviderNameError = (providerName?: string) => {
  return isEmptyOrSpaces(providerName);
};

export const hasProviderError = (providerType: ProviderType, supplier: SupplierDto | null, providerName?: string) => {
  if (providerType === 'Supplier') {
    return hasSupplierError(supplier);
  }

  if (providerType === 'External') {
    return hasExternalProviderNameError(providerName);
  }

  return false;
};

export const hasCostError = (cost?: number) => {
  return !cost || cost <= 0;
};

export const hasCurrencyError = (currency?: CurrencyDto) => {
  return !currency;
};
