import {
  Box, Container, Tab, Grid, Button,
} from '@mui/material'
import { FunctionComponent, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import ErrorStateHandler from '../components/ErrorsStateHandler'
import ErrorDialog from '../../components/ErrorDialog'
import {
  selectCurrentItem, CurrentItemState, loadSet, setCurrentItemComment, setCurrentItemDescription, updateSet,
  setCurrentItemSupplier, processToolingCheck, deleteSet, decommissateSet,
} from '../../store/sets/currentItemSlice'
import { selectSetsList, SetsListState, clearList } from '../../store/sets/setsListSlice'
import SetContent from './SetContent'
import { useSetsPermissions } from '../../application/sets/hooks'
import SetPageToolbar from './SetPageToolbar'
import { SupplierDto } from '../../api/types/SupplierData'
import { SetToolingItemDto } from '../../api/types/Sets/SetDto'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import SetHistory from './History/SetHistory'
import SetResponsibles from './SetResponsibles'
import SetOrders from './orders/SetOrders'
import { loadSetOrders, selectSetOrders, OrdersState } from '../../store/sets/orders/ordersSlice'
import TabLabel from '../tooling/view/components/TabLabel'
import { checkPermission } from '../../store/user/userSlice'
import { Permissions_Sets_Delete } from '../../api/types/permissions'

const propertiesTab = '#properties'
const ordersTab = '#orders'
const responsibleTab = '#responsible'
const historyTab = '#history'

const SetPage: FunctionComponent = () => {
  const location = useLocation()
  const hash = location.hash

  const [tabIndex, setTabIndex] = useState(hash || propertiesTab)

  let { setNumber } = useParams()
  const setNumberParsed = Number.parseInt(setNumber ?? '0')

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {
          currentItem,
          editCurrentItem,
          initCurrentItem,
          status,
        }: CurrentItemState = useAppSelector(selectCurrentItem)
  const setsListState: SetsListState = useAppSelector(selectSetsList)
  const usagesState: OrdersState = useAppSelector(selectSetOrders)

  const canEdit = useSetsPermissions(currentItem?.responsibles.map((r) => r.userId) ?? [])
  const hasDeletePermission = useAppSelector((root) => checkPermission(root, Permissions_Sets_Delete))

  useEffect(() => {
    if (!setsListState.items) {
      dispatch(loadSet({ setNumber: setNumberParsed }))
    }
  }, [])

  useEffect(() => {
    dispatch(loadSetOrders(setNumberParsed))
  }, [dispatch, setNumberParsed])

  useEffect(() => {
    setTabIndex(hash || propertiesTab)
  }, [hash])

  const isLoading = status === 'loading'
  const isSaving = status === 'saving'
  const isFailed = status === 'failed'

  const handleChangeTab = (tab: string) => {
    setTabIndex(tab)
    navigate(tab)
  }

  const handleChangeComment = (comment: string) => {
    dispatch(setCurrentItemComment(comment))
  }

  const handleChangeDescription = (description: string) => {
    dispatch(setCurrentItemDescription(description))
  }

  const handleChangeSupplier = (supplier: SupplierDto | null) => {
    dispatch(setCurrentItemSupplier(supplier))
  }
  const eqSet = (xs: number[], ys: number[]) =>
    xs.length === ys.length &&
    [...xs].every((x) => ys.includes(x))

  const handleSave = async () => {
    if (currentItem && editCurrentItem && initCurrentItem) {
      if (editCurrentItem.status !== currentItem.status) {
        await dispatch(decommissateSet({ setNumber: currentItem.toolingSetNumber }))
      }
      const editToolings = editCurrentItem.tooling.map(t => t.toolingId)
      const currentToolings = initCurrentItem.tooling.map(t => t.toolingId)

      if (!eqSet(editToolings, currentToolings) ||
        editCurrentItem.supplier?.supplierId !== currentItem.supplier?.supplierId ||
        editCurrentItem.description !== currentItem.description ||
        editCurrentItem.comment !== currentItem.comment) {
        await dispatch(updateSet(
          {
            setNumber: currentItem.toolingSetNumber,
            toolingIds: editCurrentItem.tooling.map(t => t.toolingId),
            supplierId: editCurrentItem.supplier?.supplierId ?? 0,
            description: editCurrentItem.description ?? '',
            comment: editCurrentItem.comment ?? '',
          }))
      }
    }
  }

  const handleCheckTooling = (tooling: SetToolingItemDto) => (): void => {
    dispatch(processToolingCheck(tooling))
  }

  const handleDeleteSet = async (setNumber?: number) => {
    if (setNumber) {
      await dispatch(deleteSet({ setNumber }))
      await dispatch(clearList())
      navigate(`/sets`)
    }
  }

  const checkSaveEnabled = () => {
    if (editCurrentItem && currentItem && initCurrentItem) {

      const editToolings = editCurrentItem.tooling.map(t => t.toolingId)
      const currentToolings = initCurrentItem.tooling.map(t => t.toolingId)

      if (!eqSet(editToolings, currentToolings) ||
        editCurrentItem.supplier?.supplierId !== currentItem.supplier?.supplierId ||
        editCurrentItem.description !== currentItem.description ||
        editCurrentItem.comment !== currentItem.comment ||
        editCurrentItem.status !== currentItem.status
      ) {
        return true
      }
    }
    return false
  }

  return (
    <Box mt={4} mb={4}>
      <Container maxWidth="lg">
        <SetPageToolbar title={`Set ${currentItem?.toolingSetNumber}`}
                        backPath={`/sets`}
                        isLoading={isLoading}>
          {hasDeletePermission &&
            <Grid item>
              <Button variant="outlined" color="error" disabled={isLoading}
                      onClick={() => handleDeleteSet(currentItem?.toolingSetNumber)}>
                Delete
              </Button>
            </Grid>
          }

        </SetPageToolbar>
        <TabContext value={tabIndex}>
          <TabList onChange={(_, newValue) => handleChangeTab(newValue)}>
            <Tab label="Properties" value={propertiesTab}/>
            <Tab label={<TabLabel title="Orders" count={usagesState?.orders.length ?? 0}/>}
                 value={ordersTab}/>
            <Tab label="Responsilbe" value={responsibleTab}/>
            <Tab label="History" value={historyTab}/>
          </TabList>
          <TabPanel value={propertiesTab} sx={{ padding: '0px' }}>
            {currentItem && editCurrentItem &&
              <SetContent
                toolingSetId={currentItem.toolingSetId}
                toolingSetNumber={currentItem.toolingSetNumber}
                status={editCurrentItem.status}
                description={editCurrentItem.description}
                comment={editCurrentItem.comment}
                assemblyUnits={currentItem.assemblyUnits}
                setUnit={currentItem.setUnit}
                tooling={currentItem.tooling}
                editTooling={editCurrentItem.tooling}
                supplier={editCurrentItem.supplier}
                locations={currentItem.locations}
                canEdit={canEdit}
                isLoading={isLoading}
                isSaving={isSaving}
                onChangeSupplier={handleChangeSupplier}
                onChangeDescription={handleChangeDescription}
                onChangeComment={handleChangeComment}
                onCheckTooling={handleCheckTooling}
                onSave={handleSave}
                canSave={checkSaveEnabled()}
              />}
          </TabPanel>
          <TabPanel value={ordersTab} sx={{ padding: '0px' }}>
            {currentItem && <Box mt={2}>
              <SetOrders setNumber={currentItem.toolingSetNumber}/>
            </Box>}
          </TabPanel>
          <TabPanel value={responsibleTab} sx={{ padding: '0px' }}>
            {currentItem && editCurrentItem && <Box mt={2}>
              <SetResponsibles setNumber={currentItem.toolingSetNumber}
                               responsibles={currentItem.responsibles}
                               editResponsibles={editCurrentItem?.responsibles}
                               isLoading={isLoading}
                               isSaving={isSaving}
                               isFail={isFailed}
                               canEdit={canEdit}
              />
            </Box>}
          </TabPanel>
          <TabPanel value={historyTab} sx={{ padding: '0px' }}>
            {currentItem && <Box mt={2}>
              <SetHistory setNumber={currentItem.toolingSetNumber}/>
            </Box>}
          </TabPanel>
        </TabContext>
        <ErrorStateHandler failed={isFailed}>
          <ErrorDialog
            open={isFailed}
            title={'Error'}
            text={`Error while saving data. Please try again later or contact the site administrator.\n${setsListState.actionError?.message}`}
            onOk={() => {
            }}
          />
        </ErrorStateHandler>
      </Container>
    </Box>
  )
}

export default SetPage
