import authService from '../../auth/AuthService';
import { API_ADDRESS } from '../api';
import { handleErrors } from '../errorHandler';
import { msalFetch } from '../graph';
import { UserDto } from '../types/administration/Users';

const USER_API_ADDRESS = `${API_ADDRESS}/api/administration/users`;

export class UsersApi {
  async getUsers() {
    const token = await authService.getIdentity();

    return msalFetch()
      .get(USER_API_ADDRESS, token.accessToken)
      .then(handleErrors)
      .then<UserDto[]>((res) => res.json());
  }

  async updateEnableLogin(userId: string, enableLogin: boolean) {
    const token = await authService.getIdentity();

    return msalFetch()
      .put(`${USER_API_ADDRESS}/${userId}/enable-login`, token.accessToken, JSON.stringify({ enableLogin }), {
        'content-type': 'application/json',
      })
      .then(handleErrors)
      .then(() => Promise.resolve());
  }

  async addUser(userId: string) {
    const token = await authService.getIdentity();

    return msalFetch()
      .post(`${USER_API_ADDRESS}/${userId}`, token.accessToken)
      .then(handleErrors)
      .then<UserDto>((res) => res.json());
  }
}

const usersApi = new UsersApi();

export default usersApi;
