import { FunctionComponent, useEffect } from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { loadMaterialsList, selectMaterialsList, updateMaterial } from '../../store/materials/materialsListSlice';
import MaterialsTable from './components/MaterialsTable';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import EditMaterialDialog from './components/EditMaterialDialog';
import { selectEditMaterial, close } from '../../store/materials/editMaterialSlice';
import ErrorBox from '../../components/ErrorBox';

const MaterialsListPage: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const materialsListState = useAppSelector(selectMaterialsList);
  const editMaterialState = useAppSelector(selectEditMaterial);

  useEffect(() => {
    dispatch(loadMaterialsList());
  }, [dispatch]);

  if (materialsListState.status === 'failed') {
    if (materialsListState.error) {
      return (
        <ErrorBox
          code={materialsListState.error.name}
          description={materialsListState.error.message}
          sx={{ minHeight: '90vh' }}
        >
          <Button variant="contained" onClick={() => dispatch(loadMaterialsList())}>
            Try again
          </Button>
        </ErrorBox>
      );
    } else {
      return (
        <ErrorBox code="500" description="Error while loading data" sx={{ minHeight: '90vh' }}>
          <Button variant="contained" onClick={() => dispatch(loadMaterialsList())}>
            Try again
          </Button>
        </ErrorBox>
      );
    }
  }

  return (
    <Container maxWidth="md">
      <Box mt={4} mb={4}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs>
            <Typography variant="h4">Materials</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box mt={4}>
        <MaterialsTable items={materialsListState.materialsList} status={materialsListState.status} />
      </Box>

      {editMaterialState.open && editMaterialState.material && (
        <EditMaterialDialog
          open={editMaterialState.open}
          material={editMaterialState.material}
          materialId={''}
          onClose={(material) => {
            if (material) {
              dispatch(updateMaterial(material));
            }

            dispatch(close());
          }}
        />
      )}
    </Container>
  );
};

export default MaterialsListPage;
