import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import toolingApi from '../../api/ToolingApi'
import { ToolingAccountingDto } from '../../api/types/ToolingDto'
import { RootState } from '../store'

export interface ToolingAccountingEditState {
  status: 'idle' | 'loading' | 'saving' | 'failed';
  accountingNumber?: string;
  accountingDate?: string;
  scrappingDate?: string;
  accountingDepreciationAsFixedAsset: boolean;
  actionError?: SerializedError;
}

const initialState: ToolingAccountingEditState = {
  status: 'idle',
  accountingNumber: '',
  accountingDepreciationAsFixedAsset: false,
}

export const save = createAsyncThunk(
  'tooling-accounting-edit-slice/save',
  async (data: { toolingId: number | string; accounting: ToolingAccountingDto }) => {
    return await toolingApi.updateAccounting(data.toolingId, data.accounting)
  },
)

export const accountingSlice = createSlice({
                                             name: 'tooling-accounting-edit-slice',
                                             initialState,
                                             reducers: {
                                               startLoading: (state) => {
                                                 state.status = 'loading'
                                               },
                                               successLoading: (state) => {
                                                 state.status = 'idle'
                                               },
                                               fail: (state) => {
                                                 state.status = 'failed'
                                               },
                                               init: (
                                                 state, action: PayloadAction<ToolingAccountingDto | undefined>) => {
                                                 state.accountingNumber = action.payload?.accountingNumber
                                                 state.accountingDate = action.payload?.accountingDate
                                                 state.scrappingDate = action.payload?.scrappingDate
                                                 state.accountingDepreciationAsFixedAsset = action.payload?.accountingDepreciationAsFixedAsset ?? false
                                               },
                                               setAccountingNumber: (
                                                 state, action: PayloadAction<string | undefined>) => {
                                                 state.accountingNumber = action.payload
                                               },
                                               setAccountingDate: (
                                                 state, action: PayloadAction<string | undefined>) => {
                                                 state.accountingDate = action.payload
                                               },
                                               setScrappingDate: (
                                                 state, action: PayloadAction<string | undefined>) => {
                                                 state.scrappingDate = action.payload
                                               },
                                               setAccountingDepreciationAsFixedAsset: (
                                                 state, action: PayloadAction<boolean>) => {
                                                 state.accountingDepreciationAsFixedAsset = action.payload
                                               },
                                               hideActionError: (state) => {
                                                 state.actionError = undefined
                                               },
                                             },
                                             extraReducers: (build) => {
                                               build.addCase(save.pending, (state, action) => {
                                                 state.status = 'saving'
                                               })

                                               build.addCase(save.fulfilled, (state, action) => {
                                                 state.status = 'idle'
                                               })

                                               build.addCase(save.rejected, (state, action) => {
                                                 state.status = 'idle'
                                                 state.actionError = action.error
                                               })
                                             },
                                           })

export const {
               startLoading,
               successLoading,
               fail,
               init,
               setAccountingNumber,
               setAccountingDate,
               setScrappingDate,
               setAccountingDepreciationAsFixedAsset,
               hideActionError,
             } = accountingSlice.actions

export const selectToolingEditAccounting = (state: RootState) => state.tooling.edit.accounting

export default accountingSlice.reducer
