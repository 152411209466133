import React, { FunctionComponent, useState } from 'react'
import { useAppDispatch } from '../../store/hooks'
import {
  IconButton, CircularProgress,
} from '@mui/material'
import UpdateIcon from '@mui/icons-material/Update'
import { updateUnitTooling } from '../../store/sets/currentItemSlice'

interface Props {
  unitFileId: number;
}

const UpdateUnitToolingIcon: FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch()
  const [saving, setSaving] = useState(false)

  const handleUpdate = (unitFileId: number) => {
    setSaving(true)
    dispatch(updateUnitTooling({ unitFileId: unitFileId }))
      .then(() => {
        setSaving(false)
      }).catch(() => {
      setSaving(false)
    })
  }

  return (
    <IconButton edge="end" onClick={() => handleUpdate(props.unitFileId)}>
      {saving ? <CircularProgress size={20}/> : <UpdateIcon/>}
    </IconButton>
  )
}

export default UpdateUnitToolingIcon
