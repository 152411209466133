import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    text: {
      primary: '#2b3445',
    },
  },
  typography: {
    textSmMedium: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#101828',
    },
    textSmRegular: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#667085',
    },
    textMdMedium: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#101828',
    },
    textLgSemibold: {
      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '28px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#101828',
    },
  },
  spacing: 4,
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: '#101828',
          fontSize: '18px',
          fontFamily: 'Inter',
          fontWeight: 600,
          lineHeight: '28px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          color: '#101828',
          borderRadius: '8px',
        },
      },
    },
  },
});

declare module '@mui/material/styles' {
  interface TypographyVariants {
    textSmMedium: React.CSSProperties;
    textSmRegular: React.CSSProperties;
    textMdMedium: React.CSSProperties;
    textLgSemibold: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    textSmMedium?: React.CSSProperties;
    textSmRegular?: React.CSSProperties;
    textMdMedium?: React.CSSProperties;
    textLgSemibold?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    textSmMedium: true;
    textSmRegular: true;
    textMdMedium: true;
    textLgSemibold: true;
  }
}
