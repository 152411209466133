import authService from '../auth/AuthService';
import { API_ADDRESS } from './api';
import { handleErrors } from './errorHandler';
import { msalFetch } from './graph';
import { RepairDto, RepairsResponse, UpdateRepairRequest } from './types/Repairs';

const TOOLING_API_ADDRESS = `${API_ADDRESS}/api/tooling`;

export class RepairsApi {
  async get(toolingId: number, repairId: number) {
    const result = await authService.getIdentity();

    if (result) {
      return msalFetch()
        .get(`${TOOLING_API_ADDRESS}/${toolingId}/repairs/${repairId}`, result.accessToken)
        .then(handleErrors)
        .then<RepairDto>((res) => res.json());
    }
  }

  async getRepairs(toolingId: number) {
    const result = await authService.getIdentity();

    if (result) {
      return msalFetch()
        .get(`${TOOLING_API_ADDRESS}/${toolingId}/repairs`, result.accessToken)
        .then(handleErrors)
        .then<RepairsResponse>((res) => res.json());
    }
  }

  async createRepair(toolingId: number, request: UpdateRepairRequest) {
    const result = await authService.getIdentity();

    if (result) {
      return msalFetch()
        .post(`${TOOLING_API_ADDRESS}/${toolingId}/repairs`, result.accessToken, JSON.stringify(request), {
          'content-type': 'application/json',
        })
        .then(handleErrors);
    }
  }

  async updateRepair(toolingId: number, repairId: number, request: UpdateRepairRequest) {
    const result = await authService.getIdentity();

    if (result) {
      return msalFetch()
        .put(`${TOOLING_API_ADDRESS}/${toolingId}/repairs/${repairId}`, result.accessToken, JSON.stringify(request), {
          'content-type': 'application/json',
        })
        .then(handleErrors);
    }
  }

  async deleteRepair(toolingId: number, repairId: number) {
    const result = await authService.getIdentity();

    if (result) {
      return msalFetch()
        .delete(`${TOOLING_API_ADDRESS}/${toolingId}/repairs/${repairId}`, result.accessToken)
        .then(handleErrors);
    }
  }
}

const repairsApi = new RepairsApi();

export default repairsApi;
