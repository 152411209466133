import { Box, Container } from '@mui/material'
import { FunctionComponent, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CurrencyDto } from '../../../../api/types/CurrencyDto'
import {
  ExternalRepairProviderRequest,
  ProviderType,
  SupplierRepairProviderRequest,
  UpdateRepairProviderRequest,
  UpdateRepairRequest,
} from '../../../../api/types/Repairs'
import { SupplierDto } from '../../../../api/types/SupplierData'
import ErrorDialog from '../../../../components/ErrorDialog'
import { validateFloat } from '../../../../helpers/validation'
import { useAppSelector, useAppDispatch } from '../../../../store/hooks'
import {
  hideActionError,
  selectCreateRepair,
  setReason,
  setProviderType,
  setProviderName,
  setSupplier,
  setCost,
  setCurrency,
  setRepairDate,
  setRepairOrder,
  setComment,
  save,
  clear,
  CreateRepairState,
} from '../../../../store/tooling/repairs/createRepairSlice'
import {
  ToolingViewState, load, selectToolingView,
} from '../../../../store/tooling/toolingViewSlice'
import PageToolbar from '../../components/PageToolbar'
import { useRepairPermissions } from '../../view/tabs/operation/hooks'
import ErrorStateHandler from '../../../components/ErrorsStateHandler'
import EditRepairContent from '../edit/EditRepairContent'

const CreateRepairPage: FunctionComponent = () => {
  let { toolingId } = useParams()
  const toolingIdParsed = Number.parseInt(toolingId ?? '0')

  const toolingState: ToolingViewState = useAppSelector(selectToolingView)
  const repairState: CreateRepairState = useAppSelector(selectCreateRepair)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const isLoading = toolingState.status === 'loading' || repairState.status === 'loading'
  const isSaving = repairState.status === 'saving'
  const isIdle = toolingState.status === 'idle' && repairState.status === 'idle'
  const isFailed = toolingState.status === 'failed' || repairState.status === 'failed'

  const repairPermissions = useRepairPermissions(toolingState.tooling?.responsibles?.map((u) => u.userId) ?? [])
  const canEdit = isIdle && repairPermissions

  useEffect(() => {
    dispatch(clear())
  }, [dispatch])

  useEffect(() => {
    dispatch(load(toolingIdParsed))
  }, [dispatch, toolingIdParsed])

  const handleChangeReason = (reason: string) => {
    dispatch(setReason(reason))
  }

  const handleChangeProviderType = (type: ProviderType) => {
    dispatch(setProviderType(type))
  }

  const handleChangeProviderName = (providerName: string) => {
    dispatch(setProviderName(providerName))
  }

  const handleChangeSupplier = (supplier: SupplierDto | null) => {
    dispatch(setSupplier(supplier))
  }

  const handleChangeCost = (cost: string) => {
    validateFloat(cost, (costNumber) => {
      dispatch(setCost(costNumber))
    })
  }

  const handleChangeCurrency = (currency?: CurrencyDto) => {
    dispatch(setCurrency(currency))
  }

  const handleChangeRepairDate = (repairDate?: string) => {
    dispatch(setRepairDate(repairDate))
  }

  const handleChangeOrder = (order: string) => {
    dispatch(setRepairOrder(order))
  }

  const handleChangeComment = (comment: string) => {
    dispatch(setComment(comment))
  }

  const handleSave = () => {
    const provider = {
      external:
        repairState.providerType === 'External'
        ? ({ name: repairState.providerName } as ExternalRepairProviderRequest)
        : undefined,
      supplier:
        repairState.providerType === 'Supplier'
        ? ({ supplierId: repairState.supplier?.supplierId } as SupplierRepairProviderRequest)
        : undefined,
    } as UpdateRepairProviderRequest

    const request = {
      reason: repairState.reason,
      provider,
      cost: repairState.cost,
      currencyId: repairState.currency?.currencyId ?? 0,
      repairDate: repairState.repairDate,
      repairOrder: repairState.repairOrder,
      comment: repairState.comment,
    } as UpdateRepairRequest

    dispatch(save({ toolingId: toolingIdParsed, request })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        navigate(`/tooling/${toolingId}#operations`)
      }
    })
  }

  return (
    <Box mt={4} mb={4}>
      <Container maxWidth="lg">
        <PageToolbar title="New tooling repair" backPath={`/tooling/${toolingIdParsed}#operations`}
                     isLoading={isLoading}/>
        <ErrorStateHandler failed={isFailed}>
          <EditRepairContent
            toolingId={toolingIdParsed}
            canEdit={canEdit}
            reason={repairState.reason}
            providerType={repairState.providerType}
            supplier={repairState.supplier}
            providerName={repairState.providerName}
            cost={repairState.cost}
            currency={repairState.currency}
            repairDate={repairState.repairDate}
            repairOrder={repairState.repairOrder}
            comment={repairState.comment}
            isLoading={isLoading}
            isSaving={isSaving}
            onChangeReason={handleChangeReason}
            onChangeProviderType={handleChangeProviderType}
            onChnageProviderName={handleChangeProviderName}
            onChangeSupplier={handleChangeSupplier}
            onChangeCost={handleChangeCost}
            onChangeCurrency={handleChangeCurrency}
            onChangeRepairDate={handleChangeRepairDate}
            onChangeOrder={handleChangeOrder}
            onChangeComment={handleChangeComment}
            onSave={handleSave}
          />
          <ErrorDialog
            open={repairState.actionError !== undefined}
            title={'Error'}
            text={`Error while saving data. Please try again later or contact the site administrator.\n${repairState.actionError?.message}`}
            onOk={() => dispatch(hideActionError())}
          />
        </ErrorStateHandler>
      </Container>
    </Box>
  )
}

export default CreateRepairPage
