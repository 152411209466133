import { FC } from 'react';

interface Props {
  url: string;
}

const PdfViewer: FC<Props> = (props) => {
  return (
    <object data={props.url} type="application/pdf" width="100%" height="100%">
      This browser does not support PDFs. Please download the PDF to view it: Download PDF.
    </object>
  );
};

export default PdfViewer;
