import authService from '../auth/AuthService';
import { API_ADDRESS } from './api';
import { handleErrors } from './errorHandler';
import { msalFetch } from './graph';
import { MaterialDto } from './types/MaterialDto';

const MATERIALS_API_ADDRESS = `${API_ADDRESS}/api/materials`;

export class MaterialsApi {
  async getMaterials() {
    const token = await authService.getIdentity();

    return msalFetch()
      .get(MATERIALS_API_ADDRESS, token.accessToken)
      .then(handleErrors)
      .then<MaterialDto[]>((res) => res.json());
  }

  async updateMaterial(material: MaterialDto) {
    const token = await authService.getIdentity();

    const result = await msalFetch().put(
      `${MATERIALS_API_ADDRESS}/${material.materialId}`,
      token.accessToken,
      JSON.stringify(material),
      {
        'content-type': 'application/json',
      },
    );
    await handleErrors(result);

    return result;
  }
}

const materialsApi = new MaterialsApi();

export default materialsApi;
