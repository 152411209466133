import authService from '../auth/AuthService';
import { API_ADDRESS } from './api';
import { msalFetch } from './graph';
import { ToolingHistoryResponse } from './types/history/ToolingHistoryResponse';

const HISTORY_API_ADDRESS = `${API_ADDRESS}/api/tooling`;

export class HistoryApi {
  async getHistory(toolingId: number) {
    const result = await authService.getIdentity();

    return msalFetch()
      .get(`${HISTORY_API_ADDRESS}/${toolingId}/history`, result.accessToken)
      .then<ToolingHistoryResponse>((res) => res.json());
  }
}

const historyApi = new HistoryApi();

export default historyApi;
