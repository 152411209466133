import { SupplierDto } from '../../../api/types/SupplierData'
import React, { FunctionComponent } from 'react'
import { CircularProgress, Grid } from '@mui/material'
import SupplierAutocomplete from '../../tooling/components/SupplierAutocomplete'

interface Props {
  isLoading: boolean;
  disabled?: boolean;
  selectedSupplier: SupplierDto | null;
  onChangeSupplier: (newSupplier: SupplierDto | null) => void;
}

const SetProperties: FunctionComponent<Props> = (props) => {

  if (props.isLoading) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress/>
      </Grid>
    )
  }

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <SupplierAutocomplete
          label={'Supplier'}
          disabled={props.disabled}
          supplier={props.selectedSupplier}
          error={!props.selectedSupplier}
          helperText={props.selectedSupplier ? undefined : 'Supplier cannot by empty'}
          onSelectSupplier={props.onChangeSupplier}
        />
      </Grid>
    </Grid>
  )
}

export default SetProperties
