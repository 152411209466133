import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { Edit, ExpandLess, ExpandMore } from '@mui/icons-material'
import PropertyItem from '../PropertyItem'
import CoreboxesView from './CoreboxesView'
import { FunctionComponent, useState } from 'react'
import { useAppSelector, useAccount } from '../../../../store/hooks'
import { selectToolingView } from '../../../../store/tooling/toolingViewSlice'
import { checkPermission } from '../../../../store/user/userSlice'
import {
  Permissions_Tooling_TechnicalInformation_Edit,
} from '../../../../api/types/permissions'
import EditTechnicalInfoDialog from './EditTechnicalInfoDialog'
import { SCRAPPED_STATUS } from '../../../../store/types/statuses'

interface Props {
  toolingId: string | number;
}

const TechnicalInformationCard: FunctionComponent<Props> = ({ toolingId }) => {
  const [editTechnicalInfo, setEditTechnicalInfo] = useState(false)
  const toolingViewState = useAppSelector(selectToolingView)
  const isLoading = toolingViewState.status === 'loading'
  const [expanded, setExpanded] = useState(false)

  const handleToggleExpand = () => {
    setExpanded(!expanded)
  }

  const haveEditTechInfoRight = useAppSelector((root) => checkPermission(root, Permissions_Tooling_TechnicalInformation_Edit))
  const userAccount = useAccount()

  const calcEditPermissions = () => {
    const responsibles = toolingViewState.tooling?.responsibles.filter((r) => r.userId === userAccount.localAccountId)
    const isScrappedStatus = toolingViewState.tooling?.summary?.status?.name === SCRAPPED_STATUS

    if (isScrappedStatus) {
      return (): boolean => false
    }

    return (): boolean => {
      if (haveEditTechInfoRight) {
        return true
      } else {
        if (responsibles) {
          return responsibles.length > 0
        } else {
          return false
        }
      }
    }
  }

  const canEditInfo = calcEditPermissions()

  return (
    <Card
      sx={{
        mt: 5,
      }}
    >
      <CardHeader
        title="Technical information"
        titleTypographyProps={{
          variant: 'textLgSemibold',
        }}
        action={
          <Toolbar>
            <Stack direction="row" spacing={1}>
              {toolingViewState.status === 'idle' && expanded && toolingViewState.tooling?.technicalInfo && canEditInfo() && (
                <Tooltip title="Edit technical information">
                  <IconButton onClick={() => setEditTechnicalInfo(true)}>
                    <Edit/>
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={expanded ? 'Collapse' : 'Expand'}>
                <IconButton onClick={handleToggleExpand}>{expanded ? <ExpandLess/> : <ExpandMore/>}</IconButton>
              </Tooltip>
            </Stack>
          </Toolbar>
        }
      />
      <Divider/>
      <Collapse in={expanded}>
        <CardContent>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography variant="textMdMedium" gutterBottom>
                Summary
              </Typography>
            </Grid>
            <PropertyItem caption="Сasting tolerance grade">
              {isLoading ? (
                <Skeleton width={50}/>
              ) : (
                 <Typography variant="textSmRegular">
                   {toolingViewState.tooling?.technicalInfo?.castingToleranceGrade}
                 </Typography>
               )}
            </PropertyItem>
            <PropertyItem caption="Flask dimensions">
              {isLoading ? (
                <Skeleton width={50}/>
              ) : (
                 <Typography variant="textSmRegular">
                   {toolingViewState.tooling?.technicalInfo?.flaskDimensions}
                 </Typography>
               )}
            </PropertyItem>
            <PropertyItem caption="Total weight">
              {isLoading ? (
                <Skeleton width={50}/>
              ) : (
                 <Typography variant="textSmRegular">{toolingViewState.tooling?.technicalInfo?.totalWeight}</Typography>
               )}
            </PropertyItem>
            <PropertyItem caption="Gauges">
              {isLoading ? (
                <Skeleton width={50}/>
              ) : (
                 <Typography variant="textSmRegular">{toolingViewState.tooling?.technicalInfo?.gauges}</Typography>
               )}
            </PropertyItem>
            <PropertyItem caption="Chills">
              {isLoading ? (
                <Skeleton width={50}/>
              ) : (
                 <Typography variant="textSmRegular">{toolingViewState.tooling?.technicalInfo?.chills}</Typography>
               )}
            </PropertyItem>
            <Grid
              item
              sx={{
                mt: 2,
                mb: 2,
              }}
            >
              <Divider/>
            </Grid>
            <Grid item>
              <Typography variant="textMdMedium" gutterBottom>
                Packed dimensions
              </Typography>
            </Grid>
            <PropertyItem caption="Width">
              {isLoading ? (
                <Skeleton width={50}/>
              ) : (
                 <Typography variant="textSmRegular">
                   {toolingViewState.tooling?.technicalInfo?.packing?.width}
                 </Typography>
               )}
            </PropertyItem>
            <PropertyItem caption="Height">
              {isLoading ? (
                <Skeleton width={50}/>
              ) : (
                 <Typography variant="textSmRegular">
                   {toolingViewState.tooling?.technicalInfo?.packing?.height}
                 </Typography>
               )}
            </PropertyItem>
            <PropertyItem caption="Length">
              {isLoading ? (
                <Skeleton width={50}/>
              ) : (
                 <Typography variant="textSmRegular">
                   {toolingViewState.tooling?.technicalInfo?.packing?.length}
                 </Typography>
               )}
            </PropertyItem>
            <Grid
              item
              sx={{
                mt: 2,
                mb: 2,
              }}
            >
              <Divider/>
            </Grid>
            <Grid item>
              <Typography
                variant="textMdMedium"
                gutterBottom
                sx={{
                  mt: 2,
                }}
              >
                Loose parts
              </Typography>
            </Grid>
            <PropertyItem caption="Quantity">
              {isLoading ? (
                <Skeleton width={50}/>
              ) : (
                 <Typography variant="textSmRegular">
                   {toolingViewState.tooling?.technicalInfo?.looseParts?.quantity}
                 </Typography>
               )}
            </PropertyItem>
            <PropertyItem caption="Gating system">
              {isLoading ? (
                <Skeleton width={50}/>
              ) : (
                 <Typography variant="textSmRegular">
                   {toolingViewState.tooling?.technicalInfo?.looseParts?.gatingSystem}
                 </Typography>
               )}
            </PropertyItem>
            <PropertyItem caption="Risers">
              {isLoading ? (
                <Skeleton width={50}/>
              ) : (
                 <Typography variant="textSmRegular">
                   {toolingViewState.tooling?.technicalInfo?.looseParts?.risers}
                 </Typography>
               )}
            </PropertyItem>
            <PropertyItem caption="Gas vents">
              {isLoading ? (
                <Skeleton width={50}/>
              ) : (
                 <Typography variant="textSmRegular">
                   {toolingViewState.tooling?.technicalInfo?.looseParts?.gasVents}
                 </Typography>
               )}
            </PropertyItem>
            <Grid
              item
              sx={{
                mt: 2,
                mb: 2,
              }}
            >
              <Divider/>
            </Grid>
            <Grid item>
              <Typography
                variant="textMdMedium"
                gutterBottom
                sx={{
                  mt: 2,
                }}
              >
                Coreboxes
              </Typography>
            </Grid>
            <Grid item>
              <CoreboxesView isLoading={isLoading} coreboxes={toolingViewState.tooling?.technicalInfo?.coreboxes}/>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>

      {toolingViewState.tooling?.technicalInfo && (
        <EditTechnicalInfoDialog
          open={editTechnicalInfo}
          toolingId={toolingId}
          technicalInfo={toolingViewState.tooling?.technicalInfo}
          onClose={() => setEditTechnicalInfo(false)}
        />
      )}

    </Card>
  )
}

export default TechnicalInformationCard
