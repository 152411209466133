import { Fragment, FunctionComponent } from 'react';
import { IconButton, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import EnhancedTableHead, { HeadCell } from '../../../components/EnhancedTableHead';
import { useAppSelector } from '../../../store/hooks';
import { useDispatch } from 'react-redux';
import { MaterialDto } from '../../../api/types/MaterialDto';
import {
  selectMaterialsList,
  setSortBy,
  setSortOrder,
  SortableHeads,
} from '../../../store/materials/materialsListSlice';
import EditIcon from '@mui/icons-material/Edit';
import { open } from '../../../store/materials/editMaterialSlice';
import LoadingTableBody from '../../components/LoadingTableBody';
import { checkPermission } from '../../../store/user/userSlice';
import { Permissions_Materials_Edit } from '../../../api/types/permissions';

interface MaterialsTableProps {
  status: 'idle' | 'loading' | 'failed';
  items: MaterialDto[] | null;
}

const headers: HeadCell[] = [
  { id: 'name', numeric: false, label: 'Material' },
  { id: 'defaultAllowableUsages', numeric: false, label: 'Default allowable number of usages' },
];

interface MaterialsTableItem {
  materialId: number;
  name: string;
  defaultAllowableUsages: number;
}

const convertToViewItem = (material: MaterialDto): MaterialsTableItem => {
  let item = {} as MaterialsTableItem;
  item.materialId = material.materialId;
  item.name = material.name ?? '';
  item.defaultAllowableUsages = material.defaultAllowableUsages ?? 0;
  return item;
};

const MaterialsTable: FunctionComponent<MaterialsTableProps> = (props) => {
  const dispatch = useDispatch();
  const toolingListState = useAppSelector(selectMaterialsList);

  const onRequestSort = (property: SortableHeads) => {
    const isAsc = toolingListState.sortBy === property && toolingListState.sortDirection === 'asc';
    const sortDirection = isAsc ? 'desc' : 'asc';

    dispatch(setSortOrder(sortDirection));
    dispatch(setSortBy(property));
  };

  const handleEditClick = (material: MaterialDto) => {
    dispatch(open(material));
  };

  const canEditMaterial = useAppSelector((root) => checkPermission(root, Permissions_Materials_Edit));

  return (
    <Fragment>
      <Table>
        <EnhancedTableHead
          leftColumn={false}
          headers={headers}
          extraHeaders={canEditMaterial ? ['Actions'].map((h) => <TableCell>{h}</TableCell>) : undefined}
          order={toolingListState.sortDirection}
          orderBy={toolingListState.sortBy}
          onRequestSort={(event, property) => onRequestSort(property as SortableHeads)}
        />
        <TableBody>
          {props.status === 'loading' && <LoadingTableBody rows={5} columns={3} />}
          {props.status === 'idle' &&
            props.items &&
            props.items
              .map((t) => convertToViewItem(t))
              .map((t) => (
                <TableRow key={t.materialId}>
                  <TableCell>
                    <Typography>{t.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{t.defaultAllowableUsages}</Typography>
                  </TableCell>
                  {canEditMaterial && (
                    <TableCell>
                      <IconButton onClick={() => handleEditClick(t)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </Fragment>
  );
};

export default MaterialsTable;
