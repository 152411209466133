import { AuthenticatedTemplate } from '@azure/msal-react';
import { AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { UserProfile } from './UserProfile';
import MenuIcon from '@mui/icons-material/Menu';

interface ButtonAppBarProps {
  onMenuToggle: () => void;
}

const ButtonAppBar: FunctionComponent<ButtonAppBarProps> = (props) => {
  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="menu"
          edge="start"
          onClick={props.onMenuToggle}
          sx={{ mr: 2, display: { md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Tooling
        </Typography>
        <AuthenticatedTemplate>
          <UserProfile />
        </AuthenticatedTemplate>
      </Toolbar>
    </AppBar>
  );
};

export default ButtonAppBar;
