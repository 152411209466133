import { Clear } from '@mui/icons-material';
import { Grid, IconButton, List, ListItem, listItemClasses, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { Fragment, FunctionComponent, useState } from 'react';
import { SearchUnitDto } from '../../../api/types/UnitData';
import { SearchUnit } from '../../../store/types/searchUnit';
import AlreadyUseIndicator from './AlreadyUseIndicator';
import UnitAutocomplete from './UnitAutocomplete';
import { UnitElementId, UnitProductionId } from './UnitIndicator';

interface Props {
  selectedUnits: SearchUnit[];
  onSelectUnit: (unit: SearchUnitDto) => void;
  onRemoveUnit: (unit: SearchUnitDto) => void;
  disabled?: boolean;
}

const ToolingUnits: FunctionComponent<Props> = (props) => {
  const [unit, setUnit] = useState<SearchUnitDto | null>(null);

  return (
    <Fragment>
      <UnitAutocomplete
        sx={{ mt: 2 }}
        selectedUnit={unit}
        alreadySelected={props.selectedUnits}
        disabled={props.disabled}
        onSelectUnit={(data) => data && props.onSelectUnit(data)}
      />
      <List
        sx={{
          [`& .${listItemClasses.root}:hover`]: {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
        }}
      >
        {_.sortBy(props.selectedUnits, (u) => u.elementName).map((u) => (
          <ListItem
            key={u.elementId}
            divider
            secondaryAction={
              <IconButton edge="end" onClick={() => props.onRemoveUnit(u)} disabled={props.disabled}>
                <Clear />
              </IconButton>
            }
          >
            <Stack spacing={1}>
              <Typography sx={{ fontWeight: 600 }}>{u.elementName}</Typography>
              {u.useIn?.length > 0 && <AlreadyUseIndicator useIn={u.useIn} />}
              <Stack direction="row" spacing={3} alignItems="center">
                <UnitElementId elementId={u.elementId} />
                <UnitProductionId productionId={u.productionId} />
              </Stack>
              {u.identicals?.map((i) => (
                <Stack direction="row" spacing={1} sx={{ ml: 5 }}>
                  <Typography component="span" variant="caption" color="Highlight">
                    IDNT
                  </Typography>
                  <Stack direction="column">
                    <UnitElementId elementId={i.elementId}></UnitElementId>
                    <UnitProductionId productionId={i.productionId}></UnitProductionId>
                  </Stack>
                  <Typography component="span" variant="caption" sx={{ ml: 1 }}>
                    {i.elementName}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </ListItem>
        ))}
        {!props.selectedUnits.length && (
          <Typography align="center" sx={{ color: (theme) => theme.palette.warning.main }}>
            Add tooling units using the search box above
          </Typography>
        )}
      </List>
    </Fragment>
  );
};

export default ToolingUnits;
