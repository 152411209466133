import { Fragment, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, CircularProgress, Grid, TextField, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import graphApi from '../../../../api/GraphApi';
import { GraphUser } from '../../../../api/types/graph/graph';
import { throttle } from 'lodash';
import GraphAvatar from '../../../../components/GraphAvatar';

interface Props {
  // selectedUser?: GraphUser;
  alreadySelected?: string[];
  disabled?: boolean;
  sx?: SxProps<Theme>;
  onSelectUnit: (unit: GraphUser) => void;
}

const UsersAutocomplete: FunctionComponent<Props> = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedUser, setSelectedUser] = useState<GraphUser | null>(null);
  const [options, setOptions] = useState<GraphUser[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchApi = (filter: string, callback: (results: GraphUser[]) => void) => {
    setLoading(true);

    graphApi
      .findUsers(filter)
      .then((users) => {
        callback(users.value);
        setLoading(false);
      })
      .catch((error) => callback([]));
  };

  const fetchCache = useMemo(
    () =>
      throttle((request: string, callback: (results: GraphUser[]) => void) => fetchApi(request, callback), 600, {
        leading: false,
      }),
    [],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    // if (inputValue === props.selectedUnit?.elementId) {
    //   return;
    // }

    fetchCache(inputValue, (results?: GraphUser[]) => {
      if (active) {
        let newOptions = [] as GraphUser[];

        // if (props.selectedUnit) {
        //   newOptions = [props.selectedUnit];
        // }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetchCache]);

  return (
    <Autocomplete
      sx={props.sx}
      disabled={props.disabled}
      options={options}
      noOptionsText="No users"
      getOptionLabel={(option) => option.displayName}
      getOptionDisabled={(option) => {
        if (!props.alreadySelected) {
          return false;
        }
        return props.alreadySelected.includes(option.id);
      }}
      renderOption={(liProps, option) => (
        <Box component="li" {...liProps} key={option.id}>
          <Grid container direction="row" spacing={1} alignItems="center">
            <Grid item>
              <GraphAvatar userId={option.id} />
            </Grid>
            <Grid item>
              <Typography>{option.displayName}</Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      value={selectedUser}
      inputValue={inputValue}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search by name or mail"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
      onChange={(event: any, newValue) => {
        const user = newValue as GraphUser;
        if (user) {
          props.onSelectUnit(user);
          setSelectedUser(null);
          setInputValue('');
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  );
};

export default UsersAutocomplete;
