import { Grid, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';

interface Props {
  amount?: number;
  currency?: string;
}

const Cost: FunctionComponent<Props> = (props) => {
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item>
        <Typography variant="textSmRegular">{props.amount ?? ''}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="textSmRegular">{props.currency ?? ''}</Typography>
      </Grid>
    </Grid>
  );
};

export default Cost;
