import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { CurrencyDto } from '../../../api/types/CurrencyDto'
import { SupplierDto } from '../../../api/types/SupplierData'
import { RootState } from '../../store'
import transfersApi from '../../../api/TransfersApi'
import { UpdateTransferRequest, ShipperType } from '../../../api/types/Transfers'

export interface EditTransferState {
  toolingId: number;
  transferId: number;
  status: 'idle' | 'loading' | 'saving' | 'failed';
  reason: string;
  shipperType: ShipperType;
  shipperName?: string;
  shipperId?: number;
  shipper: SupplierDto | null;
  startLocation: SupplierDto | null;
  finishLocation: SupplierDto | null;
  cost?: number;
  currency?: CurrencyDto;
  deliveryDate?: string;
  transferTaskId?: number;
  comment: string;
  responsible: string[];
  pageError?: SerializedError;
  actionError?: SerializedError;
  showConfirmDeleteDialog: boolean;
}

const initialState: EditTransferState = {
  toolingId: 0,
  transferId: 0,
  status: 'idle',
  reason: '',
  shipperType: 'Supplier',
  shipper: null,
  startLocation: null,
  finishLocation: null,
  comment: '',
  responsible: [],
  showConfirmDeleteDialog: false,
}

const sliceName = 'tooling-transfers-edit-slice'

export const load = createAsyncThunk(`${sliceName}/load`, async (data: {
  toolingId: number;
  transferId: number
}) => {
  return await transfersApi.get(data.toolingId, data.transferId)
})

export const save = createAsyncThunk(
  `${sliceName}/save`,
  async (data: {
    toolingId: number;
    transferId: number;
    request: UpdateTransferRequest
  }) => {
    await transfersApi.updateTransfer(data.toolingId, data.transferId, data.request)
  },
)

export const deleteTransfer = createAsyncThunk(
  `${sliceName}/delete`,
  async (data: {
    toolingId: number;
    transferId: number
  }) => {
    await transfersApi.deleteTransfer(data.toolingId, data.transferId)
  },
)

export const editTransferSlice = createSlice({
                                               name: sliceName,
                                               initialState,
                                               reducers: {
                                                 startLoading: (state) => {
                                                   state.status = 'loading'
                                                 },
                                                 successLoading: (state) => {
                                                   state.status = 'idle'
                                                 },
                                                 fail: (state) => {
                                                   state.status = 'failed'
                                                 },
                                                 setReason: (state, action: PayloadAction<string>) => {
                                                   state.reason = action.payload
                                                 },
                                                 setShipperType: (state, action: PayloadAction<ShipperType>) => {
                                                   state.shipperType = action.payload
                                                 },
                                                 setShipper: (state, action: PayloadAction<SupplierDto | null>) => {
                                                   state.shipper = action.payload
                                                 },
                                                 setStartLocation: (
                                                   state, action: PayloadAction<SupplierDto | null>) => {
                                                   state.startLocation = action.payload
                                                 },
                                                 setFinishLocation: (
                                                   state, action: PayloadAction<SupplierDto | null>) => {
                                                   state.finishLocation = action.payload
                                                 },
                                                 setShipperName: (state, action: PayloadAction<string>) => {
                                                   state.shipperName = action.payload
                                                 },
                                                 setCost: (state, action: PayloadAction<number | undefined>) => {
                                                   state.cost = action.payload
                                                 },
                                                 setCurrency: (
                                                   state, action: PayloadAction<CurrencyDto | undefined>) => {
                                                   state.currency = action.payload
                                                 },
                                                 setDeliveryDate: (
                                                   state, action: PayloadAction<string | undefined>) => {
                                                   state.deliveryDate = action.payload
                                                 },
                                                 setTransferTaskId: (
                                                   state, action: PayloadAction<number | undefined>) => {
                                                   state.transferTaskId = action.payload
                                                 },
                                                 setComment: (state, action: PayloadAction<string>) => {
                                                   state.comment = action.payload
                                                 },
                                                 hideActionError: (state) => {
                                                   state.actionError = undefined
                                                 },
                                                 showConfirmDeleteDialog: (state) => {
                                                   state.showConfirmDeleteDialog = true
                                                 },
                                                 hideConfirmDeleteTooling: (state) => {
                                                   state.showConfirmDeleteDialog = false
                                                 },
                                               },
                                               extraReducers: (build) => {
                                                 build.addCase(load.pending, (state, action) => {
                                                   state.transferId = action.meta.arg.transferId
                                                   state.toolingId = action.meta.arg.toolingId
                                                   state.status = 'loading'
                                                 })

                                                 build.addCase(load.fulfilled, (state, action) => {
                                                   state.status = 'idle'

                                                   state.reason = action.payload?.reason ?? ''
                                                   state.shipperType = action.payload?.shipper.type ?? 'Supplier'
                                                   state.shipperName = action.payload?.shipper.name ?? ''
                                                   state.shipperId = action.payload?.shipper.shipperId
                                                   state.cost = action.payload?.cost.amount
                                                   state.currency = action.payload?.cost.currency
                                                   state.deliveryDate = action.payload?.deliveryDate
                                                   state.transferTaskId = action.payload?.transferTaskId
                                                   state.comment = action.payload?.comment ?? ''

                                                   state.responsible = action.payload?.responsible ?? []

                                                   if (action.payload?.shipper.type === 'Supplier') {
                                                     state.shipper = {
                                                       supplierId: action.payload.shipper.shipperId,
                                                       name: action.payload.shipper.name,
                                                     } as SupplierDto
                                                   }
                                                   state.startLocation = {
                                                     supplierId: action.payload?.startLocation.shipperId,
                                                     name: action.payload?.startLocation.name,
                                                   } as SupplierDto

                                                   state.finishLocation = {
                                                     supplierId: action.payload?.finishLocation.shipperId,
                                                     name: action.payload?.finishLocation.name,
                                                   } as SupplierDto
                                                 })

                                                 build.addCase(load.rejected, (state, action) => {
                                                   state.status = 'failed'
                                                   state.pageError = action.error
                                                 })

                                                 build.addCase(save.pending, (state, action) => {
                                                   state.status = 'saving'
                                                 })

                                                 build.addCase(save.fulfilled, (state, action) => {
                                                   state.status = 'idle'
                                                 })

                                                 build.addCase(save.rejected, (state, action) => {
                                                   state.status = 'idle'
                                                   state.actionError = action.error
                                                 })
                                               },
                                             })

export const {
               startLoading,
               successLoading,
               fail,
               setReason,
               setShipperType,
               setShipper,
               setStartLocation,
               setFinishLocation,
               setShipperName,
               setCost,
               setCurrency,
               setDeliveryDate,
               setTransferTaskId,
               setComment,
               hideActionError,
               showConfirmDeleteDialog,
               hideConfirmDeleteTooling,
             } = editTransferSlice.actions

export const selectEditTransfer = (state: RootState) => state.tooling.transfers.edit.properties

export default editTransferSlice.reducer
