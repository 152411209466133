import { Permissions_Repairs_EditAny, Permissions_Transfers_EditAny } from '../../../../../api/types/permissions'
import { useCheckResponsibleOrPermissions } from '../../../../../helpers/useCheckResponsibleOrPermissions'

export function useRepairPermissions(responsible: string[]): boolean {
  return useCheckResponsibleOrPermissions(responsible, Permissions_Repairs_EditAny)
}

export function useTransferPermissions(responsible: string[]): boolean {
  return useCheckResponsibleOrPermissions(responsible, Permissions_Transfers_EditAny)
}

