import { Clear } from '@mui/icons-material'
import { IconButton, List, ListItem, listItemClasses, Stack, Typography } from '@mui/material'
import { Fragment, FunctionComponent, useState } from 'react'
import { SearchUnitDto } from '../../../api/types/UnitData'
import { UnitElementId, UnitProductionId } from '../../tooling/components/UnitIndicator'
import AssemblyUnitAutocomplete from './AssemblyUnitAutocomplete'

interface Props {
  selectedUnit?: SearchUnitDto;
  onSelectUnit: (unit: SearchUnitDto) => void;
  onRemoveUnit: () => void;
  disabled?: boolean;
}

const SetUnit: FunctionComponent<Props> = (props) => {
  const [unit, setUnit] = useState<SearchUnitDto | null>(null)

  return (
    <Fragment>
      {!props.selectedUnit && <AssemblyUnitAutocomplete
        sx={{ mt: 2 }}
        selectedUnit={unit}
        alreadySelected={props.selectedUnit && new Array(props.selectedUnit)}
        disabled={props.disabled}
        onSelectUnit={(data) => data && props.onSelectUnit(data)}
      />}
      <List
        sx={{
          [`& .${listItemClasses.root}:hover`]: {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
        }}
      >
        {props.selectedUnit && <ListItem
          key={props.selectedUnit.elementId}
          divider
          secondaryAction={
            <IconButton edge="end" onClick={() => props.onRemoveUnit()} disabled={props.disabled}>
              <Clear/>
            </IconButton>
          }
        >
          <Stack spacing={1}>
            <Typography sx={{ fontWeight: 600 }}>{props.selectedUnit.elementName}</Typography>
            <Stack direction="row" spacing={3} alignItems="center">
              <UnitElementId elementId={props.selectedUnit.elementId}/>
              <UnitProductionId productionId={props.selectedUnit.productionId}/>
            </Stack>
          </Stack>
        </ListItem>}
        {!props.selectedUnit && (
          <Typography align="center" sx={{ color: (theme) => theme.palette.warning.main }}>
            Select assembly unit using the search box above
          </Typography>
        )}
      </List>
    </Fragment>
  )
}

export default SetUnit
