import { Fragment, FunctionComponent } from 'react';
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  Skeleton,
  Switch,
  Checkbox,
} from '@mui/material';
import _ from 'lodash';
import { selectEditRole } from '../../../store/administration/roleSlice';
import { useAppSelector } from '../../../store/hooks';

const RoleRightsList: FunctionComponent = () => {
  const roleState = useAppSelector(selectEditRole);

  if (roleState.status === 'loading') {
    return (
      <List>
        {Array.from(Array(5).keys()).map((i) => {
          return (
            <ListItem key={i} divider>
              <Box sx={{ width: '100%' }}>
                <Skeleton />
              </Box>
            </ListItem>
          );
        })}
      </List>
    );
  }

  const orderedItesm = _.orderBy(roleState.rights, ['grouping', 'description']);
  const groups = _.groupBy(orderedItesm, 'grouping');

  return (
    <List>
      {_.map(groups, (group, name) => (
        <Fragment key={name}>
          <ListSubheader color="primary" disableSticky key={name}>
            {name}
          </ListSubheader>
          {group.map((r) => {
            return (
              <ListItem key={r.rightId}>
                <ListItemText primary={r.description} secondary={r.name} />
                <ListItemSecondaryAction>
                  <Checkbox readOnly checked={true} />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </Fragment>
      ))}
    </List>
  );
};

export default RoleRightsList;
