import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Skeleton,
  Box,
} from '@mui/material';
import lodash from 'lodash';
import GraphAvatar from '../../../components/GraphAvatar';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { loadUsers, selectRoleUsersDialog, UserListItem } from '../../../store/administration/selectRoleUsersSlice';
import ErrorBox from '../../../components/ErrorBox';

interface Props {
  open: boolean;
  exists: string[];
  onCancel: () => void;
  onOk: (selected: UserListItem[]) => void;
}

const SelectUserDialog: FunctionComponent<Props> = (props) => {
  const [selected, setSelected] = useState<UserListItem[]>([]);

  const dispatch = useAppDispatch();
  const dialogState = useAppSelector(selectRoleUsersDialog);

  useEffect(() => {
    setSelected([]);

    if (props.open) {
      dispatch(loadUsers());
    }
  }, [dispatch, props.open]);

  const handleSelect = (user: UserListItem) => {
    const index = selected.findIndex((u) => u.userId === user.userId);
    if (index >= 0) {
      setSelected(selected.filter((u) => u.userId !== user.userId));
    } else {
      setSelected([...selected, user]);
    }
  };

  const renderContent = () => {
    if (dialogState.status === 'loading') {
      return (
        <List>
          {Array.from(Array(5).keys()).map((i) => {
            return (
              <ListItem key={i} divider>
                <ListItemAvatar>
                  <Skeleton variant="circular" width={40} height={40} />
                </ListItemAvatar>
                <Box sx={{ width: '100%' }}>
                  <Skeleton />
                </Box>
              </ListItem>
            );
          })}
        </List>
      );
    }

    if (dialogState.status === 'failed' && dialogState.dialogError) {
      return (
        <ErrorBox
          code={dialogState.dialogError.name}
          description={dialogState.dialogError.message}
          sx={{ minHeight: '90vh' }}
        >
          <Button variant="contained" onClick={() => dispatch(loadUsers())}>
            Try again
          </Button>
        </ErrorBox>
      );
    }

    return (
      <List>
        {lodash.sortBy(dialogState.users, ['displayName']).map((u) => {
          return (
            <ListItem
              key={u.userId}
              button
              disabled={props.exists.find((s) => s === u.userId) !== undefined}
              selected={selected.find((s) => s.userId === u.userId) !== undefined}
              sx={{
                selected: {
                  color: (theme) => theme.palette.action.selected,
                },
              }}
              onClick={() => handleSelect(u)}
            >
              <ListItemAvatar>
                <GraphAvatar userId={u.userId} />
              </ListItemAvatar>
              <ListItemText primary={u.displayName} secondary={u.userPrincipalName} />
            </ListItem>
          );
        })}
      </List>
    );
  };

  return (
    <Dialog open={props.open} onClose={props.onCancel} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle>Select user</DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="primary">
          Cancel
        </Button>
        <Button
          disabled={selected.length === 0}
          onClick={() => selected && props.onOk(selected)}
          color="primary"
          variant="contained"
        >
          {selected.length > 0 ? `OK (${selected.length})` : 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectUserDialog;
