import { FunctionComponent } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import TextEditPropertyItem from '../../components/TextEditPropertyItem';
import { validateInt } from '../../../helpers/validation';
import {
  clearActionError,
  selectEditMaterial,
  setDefaultAllowableUsages,
  setName,
  updateMaterial,
} from '../../../store/materials/editMaterialSlice';
import { MaterialDto } from '../../../api/types/MaterialDto';
import ErrorDialog from '../../../components/ErrorDialog';

interface Props {
  materialId: string | number;
  material?: MaterialDto;
  open: boolean;
  onClose: (material?: MaterialDto) => void;
}

const EditMaterialDialog: FunctionComponent<Props> = (props) => {
  const state = useAppSelector(selectEditMaterial);
  const dispatch = useAppDispatch();

  const saving = state.status === 'saving';
  const isFail = state.status === 'failed';

  const handleChangeName = (value: string) => {
    dispatch(setName(value));
  };

  const handleChangeDefaultAllowableUsages = (value: string) => {
    validateInt(value, (numberOfUsages) => {
      dispatch(setDefaultAllowableUsages(numberOfUsages ?? 0));
    });
  };

  const handleUpdate = () => {
    if (state.material) {
      dispatch(updateMaterial(state.material)).then(
        (request) => request.meta.requestStatus === 'fulfilled' && state.material && props.onClose(state.material),
      );
    }
  };

  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle>Edit material</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <TextEditPropertyItem
            disabled={saving}
            caption="Name"
            value={state.material?.name || ''}
            onChange={(value) => handleChangeName(value)}
          />
          <TextEditPropertyItem
            disabled={saving}
            caption="Default allowable number of usage"
            type="number"
            value={state.material?.defaultAllowableUsages || 0}
            onChange={(value) => handleChangeDefaultAllowableUsages(value)}
          />
        </Grid>
        <ErrorDialog
          open={isFail}
          title={state.actionError?.name || 'Error'}
          text={state.actionError?.message ?? ''}
          onOk={() => dispatch(clearActionError())}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={saving} onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="contained" disabled={saving || isFail} onClick={() => handleUpdate()}>
          {saving ? <CircularProgress size={20} /> : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMaterialDialog;
