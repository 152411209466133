export interface StatusDto {
  statusId: number;
  name: string;
}

export enum ToolingStatuses {
  Draft          = 1,
  Functional     = 2,
  Decommissioned = 3,
  Suspended      = 4,
  Scrapped       = 5
}
