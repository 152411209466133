import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import filesApi from '../../../api/FilesApi';
import { FileDto } from '../../../api/types/Files';
import { RootState } from '../../store';

export interface ViewerState {
  status: 'idle' | 'loading' | 'failed';
  open: boolean;
  file?: FileDto;
  url?: string;
  downloadUrl?: string;
  actionError?: SerializedError;
}

const initialState: ViewerState = {
  status: 'idle',
  open: false,
};

const sliceName = 'tooling-file-viewer-slice';

export const getFileUrls = createAsyncThunk(`${sliceName}/load-link`, async (fileId: number) => {
  return await filesApi.getFileUrl(fileId);
});

export const viewerSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    startLoading: (state) => {
      state.status = 'loading';
    },
    successLoading: (state) => {
      state.status = 'idle';
    },
    fail: (state) => {
      state.status = 'failed';
    },
    openFileViewer: (state, action: PayloadAction<FileDto>) => {
      state.open = true;
      state.file = action.payload;
    },
    closeFileViewer: (state) => {
      state.open = false;
      state.file = undefined;
    },
  },
  extraReducers: (build) => {
    build.addCase(getFileUrls.pending, (state, action) => {
      state.status = 'loading';
      state.url = undefined;
      state.downloadUrl = undefined;
    });

    build.addCase(getFileUrls.fulfilled, (state, action) => {
      state.status = 'idle';
      state.url = action.payload.url;
      state.downloadUrl = action.payload.downloadUrl;
    });

    build.addCase(getFileUrls.rejected, (state, action) => {
      state.status = 'failed';
      state.url = undefined;
      state.downloadUrl = undefined;
      state.actionError = action.error;
    });
  },
});

export const { startLoading, successLoading, fail, openFileViewer, closeFileViewer } = viewerSlice.actions;

export const selectFilesViewer = (state: RootState) => state.tooling.viewer;

export default viewerSlice.reducer;
