import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { Tooltip, FormControl, IconButton, Input, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { isEmpty } from 'lodash';
import { SxProps, Theme } from '@mui/system';
import { Clear } from '@mui/icons-material';

interface SearchBoxProps {
  caption?: string;
  filter?: string;
  width?: number;
  fullWidth?: boolean;
  endAdornment?: ReactNode;
  size?: 'small' | 'medium';
  sx?: SxProps<Theme>;
  onFilterChanged: (filter: string) => void;
  onFilter: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  onClear?: () => void;
}

const SearchBox: FunctionComponent<SearchBoxProps> = (props) => {
  // const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
  // const classes = useStyles(props);

  // useEffect(() => {
  //   if (isEmpty(props.filter) && !typingTimeout) {
  //     return;
  //   }

  //   if (typingTimeout) {
  //     clearTimeout(typingTimeout);
  //   }

  //   setTypingTimeout(
  //     setTimeout(function () {
  //       props.onFilter();
  //     }, 600),
  //   );

  //   return () => {
  //     typingTimeout && clearTimeout(typingTimeout);
  //   };
  // }, [props.filter]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      //typingTimeout && clearTimeout(typingTimeout);

      props.onFilter();
    }
  };

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onFilterChanged(event.target.value);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    props.onFocus && props.onFocus();
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    props.onBlur && props.onBlur();
  };

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    props.onClick && props.onClick();
  };

  const handleClickClear = () => {
    props.onClear && props.onClear();
  };

  return (
    <TextField
      sx={props.sx}
      fullWidth={props.fullWidth}
      label={props.caption ?? ''}
      //placeholder={props.caption ?? ''}
      size={props.size}
      value={props.filter ?? ''}
      onChange={handleSearchTextChange}
      InputProps={{
        //startAdornment: <SearchIcon color="action" />,
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="Clear">
              <IconButton onClick={handleClickClear}>
                <Clear />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      onKeyPress={handleKeyPress}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onClick={handleClick}
    />
  );
};

export default SearchBox;
