import * as React from 'react';
import { FunctionComponent } from 'react';
import ReactPlayer from 'react-player';
import CenteredCircularProgress from '../CenteredCircularProgress';
import HeicViewer from './HeicViewer';
import PdfViewer from './PdfViewer';
import UnsupportedType from './UnsupportedType';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  contentType: string;
}

const AuthorizedViewer: FunctionComponent<Props> = (props) => {
  if (!props.src) {
    return <CenteredCircularProgress />;
  }

  // if (error) {
  //   return <ErrorBox description="Error loading file" sx={{ color: 'white' }} />;
  // }

  if (props.contentType === 'application/pdf') {
    return <PdfViewer url={props.src} />;
  }

  if (props.contentType === 'image/heic') {
    return <HeicViewer url={props.src} />;
  }

  if (props.contentType.match('image/*')) {
    return <img {...props} src={props.src} alt="" />;
  }

  if (props.contentType.match('video/*')) {
    return <React.Fragment><ReactPlayer url={props.src} playing controls width="100%" height="100%" /></React.Fragment>;
  }

  return <UnsupportedType />;
};

export default AuthorizedViewer;
