import React, { FunctionComponent, useState } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Skeleton,
  Toolbar,
  Tooltip,
  List, ListItem, ListItemText,
} from '@mui/material'
import { Edit } from '@mui/icons-material'
import { ToolingViewState, selectToolingView } from '../../../store/tooling/toolingViewSlice'
import { useAppSelector, useAccount } from '../../../store/hooks'
import {
  Permissions_Tooling_Units_Edit,
} from '../../../api/types/permissions'
import { checkPermission } from '../../../store/user/userSlice'
import _ from 'lodash'
import UnitInfo from '../components/UnitInfo'
import { SCRAPPED_STATUS } from '../../../store/types/statuses'
import EditUnitsDialog from './components/EditUnitsDialog'

interface Props {
  toolingId: string | number;
}

export const UnitCard: FunctionComponent<Props> = ({ toolingId }) => {

  const [editUnits, setEditUnits] = useState(false)

  const haveEditUnitsRight = useAppSelector((root) => checkPermission(root, Permissions_Tooling_Units_Edit))
  const toolingViewState: ToolingViewState = useAppSelector(selectToolingView)
  const isLoading = toolingViewState.status === 'loading'

  const userAccount = useAccount()

  const calcEditPermissions = (haveRight: boolean) => {
    const responsibles = toolingViewState.tooling?.responsibles.filter((r) => r.userId === userAccount.localAccountId)
    const isScrappedStatus = toolingViewState.tooling?.summary?.status?.name === SCRAPPED_STATUS

    if (isScrappedStatus) {
      return (): boolean => false
    }

    return (): boolean => {
      if (haveRight) {
        return true
      } else {
        if (responsibles) {
          return responsibles.length > 0
        } else {
          return false
        }
      }
    }
  }

  const canUnitsSummary = calcEditPermissions(haveEditUnitsRight)

  return (
    <Card sx={{ mt: 5 }}>
      <CardHeader
        title="Units"
        titleTypographyProps={{
          variant: 'textLgSemibold',
        }}
        action={
          toolingViewState.status === 'idle' && canUnitsSummary() && (
            <Toolbar>
              <Tooltip title="Add or remove units">
                <IconButton onClick={() => setEditUnits(true)}>
                  <Edit/>
                </IconButton>
              </Tooltip>
            </Toolbar>
          )
        }
      />
      <Divider/>
      <CardContent>
        <List>
          {isLoading ? (
            <Skeleton/>
          ) : (
             toolingViewState.tooling?.units &&
             _.sortBy(toolingViewState.tooling?.units, (u) => u.elementId).map((unit) => (
               <ListItem key={unit.unitFileId} disablePadding sx={{ mt: 6 }}>
                 <ListItemText
                   primary={unit.elementId}
                   primaryTypographyProps={{ variant: 'textSmMedium' }}
                   secondary={<UnitInfo unit={unit} captionWidth={1}/>}
                 />
               </ListItem>
             ))
           )}
        </List>
      </CardContent>
      <EditUnitsDialog
        open={editUnits}
        toolingId={toolingId}
        existUnits={toolingViewState.tooling?.units || []}
        onClose={() => setEditUnits(false)}
      />
    </Card>
  )
}
