import { FunctionComponent, useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material'
import { SearchUnitDto, UnitDto } from '../../../../api/types/UnitData'
import ToolingUnits from '../../components/ToolingUnits'
import toolingApi from '../../../../api/ToolingApi'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { updateUnits, selectToolingViewActionError, showActionError } from '../../../../store/tooling/toolingViewSlice'
import {
  createSearchUnitFromSearchDto,
  createSearchUnitFromUnit,
  createUnitFromUnitDto,
  SearchUnit,
} from '../../../../store/types/searchUnit'
import unitsApi from '../../../../api/UnitsApi'
import { ToolingUnit } from '../../../../store/types/tooling'
import ErrorDialog from '../../../../components/ErrorDialog'
import { hideActionError } from '../../../../store/tooling/summarySlice'
import { SerializedError } from '@reduxjs/toolkit'

interface Props {
  toolingId: number | string;
  open: boolean;
  onClose: () => void;
  existUnits: ToolingUnit[];
}

const EditUnitsDialog: FunctionComponent<Props> = (props) => {
  const [saving, setSaving] = useState(false)
  const [selectedUnits, setSelectedUnits] = useState<SearchUnit[]>([])

  const error: SerializedError | undefined = useAppSelector(selectToolingViewActionError)

  useEffect(() => {
    if (props.open) {
      const manualAddedUnits = props.existUnits.filter((u) => u.addedManually).map((u) => createSearchUnitFromUnit(u))
      manualAddedUnits.forEach((u) => {
        if (u.analogId) {
          u.identicals = props.existUnits
                              .filter((i) => i.analogId === u.analogId)
                              .map((i) => createSearchUnitFromUnit(i))
        }
      })

      setSelectedUnits([...manualAddedUnits])
    }
  }, [props.existUnits, props.open])

  useEffect(() => {
    if (props.open) {
      // setIsOwnerLoading(true);
      // ownersApi
      //   .getOwners()
      //   .then((res) => {
      //     res && setOwners(res);
      //     setIsOwnerLoading(false);
      //   })
      //   .catch(() => {
      //     setOwners([]);
      //     setIsOwnerLoading(false);
      //   });
    }
  }, [props.open])

  const dispatch = useAppDispatch()

  const onAddUnit = (addedUnit: SearchUnitDto) => {
    if (selectedUnits.some((u) => u.unitFileId === addedUnit.unitFileId)) {
      return
    }

    const unit = createSearchUnitFromSearchDto(addedUnit)

    unitsApi.getAnalogs(unit).then((analogs) => {
      if (analogs) {
        unit.identicals = [...analogs.map((a) => createSearchUnitFromSearchDto(a))]
      }

      setSelectedUnits([...selectedUnits, unit])
    })
  }

  const onRemoveUnit = (unit: SearchUnitDto) => {
    setSelectedUnits(selectedUnits.filter((u) => u !== unit))
  }

  const onUpdate = () => {
    setSaving(true)

    toolingApi
      .updateUnits(props.toolingId, selectedUnits)
      .then((units) => {
        dispatch(updateUnits((units ?? new Array<UnitDto>()).map((u) => createUnitFromUnitDto(u))))
        setSaving(false)
        props.onClose()
      })
      .catch((e) => {
        setSaving(false)
        dispatch(showActionError(e))
      })
  }

  return (
    <Dialog open={props.open} fullWidth maxWidth="md">
      <DialogTitle>Tooling units</DialogTitle>
      <Divider/>
      <DialogContent>
        <ToolingUnits
          selectedUnits={selectedUnits}
          disabled={saving}
          onSelectUnit={(unit) => onAddUnit(unit)}
          onRemoveUnit={(unit) => onRemoveUnit(unit)}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={saving} onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }}/>
        <Button variant="contained" disabled={saving || selectedUnits.length === 0} onClick={() => onUpdate()}>
          {saving ? <CircularProgress size={20}/> : 'Update'}
        </Button>
        <ErrorDialog
          open={error !== undefined}
          title={'Error'}
          text={`Error while saving data. Please try again later or contact the site administrator.\n${error?.message ?? ''}`}
          onOk={() => dispatch(hideActionError())}
        />
      </DialogActions>
    </Dialog>
  )
}

export default EditUnitsDialog
