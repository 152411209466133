import { FunctionComponent, useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Skeleton,
} from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { ToolingSummaryDto, UpdateToolingStatusDto } from '../../../../api/types/ToolingDto'
import {
  init,
  startLoading,
  successLoading,
  fail,
  setStatus,
  hideActionError,
  saveStatus,
  selectToolingEditStatus,
  selectToolingLoadEditStatus,
  selectToolingEditActionError,
} from '../../../../store/tooling/summarySlice'
import ErrorBox from '../../../../components/ErrorBox'
import statusesApi from '../../../../api/StatusesApi'
import { load, selectToolingViewStatus } from '../../../../store/tooling/toolingViewSlice'
import moment from 'moment'
import { StatusDto } from '../../../../api/types/StatusDto'
import StatusSelector from '../../components/StatusSelector'
import { SupplierDto } from '../../../../api/types/SupplierData'
import ErrorDialog from '../../../../components/ErrorDialog'
import CommentDialog from './CommentDialog'
import { SerializedError } from '@reduxjs/toolkit'
import { checkPermission } from '../../../../store/user/userSlice'
import { Permissions_Tooling_Scrape, Permissions_Administration_Edit } from '../../../../api/types/permissions'

enum RequiresConfirmationStatuses {
  Suspended      = 'Suspended',
  Decommissioned = 'Decommissioned'
}

enum ScrappedStatus {
  Scrapped = 'Scrapped',
}

enum InitialStatus {
  Draft = 'Draft',
}

interface Props {
  toolingId: string | number;
  summary?: ToolingSummaryDto;
  suppliers?: SupplierDto[];
  open: boolean;
  onClose: () => void;
}

const EditStatusDialog: FunctionComponent<Props> = (props) => {
  const [statuses, setStatuses] = useState<StatusDto[]>([])
  const [confirmChangeStatus, setConfirmChangeStatus] = useState(false)
  const haveScrapeRight = useAppSelector((root) => checkPermission(root, Permissions_Tooling_Scrape))
  const haveAdminRight = useAppSelector((root) => checkPermission(root, Permissions_Administration_Edit))

  const editStatus: StatusDto | undefined = useAppSelector(selectToolingEditStatus)
  const viewStatus: StatusDto | undefined = useAppSelector(selectToolingViewStatus)
  const loadStatus: string = useAppSelector(selectToolingLoadEditStatus)
  const error: SerializedError | undefined = useAppSelector(selectToolingEditActionError)

  const dispatch = useAppDispatch()

  const isLoading = loadStatus === 'loading'
  const isSaving = loadStatus === 'saving'
  const isFail = loadStatus === 'failed'

  const [statusEquals, setStatusEquals] = useState(true)

  useEffect(() => {
    if (props.open) {
      dispatch(startLoading())
      dispatch(init({ summary: props.summary, suppliers: props.suppliers ?? [] }))

      const getStatuses = statusesApi.getStatuses().then((res) => {
        if (res) {
          if (haveScrapeRight) {
            setStatuses(res)
          } else {
            res = res.filter(s => s.name !== ScrappedStatus.Scrapped)
            setStatuses(res)
          }
        }
      })

      Promise.all([getStatuses])
             .then(() => dispatch(successLoading()))
             .catch(() => dispatch(fail()))
    }
  }, [dispatch, props.open, props.summary, props.suppliers])

  useEffect(() => {
    moment.locale('en', { week: { dow: 1 } })
  }, [])

  const handleChangeStatusSelector = async (status?: StatusDto) => {
    setStatusEquals(viewStatus?.statusId === status?.statusId)

    await dispatch(setStatus(status))
  }

  useEffect(() => {
    setStatusEquals(viewStatus?.statusId === editStatus?.statusId)
  }, [editStatus, viewStatus])

  const onUpdate = async () => {
    const status = {
      status: editStatus,
    } as UpdateToolingStatusDto
    const statusName = status?.status?.name ?? ''
    if (viewStatus?.statusId !== editStatus?.statusId && statusName in RequiresConfirmationStatuses) {
      setConfirmChangeStatus(true)
    } else {
      await dispatch(saveStatus({ toolingId: props.toolingId, status }))
        .unwrap()
        .then(() => {
          dispatch(setStatus(editStatus))
          dispatch(load(props.toolingId))
          props.onClose()
        }).then(() => {
          setStatusEquals(viewStatus?.statusId === editStatus?.statusId)
        })
        .catch(() => {
        })
    }
  }

  return (
    <Dialog open={props.open} fullWidth maxWidth="md">
      <DialogTitle>Status changing</DialogTitle>
      <Divider/>
      <DialogContent>
        {isFail && <ErrorBox code="500" description="Error while loading data"/>}
        {!isFail && (
          <Grid container direction="column" spacing={4}>
            <Grid item>
              {isLoading ? (
                <Skeleton/>
              ) : (
                 <StatusSelector
                   isLoading={isLoading}
                   statuses={statuses}
                   currentStatus={editStatus?.statusId}
                   onChange={(status) => handleChangeStatusSelector(status)}
                   disabled={isSaving || isLoading || isFail}
                 />
               )}
            </Grid>
          </Grid>
        )}
        {confirmChangeStatus && (
          <CommentDialog
            title="Change tooling status"
            text={`When changing the status to '${RequiresConfirmationStatuses.Suspended}' or '${RequiresConfirmationStatuses.Decommissioned}' you have leave a comment`}
            onCancel={() => setConfirmChangeStatus(false)}
            onConfirm={async (comment: string) => {
              const status = {
                status: editStatus,
                comment: comment,
              } as UpdateToolingStatusDto
              await dispatch(saveStatus({ toolingId: props.toolingId, status }))
                .unwrap()
                .then(() => {
                  dispatch(setStatus(editStatus))

                  dispatch(load(props.toolingId))
                  props.onClose()
                }).then(() => {
                  setStatusEquals(viewStatus?.statusId === editStatus?.statusId)
                })
                .catch(() => {
                })
              setConfirmChangeStatus(false)
            }}
          />
        )}
        <ErrorDialog
          open={error !== undefined}
          title={'Error'}
          text={`Error while saving data. Please try again later or contact the site administrator.\n${error?.message}`}
          onOk={() => dispatch(hideActionError())}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSaving} onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Box sx={{ flexGrow: 1 }}/>
        <Button
          variant="contained"
          disabled={isSaving || isLoading || isFail || statusEquals}
          onClick={() => onUpdate()}
        >
          {isSaving ? <CircularProgress size={20}/> : 'Update status'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditStatusDialog
