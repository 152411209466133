import { ArrowBackIos } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';


interface Props {
  title: string;
  backPath: string;
  isLoading: boolean;
  hideButton?: boolean;
  onButtonAction?: () => void;
}

const PageToolbar: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate()

  const handleDelete = () => {
    props.onButtonAction && props.onButtonAction();
  };

  return (
    <Box mb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Tooltip title="Back">
            <IconButton onClick={() => navigate(props.backPath)}>
              <ArrowBackIos />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Typography variant="h5">{props.isLoading ? <Skeleton width={50} /> : props.title}</Typography>
        </Grid>
        <Grid item xs />
        {props.hideButton && (
          <Grid item>
            <Button variant="outlined" color="error" disabled={props.isLoading} onClick={handleDelete}>
              Delete
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PageToolbar;
