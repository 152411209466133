import authService from '../auth/AuthService'
import { API_ADDRESS } from './api'
import { msalFetch } from './graph'
import { handleErrors } from './errorHandler'
import { SetsListResponse } from './types/Sets/SetsListResponse'
import { SetItemDto } from './types/Sets/SetDto'
import { CreateSetRequest } from './types/Sets/CreateSetRequest'
import { UpdateSetRequest } from './types/Sets/UpdateSetRequest'
import { SetHistoryItem } from './types/Sets/SetHistoryItem'
import { UpdateSetResponsibleRequest } from './types/Sets/UpdateSetResponsibleRequest'

const SETS_API_ADDRESS = `${API_ADDRESS}/api/sets`

export class SetsApi {
  async getSetsList(
    page?: number,
    pageSize?: number,
    setNumber?: string,
    status?: string,
    supplier?: string,
    unit?: string,
    responsible?: string,
  ) {
    const result = await authService.getIdentity()

    let url = new URL(`${SETS_API_ADDRESS}`)
    let params = new URLSearchParams(url.search)

    if (page) {
      params.append('page', page.toString() ?? '0')
    }
    if (pageSize) {
      params.append('pageSize', pageSize.toString() ?? '25')
    }
    if (setNumber) {
      params.append('setNumber', setNumber)
    }
    if (setNumber) {
      params.append('setNumber', setNumber)
    }
    if (status) {
      params.append('status', status)
    }
    if (supplier) {
      params.append('supplier', supplier)
    }
    if (unit) {
      params.append('unit', unit)
    }
    if (responsible) {
      params.append('responsible', responsible)
    }

    if (result) {
      return msalFetch()
        .get(`${url.toString()}?${params.toString()}`, result.accessToken)
        .then(handleErrors)
        .then<SetsListResponse>((res) => res.json())
    }
  }

  async exportSetsList(
    page?: number,
    pageSize?: number,
    setNumber?: string,
    status?: string,
    supplier?: string,
    unit?: string,
    responsible?: string,
  ) {
    const result = await authService.getIdentity()

    let url = new URL(`${SETS_API_ADDRESS}/export`)
    let params = new URLSearchParams(url.search)

    if (page) {
      params.append('page', page.toString() ?? '0')
    }
    if (pageSize) {
      params.append('pageSize', pageSize.toString() ?? '10000')
    }
    if (setNumber) {
      params.append('setNumber', setNumber)
    }
    if (setNumber) {
      params.append('setNumber', setNumber)
    }
    if (status) {
      params.append('status', status)
    }
    if (supplier) {
      params.append('supplier', supplier)
    }
    if (unit) {
      params.append('unit', unit)
    }
    if (responsible) {
      params.append('responsible', responsible)
    }

    if (result) {
      return msalFetch()
        .get(`${url.toString()}?${params.toString()}`, result.accessToken)
        .then(handleErrors)
        .then(async (res) => await res.blob())
    }
  }

  async getSet(
    setNumber: number,
  ) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .get(
          `${SETS_API_ADDRESS}/${setNumber}`,
          result.accessToken,
        )
        .then(handleErrors)
        .then<SetItemDto>((res) => res.json())
    }
  }

  async calculateSet(
    setNumber: number,
  ) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .put(
          `${SETS_API_ADDRESS}/${setNumber}/calculate`,
          result.accessToken,
        )
        .then(handleErrors)
        .then<SetItemDto>((res) => res.json())
    }
  }

  async createSet(
    request: CreateSetRequest,
  ) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .post(
          `${SETS_API_ADDRESS}`,
          result.accessToken,
          JSON.stringify(request),
          {
            'content-type': 'application/json',
          },
        )
        .then(handleErrors)
        .then<SetItemDto>((res) => res.json())
    }
  }

  async deleteSet(
    setNumber: number,
  ) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .delete(
          `${SETS_API_ADDRESS}/${setNumber}`,
          result.accessToken,
          {
            'content-type': 'application/json',
          },
        )
        .then(handleErrors)
    }
  }

  async updateSet(
    setNumber: number,
    request: UpdateSetRequest,
  ) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .put(
          `${SETS_API_ADDRESS}/${setNumber}/update`,
          result.accessToken,
          JSON.stringify(request),
          {
            'content-type': 'application/json',
          },
        )
        .then(handleErrors)
        .then<SetItemDto>((res) => res.json())
    }
  }

  async decommissateSet(
    setNumber: number,
  ) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .put(
          `${SETS_API_ADDRESS}/${setNumber}/decommissate`,
          result.accessToken,
          {
            'content-type': 'application/json',
          },
        )
        .then(handleErrors)
        .then<SetItemDto>((res) => res.json())
    }
  }

  async updateSetResponsible(
    setNumber: number,
    request: UpdateSetResponsibleRequest,
  ) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .put(
          `${SETS_API_ADDRESS}/${setNumber}/responsible`,
          result.accessToken,
          JSON.stringify(request),
          {
            'content-type': 'application/json',
          },
        )
        .then(handleErrors)
        .then<SetItemDto>((res) => res.json())
    }
  }

  async getHistory(setNumber: number) {
    const result = await authService.getIdentity()

    return msalFetch()
      .get(`${SETS_API_ADDRESS}/${setNumber}/history`,
           result.accessToken)
      .then<SetHistoryItem[]>((res) => res.json())
  }

  async getStatuses() {
    const result = await authService.getIdentity()

    return msalFetch()
      .get(`${SETS_API_ADDRESS}/statuses`,
           result.accessToken)
      .then<string[]>((res) => res.json())
  }
}

const setsApi = new SetsApi()

export default setsApi
