import React, { FunctionComponent } from 'react';
import { Box, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { OrderType } from '../helpers/tableCompare';
import { visuallyHidden } from '@mui/utils';

export interface HeadCell {
  id: string;
  label: string;
  numeric: boolean;
  disabled?: boolean;
}

interface EnhancedTableProps {
  leftColumn?: boolean;
  headers: HeadCell[];
  extraHeaders?: React.ReactElement[];
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
  order: OrderType;
  orderBy: string;
}

const EnhancedTableHead: FunctionComponent<EnhancedTableProps> = (props) => {
  return (
    <TableHead>
      <TableRow>
        {props.leftColumn && <TableCell></TableCell>}
        {props.headers.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={props.orderBy === headCell.id ? props.order : false}
            sx={{ padding: (theme) => theme.spacing(1) }}
          >
            <TableSortLabel
              disabled={headCell.disabled}
              active={props.orderBy === headCell.id}
              direction={props.orderBy === headCell.id ? props.order : 'asc'}
              onClick={(event: React.MouseEvent<unknown>) =>
                props.onRequestSort && props.onRequestSort(event, headCell.id)
              }
            >
              <Typography variant="textSmMedium">{headCell.label}</Typography>

              {props.orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {props.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {props.extraHeaders}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
