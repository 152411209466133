import React, { FunctionComponent, useState } from 'react';
import { TextField, Autocomplete, CircularProgress, Box, Typography, Stack } from '@mui/material';
import { throttle } from 'lodash';
import { SearchUnitDto } from '../../../api/types/UnitData';
import { SxProps, Theme } from '@mui/system';
import UnitIndicator from './UnitIndicator';
import IdenticalIndicator from './IdenticalIndicator';
import unitsApi from '../../../api/UnitsApi';
import UnitAlreadySelectedIndicator from './UnitAlreadySelectedIndicator';
import AlreadyUseIndicator from './AlreadyUseIndicator';

interface Props {
  selectedUnit: SearchUnitDto | null;
  disabled?: boolean;
  alreadySelected?: SearchUnitDto[];
  sx?: SxProps<Theme>;
  onSelectUnit: (unit: SearchUnitDto | null) => void;
}

const UnitAutocomplete: FunctionComponent<Props> = (props) => {
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<SearchUnitDto[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchApi = (filter: string, callback: (results: SearchUnitDto[]) => void) => {
    setLoading(true);

    unitsApi
      .search(10, filter)
      .then((data) => {
        callback(data || []);
        setLoading(false);
      })
      .catch((error) => callback([]));
  };

  const fetchCache = React.useMemo(
    () =>
      throttle((request: string, callback: (results: SearchUnitDto[]) => void) => fetchApi(request, callback), 600, {
        leading: false,
      }),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    if (inputValue === props.selectedUnit?.elementId) {
      return;
    }

    fetchCache(inputValue, (results?: SearchUnitDto[]) => {
      if (active) {
        let newOptions = [] as SearchUnitDto[];

        if (props.selectedUnit) {
          newOptions = [props.selectedUnit];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetchCache]);

  return (
    <Autocomplete
      sx={props.sx}
      disabled={props.disabled}
      options={options}
      noOptionsText="No units"
      getOptionLabel={(option) =>
        `${option.unitFileId}-${option.elementId}-${option.productionId}-${option.byElementId}` || ''
      }
      renderOption={(liProps, option) => (
        <Box
          key={`${option.unitFileId}-${option.elementId}-${option.productionId}-${option.byElementId}`}
          component="li"
          {...liProps}
        >
          <Stack direction="row" spacing={2}>
            <UnitIndicator useElementId={option.byElementId} />
            <Typography>{option.byElementId ? option.elementId : option.productionId}</Typography>
            {option.hasIdentical && <IdenticalIndicator hasIdentical={option.hasIdentical} />}
            <AlreadyUseIndicator useIn={option.useIn} />
            {props.alreadySelected && props.alreadySelected.some((s) => s.unitFileId === option.unitFileId) && (
              <UnitAlreadySelectedIndicator />
            )}
          </Stack>
        </Box>
      )}
      value={props.selectedUnit}
      inputValue={inputValue}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search by Element ID or Production ID"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      onChange={(event: any, newValue) => {
        if (newValue as SearchUnitDto) {
          props.onSelectUnit(newValue as SearchUnitDto);
          setInputValue('');
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  );
};

export default UnitAutocomplete;
