import authService from '../auth/AuthService'
import { API_ADDRESS } from './api'
import { handleErrors } from './errorHandler'
import { msalFetch } from './graph'
import { SetOrdersResponse } from './types/Sets/SetUsages'

const TOOLING_API_ADDRESS = `${API_ADDRESS}/api/sets`

export class SetUsagesApi {
  async getOrders(setNumber: number) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .get(`${TOOLING_API_ADDRESS}/${setNumber}/usages/orders`, result.accessToken)
        .then(handleErrors)
        .then<SetOrdersResponse>((res) => res.json())
    }
  }
}

const setUsagesApi = new SetUsagesApi()

export default setUsagesApi
