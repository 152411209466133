import { Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import { RepairDto } from '../../../../../api/types/Repairs'
import Cost from '../../components/Cost'

interface Props {
  repair: RepairDto;
  disabled?: boolean;
}

const RepairItemCard: FunctionComponent<Props> = (props) => {
  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={8} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ overflowWrap: 'anywhere' }}>
                Repair
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ overflowWrap: 'anywhere' }}>
                {props.repair.reason}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ overflowWrap: 'anywhere' }}>{props.repair.provider.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ overflowWrap: 'anywhere' }}>{props.repair.repairOrder ?? ''}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="GrayText">
                {(props.repair.comment ?? '').split('\n').map((p, key) => (
                  <span key={key}>{p}</span>
                ))}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Stack spacing={1} alignItems="flex-end">
              <Grid item>
                <Typography variant="body2">
                  {props.repair.repairDate ? new Date(props.repair.repairDate).toLocaleDateString() : ''}
                </Typography>
              </Grid>
              <Grid item>
                <Stack>
                  <Cost amount={props.repair.cost.amount} currency={props.repair.cost.currency.name}/>
                  <Typography variant="caption">VAT included</Typography>
                </Stack>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default RepairItemCard
