import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import usersApi from '../../api/administration/UsersApi';
import { RootState } from '../store';

export interface SelectRoleUserDialogState {
  status: 'idle' | 'loading' | 'failed';
  users: UserListItem[];
  selectUsersDialogOpened: boolean;
  dialogError?: SerializedError;
  actionError?: SerializedError;
}

export interface UserListItem {
  status: 'idle' | 'loading' | 'saving' | 'failed';
  userId: string;
  userPrincipalName: string;
  displayName: string;
  enableLogin: boolean;
}

const initialState: SelectRoleUserDialogState = {
  status: 'idle',
  selectUsersDialogOpened: false,
  users: [],
};

export const loadUsers = createAsyncThunk('select-role-users/load', async () => {
  return await usersApi.getUsers();
});

export const updateEnableLogin = createAsyncThunk(
  'users-list/update-enable-login',
  async (data: { userId: string; enableLogin: boolean }, thunkApi) => {
    const { userId, enableLogin } = data;
    return await usersApi.updateEnableLogin(userId, enableLogin);
  },
);

export const addUser = createAsyncThunk('select-role-users/add-user', async (userId: string, thunkApi) => {
  return await usersApi.addUser(userId);
});

export const selectRoleUsersSlice = createSlice({
  name: 'select-role-users',
  initialState,
  reducers: {
    setActionError: (state, action: PayloadAction<Error | undefined>) => {
      state.actionError = action.payload;
    },
  },
  extraReducers: (build) => {
    build.addCase(loadUsers.pending, (state, action) => {
      state.status = 'loading';
    });

    build.addCase(loadUsers.fulfilled, (state, action) => {
      state.status = 'idle';
      state.users = action.payload.map((u) => {
        return {
          status: 'idle',
          userId: u.userId,
          userPrincipalName: u.userPrincipalName,
          displayName: u.displayName,
          enableLogin: u.enableLogin,
        } as UserListItem;
      });
    });

    build.addCase(loadUsers.rejected, (state, action) => {
      state.status = 'failed';
      state.dialogError = action.error;
    });

    build.addCase(updateEnableLogin.pending, (state, action) => {
      const user = state.users.find((u) => u.userId === action.meta.arg.userId);
      if (user) {
        user.status = 'saving';
      }
    });

    build.addCase(updateEnableLogin.fulfilled, (state, action) => {
      const user = state.users.find((u) => u.userId === action.meta.arg.userId);
      if (user) {
        user.status = 'idle';
        user.enableLogin = action.meta.arg.enableLogin;
      }
    });

    build.addCase(updateEnableLogin.rejected, (state, action) => {
      const user = state.users.find((u) => u.userId === action.meta.arg.userId);
      if (user) {
        user.status = 'failed';
        state.actionError = action.error;
      }
    });

    build.addCase(addUser.pending, (state, action) => {
      state.users = [
        ...state.users,
        { userId: action.meta.arg, displayName: 'Adding...', status: 'loading' } as UserListItem,
      ];
    });

    build.addCase(addUser.fulfilled, (state, action) => {
      const user = state.users.find((u) => u.userId === action.meta.arg);
      if (user) {
        user.status = 'idle';
        user.displayName = action.payload.displayName;
        user.userPrincipalName = action.payload.userPrincipalName;
        user.enableLogin = action.payload.enableLogin;
      }
    });

    build.addCase(addUser.rejected, (state, action) => {
      state.users = state.users.filter((u) => u.userId !== action.meta.arg);
      state.actionError = action.error;
    });
  },
});

export const { setActionError } = selectRoleUsersSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRoleUsersDialog = (state: RootState) => state.administration.roles.edit.selectUsers;

export default selectRoleUsersSlice.reducer;
