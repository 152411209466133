import authService from '../auth/AuthService';
import { API_ADDRESS } from './api';
import { msalFetch } from './graph';
import { SupplierDto } from './types/SupplierData';

const SUPPLIERS_API_ADDRESS = `${API_ADDRESS}/api/suppliers`;

export class SuppliersApi {
  async getSuppliersList() {
    const result = await authService.getIdentity();

    if (result) {
      return msalFetch()
        .get(SUPPLIERS_API_ADDRESS, result.accessToken)
        .then<SupplierDto>((res) => res.json());
    }
  }

  async search(count: number, filter: string) {
    const result = await authService.getIdentity();

    if (result) {
      return msalFetch()
        .get(`${SUPPLIERS_API_ADDRESS}/search?count=${count}&filter=${filter}`, result.accessToken)
        .then<SupplierDto[]>((res) => res.json());
    }
  }
}

const suppliersApi = new SuppliersApi();

export default suppliersApi;
