import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import repairFilesApi from '../../../api/RepairFilesApi'
import { RootState } from '../../store'
import { FilesState, FileState, UploadFile } from '../files/filesSlice'

const initialState: FilesState = {
  status: 'idle',
  files: [],
  uploading: [],
}

const sliceName = 'tooling-operation-files-slice'

export const loadFiles = createAsyncThunk(
  `${sliceName}/load`,
  async (payload: { toolingId: number; repairId: number }) => {
    return await repairFilesApi.getFiles(payload.toolingId, payload.repairId)
  },
)

export const upload = createAsyncThunk(
  `${sliceName}/upload`,
  async (payload: { toolingId: number; repairId: number; file: File }) => {
    return await repairFilesApi.upload(payload.toolingId, payload.repairId, payload.file)
  },
)

export const deleteFile = createAsyncThunk(
  `${sliceName}/delete`,
  async (payload: { toolingId: number; repairId: number; fileId: number }) => {
    return await repairFilesApi.deleteFile(payload.toolingId, payload.repairId, payload.fileId)
  },
)

export const filesSlice = createSlice({
                                        name: sliceName,
                                        initialState,
                                        reducers: {
                                          startLoading: (state) => {
                                            state.status = 'loading'
                                          },
                                          successLoading: (state) => {
                                            state.status = 'idle'
                                          },
                                          fail: (state) => {
                                            state.status = 'failed'
                                          },
                                        },
                                        extraReducers: (build) => {
                                          build.addCase(loadFiles.pending, (state, action) => {
                                            state.status = 'loading'
                                          })

                                          build.addCase(loadFiles.fulfilled, (state, action) => {
                                            state.status = 'idle'
                                            state.files =
                                              action.payload?.items.map((f) => {
                                                return { status: 'idle', file: f }
                                              }) ?? []
                                          })

                                          build.addCase(loadFiles.rejected, (state, action) => {
                                            state.status = 'failed'
                                            state.actionError = action.error
                                          })

                                          build.addCase(deleteFile.pending, (state, action) => {
                                            const file = state.files.find((f) => f.file.fileId === action.meta.arg.fileId)
                                            if (file) {
                                              file.status = 'loading'
                                            }
                                          })

                                          build.addCase(deleteFile.fulfilled, (state, action) => {
                                            state.files = state.files.filter((f) => f.file.fileId !== action.meta.arg.fileId)
                                          })

                                          build.addCase(deleteFile.rejected, (state, action) => {
                                            const file = state.files.find((f) => f.file.fileId === action.meta.arg.fileId)
                                            if (file) {
                                              file.status = 'failed'
                                              file.actionError = 'Error while deleting file'
                                            }
                                          })

                                          build.addCase(upload.pending, (state, action) => {
                                            const file = {
                                              status: 'loading',
                                              uploadId: action.meta.requestId,
                                              name: action.meta.arg.file.name,
                                            } as UploadFile
                                            state.uploading = [...state.uploading, file]
                                          })
                                          build.addCase(upload.fulfilled, (state, action) => {
                                            const uploadFile = state.uploading.find((f) => f.uploadId === action.meta.requestId)
                                            if (uploadFile) {
                                              state.uploading = state.uploading.filter((f) => f.uploadId !== uploadFile.uploadId)

                                              const fileState = { status: 'idle', file: action.payload } as FileState

                                              state.files = [...state.files, fileState]
                                            }
                                          })
                                          build.addCase(upload.rejected, (state, action) => {
                                            const file = state.uploading.find((f) => f.uploadId === action.meta.requestId)
                                            if (file) {
                                              file.status = 'failed'
                                              file.actionError = 'Error while uploading file'
                                            }
                                          })
                                        },
                                      })

export const { startLoading, successLoading, fail } = filesSlice.actions

export const selectRepairFiles = (state: RootState) => state.tooling.repairs.edit.files

export default filesSlice.reducer
