import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { FunctionComponent } from 'react';
import { CurrencyDto } from '../../../api/types/CurrencyDto';
import { SxProps, Theme } from '@mui/system';
import _ from 'lodash';

interface Props {
  currencies: CurrencyDto[];
  currentCurrency: CurrencyDto | undefined;
  onChangeCurrency: (newOwner: CurrencyDto | undefined) => void;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}

const CurrencySelector: FunctionComponent<Props> = (props) => {
  return (
    <FormControl fullWidth sx={props.sx}>
      <InputLabel id="currency-label">Currency</InputLabel>
      <Select
        labelId="currency-label"
        label="Currency"
        disabled={props.disabled}
        value={props.currentCurrency?.currencyId || 0}
        error={props.error}
        onChange={(value) => {
          const currencyId = value.target.value as number;

          if (currencyId) {
            const newCurrency = props.currencies.find((o) => o.currencyId === currencyId);
            props.onChangeCurrency(newCurrency);
          } else {
            props.onChangeCurrency(undefined);
          }
        }}
      >
        <MenuItem value={0}>
          <em>No currency</em>
        </MenuItem>
        {_.sortBy(props.currencies, (c) => c.name).map((o) => (
          <MenuItem key={o.currencyId} value={o.currencyId}>
            {o.name}
          </MenuItem>
        ))}
      </Select>
      {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CurrencySelector;
