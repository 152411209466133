import React, { Fragment, FunctionComponent } from 'react';
import { Stack, Typography } from '@mui/material';
import { FindToolingDto } from '../../../api/types/ToolingDto';
import { Warning } from '@mui/icons-material';

interface Props {
  useIn: FindToolingDto[];
}

const AlreadyUseIndicator: FunctionComponent<Props> = (props) => {
  if (props.useIn.length > 0) {
    return (
      <Stack direction="row">
        <Warning color="warning" />
        <Typography color={(theme) => theme.palette.warning.main}>Has tooling</Typography>
      </Stack>
    );
  }

  return <Fragment />;
};

export default AlreadyUseIndicator;
