import { FunctionComponent } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material'

interface Props {
  title: string;
  text: string;
  onNo: () => void;
  onYes: () => void;
}

export const ScrapeToolingDialog: FunctionComponent<Props> = (props) => {
  return (
    <Dialog open={true} onClose={props.onNo} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <DialogContentText>{props.text}</DialogContentText>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onNo}>NO</Button>
        <Button onClick={props.onYes}>YES</Button>
      </DialogActions>
    </Dialog>
  )
}
