import { ArrowBackIos } from '@mui/icons-material'
import { Box, Button, Grid, IconButton, Skeleton, Tooltip, Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
  title: string;
  backPath: string;
  isLoading: boolean;
  hideScrapeButton?: boolean;
  hideDeleteButton?: boolean;
  onScrapeButtonAction?: () => void;
  onDeleteButtonAction?: () => void;
}

export const ToolingPageToolbar: FunctionComponent<Props> = (props) => {
  const navigate = useNavigate()

  const handleScrape = () => {
    props.onScrapeButtonAction && props.onScrapeButtonAction()
  }

  const handleDelete = () => {
    props.onDeleteButtonAction && props.onDeleteButtonAction()
  }

  return (
    <Box mb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Tooltip title="Back">
            <IconButton onClick={() => navigate(props.backPath)}>
              <ArrowBackIos/>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Typography variant="h5">{props.isLoading ? <Skeleton width={50}/> : props.title}</Typography>
        </Grid>
        <Grid item xs/>
        {props.hideScrapeButton && (
          <Grid item>
            <Button variant="outlined" color="error" disabled={props.isLoading} onClick={handleScrape}>
              Scrape
            </Button>
          </Grid>
        )}
        {props.hideDeleteButton && (
          <Grid item>
            <Button variant="outlined" color="error" disabled={props.isLoading} onClick={handleDelete}>
              Delete
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
