import authService from '../auth/AuthService';
import { API_ADDRESS } from './api';
import { handleErrors } from './errorHandler';
import { msalFetch } from './graph';
import { FileDto, FilesResponse } from './types/Files';

const TOOLING_API_ADDRESS = `${API_ADDRESS}/api/tooling`;

export class ToolingFilesApi {
  async getFiles(toolingId: number) {
    const identity = await authService.getIdentity();

    return msalFetch()
      .get(`${TOOLING_API_ADDRESS}/${toolingId}/files`, identity.accessToken)
      .then(handleErrors)
      .then<FilesResponse>((res) => res.json());
  }

  async upload(toolingId: number, file: File) {
    const formData = new FormData();
    formData.append('file', file);

    const identity = await authService.getIdentity();

    return msalFetch()
      .post(`${TOOLING_API_ADDRESS}/${toolingId}/files`, identity.accessToken, formData)
      .then(handleErrors)
      .then<FileDto>((res) => res.json());
  }

  async deleteFile(toolingId: number, fileId: number) {
    const identity = await authService.getIdentity();
    msalFetch().delete(`${TOOLING_API_ADDRESS}/${toolingId}/files/${fileId}`, identity.accessToken).then(handleErrors);
  }
}

const toolingFilesApi = new ToolingFilesApi();

export default toolingFilesApi;
