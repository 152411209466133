import { FunctionComponent, useEffect } from 'react'
import { FileDto } from '../../../../../api/types/Files'
import BackdropViewer from '../../../../../components/viewers/BackdropViewer'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import {
  closeFileViewer,
  getFileUrls,
  openFileViewer,
  selectFilesViewer,
} from '../../../../../store/tooling/files/viewerSlice'
import FilesContainer from '../../../../../components/files/FilesContainer'
import FileIteratorHandler from '../../../../../components/files/FileIteratorHandler'
import { deleteFile, loadFiles, selectRepairFiles, upload } from '../../../../../store/tooling/repairs/filesSlice'
import { useRepairPermissions } from '../../../view/tabs/operation/hooks'
import { selectEditRepair } from '../../../../../store/tooling/repairs/editRepairSlice'

interface Props {
  toolingId: number;
  repairId: number;
}

const RepairFilesPanel: FunctionComponent<Props> = (props) => {
  const repairState = useAppSelector(selectEditRepair)
  const filesState = useAppSelector(selectRepairFiles)
  const viewerState = useAppSelector(selectFilesViewer)
  const dispatch = useAppDispatch()

  const isLoading = filesState.status === 'loading'
  const isIdle = filesState.status === 'idle'
  const isFailed = filesState.status === 'failed'

  const repairPermissions = useRepairPermissions(repairState.responsible)
  const canEdit = isIdle && repairPermissions

  useEffect(() => {
    dispatch(loadFiles({ toolingId: props.toolingId, repairId: props.repairId }))
  }, [dispatch, props.toolingId, props.repairId])

  useEffect(() => {
    if (viewerState.file) {
      dispatch(getFileUrls(viewerState.file.fileId))
    }
  }, [dispatch, viewerState.file])

  const handleOpenViewer = (file: FileDto) => {
    dispatch(openFileViewer(file))
  }

  const handleCloseViwer = () => {
    dispatch(closeFileViewer())
  }

  const handleDelete = (file: FileDto) => {
    dispatch(deleteFile({ toolingId: props.toolingId, repairId: props.repairId, fileId: file.fileId }))
  }

  const handleUpload = (files: FileList) => {
    for (let index = 0; index < files.length; index++) {
      const file = files[index]

      dispatch(upload({ toolingId: props.toolingId, repairId: props.repairId, file }))
    }
  }

  return (
    <FileIteratorHandler
      files={filesState.files}
      openedFile={viewerState.file}
      onOpen={handleOpenViewer}
      onClose={handleCloseViwer}
    >
      <FilesContainer
        isLoading={isLoading}
        isFailed={isFailed}
        canEdit={canEdit}
        files={filesState.files}
        uploading={filesState.uploading}
        onUpload={handleUpload}
        onOpen={handleOpenViewer}
        onClose={handleCloseViwer}
        onDelete={handleDelete}
      />

      <BackdropViewer
        open={viewerState.open}
        loading={viewerState.status === 'loading'}
        onClose={handleCloseViwer}
        file={viewerState.file}
        url={viewerState.url}
        downloadUrl={viewerState.downloadUrl}
      />
    </FileIteratorHandler>
  )
}

export default RepairFilesPanel
