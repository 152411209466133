import { REDIRECT_ADDRESS } from '../api/api';

export const msalConfig = {
  auth: {
    clientId: '5cc787f6-09ac-47c6-b4cf-0733094edb23',
    authority: 'https://login.microsoftonline.com/73082264-d8cb-482d-91c7-71787b25397a',
    redirectUri: REDIRECT_ADDRESS,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read', 'User.ReadBasic.All'],
};

export const toolingApiRequest = {
  scopes: ['api://470d3650-e81a-4171-8af1-815601e9e892/access_as_user'],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphUsersEndpoint: 'https://graph.microsoft.com/v1.0/users',
};
