import { FunctionComponent, useEffect, useState, useCallback } from 'react'
import { Button, Container, Box, Grid, Stack, Typography, Tooltip, IconButton } from '@mui/material'
import ErrorBox from '../../../components/ErrorBox'
import { useAppSelector, useAppDispatch } from '../../../store/hooks'
import { selectSetsList, loadSets } from '../../../store/sets/setsListSlice'
import SetsList from './SetsList'
import ListLoadingStateHandler from '../../components/LoadingStateHandler'
import SetPageToolbar from '../SetPageToolbar'
import CreateNewSetDialog from '../CreateNewSetDialog/CreateNewSetDialog'
import { useNavigate } from 'react-router-dom'
import { Permissions_Sets_Edit } from '../../../api/types/permissions'
import { checkPermission } from '../../../store/user/userSlice'
import { setCurrentItem } from '../../../store/sets/currentItemSlice'
import { DownloadReport } from './DownloadReport'
import SearchBox from '../../../components/SearchBox'

import { ClearAll } from '@mui/icons-material'
import {
  selectSetsFilters, setNumberFilter, setSupplierFilter, setUnitFilter, setResponsibleFilter, getSetsStatuses,
  clearAllFilters, setStatusFilter,
} from '../../../store/sets/setsFiltersSlice'
import _ from 'lodash'
import { SetStatusSelector } from './SetStatusSelector'

const SetsFilters: FunctionComponent = () => {
  const dispatch = useAppDispatch()

  const filtersState = useAppSelector(selectSetsFilters)

  const loadSetListThrottled = useCallback(
    _.debounce(
      (
        page: number,
        pageSize: number,
        setNumberFilter: string,
        statusFilter: string,
        supplierFilter: string,
        unitFilter: string,
        responsibleFilter: string,
      ) =>
        dispatch(loadSets(
          {
            page: page,
            pageSize: pageSize,
            setNumber: setNumberFilter,
            status: statusFilter,
            supplier: supplierFilter,
            unit: unitFilter,
            responsible: responsibleFilter,
          })),
      800,
    ),
    [dispatch],
  )

  useEffect(() => {
    loadSetListThrottled(
      0,
      25,
      filtersState.setNumberFilter ?? '',
      filtersState.statusFilter ?? '',
      filtersState.supplierFilter ?? '',
      filtersState.unitFilter ?? '',
      filtersState.responsibleFilter ?? '',
    )
  }, [
              0,
              25,
              filtersState.setNumberFilter,
              filtersState.statusFilter,
              filtersState.supplierFilter,
              filtersState.unitFilter,
              filtersState.responsibleFilter,
            ])

  useEffect(() => {
    dispatch(getSetsStatuses())
  }, [])

  const handleClickClearAll = () => {
    dispatch(clearAllFilters())
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6">Filters</Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} md={12} lg={4} xl={2}>
            <SearchBox
              caption="Set number"
              size="small"
              fullWidth
              filter={filtersState.setNumberFilter}
              onFilterChanged={(filter) => dispatch(setNumberFilter(filter))}
              onFilter={() => {
              }}
              onClear={() => dispatch(setNumberFilter(''))}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={2}>
            {filtersState.statusesStatus !== 'failed' && (
              <>
                <SetStatusSelector
                  size="small"
                  statuses={filtersState.statuses ?? []}
                  isLoading={false}
                  hasEmpty={true}
                  currentStatus={filtersState.statusFilter}
                  onChange={(filter) => dispatch(setStatusFilter(filter))}
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg>
            <SearchBox
              caption="Supplier"
              size="small"
              fullWidth
              filter={filtersState.supplierFilter}
              onFilterChanged={(filter) => dispatch(setSupplierFilter(filter))}
              onFilter={() => {
              }}
              onClear={() => dispatch(setSupplierFilter(''))}
            />
          </Grid>
          <Grid item xs={12} md={6} lg>
            <SearchBox
              caption="Unit"
              size="small"
              fullWidth
              filter={filtersState.unitFilter}
              onFilterChanged={(filter) => dispatch(setUnitFilter(filter))}
              onFilter={() => {
              }}
              onClear={() => dispatch(setUnitFilter(''))}
            />
          </Grid>
          <Grid item xs={12} md={6} lg>
            <SearchBox
              caption="Responsbile"
              size="small"
              fullWidth
              filter={filtersState.responsibleFilter}
              onFilterChanged={(filter) => dispatch(setResponsibleFilter(filter))}
              onFilter={() => {
              }}
              onClear={() => dispatch(setResponsibleFilter(''))}
            />
          </Grid>
          <Grid item xs="auto">
            <Tooltip title="Clear all">
              <IconButton onClick={handleClickClearAll}>
                <ClearAll/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SetsFilters
