import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import materialsApi from '../../api/MaterialsApi';
import { MaterialDto } from '../../api/types/MaterialDto';
import { RootState } from '../store';

export interface EditMaterialState {
  status: 'idle' | 'saving' | 'failed';
  material: MaterialDto | null;
  open: boolean;
  actionError: SerializedError | null;
}

const initialState: EditMaterialState = {
  status: 'idle',
  material: null,
  open: false,
  actionError: null,
};

export const updateMaterial = createAsyncThunk('edit-materials/update', async (payload: MaterialDto, thunkAPI) => {
  await materialsApi.updateMaterial(payload);
});

export const editMaterialSlice = createSlice({
  name: 'materials-edit-slice',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.status = 'saving';
    },
    successLoading: (state) => {
      state.status = 'idle';
    },
    fail: (state) => {
      state.status = 'failed';
    },
    setName: (state, action: PayloadAction<string>) => {
      if (state.material) {
        state.material.name = action.payload;
      }
    },
    setDefaultAllowableUsages: (state, action: PayloadAction<number>) => {
      if (state.material) {
        state.material.defaultAllowableUsages = action.payload;
      }
    },
    open: (state, action: PayloadAction<MaterialDto>) => {
      state.material = action.payload;
      state.open = true;
    },

    close: (state) => {
      state.material = null;
      state.open = false;
      editMaterialSlice.actions.clearActionError();
    },

    clearActionError: (state) => {
      state.actionError = null;
      state.status = 'idle';
    },
  },
  extraReducers: (build) => {
    build.addCase(updateMaterial.pending, (state, action) => {
      state.status = 'saving';
    });

    build.addCase(updateMaterial.fulfilled, (state, action) => {
      state.status = 'idle';
      editMaterialSlice.actions.close();
    });

    build.addCase(updateMaterial.rejected, (state, action) => {
      state.status = 'failed';
      state.actionError = action.error;
    });
  },
});

export const { startLoading, successLoading, fail, setName, setDefaultAllowableUsages, open, close, clearActionError } =
  editMaterialSlice.actions;

export const selectEditMaterial = (state: RootState) => state.materials.edit;

export default editMaterialSlice.reducer;
