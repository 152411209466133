import authService from '../auth/AuthService';
import { API_ADDRESS } from './api';

export class FilesApi {
  async getFileUrl(fileId: number) {
    const identity = await authService.getIdentity();
    const url = `${API_ADDRESS}/api/files/${fileId}?access_token=${identity.accessToken}`;
    const downloadUrl = `${API_ADDRESS}/api/files/${fileId}/download?access_token=${identity.accessToken}`;

    return { url, downloadUrl };
  }
}

const filesApi = new FilesApi();

export default filesApi;
