import React, { FunctionComponent } from 'react';
import { useMsal } from "@azure/msal-react";
import { Typography } from '@mui/material';


export const UserProfile: FunctionComponent = () => {
  const { accounts } = useMsal();
  const name = accounts[0] && accounts[0].name;

  return (
    <Typography>{name}</Typography>
  );
};
