import { combineReducers } from '@reduxjs/toolkit'
import toolingList from './toolingListSlice'
import filters from './toolingFiltersSlice'
import main from './toolingViewSlice'
import technicalInfo from './toolingTechincalInfoEditSlice'
import summary from './summarySlice'
import accounting from './accountingSlice'
import material from './create/materialSlice'
import dialog from './create/dialogSlice'
import responsible from './responsibleSlice'
import { repairs } from './repairs/redusers'
import { transfers } from './transfers/reducers'
import history from './history/historySlice'
import orders from './orders/ordersSlice'
import files from './files/filesSlice'
import viewer from './files/viewerSlice'

export const tooling = combineReducers({
                                         list: combineReducers({
                                                                 toolingList,
                                                                 filters,
                                                               }),
                                         view: combineReducers({
                                                                 main,
                                                               }),
                                         create: combineReducers({
                                                                   dialog,
                                                                   material,
                                                                 }),
                                         edit: combineReducers({
                                                                 summary,
                                                                 accounting,
                                                                 technicalInfo,
                                                                 responsible,
                                                               }),
                                         repairs,
                                         transfers,
                                         history,
                                         orders,
                                         files,
                                         viewer,
                                       })
