import { graphConfig } from '../auth/authConfig';
import authService from '../auth/AuthService';
import { handleErrors } from './errorHandler';
import { msalFetch } from './graph';
import { Users } from './types/graph/graph';

export class GraphApi {
  async getUserPhoto(userId: string) {
    const token = await authService.getClientToken();

    return msalFetch()
      .get(`${graphConfig.graphUsersEndpoint}/${userId}/photo/$value`, token.accessToken)
      .then(handleErrors);
  }

  async findUsers(value: string) {
    const token = await authService.getClientToken();

    const headers = new Headers();
    headers.append('ConsistencyLevel', 'eventual');

    return msalFetch()
      .get(
        `${graphConfig.graphUsersEndpoint}?$search="displayName:${value}" OR "mail:${value}"&$orderby=displayName`,
        token.accessToken,
        undefined,
        headers,
      )
      .then(handleErrors)
      .then<Users>((res) => res.json());
  }
}

const graphApi = new GraphApi();

export default graphApi;
