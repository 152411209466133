import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CoreboxDto, TechnicalInfoDto } from '../../api/types/ToolingDto';
import { RootState } from '../store';

export interface ToolingTechincalInfoEditState {
  status: 'idle' | 'loading' | 'failed';
  technicalInfo?: TechnicalInfoDto;
}

const initialState: ToolingTechincalInfoEditState = {
  status: 'idle',
};

export const toolingTechincalInfoEditSlice = createSlice({
  name: 'tooling-techincal-info-edit-slice',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.status = 'loading';
    },
    successLoading: (state) => {
      state.status = 'idle';
    },
    fail: (state) => {
      state.status = 'failed';
    },
    init: (state, action: PayloadAction<TechnicalInfoDto>) => {
      state.technicalInfo = action.payload;
    },
    setCastingToleranceGrade: (state, action: PayloadAction<string>) => {
      if (state.technicalInfo) {
        state.technicalInfo.castingToleranceGrade = action.payload;
      }
    },
    setFlaskDimensions: (state, action: PayloadAction<number | undefined>) => {
      if (state.technicalInfo) {
        state.technicalInfo.flaskDimensions = action.payload;
      }
    },
    setTotalWeight: (state, action: PayloadAction<number | undefined>) => {
      if (state.technicalInfo) {
        state.technicalInfo.totalWeight = action.payload;
      }
    },
    setGauges: (state, action: PayloadAction<number | undefined>) => {
      if (state.technicalInfo) {
        state.technicalInfo.gauges = action.payload;
      }
    },
    setChills: (state, action: PayloadAction<number | undefined>) => {
      if (state.technicalInfo) {
        state.technicalInfo.chills = action.payload;
      }
    },
    setPackingWidth: (state, action: PayloadAction<number | undefined>) => {
      if (state.technicalInfo?.packing) {
        state.technicalInfo.packing.width = action.payload;
      }
    },
    setPackingHeight: (state, action: PayloadAction<number | undefined>) => {
      if (state.technicalInfo?.packing) {
        state.technicalInfo.packing.height = action.payload;
      }
    },
    setPackingLength: (state, action: PayloadAction<number | undefined>) => {
      if (state.technicalInfo?.packing) {
        state.technicalInfo.packing.length = action.payload;
      }
    },
    setLoosePartsQuantity: (state, action: PayloadAction<number | undefined>) => {
      if (state.technicalInfo?.looseParts) {
        state.technicalInfo.looseParts.quantity = action.payload;
      }
    },
    setLoosePartsGatingSystem: (state, action: PayloadAction<number | undefined>) => {
      if (state.technicalInfo?.looseParts) {
        state.technicalInfo.looseParts.gatingSystem = action.payload;
      }
    },
    setLoosePartsRisers: (state, action: PayloadAction<number | undefined>) => {
      if (state.technicalInfo?.looseParts) {
        state.technicalInfo.looseParts.risers = action.payload;
      }
    },
    setLoosePartsGasVents: (state, action: PayloadAction<number | undefined>) => {
      if (state.technicalInfo?.looseParts) {
        state.technicalInfo.looseParts.gasVents = action.payload;
      }
    },
    addCorebox: (state) => {
      if (state.technicalInfo) {
        const length = state.technicalInfo.coreboxes?.length || 0;
        const corebox = {
          coreboxId: length + 1,
          quantity: 1,
        } as CoreboxDto;

        if (state.technicalInfo.coreboxes) {
          state.technicalInfo.coreboxes.push(corebox);
        } else {
          state.technicalInfo.coreboxes = [corebox];
        }
      }
    },
    removeCorebox: (state, action: PayloadAction<number>) => {
      if (state.technicalInfo?.coreboxes) {
        state.technicalInfo.coreboxes = state.technicalInfo.coreboxes.filter((cb) => cb.coreboxId !== action.payload);
      }
    },
    updateCorebox: (state, action: PayloadAction<CoreboxDto>) => {
      if (state.technicalInfo?.coreboxes) {
        const corebox = state.technicalInfo.coreboxes.find((cb) => cb.coreboxId === action.payload.coreboxId);
        if (corebox) {
          corebox.quantity = action.payload.quantity;
          corebox.material = action.payload.material;
        }
      }
    },
  },
});

export const {
  startLoading,
  successLoading,
  fail,
  init,
  setCastingToleranceGrade,
  setFlaskDimensions,
  setTotalWeight,
  setGauges,
  setChills,
  setPackingWidth,
  setPackingHeight,
  setPackingLength,
  setLoosePartsQuantity,
  setLoosePartsGatingSystem,
  setLoosePartsRisers,
  setLoosePartsGasVents,
  addCorebox,
  removeCorebox,
  updateCorebox,
} = toolingTechincalInfoEditSlice.actions;

export const selectToolingTechnicalInfoEdit = (state: RootState) => state.tooling.edit.technicalInfo;

export default toolingTechincalInfoEditSlice.reducer;
