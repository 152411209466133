import { Box, Card, CardContent, CardHeader, Container, Divider, Grid } from '@mui/material'
import { FunctionComponent, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ErrorDialog from '../../../../components/ErrorDialog'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'

import PageToolbar from '../../components/PageToolbar'
import EditTransferContent from './EditTransferContent'
import ErrorStateHandler from '../../../components/ErrorsStateHandler'
import YesNoDialog from '../../../../components/YesNoDialog'

import { SupplierDto } from '../../../../api/types/SupplierData'
import { CurrencyDto } from '../../../../api/types/CurrencyDto'
import { validateFloat } from '../../../../helpers/validation'

import {
  load, showConfirmDeleteDialog, hideConfirmDeleteTooling, deleteTransfer, setReason, hideActionError,
  EditTransferState, selectEditTransfer, setShipperType, setShipperName, setShipper, setStartLocation,
  setFinishLocation, setCurrency, setCost, setDeliveryDate, setTransferTaskId, setComment, save,
} from '../../../../store/tooling/transfers/editTransferSlice'
import { useTransferPermissions } from '../../view/tabs/operation/hooks'
import {
  ShipperType, ExternalShipperRequest, ShipperRequest, UpdateShipperRequest, UpdateTransferRequest,
} from '../../../../api/types/Transfers'
import TransferFilesPanel from './files/TransferFilesPanel'

const EditTransferPage: FunctionComponent = () => {
  let { toolingId, transferId } = useParams()
  const toolingIdParsed = Number.parseInt(toolingId ?? '0')
  const transferIdParsed = Number.parseInt(transferId ?? '0')

  const transferState: EditTransferState = useAppSelector(selectEditTransfer)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(load({ toolingId: toolingIdParsed, transferId: transferIdParsed }))
  }, [dispatch, toolingIdParsed, transferIdParsed])

  const isLoading = transferState.status === 'loading'
  const isSaving = transferState.status === 'saving'
  const isFailed = transferState.status === 'failed'
  const isIdle = transferState.status === 'idle'

  const transferPermissions = useTransferPermissions(transferState.responsible)
  const canEdit = isIdle && transferPermissions

  const handleDelete = () => {
    dispatch(showConfirmDeleteDialog())
  }

  const handleConfirmDelete = () => {
    dispatch(hideConfirmDeleteTooling())
    dispatch(deleteTransfer({ toolingId: toolingIdParsed, transferId: transferIdParsed }))
      .then(() => navigate(`/tooling/${toolingIdParsed}#operations`))
  }

  const handleChangeReason = (reason: string) => {
    dispatch(setReason(reason))
  }

  const handleChangeShipperType = (type: ShipperType) => {
    dispatch(setShipperType(type))
  }

  const handleChangeShipperName = (shipperName: string) => {
    dispatch(setShipperName(shipperName))
  }
  const handleChangeShipper = (shipper: SupplierDto | null) => {
    dispatch(setShipper(shipper))
  }

  const handleChangeStartLocation = (location: SupplierDto | null) => {
    dispatch(setStartLocation(location))
  }

  const handleChangeFinishLocation = (location: SupplierDto | null) => {
    dispatch(setFinishLocation(location))
  }

  const handleChangeCost = (cost: string) => {
    validateFloat(cost, (costNumber) => {
      dispatch(setCost(costNumber))
    })
  }

  const handleChangeCurrency = (currency?: CurrencyDto) => {
    dispatch(setCurrency(currency))
  }

  const handleChangeDeliveryDate = (deliveryDate?: string) => {
    dispatch(setDeliveryDate(deliveryDate))
  }

  const handleChangeTask = (transferTaskId?: number) => {
    dispatch(setTransferTaskId(transferTaskId))
  }

  const handleChangeComment = (comment: string) => {
    dispatch(setComment(comment))
  }

  const handleSave = () => {
    const shipper = {
      external:
        transferState.shipperType === 'External'
        ? ({ name: transferState.shipperName } as ExternalShipperRequest)
        : undefined,
      shipper:
        transferState.shipperType === 'Supplier'
        ? ({ shipperId: transferState.shipper?.supplierId } as ShipperRequest)
        : undefined,
    } as UpdateShipperRequest

    const request = {
      comment: transferState.comment,
      cost: transferState.cost,
      currencyId: transferState.currency?.currencyId ?? 0,
      deliveryDate: transferState.deliveryDate,
      finishLocationId: transferState.finishLocation?.supplierId,
      reason: transferState.reason,
      shipper: shipper,
      startLocationId: transferState.startLocation?.supplierId,
      transferTaskId: transferState.transferTaskId ? '' : undefined,
    } as unknown as UpdateTransferRequest

    dispatch(save({ toolingId: toolingIdParsed, transferId: transferIdParsed, request })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        navigate(`/tooling/${toolingId}#operations`)
      }
    })
  }

  return (
    <Box mt={4} mb={4}>
      <Container maxWidth="lg">
        <PageToolbar
          title="Edit tooling transfer"
          backPath={`/tooling/${toolingIdParsed}#operations`}
          isLoading={isLoading}
          hideButton={canEdit}
          onButtonAction={handleDelete}
        />

        <ErrorStateHandler failed={isFailed}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Card>
                <CardHeader title="Properties"/>
                <Divider/>
                <CardContent>
                  <EditTransferContent
                    toolingId={toolingIdParsed}
                    canEdit={canEdit}
                    reason={transferState.reason}
                    shipperType={transferState.shipperType}
                    shipperName={transferState.shipperName}
                    shipper={transferState.shipper}
                    startLocation={transferState.startLocation}
                    finishLocation={transferState.finishLocation}
                    cost={transferState.cost}
                    currency={transferState.currency}
                    deliveryDate={transferState.deliveryDate}
                    transferTaskId={transferState.transferTaskId}
                    comment={transferState.comment}
                    isLoading={isLoading}
                    isSaving={isSaving}
                    onChangeReason={handleChangeReason}
                    onChangeShipperType={handleChangeShipperType}
                    onChangeShipperName={handleChangeShipperName}
                    onChangeShipper={handleChangeShipper}
                    onChangeStartLocation={handleChangeStartLocation}
                    onChangeFinishLocation={handleChangeFinishLocation}
                    onChangeCost={handleChangeCost}
                    onChangeCurrency={handleChangeCurrency}
                    onChangeDeliveryDate={handleChangeDeliveryDate}
                    onChangeTransferTaskId={handleChangeTask}
                    onChangeComment={handleChangeComment}
                    onSave={handleSave}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <TransferFilesPanel toolingId={toolingIdParsed} transferId={transferIdParsed}/>
            </Grid>
          </Grid>
          <ErrorDialog
            open={transferState.actionError !== undefined}
            title={'Error'}
            text={`Error while saving data. Please try again later or contact the site administrator.\n${transferState.actionError?.message}`}
            onOk={() => dispatch(hideActionError())}
          />
          <YesNoDialog
            open={transferState.showConfirmDeleteDialog}
            title="Delete transfer"
            onNo={() => dispatch(hideConfirmDeleteTooling())}
            onYes={handleConfirmDelete}
          >
            {`Are you sure you want to delete tooling transfer?`}
          </YesNoDialog>
        </ErrorStateHandler>
      </Container>
    </Box>
  )
}

export default EditTransferPage
