import { Fragment, FunctionComponent } from 'react'
import { Skeleton, Stack } from '@mui/material'
import _ from 'lodash'

interface Props extends React.PropsWithChildren {
  isLoading: boolean;
  itemsCount: number;
  itemHeight: number;
}

const ListLoadingStateHandler: FunctionComponent<Props> = (props) => {
  if (props.isLoading) {
    return (
      <Stack direction={'column'} spacing={3} sx={{ margin: 2 }}>
        {_.range(props.itemsCount).map((r) => (
          <Skeleton key={r} variant="rounded" height={props.itemHeight}
                    sx={{ width: '100%' }}/>
        ))}
      </Stack>
    )
  }

  return <Fragment>{props.children}</Fragment>
}

export default ListLoadingStateHandler
