type CallbackFunctionVariadic = (intValue: number | undefined) => void;

export const validateInt = (value: string, callback: CallbackFunctionVariadic) => {
  const validation = /\d+/g;
  if (value === '') {
    callback(undefined);
  } else if (validation.test(value)) {
    const intValue = Number.parseInt(value);
    callback(intValue);
  }
};

export const validateFloat = (value: string, callback: CallbackFunctionVariadic) => {
  const validation = /\d+\.*/g;
  if (value === '') {
    callback(undefined);
  } else if (validation.test(value)) {
    const floadValue = Number.parseFloat(value);
    callback(floadValue);
  }
};
