import { FunctionComponent, useState } from 'react'
import { Button, Container, Box, Grid, Stack } from '@mui/material'
import ErrorBox from '../../../components/ErrorBox'
import { useAppSelector, useAppDispatch } from '../../../store/hooks'
import { selectSetsList, loadSets } from '../../../store/sets/setsListSlice'
import SetsList from './SetsList'
import ListLoadingStateHandler from '../../components/LoadingStateHandler'
import SetPageToolbar from '../SetPageToolbar'
import CreateNewSetDialog from '../CreateNewSetDialog/CreateNewSetDialog'
import { useNavigate } from 'react-router-dom'
import { Permissions_Sets_Edit } from '../../../api/types/permissions'
import { checkPermission } from '../../../store/user/userSlice'
import { setCurrentItem } from '../../../store/sets/currentItemSlice'
import { DownloadReport } from './DownloadReport'
import SetsFilters from './SetsFilters'

const SetsListPage: FunctionComponent = () => {
  const [isOpenCreateSetNewDialog, setIsOpenCreateSetNewDialog] = useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const setsListState = useAppSelector(selectSetsList)

  const isLoading = setsListState.status === 'loading'

  const canEdit = useAppSelector((root) => checkPermission(root, Permissions_Sets_Edit))

  const handleCreateNew = () => {
    setIsOpenCreateSetNewDialog(true)
  }

  if (setsListState.status === 'failed') {
    if (setsListState.error) {
      return (
        <ErrorBox
          code={setsListState.error.name}
          description={setsListState.error.message}
          sx={{ minHeight: '90vh' }}
        >
          <Button variant="contained" onClick={() => loadSets({ page: 0, pageSize: 25 })}>
            Try again
          </Button>
        </ErrorBox>
      )
    } else {
      return (
        <ErrorBox code="500" description="Error while loading data" sx={{ minHeight: '90vh' }}>
          <Button variant="contained" onClick={() => loadSets({ page: 0, pageSize: 25 })}>
            Try again
          </Button>
        </ErrorBox>
      )
    }
  }

  return (
    <Box mt={4} mb={4}>
      <Container maxWidth="lg">
        <SetPageToolbar title={`Tooling sets`}
                        isLoading={false}>
          <Grid item>
            <Stack direction={'row'} spacing={2}>
              {canEdit && <Button
                sx={{ whiteSpace: 'nowrap' }}
                variant="contained"
                disabled={isLoading}
                onClick={handleCreateNew}
                fullWidth>
                Create new set
              </Button>}
              <DownloadReport name={'Sets report'} disabled={isLoading}/>
            </Stack>
          </Grid>
        </SetPageToolbar>
        <SetsFilters/>
        <ListLoadingStateHandler isLoading={isLoading} itemsCount={3} itemHeight={130}>
          <SetsList items={setsListState.items ?? []} count={setsListState.count ?? 0}/>
        </ListLoadingStateHandler>
        <CreateNewSetDialog
          open={isOpenCreateSetNewDialog}
          closeCallBack={setIsOpenCreateSetNewDialog}
          onClose={(set) => {
            if (set) {
              dispatch(setCurrentItem(set))
              navigate(`/sets/${set.toolingSetNumber}`)
            } else {
              setIsOpenCreateSetNewDialog(false)
            }
          }}
        />

      </Container>
    </Box>
  )
}

export default SetsListPage
