import { FunctionComponent } from 'react';
import { MenuItem, Select, Skeleton, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { MaterialDto } from '../../../../api/types/MaterialDto';
import _ from 'lodash';

interface Props {
  isLoading: boolean;
  selectedMaterialId?: number;
  materials: MaterialDto[];
  onChange: (material: MaterialDto | undefined) => void;
  sx?: SxProps<Theme>;
}

const MaterialSelector: FunctionComponent<Props> = (props) => {
  const onChange = (materialId: number | undefined) => {
    const material = props.materials.find((m) => m.materialId === materialId);
    props.onChange(material);
  };

  return props.isLoading ? (
    <Skeleton />
  ) : (
    <Select
      labelId="material-label"
      label="Material"
      value={props.selectedMaterialId || ''}
      onChange={(event) => onChange(event.target.value as number)}
      sx={props.sx}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {_.sortBy(props.materials, (m) => m.name).map((m) => (
        <MenuItem key={m.materialId} value={m.materialId}>
          {m.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default MaterialSelector;
