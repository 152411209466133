import React, { FunctionComponent } from 'react'
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography, Grid, CardHeader, Divider, Card, CardContent,
} from '@mui/material'
import { Clear } from '@mui/icons-material'
import _ from 'lodash'
import GraphAvatar from '../../components/GraphAvatar'
import { useAppDispatch } from '../../store/hooks'
import UsersAutocomplete from '../tooling/view/components/UsersAutocomplete'
import ErrorBox from '../../components/ErrorBox'
import { SetResponsibleDto } from '../../api/types/Sets/SetDto'
import { GraphUser } from '../../api/types/graph/graph'
import { updateSetResponsible, removeResponsible, addResponsible } from '../../store/sets/currentItemSlice'

interface Props {
  setNumber: number,
  responsibles: SetResponsibleDto[];
  editResponsibles: SetResponsibleDto[];
  isLoading: boolean;
  isSaving: boolean;
  isFail: boolean;
  canEdit: boolean;
}

const compareResponsible = (a: SetResponsibleDto, b: SetResponsibleDto) => {
  const nameA = a.displayName.toUpperCase()
  const nameB = b.displayName.toUpperCase()
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
}

const isEqual = (
  a: SetResponsibleDto[],
  b: SetResponsibleDto[]) => {
  if (a && b) {
    const aCopy = [...a]
    const bCopy = [...b]
    return JSON.stringify(aCopy.sort(compareResponsible)) ===
      JSON.stringify(bCopy.sort(compareResponsible))
  }
  return false
}

const SetResponsibles: FunctionComponent<Props> = (props) => {
  const dispatch = useAppDispatch()

  const handleAddUser = (user: GraphUser) => {
    dispatch(addResponsible({ userId: user.id, displayName: user.displayName, userPrincipalName: user.userPrincipalName, mail: user.mail } as SetResponsibleDto))
  }

  const handleSaveChanges = () => {
    dispatch(updateSetResponsible({ setNumber: props.setNumber, responsible: props.editResponsibles.map((u) => u.userId) }))
  }

  return (
    <Box mt={2}>
      <Card>
        <CardHeader title="Responsible"/>
        <Divider/>
        <CardContent>
          {props.canEdit &&
            <UsersAutocomplete alreadySelected={props.responsibles.map((u) => u.userId)} onSelectUnit={handleAddUser}/>}
          {props.isFail && <ErrorBox code="500" description="Error while loading data"/>}
          {!props.isFail &&
            <ResponsibleList disabled={props.isSaving} responsibles={props.editResponsibles} canEdit={props.canEdit}/>}
          <Box sx={{ flexGrow: 1 }}/>

          <Grid item xs={12} container justifyContent="end" spacing={2}>

            {props.canEdit && <Grid item>
              <Button
                variant="contained"
                disabled={props.isLoading || props.isSaving || props.isFail || isEqual(props.responsibles, props.editResponsibles)}
                onClick={() => handleSaveChanges()
                }
              >
                Save changes
              </Button>
            </Grid>}
          </Grid>
        </CardContent>
      </Card>
    </Box>

  )
}

interface ResponsibleListProps {
  responsibles: SetResponsibleDto[];
  disabled?: boolean;
  canEdit?: boolean;
}

const ResponsibleList: FunctionComponent<ResponsibleListProps> = (props) => {
  const dispatch = useAppDispatch()

  const handleRemoveUser = (userId: string) => {
    dispatch(removeResponsible(userId))
  }

  if (props.responsibles.length === 0) {
    return (
      <Typography textAlign={'center'} color={(theme) => theme.palette.text.secondary} sx={{ mt: 1 }}>
        No responsible
      </Typography>
    )
  }

  return (
    <List>
      {_.sortBy(props.responsibles, (u) => u.displayName).map((u) => {
        return (
          <ListItem
            key={u.userId}
            secondaryAction={props.canEdit &&
              <Tooltip title="Remove user">
                <IconButton disabled={props.disabled} onClick={() => handleRemoveUser(u.userId)}>
                  <Clear/>
                </IconButton>
              </Tooltip>
            }
          >
            <ListItemAvatar>
              <GraphAvatar userId={u.userId}/>
            </ListItemAvatar>
            <ListItemText primary={u.displayName ?? ''}/>
          </ListItem>
        )
      })}
    </List>
  )
}

export default SetResponsibles
