import authService from '../auth/AuthService'
import { API_ADDRESS } from './api'
import { msalFetch } from './graph'
import { SearchUnitDto } from './types/UnitData'

const UNITS_API_ADDRESS = `${API_ADDRESS}/api/units`

export class UnitsApi {
  async search(count: number, filter: string, includeBom?: boolean) {
    const result = await authService.getIdentity()
    if (includeBom) {
      return msalFetch()
        .get(`${UNITS_API_ADDRESS}/search?count=${count}&filter=${filter}&includeBom=true`, result.accessToken)
        .then<SearchUnitDto[]>((res) => res.json())
    }
    return msalFetch()
      .get(`${UNITS_API_ADDRESS}/search?count=${count}&filter=${filter}`, result.accessToken)
      .then<SearchUnitDto[]>((res) => res.json())
  }

  async getAnalogs(unit: SearchUnitDto) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .get(`${UNITS_API_ADDRESS}/${unit.unitFileId}/analogs`, result.accessToken)
        .then<SearchUnitDto[]>((res) => res.json())
    }
  }

  async getUnitBom(unitFileId: number) {
    const result = await authService.getIdentity()

    if (result) {
      return msalFetch()
        .get(`${UNITS_API_ADDRESS}/${unitFileId}/bom`, result.accessToken)
        .then<SearchUnitDto[]>((res) => res.json())
    }
  }
}

const unitsApi = new UnitsApi()

export default unitsApi
