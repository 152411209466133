import { createSlice, PayloadAction, SerializedError, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { SetItemDto, SetToolingItemDto, SetResponsibleDto } from '../../api/types/Sets/SetDto'
import { SupplierDto } from '../../api/types/SupplierData'
import setsApi from '../../api/SetsApi'
import unitsToolingApi, { UnitsToolingApi } from '../../api/UnitsToolingApi'

export interface CurrentItemState {
  status: 'idle' | 'saving' | 'loading' | 'failed';
  error?: SerializedError;
  currentItem?: SetItemDto,
  editCurrentItem?: SetItemDto,
  initCurrentItem?: SetItemDto,
}

const initialState: CurrentItemState = {
  status: 'idle',
}

export const loadSet = createAsyncThunk(
  'current-item-slice/loadSet',
  async (payload: { setNumber: number }) => {
    return await setsApi.getSet(payload.setNumber)
  },
)

export const calculateSet = createAsyncThunk(
  'current-item-slice/calculateSet',
  async (payload: { setNumber: number }) => {
    return await setsApi.calculateSet(payload.setNumber)
  },
)

export const deleteSet = createAsyncThunk(
  'current-item-slice/deleteSet',
  async (payload: { setNumber: number }) => {
    return await setsApi.deleteSet(payload.setNumber)
  },
)

export const updateSet = createAsyncThunk(
  'current-item-slice/updateSet',
  async (payload: {
    setNumber: number,
    toolingIds: number[],
    supplierId: number,
    description: string,
    comment: string
  }) => {
    return await setsApi.updateSet(
      payload.setNumber,
      {
        toolingIds: payload.toolingIds,
        supplierId: payload.supplierId,
        description: payload.description,
        comment: payload.comment,
      })
  },
)

export const decommissateSet = createAsyncThunk(
  'current-item-slice/decommissateSet',
  async (payload: {
    setNumber: number
  }) => {
    return await setsApi.decommissateSet(
      payload.setNumber)
  },
)

export const updateSetResponsible = createAsyncThunk(
  'current-item-slice/updateSetResponsible',
  async (payload: {
    setNumber: number,
    responsible: string[],
  }) => {
    return await setsApi.updateSetResponsible(
      payload.setNumber,
      {
        responsible: payload.responsible,
      })
  },
)

export const updateUnitTooling = createAsyncThunk(
  'current-item-slice/updateUnitTooling',
  async (payload: {
    unitFileId: number
  }) => {
    return await unitsToolingApi.getUnitsToolings(payload.unitFileId)
  },
)

export const currentItemSlice = createSlice(
  {
    name: 'sets-current-item',
    initialState,
    reducers: {
      removeResponsible: (state, action: PayloadAction<string>) => {
        if (state.editCurrentItem) {
          state.editCurrentItem.responsibles = state.editCurrentItem
                                                    .responsibles.filter(u => u.userId !== action.payload)
        }
      },
      addResponsible: (state, action: PayloadAction<SetResponsibleDto>) => {
        if (state.editCurrentItem) {
          state.editCurrentItem.responsibles.push(action.payload)
        }
      },

      setCurrentItemStatus: (state, action: PayloadAction<string>) => {
        if (state.editCurrentItem) {
          state.editCurrentItem.status = action.payload
        }
      },
      setCurrentItemComment: (state, action: PayloadAction<string>) => {
        if (state.editCurrentItem) {
          state.editCurrentItem.comment = action.payload
        }
      },
      setCurrentItemDescription: (state, action: PayloadAction<string>) => {
        if (state.editCurrentItem) {
          state.editCurrentItem.description = action.payload
        }
      },
      setCurrentItemSupplier: (
        state, action: PayloadAction<SupplierDto | null>) => {
        if (state.editCurrentItem) {
          state.editCurrentItem.supplier = action.payload
        }
      },
      setCurrentItem: (state, action: PayloadAction<SetItemDto>) => {
        state.currentItem = action.payload
        state.editCurrentItem = action.payload
        state.initCurrentItem = action.payload
      },
      clearCurrentItem: (state) => {
        state.currentItem = undefined
        state.editCurrentItem = undefined
        state.initCurrentItem = undefined
      },
      processToolingCheck: (state, action: PayloadAction<SetToolingItemDto>) => {
        if (state.editCurrentItem) {
          const isExist = state.editCurrentItem.tooling
                               .some(t => t.toolingNumber === action.payload.toolingNumber)
          if (isExist) {
            state.editCurrentItem.tooling =
              state.editCurrentItem.tooling
                   .filter(t => t.toolingNumber !== action.payload.toolingNumber)
          } else {
            state.editCurrentItem.tooling.push(action.payload)
          }
        }
      },
    },
    extraReducers: (build) => {
      build.addCase(loadSet.pending, (state) => {
        state.status = 'loading'
      })

      build.addCase(loadSet.fulfilled, (state, action) => {
        state.status = 'idle'
        state.currentItem = action.payload
        state.editCurrentItem = action.payload
        state.initCurrentItem = action.payload
      })

      build.addCase(loadSet.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error
      })

      build.addCase(updateSet.pending, (state) => {
        state.status = 'saving'
      })

      build.addCase(updateSet.fulfilled, (state, action) => {
        state.currentItem = action.payload
        state.editCurrentItem = action.payload
        state.initCurrentItem = action.payload
        state.status = 'idle'
      })

      build.addCase(updateSet.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error
      })

      build.addCase(updateSetResponsible.pending, (state) => {
        state.status = 'saving'
      })

      build.addCase(updateSetResponsible.fulfilled, (state, action) => {
        state.currentItem = action.payload
        state.editCurrentItem = action.payload
        state.initCurrentItem = action.payload
        state.status = 'idle'
      })

      build.addCase(updateSetResponsible.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error
      })

      build.addCase(calculateSet.pending, (state) => {
        state.status = 'saving'
      })

      build.addCase(calculateSet.fulfilled, (state, action) => {
        state.currentItem = action.payload
        state.editCurrentItem = action.payload
        state.initCurrentItem = action.payload
        state.status = 'idle'
      })

      build.addCase(calculateSet.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error
      })

      build.addCase(deleteSet.pending, (state) => {
        state.status = 'saving'
      })

      build.addCase(deleteSet.fulfilled, (state) => {
        state.status = 'idle'
      })

      build.addCase(deleteSet.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error
      })

      build.addCase(decommissateSet.pending, (state) => {

        state.status = 'saving'
      })

      build.addCase(decommissateSet.fulfilled, (state, action) => {
        state.currentItem = action.payload
        state.editCurrentItem = action.payload
        state.initCurrentItem = action.payload
        state.status = 'idle'
      })

      build.addCase(decommissateSet.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error
      })

      build.addCase(updateUnitTooling.fulfilled, (state, action) => {
        if (action.payload && state.currentItem) {
          const setNumbers = state.currentItem.tooling.map(t => t.toolingNumber)
          action.payload.forEach(t => {
            if (!setNumbers.includes(t.toolingNumber)) {
              state.currentItem?.tooling.push(t)
            }
          })
        }
        state.status = 'idle'
      })
    },
  })

export const {
               addResponsible,
               removeResponsible,
               setCurrentItemStatus,
               processToolingCheck,
               setCurrentItemSupplier,
               setCurrentItemDescription,
               setCurrentItemComment,
               setCurrentItem,
               clearCurrentItem,
             } = currentItemSlice.actions

export const selectCurrentItem = (state: RootState) => state.sets.currentItem

export default currentItemSlice.reducer
