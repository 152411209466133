import { Grid } from '@mui/material'
import { FunctionComponent } from 'react'
import TextEditPropertyItem from '../components/TextEditPropertyItem'

interface Props {
  title: string;
  value: string;
  isLoading: boolean;
  onChange: (value: string) => void;
  disabled?: boolean;
  validate?: (value: string) => boolean;
}

const PropertyGridItem: FunctionComponent<Props> = (props) => {
  const handleChange = (value: string) => {
    props.onChange(value)
  }

  const isError = (): boolean => {
    if (!props.validate) {
      return false
    }
    return props.validate(props.value)
  }

  const helperText = (): string => {
    if (!props.validate) {
      return ''
    }
    return props.validate(props.value) ? `${props.title} cannot be empty` : ''
  }

  return (
    <Grid item xs={12}>
      <TextEditPropertyItem
        caption={props.title}
        value={props.value}
        isLoading={props.isLoading}
        disabled={props.disabled}
        error={isError()}
        helperText={helperText()}
        onChange={handleChange}
      />
    </Grid>
  )
}

export default PropertyGridItem
